import { Fade, Modal } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from './VideoModal.module.scss';

// interface Props {
//   isOpen: boolean;
//   url: String;
//   onClose: () => void;
// }

export const VideoModal = (props) => {
  const { isOpen, url, onClose } = props;

  useEffect(() => {
    if (isOpen) {
      const handleKeyDown = (e) => {
        e.preventDefault();
        if (e.keyCode === 27) {
          onClose();
        }
        if (e.keyCode === 9) {
          let el = document.getElementById('btnClose');
          el.focus();
        }
        if (e.keyCode === 13) {
          document.activeElement.click();
        }
      };
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, onClose]);

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      className={styles.videoFrame}>
      <Fade in={isOpen}>
        <>
          <button onClick={onClose} id='btnClose'>
            <span aria-hidden='true'>&times;</span>
          </button>

          <iframe title='videoAutoPlay' src={url} allow='autoplay' allowFullScreen></iframe>
        </>
      </Fade>
    </Modal>
  );
};
