import { archivesData } from '../../data/archives';
import { Container } from 'react-bootstrap';
import SearchableAccordion from '../SearchableAccordion/SearchableAccordion';

const ArchivesQIModules = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='align-center mb-2'>Archives</h1>
        <h2 className='align-center mb-2'>QI Modules</h2>
      </Container>
      <SearchableAccordion data={archivesData} section={'modules'} />
      <Container className='outerContainer'>
        <hr />
        <p className='subtitle mt-3'>
          These prior versions of the Quality Indicator documentation and software have been
          replaced and are no longer being supported.
        </p>
        <ul>
          <li className='subtitle'>
            Guidance on Using the AHRQ QI for Hospital-Level Comparative Reporting{' '}
            <a href='/Downloads/Modules/QI_Reporting/Model_Report_Appendix_A.pdf'>Appendix A</a>{' '}
            (PDF File, 279 KB) and{' '}
            <a href='/Downloads/Modules/QI_Reporting/Model_Report_Appendix_B.pdf'>Appendix B</a>{' '}
            (PDF File, 98 KB)
          </li>
          <li className='subtitle'>
            List of AHRQ QI Endorsed by the National Quality Forum -{' '}
            <a href='/Downloads/Modules/V44/Module_NQF_Endorsement_V4.4.pdf'>
              Version 4.4, March 2012
            </a>{' '}
            (PDF File, 209 KB)
          </li>
          <li className='subtitle'>
            List of AHRQ QI Endorsed by the National Quality Forum -{' '}
            <a href='/Downloads/Modules/V43/Module_NQF_Endorsement_V4.3.pdf'>
              Version 4.3, August 2011
            </a>{' '}
            (PDF File, 116 KB)
          </li>
        </ul>
      </Container>
    </>
  );
};
export default ArchivesQIModules;
