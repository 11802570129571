import React, { useEffect } from 'react';
import HomeComponent from '../../components/Home/Home';

const Home = () => {
  useEffect(() => {
    document.title = `AHRQ Quality Indicators`;
  });

  return <HomeComponent />;
};

export default Home;
