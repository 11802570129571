export const parseReleaseItems = (version, revision, group, source) => {
  return source.map((item) => {
    if ('filename' in item) {
      const ctaLabel = item.ctaLabel !== undefined ? item.ctaLabel : 'Download';
      const info = `(${item.format} File, ${item.size})`;
      return {
        url: `${item.path}${item.filename}`,
        aria: `Download ${item.filename}`,
        info,
        cta: `${ctaLabel} ${info}`,
        version,
        revision: item.revision ? item.revision : revision,
        group: group !== undefined ? group.toLowerCase() : group,
        ...item,
      };
    }

    return {
      version,
      revision,
      group: group !== undefined ? group.toLowerCase() : group,
      ...item,
    };
  });
};
