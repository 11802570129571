import React, { Fragment, useEffect } from 'react';
import { newsData } from '../../data/news';
import { Container, Tab, Tabs } from 'react-bootstrap';
import styles from '../Measures/Measures.module.scss';
import { Link } from 'react-router-dom';
import EnvelopeRegularBlackIcon from '../../img/envelope-regular-black.png';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const NewsMain = () => {
  useEffect(() => {
    var hashValue = window.location.hash?.replace('#', '');
    if (hashValue) {
      const el = document.getElementById(hashValue);
      if (el) {
        setTimeout(function () {
          requestAnimationFrame(() => el.scrollIntoView());
        });
      }
    }
  });

  return (
    <div className={styles.News}>
      <NormalTopBanner>
        <h1>News</h1>
        <p>
          This page includes announcements related to software updates, Federal Register notices and
          other pertinent updates for AHRQ QI users.
        </p>
      </NormalTopBanner>
      <Container className='outer-container'>
        <Tabs defaultActiveKey={newsData['default-categories']} className={'qiTabs'}>
          {newsData.categories.map((category, index) => (
            <Tab key={index} eventKey={category.category} title={category.title}>
              <Container>
                <div>
                  {newsData.items
                    .filter(
                      (newsItem) =>
                        category.category === newsData['all-categories'] ||
                        newsItem.category.includes(category.category)
                    )
                    .map((newsItem, index) => (
                      <Fragment key={index}>
                        <div>
                          <div id={newsItem.id} className='mb-3'>
                            {newsItem.date ? (
                              <p className='text-lead m-0'>{newsItem.date}</p>
                            ) : null}
                          </div>
                          <div className='text-xl m-0'>
                            {newsItem.url ? (
                              <a
                                href={newsItem.url}
                                target={newsItem.target ? newsItem.target : '_self'}
                                rel='noreferrer'>
                                {newsItem.title}
                                {newsItem.isNew && <span className='new-label'> New!</span>}
                              </a>
                            ) : (
                              <div>
                                {newsItem.title}
                                {newsItem.isNew && <span className='new-label'> New!</span>}
                              </div>
                            )}
                            {newsItem.url && newsItem.isTargetExternal ? (<a href='/ExternalDisclaimer'><img alt='external web link policy' className='ml-1' src='/images/exit_disclaimer.png'/></a>) : ''}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{ __html: newsItem.desc }}
                            className='mt-3'
                          />
                        </div>
                        <hr />
                      </Fragment>
                    ))}
                </div>
              </Container>
            </Tab>
          ))}
        </Tabs>
        <p>
          For news prior to 2020 see the <Link to='/archive/news'>Archive News</Link> page
        </p>
        <div>
          <h3>Email updates</h3>
          <p className='text-regular'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://subscriptions.ahrq.gov/accounts/USAHRQ/subscriber/new?topic_id=USAHRQ_39'>
              Sign up
            </a>{' '}
            for email updates to stay informed about modifications and enhancements to the Quality
            Indicators and other information related to the AHRQ Quality Indicators. By signing up,
            you will be notified by email when AHRQ announces the availability of new Quality
            Indicators, revisions to the software and related tool, as well as publications,
            reports, and other related news.
          </p>
          <div className='flex'>
            <div className=''>
              <img alt='envelope icon' className='envelope pr-2' src={EnvelopeRegularBlackIcon} />
            </div>
            <div className=''>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://subscriptions.ahrq.gov/accounts/USAHRQ/subscriber/new?topic_id=USAHRQ_39'>
                Sign Up: Quality Indicators email updates
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewsMain;
