import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { Link } from 'react-router-dom';

export const AnnouncementBanner = () => {
  const keyName = 'announcement-hide';
  const keyValue = 'mhi-ewg';

  const [showAlert, setShowAlert] = useState(() => sessionStorage.getItem(keyName) !== keyValue);

  const handleAlertClose = () => {
    sessionStorage.setItem(keyName, keyValue);
    setShowAlert(false);
  };

  if (showAlert)
    return (
      <div className='ahrq-alert'>
        <Container>
          <div>
            AHRQ Seeking Members for Maternal Health Indicators Workgroup!{' '}
            <Link to='/announcements/2024/10' aria-label='Announcements'>
              Click here
            </Link>{' '}
            to learn more.
          </div>
          <CloseButton onClick={() => handleAlertClose()} aria-label='warning close' />
        </Container>
      </div>
    );

  return null;
};
