import React from 'react';
import {useHistory} from 'react-router-dom';
import {alpha, Box, styled} from '@mui/material';
import {HeroContent, MeasuresContent, NewsContent} from './Content';
import Grid from '@mui/material/Unstable_Grid2';

export const Tile = ({
  type,
  title,
  description,
  subDescription,
  subtitle,
  date,
  label,
  to,
  toLabel,
  buttonTo,
  buttonLabel,
  promote,
  newTab,
  tag,
  showPsiCloudQi,
  ...rest
}) => {
  let history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (newTab) {
      window.open(to, '_blank');
    } else {
      history.push(to);
    }
  };

  return (
    <TileContainer xs={2} {...rest}>
      <TileBody inverse={type !== 'hero'} promote={promote} onClick={handleClick}>
        {type === 'hero' && (
          <HeroContent title={title} description={description} subDescription={subDescription} to={to} promote={promote} tag={tag}/>
        )}
        {type === 'measures' && (
          <MeasuresContent
            title={title}
            description={description}
            subtitle={subtitle}
            to={to}
            toLabel={toLabel}
            buttonTo={buttonTo}
            buttonLabel={buttonLabel}
            promote={promote}
            showPsiCloudQi={showPsiCloudQi}
          />
        )}
        {type === 'news' && (
          <NewsContent
            title={title}
            description={description}
            date={date}
            label={label}
            to={to}
            promote={promote}
          />
        )}
      </TileBody>
    </TileContainer>
  );
};

const TileContainer = styled(Grid)(() => ({
  position: 'relative',
  display: 'flex',
}));
TileContainer.defaultProps = {
  item: true,
  zeroMinWidth: true,
};

const TileBody = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inverse' && prop !== 'promote',
})(({theme, inverse, promote}) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  backgroundColor: inverse ? alpha(theme.palette.primary.light, 0.05) : theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '0.5rem',
  boxShadow: theme.shadows[4],
  cursor: 'pointer',
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  textAlign: 'left',
  transition: 'box-shadow .3s ease-in-out, background-color .2s',

  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    right: 0,

    display: 'block',
    borderRadius: `0 0 0.5rem 0`,
    width: 30,
    height: 60,
    transition: 'width .2s',

    backgroundImage: `linear-gradient(to bottom right, transparent 50%, ${
      promote ? theme.palette.warning.main : 'rgba(0, 113, 188, 0.25)'
    } 51%)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: '100%',
  },
  '&:after': {
    content: `""`,
    position: 'absolute',
    bottom: 2,
    right: 2,

    display: 'block',
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },

  '&:hover': {
    backgroundColor: inverse ? theme.palette.common.white : theme.palette.grey[50],
    boxShadow: theme.shadows[5],

    '&:before': {
      backgroundImage: `linear-gradient(to bottom right, transparent 50%, ${alpha(
        theme.palette.secondary.main,
        0.9
      )} 51%)`,
      width: 40,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="white" fill-opacity="0.5" d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/></svg>')`,
    },
  },

  '.news-home-card': {
    outline: '4px dashed hotpink',
    outlineOffset: -2,
  },

  '.title': {
    position: 'relative',
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
    zIndex: 1,

    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}));
