import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications
        </h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications Updates - Version v2018 and
          v2018.0.1 (ICD 10-CM/PCS), June 2018
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Prevention Quality Indicators Technical Specifications (PDF Format), Version
              v2018 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PQI_2018_ICD10_techspecs_Provider_pdf.zip'
              href='/Downloads/Modules/PQI/V2018/TechSpecs/PQI_2018_ICD10_techspecs_Provider_pdf.zip'>
              Download All (ZIP File, 11.9 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pqi'} version={'icd10_v2018'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <ul>
          <li>PQI 13 Angina Without Procedure Admission Rate is retired in version 6.0</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Prevention Quality Indicators Technical Specifications for SAS QI and WinQI ICD-10 v7.0
            can be found <Link to='/archive/qi_modules?category=pqi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PQITechnical;
