import {Box, Button, Grid, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Link} from 'react-router-dom';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import {AltButton, Flex, LinkItem, LinkTable, Rule} from '../../ui';

export const MuiUx = () => {
  console.log('');
  console.log('%c MuiUx()', 'color:yellow');

  const theme = useTheme();
  // console.log('%c theme', 'color:yellow', theme);
  // console.log('%c theme.breakpoints', 'color:yellow', theme.breakpoints);
  // console.log('%c theme.components', 'color:yellow', theme.components);
  console.log('%c theme.palette', 'color:yellow', theme.palette);
  // console.log('%c theme.shadows', 'color:yellow', theme.shadows);
  console.log('%c theme.typography', 'color:yellow', theme.typography);

  return (
    <Box m={4}>
      <Rule />

      <Typography variant='h2' gutterBottom mt={2}>
        MUI Theme (In Development)
      </Typography>

      <Box mt={3}>
        <Typography variant='h3' gutterBottom>
          Palette
        </Typography>

        <Grid container mb={3} spacing={4}>
          {/* status */}
          {['primary', 'secondary', 'info', 'success', 'warning', 'error'].map((color) => (
            <Grid key={color} item xs>
              <pre>{color}</pre>
              {['dark', 'main', 'light'].map((tint) => (
                <PaletteColor key={tint} value={`${color}.${tint}`} />
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid container mb={3} spacing={4}>
          {/* common */}
          <Grid item xs>
            <pre>common</pre>
            <PaletteColor value='common.black' />
            <PaletteColor value='common.white' />
          </Grid>
          {/* divider */}
          <Grid item xs>
            <pre>divider</pre>
            <PaletteColor value='divider' />
          </Grid>
          {/* text */}
          <Grid item xs>
            <pre>text</pre>
            {['primary', 'secondary', 'disabled'].map((tint) => (
              <PaletteColor key={tint} value={`text.${tint}`} />
            ))}
          </Grid>
          {/* fill */}
          <Grid item xs>
            <pre>fill</pre>
            <PaletteColor value='fill.blue' />
            <PaletteColor value='fill.lightBlue' />
            <PaletteColor value='fill.grey' />
            <PaletteColor value='fill.lightGrey' />
            <PaletteColor value='fill.purple' />
          </Grid>
          {/* link */}
          <Grid item xs>
            <pre>link</pre>
            <PaletteColor value='link.main' />
            <PaletteColor value='link.hover' />
          </Grid>
          {/* stroke */}
          <Grid item xs>
            <pre>stroke</pre>
            <PaletteColor value='stroke.blue' />
          </Grid>
          {/* primaryButton */}
          <Grid item xs>
            <pre>primaryButton</pre>
            <PaletteColor value='primaryButton.main' />
            <PaletteColor value='primaryButton.hover' />
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={4}>
          {/* grey */}
          <Grid item xs>
            <pre>grey</pre>
            <Grid container>
              {[
                50,
                100,
                200,
                300,
                400,
                500,
                600,
                700,
                800,
                900,
                'A100',
                'A200',
                'A400',
                'A700',
              ].map((tint) => (
                <PaletteColor key={tint} value={`grey.${tint}`} />
                // <Grid
                //   key={tint}
                //   item
                //   p={2}
                //   bgcolor={`grey.${tint}`}
                //   color={theme.palette.getContrastText(theme.palette.grey[tint])}>
                //   {tint}
                // </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* TYPOGRAPHY */}
      <Box mt={3}>
        <Typography variant='h3' gutterBottom>
          Typography
        </Typography>
        {[
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'subtitle1',
          'subtitle2',
          'body1',
          'body2',
          'button',
          // 'caption',
          // 'overline',
        ].map((variant) => (
          <Box key={variant}>
            <Typography variant={variant}>
              {variant}: Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </Typography>
          </Box>
        ))}
        <Box>
          <Link to='/ux'>Link: Lorem ipsum dolor sit amet, consectetur adipisicing elit.</Link>
        </Box>
      </Box>

      {/* BREAKPOINTS */}
      <Box mt={3}>
        <Typography variant='h3' gutterBottom>
          Breakpoints
        </Typography>
        {['xs', 'sm', 'md', 'lg', 'xl'].map((size) => (
          <Box key={size}>
            <pre>
              {size}: {theme.breakpoints.values[size]}px
            </pre>
          </Box>
        ))}
      </Box>

      {/* DIVIDERS */}
      <Box mt={3}>
        <Typography variant='h3' gutterBottom>
          Dividers
        </Typography>

        <Box>
          <pre>&lt;Rule /&gt;</pre>
          <Rule />
          <pre>&lt;Rule spacing=4 /&gt;</pre>
          <Rule spacing={4} />
          <pre>&lt;Rule dashed /&gt;</pre>
          <Rule dashed />
          <pre>&lt;Rule dotted /&gt;</pre>
          <Rule dotted />
        </Box>
      </Box>

      <Box mt={3}>
        <Typography variant='h3' gutterBottom>
          Buttons
        </Typography>
        {['contained', 'outlined', 'text'].map((variant) => (
          <Box key={variant} mt={3}>
            <pre>variant: {variant}</pre>
            <Grid container spacing={1} border={1} borderColor='divider' p={2}>
              {[
                'primary',
                'secondary',
                'info',
                'success',
                'warning',
                'error',
                'inherit',
                'white',
              ].map((color) => (
                <Grid
                  key={color}
                  item
                  xs
                  bgcolor={color === 'white' ? 'gray' : 'transparent'}
                  color={color === 'white' ? 'white' : 'inherit'}>
                  <pre>color: {color}</pre>
                  {['small', 'medium', 'large'].map((size) => (
                    <Box key={size}>
                      <pre>size: {size}</pre>
                      <ButtonSet>
                        <Button
                          variant={variant}
                          color={color}
                          size={size}
                          endIcon={<ChevronRightIcon />}>
                          Button Label
                        </Button>
                        <Button
                          variant={variant}
                          color={color}
                          size={size}
                          disabled
                          startIcon={<DoNotDisturbOnIcon />}>
                          Disabled
                        </Button>
                      </ButtonSet>
                    </Box>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
        <Box mt={3}>
          <pre>custom</pre>
          <Grid container spacing={1} border={1} borderColor='divider' p={2}>
            {[
              'primary',
              'secondary',
              'info',
              // 'success',
              // 'warning',
              // 'error',
              // 'inherit',
              // 'white',
            ].map((color) => (
              <Grid
                key={color}
                item
                xs
                bgcolor={color === 'white' ? 'gray' : 'transparent'}
                color={color === 'white' ? 'white' : 'inherit'}>
                <pre>component: AltButton</pre>
                <pre>color: {color}</pre>
                {['small', 'medium', 'large'].map((size) => (
                  <Box key={size}>
                    <pre>size: {size}</pre>
                    <ButtonSet>
                      <AltButton
                        label='Button Label'
                        color={color}
                        size={size}
                        endIcon={<ChevronRightIcon />}
                      />
                      <AltButton
                        label='Disabled'
                        color={color}
                        size={size}
                        disabled
                        startIcon={<DoNotDisturbOnIcon />}
                      />
                    </ButtonSet>
                  </Box>
                ))}
                {['small', 'medium', 'large'].map((size) => (
                  <Box key={size}>
                    <pre>size: {size} w/ prop: inverse</pre>
                    <ButtonSet>
                      <AltButton
                        label='Button Label'
                        color={color}
                        size={size}
                        endIcon={<ChevronRightIcon />}
                        inverse
                      />
                      <AltButton
                        label='Disabled'
                        color={color}
                        size={size}
                        disabled
                        startIcon={<DoNotDisturbOnIcon />}
                        inverse
                      />
                    </ButtonSet>
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* LINK_ITEM */}
      <Box mt={3}>
        <Typography variant='h3' gutterBottom>
          LinkItem
        </Typography>

        <Flex gap={4}>
          <Box>
            <pre>
              &lt;LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)'&gt;Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt
            </LinkItem>
          </Box>
          <Box>
            <pre>
              &lt;LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' rounded&gt;Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' rounded>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt
            </LinkItem>
          </Box>
          <Box>
            <pre>
              &lt;LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' light&gt;Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' light>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt
            </LinkItem>
          </Box>

          <Box>
            <pre>
              &lt;LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' noborder&gt;Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' noborder>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt
            </LinkItem>
          </Box>

          <Box>
            <pre>
              &lt;LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' leftBorder&gt;Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' leftBorder>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt
            </LinkItem>
          </Box>

          <Box>
            <pre>
              &lt;LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' noicon&gt;Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' noicon>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt
            </LinkItem>
          </Box>

          <Box>
            <pre>
              &lt;LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' noicon wrap&gt;Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat.&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/file.pdf' meta='(PDF File, 1.23 MB)' noicon wrap>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </LinkItem>
          </Box>

          <Box>
            <pre>
              &lt;LinkItem href='/path/page'&gt;Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt&lt;/LinkItem&gt;
            </pre>
            <LinkItem href='/path/page'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt
            </LinkItem>
          </Box>

          <Box>
            <pre>
              &lt;LinkTable&gt; &lt;tbody&gt; &lt;tr&gt; &lt;td&gt; &lt;LinkItem
              href='/path/file.pdf'&gt; Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididunt &lt;/LinkItem&gt; &lt;/td&gt; &lt;/tr&gt; &lt;tr&gt;
              &lt;td&gt; &lt;LinkItem href='/path/file.pdf'&gt; Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt &lt;/LinkItem&gt;
              &lt;/td&gt; &lt;/tr&gt; &lt;tr&gt; &lt;td&gt; &lt;LinkItem href='/path/file.pdf'&gt;
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt &lt;/LinkItem&gt; &lt;/td&gt; &lt;/tr&gt; &lt;/tbody&gt; &lt;/LinkTable&gt;
            </pre>
            <LinkTable>
              <tbody>
                <tr>
                  <td>
                    <LinkItem href='/path/file.pdf'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt
                    </LinkItem>
                  </td>
                </tr>
                <tr>
                  <td>
                    <LinkItem href='/path/file.pdf'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt
                    </LinkItem>
                  </td>
                </tr>
                <tr>
                  <td>
                    <LinkItem href='/path/file.pdf'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt
                    </LinkItem>
                  </td>
                </tr>
              </tbody>
            </LinkTable>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

const ButtonSet = styled(Box)(({theme}) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  'button+button': {
    marginLeft: theme.spacing(2),
  },
}));

const Value = styled('pre')(({theme}) => ({
  margin: 0,
  opacity: 0.5,
}));

export const PaletteColor = ({value, fullLabel, ...rest}) => {
  const theme = useTheme();
  const base = value.split('.')[0];
  const mod = value.split('.')[1];
  const result = mod ? theme.palette[base][mod] : theme.palette[base];

  if (result) {
    return (
      <Box {...rest} p={2} bgcolor={result} color={theme.palette.getContrastText(result)}>
        {fullLabel ? value : mod ? mod : base}
        <Value>{result}</Value>
      </Box>
    );
  }

  return (
    <Box p={2} bgcolor='error.light' color='error.dark'>
      NOT_FOUND: {value}
    </Box>
  );
};
