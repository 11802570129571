import { getReleaseItem } from '../releases';

export const iqiMeasuresData = {
  technical: [
    {
      ...getReleaseItem('IndividualMeasureTechnicalSpecifications', 'IQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('ParameterEstimates', 'IQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('BenchmarkDataTables', 'IQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('LogOfCodingUpdates', 'IQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('CompositeMeasures', 'IQI'),
      status: 'new',
    },
  ],
  additional: [
    {
      title: 'AHRQ Composite Measures Workgroup',
      desc: "<p>This report summarizes the work of the IQI Composite Measure Workgroup, which assisted AHRQ in developing a composite measure based on the Inpatient Quality Indicators. For more information on Composite Workgroups, visit the <a href='../measures/composite_workgroup'>AHRQ QI Composite Workgroups page</a>.</p>",
      url: '/Downloads/Modules/IQI/IQI_Composite_Development.pdf',
      aria: 'Download IQI_Composite_Development.pdf',
      cta: 'Download (PDF File, 760 KB)',
    },
    {
      title: 'AHRQ IQI Development',
      desc: '<p>How the IQI measure set was developed.</p>',
      url: '/Downloads/Modules/IQI/iqi_development.zip',
      aria: 'Download iqi_development.zip',
      cta: 'Download (ZIP File, 771 KB)',
    },
    {
      title: 'Retirement of the Area-Level Inpatient Quality Indicators (IQIs)',
      desc: '<p>Starting with V7.0 ICD-10-CM/PCS, all versions of the QI software will not include any area-level IQIs. For additional information, see the retirement announcement.</p>',
      url: '/News/IQI_Area_Level_Retirement_Notice.pdf',
      aria: 'Download IQI_Area_Level_Retirement_Notice.pdf',
      cta: 'Download (PDF File, 342 KB)',
    },
  ],
  // specifications: [
  //   {
  //     title: 'IQI 08 Esophageal Resection Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
  //     aria: 'Download IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 09 Pancreatic Resection Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
  //     aria: 'Download IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
  //     aria: 'Download IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
  //     aria: 'Download IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
  //     aria: 'Download IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 16 Heart Failure Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
  //     aria: 'Download IQI_16_Heart_Failure_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 17 Acute Stroke Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
  //     aria: 'Download IQI_17_Acute_Stroke_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
  //     aria: 'Download IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 19 Hip Fracture Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
  //     aria: 'Download IQI_19_Hip_Fracture_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 20 Pneumonia Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
  //     aria: 'Download IQI_20_Pneumonia_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
  //     aria: 'Download IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
  //     aria: 'Download IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
  //     aria: 'Download IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 31 Carotid Endarterectomy Mortality Rate',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
  //     aria: 'Download IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
  //     aria: 'Download IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 90 Mortality for Selected Inpatient Procedures',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI%2090%20Mortality%20for%20Selected%20Inpatient%20Procedures.pdf',
  //     aria: 'Download IQI 90 Mortality for Selected Inpatient Procedures.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'IQI 91 Mortality for Selected Inpatient Conditions',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI%2091%20Mortality%20for%20Selected%20Inpatient%20Conditions.pdf',
  //     aria: 'Download IQI 91 Mortality for Selected Inpatient Conditions.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title:
  //       'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
  //     url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_Appendix_A.pdf',
  //     cta: 'Download PDF',
  //     aria: 'Download IQI_Appendix_A.pdf',
  //   },
  // ],
  // retired: [
  //   {
  //     desc: "<p>Effective v2021, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='/News/AHRQ%20QI%20v2021%20Retirement%20Announcement.pdf'>Indicators Retirement Announcement</a>.</p>",
  //     title: [
  //       {
  //         titledesc:
  //           'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, without Transfer Cases',
  //       },
  //       {
  //         titledesc: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate',
  //       },
  //     ],
  //   },
  //   {
  //     desc: "<p>Effective v2019, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='//News/Retirement%20Notice_v2019_Indicators.pdf'>Indicators Retirement Announcement</a>.</p>",
  //     title: [
  //       {
  //         titledesc: 'IQI 01 Esophageal Resection Volume',
  //       },
  //       {
  //         titledesc: 'IQI 02 Pancreatic Resection Volume',
  //       },
  //       {
  //         titledesc: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
  //       },
  //       {
  //         titledesc: 'IQI 05 Coronary Artery Bypass Graft (CABG)',
  //       },
  //       {
  //         titledesc: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
  //       },
  //       {
  //         titledesc: 'IQI 07 Carotid Endarterectomy Volume',
  //       },
  //       {
  //         titledesc: 'IQI 13 Craniotomy Mortality Rate',
  //       },
  //       {
  //         titledesc: 'IQI 14 Hip Replacement Mortality Rate',
  //       },
  //     ],
  //   },
  //   {
  //     desc: '<p>Effective v7.0, the following indicators have been retired. For additional information, refer to the retirement announcements: IQI Area Level Indicators , IQI Hospital Level Indicators.</p>',
  //     title: [
  //       {
  //         titledesc: 'IQI 23 Laparoscopic Cholecystectomy Rate',
  //       },
  //       {
  //         titledesc: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
  //       },
  //       {
  //         titledesc: 'IQI 25 Bilateral Cardiac Catheterization Rate',
  //       },
  //       {
  //         titledesc: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
  //       },
  //       {
  //         titledesc: 'IQI 27 Percutaneous Coronary Intervention (PCI) Rate',
  //       },
  //       {
  //         titledesc: 'IQI 28 Hysterectomy Rate',
  //       },
  //       {
  //         titledesc: 'IQI 29 Laminectomy or Spinal Fusion Rate',
  //       },
  //     ],
  //   },
  // ],
};
