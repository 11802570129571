const CLOUDQI_ITEMS = [
  {
    name: 'CloudQi',
    title: 'CloudQI PSI Beta v2023.0.1',
    filename: 'cloudqi_1.0.1_x64_Setup.zip',
    path: '/Downloads/Software/CloudQI_PSI_Beta/',
    size: '103 MB',
    format: 'ZIP',
    ctaLabel: 'Download CloudQI PSI Beta v2023.0.1',
    revision: 'December 2023',
    dateModified: '2023-12-21',
    version: 'v2023.0.1',
  },
  {
    name: 'CloudQi',
    title: 'CloudQI PSI Beta v2023.0.0',
    filename: 'cloudqi_1.0.0_x64_Setup.zip',
    path: '/Downloads/Software/CloudQI_PSI_Beta/',
    size: '103 MB',
    format: 'ZIP',
    ctaLabel: 'Download CloudQI PSI Beta v2023.0.0',
    revision: 'August 2023',
    dateModified: '2023-08-11',
    version: 'v2023.0.0',
  },
  {
    name: 'PsiInfoSheet',
    title: 'CloudQI PSI Beta Software Information Sheet',
    desc: 'A beta software for the PSI module that supports multiple versions. This software can be easily installed on desktops or local cloud environments, allowing seamless remote access via any web browser. It is the perfect solution for PSI users seeking convenient remote accessibility.',
    filename: 'CloudQI PSI Beta software Information Sheet.pdf',
    path: '/Downloads/Software/CloudQI_PSI_Beta/',
    size: '179 KB',
    format: 'PDF',
    dateModified: '2023-09-12',
  },
  {
    name: 'CloudQIReleaseNotes',
    title: 'CloudQI v2023.0.1 Release Notes',
    filename: 'AHRQ_CloudQI_PSI_Beta_v2023.0.1_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/CloudQI_PSI_Beta/',
    size: '189 KB',
    format: 'PDF',
    ctaLabel: 'Download v2023.0.1 release notes',
    revision: 'December 2023',
    dateModified: '2023-12-18',
    version: 'v2023.0.1',
  },
];

export default CLOUDQI_ITEMS;
