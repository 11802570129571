import React from 'react';
import { Table } from 'react-bootstrap';
import { Button } from '../../../ui';

const CodeLogTable = (props) => {
  const { items } = props;

  return (
    <div key={`code-log-div`}>
      <Table className='align-justify' striped>
        <tbody>
          {items.map((info, index) => (
            <tr key={`code-log-tr-${index}`}>
              <td className='px-3 py-6'>
                <h3 className='align-left'>{info.title}</h3>
                <p className='text-regular'>{info.desc}</p>
                <Button ariaLabel={info.aria} href={info.url} target="_blank">
                  {info.cta}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CodeLogTable;
