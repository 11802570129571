import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const IQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='align-center mb-9'>Inpatient Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Inpatient Quality Indicators Technical Specifications, Version 4.5, May 2013
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              All Inpatient Quality Indicators Technical Specifications, Version 4.5 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download IQI_Technical_Specifications_Version45.zip'
              href='/Downloads/Modules/IQI/V45/TechSpecs/IQI_Technical_Specifications_Version45.zip'
            >
              Download All (ZIP File, 6.9 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'iqi'} version={'v45'} />
      </Container>
    </>
  );
};

export default IQITechnical;
