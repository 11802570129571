import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PDITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Pediatric Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Pediatric Quality Indicators Technical Specifications Updates - Version v2019 (ICD
          10-CM/PCS), July 2019
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Pediatric Quality Indicators Technical Specifications (PDF Format), Version
              v2019 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PDI_2019_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/PDI/V2019/TechSpecs/PDI_2019_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 13.7 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pdi'} version={'icd10_v2019'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          <span className='danger'>Effective v2019</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/Retirement%20Notice_v2019_Indicators.pdf'>
            Indicators Retirement Announcement.
          </a>{' '}
          (PDF File, 108 KB)
        </p>
        <ul>
          <li>NQI 01 Neonatal Iatrogenic Pneumothorax Rate</li>
          <li>PDI 02 Pressure Ulcer Rate</li>
          <li>PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count</li>
          <li>PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate</li>
          <li>PDI 07 RACHS-1 Pediatric Heart Surgery Volume</li>
          <li>PDI 11 Postoperative Wound Dehiscence Rate</li>
          <li>PDI 13 Transfusion Reaction Count</li>
          <li>PDI 17 Perforated Appendix Admission Rate</li>
          <li>PDI 19 Pediatric Safety for Selected Indicators Composite</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Pediatric Quality Indicators Technical Specifications for SAS and WinQI ICD-10 version
            v2018 can be found <Link to='/archive/qi_modules?category=pdi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PDITechnical;
