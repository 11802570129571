import React from 'react';
import { Container, Table } from 'react-bootstrap';

const SoftwareICDPlanning = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-4'>ICD-10 Software Release Plan</h1>
        <p>
          The Agency for Healthcare Research and Quality (AHRQ) will release the ICD-10 compliant
          versions of SAS QI v6.0 and WinQI v6.0 software, plus supporting technical documentation
          in late spring of 2016. The SAS QI v6.0 and WinQI v6.0 software will enable organizations
          to apply the AHRQ Quality Indicators (QIs) to their own inpatient hospital administrative
          data to inform quality improvement efforts in acute care settings.
        </p>
        <p>
          This free SAS or Windows-based software can be used to calculate results for the AHRQ QIs,
          including: Prevention Quality Indicators in Inpatient Settings (PQI), Inpatient Quality
          Indicators (IQI), Patient Safety Indicators (PSI), and Pediatric Quality Indicators (PDI).
        </p>
        <p>
          As part of the ICD-10 software release process, AHRQ will facilitate alpha and beta
          testing opportunities for a select number of hospitals. Hospitals currently dual coding in
          ICD-9 and ICD-10 will be able to test the AHRQ QI Hospital Toolkit or SAS QI Software.
        </p>
      </Container>
      <Container className='outerContainer'>
        <h2 className='mb-4'>
          1. SAS QI v6.0 and WinQI v6.0 will be released in late spring 2016! Noteworthy upgrades
          include:
        </h2>
        <ul>
          <li>
            Support for ICD-10 coding in SAS QI and WinQI (observed rates only; no risk adjustment
            supported for ICD-10 in v6.0 software).
          </li>
          <li>Separate code packages for the ICD-9 version of SAS QI v6.0.</li>
          <li>WinQI v6.0 installer to contain both ICD-9 and ICD-10 applications</li>
          <li>Specification changes to reflect ICD-10 coding</li>
          <li>Support for Quarter 4, 2015 hospital data</li>
        </ul>
        <p>
          When the SAS QI v6.0 and WinQI v6.0 are released, the software will be posted on the AHRQ
          Quality Indicators website and anyone who has signed up for the AHRQ QI email list serve
          will receive an email with a link to the software. Complete information about all of the
          changes in the software will be included in the Release Notes that will be published when
          the software is released.
        </p>
      </Container>
      <Container className='outerContainer'>
        <h2 className='mb-4'>
          2. Opportunity for hospitals interested in alpha testing the ICD-10 AHRQ QI Hospital
          Toolkit:
        </h2>
        <p>
          AHRQ is seeking 3-5 hospitals to provide feedback on an updated version of the AHRQ
          Quality Indicators™ Toolkit for Hospitals that incorporates the upcoming conversion to
          ICD-10-CM. We are partnering with the RAND Corporation to assist us in reaching out to
          hospitals like yours that can provide unique insights into the toolkit. Specifically, the
          Agency is seeking only those hospitals that meet the following criteria:
        </p>
        <ol>
          <li>Have experience using the AHRQ QI Toolkit in the past;</li>
          <li>Currently use AHRQ’s SAS QI® software to generate QI rates; and</li>
          <li>
            Ideally use the pediatric quality indicators (PDIs), either alone or in addition to the
            patient safety indicators (PSIs).
          </li>
        </ol>
        <p>
          The project would require you to use the toolkit, generate QI rates for your hospital
          using the alpha v6.0 software, and participate in two telephone meetings that will involve
          a discussion of your experiences using the revised ICD-10 QI Toolkit. The project is
          expected to take place between November 2015 and March 2016. A small honorarium will be
          offered for your participation. Your feedback will help improve the experience for other
          hospitals that use the toolkit in the future.
        </p>
        <p>
          If you are interested in participating or have any questions about the project, please
          contact Ms. Courtney Armstrong at the RAND Corporation at{' '}
          <a href='mailto: icd10@rand.org'>icd10@rand.org</a>. We look forward to hearing from you
          by Friday, October 16. Please note that the remaining spots on the project may fill
          quickly.
        </p>
      </Container>
      <Container className='outerContainer'>
        <h2 className='mb-4'>
          3.Opportunity for hospitals interested in participating in ICD-10 SAS QI v6.0 and ICD-10
          WinQI v6.0 software alpha and beta testing:
        </h2>
        <p>
          The alpha and beta testing activities will provide organizations an opportunity to use
          their ICD-10 hospital discharge data with the ICD-10 SAS QI v6.0 and WinQI v6.0 alpha
          software to produce AHRQ QI rates and report any issues encountered during their testing.
          AHRQ will provide a timeline for receiving feedback. The comments received during this
          period will either be incorporated in the final production release of ICD-10 SAS QI v6.0
          and WinQI v6.0 software or may be considered for future improvements.
        </p>
        <p className='bold'>
          What high level criteria will be used in selecting hospitals for alpha and beta testing?
        </p>
        <ol>
          <li>
            Five hospitals (two large hospitals, two medium and one small rural) will be selected to
            test the SAS QI v6.0 and WinQI v6.0 software.
          </li>
          <li>Hospitals engaged in dual coding are preferred.</li>
          <li>Hospitals that provide general acute care services are preferred.</li>
          <li>
            Hospitals that are coding directly from medical documentation are preferred, rather than
            hospitals coding using a software conversion tool such as MapIt.
          </li>
          <li>
            Large hospital serving the pediatric population (not a pediatric hospital) are preferred
            for testing the PDI module.
          </li>
        </ol>
        <p className='bold'>What needs to be tested?</p>
        <ol>
          <p className='bold'>ICD-10 SAS QI v6.0</p>
          <li>
            Test the functionality of the ICD-10 SAS v6.0 alpha software. Error and warning logs
            should be shared with AHRQ.
          </li>
          <li>
            Hospitals with dual coded data will run the ICD-9 SAS QI v6.0 and the ICD-10 SAS QI v6.0
            to compare indicators rates and provide AHRQ the report. Note: we expect that there will
            be differences in the rates produced by the ICD-9 and the ICD-10 versions.
          </li>
          <li>
            The the performance by recording the time it takes to import data and generate rates.
          </li>
          <li>
            Test that the ICD-10 indicator rates are in line with hospital’s historical rate for the
            indicators and report findings.
          </li>
          <li>
            Following the alpha testing round, participate in beta testing for the ICD-10 SAS v6.0
            production version to test and validate that the issues reported during alpha testing
            have been fixed.
          </li>
        </ol>
        <ol>
          <p className='bold'>ICD-10 WinQI v6.0</p>
          <li>
            Test ICD-10 data import process, indicator rates generation, and reporting features of
            the ICD-10 WinQI v6.0 alpha beta software.
          </li>
          <li>Performance testing – record the time it takes to import data and generate rates.</li>
          <li>
            Test that the ICD-10 indicator rates are in line with hospital’s historical rate for the
            indicators and report findings.
          </li>
          <li>
            Following the alpha testing round, participate in beta testing for ICD-10 WinQI v6.0
            production version to test and validate that the issues reported are fixed.
          </li>
        </ol>
        <p className='bold'>What is not tested?</p>
        <ol>
          <li>Alpha and beta testing results should not be used to report final rates.</li>
          <li>
            PSI output is not representative of the rates used by CMS for FY 2016 reporting. In
            particular, this alpha and beta testing is not to be used for testing PSI 90 rates.
          </li>
          <li>
            This testing is not meant to compare ICD-10 SAS QI results with any prior WinQI results.
          </li>
          <li>
            This is a test of the software and not meant to test the clinical validity of any of the
            AHRQ QI technical specifications.
          </li>
        </ol>
        <p className='bold'>Documents and Resources to be Provided:</p>
        <ol>
          <li>ICD-10 SAS QI v6.0 and WinQI v6.0 alpha and beta versions of software.</li>
          <li>Software instructions.</li>
          <li>Individual measure Technical Specifications (ICD-10 compliant).</li>
          <li>Production Change Log spreadsheet</li>
        </ol>
        <p className='bold'>Timelines for Testing and Feedback:</p>
        <ol>
          <p className='bold'>ICD-10 SAS QI v6.0</p>
          <li>Round 1 alpha testing package to be sent on 12/01/2015.</li>
          <li>Round 1 alpha testing to be completed and reported back to AHRQ by 12/15/2015.</li>
          <li>Round 2 beta testing to validate the fixes beginning 2/22/2016.</li>
          <li>Round 2 beta testing results back to AHRQ by 3/4/2016.</li>
        </ol>
        <ol>
          <p className='bold'>ICD-10 WinQI v6.0</p>
          <li>Round 1 alpha testing package to be sent in December 2015 or early January 2016</li>
          <li>Round 1 alpha testing to be completed and reported back to AHRQ in January 2016</li>
          <li>Round 2 beta testing to validate the fixes beginning 3/28/2016</li>
          <li>Round 2 beta testing results back to AHRQ by 4/8/2016</li>
        </ol>
        <h2 className='mb-4'>How can an organization participate?</h2>
        <p>
          Interested organizations that meet the criteria listed above should contact the{' '}
          <a href='mailto: QIsupport@ahrq.hhs.gov'>QI Support</a> mailbox by November 2nd with the
          subject line: SAS QI Beta Testing.
        </p>
      </Container>
      <Container className='outerContainer'>
        <h2 className='mb-4'>4. AHRQ QI Current and Future Resources</h2>
        <p>
          The AHRQ QI website has been updated with ICD-10 related materials to help organizations
          prepare for conversion to the use of ICD-10 codes. The AHRQ QI team will continue to
          publish updated materials as they become available. The table below lists the software and
          documentation that is or will be available on the AHRQ QI website:
        </p>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td />
              <th>SAS QI</th>
              <th>WinQI</th>
              <th>Documentation</th>
              <th>Release Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <a href='/software/sas_qi'>QI Version 5.0</a>
                <p>(supports ICD 9 and no support for ICD 10 coding)</p>
              </td>
              <td>
                <a href='/software/win_qi'>WinQI Version 5.0</a>
                <p>(supports ICD 9 and no support for ICD 10 coding)</p>
              </td>
              <td>
                <p>Technical Specification</p>
                <a href='/measures/PQI_TechSpec'>PQI 5.0 version</a>
                <a href='/measures/IQI_TechSpec'>IQI 5.0 version</a>
                <a href='/measures/PSI_TechSpec'>PSI 5.0 version</a>
                <a href='/measures/PDI_TechSpec'>PDI 5.0 version</a>
              </td>
              <td>
                <p>March 2015</p>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>SAS QI ICD-10 Software (Alpha version), Version 4.5a</td>
              <td>N/A</td>
              <td>Technical Specification ICD-10 version 4.5a (All Modules)</td>
              <td>
                <p>March 2015</p>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>SAS QI ICD 10 v5.0 was not developed and is not available for download</td>
              <td>WinQI ICD 10 v5.0 was not developed and is not available for download</td>
              <td>
                <p>
                  ICD-10 technical specifications for the 5.0 version of the software will be
                  released for organizations that want to get familiar with ICD-10 coding.
                </p>{' '}
                <p className='italic-footnote'>
                  Note: there is no accompanying software released with the documentation
                </p>
              </td>
              <td>
                <p>
                  The technical specifications documents will be released in the following order:
                </p>

                <ol>
                  <li>
                    <a href='/measures/PSI_TechSpec'>Provider level PSI Module</a>
                  </li>

                  <li>Remainder of the modules and indictors (Coming soon - December 2016)</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>SAS QI ICD-10 software and technical specifications v6.0</td>
              <td>WinQI ICD-10 software and technical specifications v6.0</td>
              <td>Spring 2016</td>
              <td>Spring 2016</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default SoftwareICDPlanning;
