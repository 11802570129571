import React, { useRef } from 'react';
import { Box, styled } from '@mui/material';
import { PageDisclaimer, PageHeader, PageRow } from '../../../ui';
import { releaseVersion } from '../../../data/releases';
import CapitolIcon from '../../../img/large-icon-capitol.png';
import CardPaymentIcon from '../../../img/large-icon-card-payment.png';
import HospitalIcon from '../../../img/large-icon-hospital.png';

export const PQEResourcesHowTo = () => {
  const populationHealthImprovementRef = useRef(null);
  const publicReportingRef = useRef(null);
  const researchRef = useRef(null);

  const measure = {
    title: 'Prevention Quality Indicator in Emergency Department Settings',
    titlePlural: 'Prevention Quality Indicators in Emergency Department Settings',
    abbrev: 'PQE',
  };

  return (
    <>
      <PageHeader.Row bgSrc='/images/wavy-grey.jpg' sx={{ paddingBottom: '6rem' }}>
        <PageHeader.Title>How to Use {measure.abbrev} Measures</PageHeader.Title>
        <PageHeader.Subtitle>
          {measure.titlePlural} ({measure.abbrev}) Use Cases
        </PageHeader.Subtitle>
      </PageHeader.Row>

      <PageRow
        sx={{
          paddingTop: '3.75rem',
          borderBottom: '1px solid',
          borderColor: (t) => t.palette.divider,
        }}
      >
        <h2 className='align-center'>Introduction to the {measure.abbrev} Module</h2>

        <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={4} mt={5}>
          <Box>
            <p>
              The Prevention Quality Indicators in Emergency Department Settings (PQEs) are measures of visits to the emergency department (ED) 
              (treat and release ED visits and inpatient admissions through the ED) that may be associated with a lack of access to quality care in other settings. 
              They may reflect availability of community health resources (e.g., medical care, dental care) or disease burden or both. 
              It should be noted that the PQEs do NOT reflect the quality of care provided in the ED. {' '}
              
            </p>

            <p>
              PQEs are reported as ED visit rates.{' '}
              <Emphasize>
                Individuals included span from infants through older adults, depending on the
                measure.
              </Emphasize>
            </p>

            <p>
              Like the other area-level QIs, such as Prevention Quality Indicators in Inpatient Settings, 
              they include "avoidable use" measures that identify conditions
              for which access to quality ambulatory care can reduce the likelihood of hospital
              care.
            </p>

            <p>
              PQEs are part of the set of area-level QIs that includes the inpatient Prevention
              Quality Indicators (PQIs) and inpatient Pediatric Quality Indicators (PDIs).{' '}
              <Emphasize>
                Area-level indicators capture (all) cases of the event that occur in a given
                population (e.g., metropolitan area or county), rather than at the hospital-level.
              </Emphasize>
            </p>
          </Box>
          <Box minWidth='33%' borderTop='5px solid #691F74' bgcolor='fill.grey' p={2.5}>
            <h5>There are five Prevention Quality Indicators in Emergency Department Settings (PQEs):</h5>
            <ul>
              <li>
                <strong>PQE 01</strong> Visits for Non-Traumatic Dental Conditions in ED
              </li>
              <li>
                <strong>PQE 02</strong> Visits for Chronic Ambulatory Care Sensitive Conditions in ED
              </li>
              <li>
                <strong>PQE 03</strong> Visits for Acute Ambulatory Care Sensitive Conditions in ED
              </li>
              <li>
                <strong>PQE 04</strong> Visits for Asthma in ED
              </li>
              <li>
                <strong>PQE 05</strong> Visits for Back Pain in ED
              </li>
            </ul>
          </Box>
        </Box>
      </PageRow>

      <PageRow lightBlue>
        <UseCasesHeader>Use Cases</UseCasesHeader>

        <UseCaseNav>
          <li onClick={() => populationHealthImprovementRef.current.scrollIntoView()}>
            Population Health Improvement
          </li>
          <li className='dot'>&middot;</li>
          <li onClick={() => researchRef.current.scrollIntoView()}>Research</li>
          <li className='dot'>&middot;</li>
          <li onClick={() => publicReportingRef.current.scrollIntoView()}>Public Reporting</li>
        </UseCaseNav>

        <UseCaseBox ref={populationHealthImprovementRef}>
          <UseCaseTitle>Population Health Improvement</UseCaseTitle>
          <p>
            As area-level indicators, the PQEs provide users with an opportunity to review healthcare utilization
            by specific populations within geographic areas (i.e., within a metropolitan area or a
            county). There are several different groups that could use the indicators in this way:
          </p>
          <GroupItem>
            <div className='icon-col'>
              <img src={CapitolIcon} alt='Capitol icon' height='70' width='70' />
            </div>
            <div className='content-col'>
              <h5>State & Local Health Agencies</h5>
              <ul>
                <li>
                  Indicators can be used to evaluate/understand potential resource needs and access
                  to health care within communities (e.g., use of PQE 01 to evaluate access to
                  preventive dental care) and can also assess the quality of those services.
                </li>
                <li>
                  Inform the development of policies related to access to and availability of care.
                </li>
              </ul>
            </div>
          </GroupItem>
          <GroupItem>
            <div className='icon-col'>
              <img src={HospitalIcon} alt='Hospital icon' height='70' width='70' />
            </div>
            <div className='content-col'>
              <h5>Health Systems and State Hospital Associations</h5>
              <ul>
                <li>
                  Identify areas of opportunity to improve care for populations that the health
                  system serves. For example, if there is a sustained elevation in the rate of
                  visits for acute ambulatory care sensitive conditions (PQE 03) for pediatric patients 
                  in a given region, that may flag an opportunity to establish pediatric urgent care 
                  centers or offer more same-day acute visits within ambulatory clinics. 
                </li>
                <li>
                  For high utilization conditions (e.g., back pain, asthma), develop patient and
                  family outreach programs and education.
                </li>
              </ul>
            </div>
          </GroupItem>
          <GroupItem>
            <div className='icon-col'>
              <img src={CardPaymentIcon} alt='Card Payment icon' height='70' width='70' />
            </div>
            <div className='content-col'>
              <h5>Payors</h5>
              <ul>
                <li>
                  Use data to assist in the design of benefits, including prevention program
                  opportunities. For example, covering alternative forms of treatment, such as
                  non-pharmacologic interventions, for low back pain.
                </li>
                <li>
                  Data can also be used to assess the adequacy of specialty provider networks in a given
                  community.
                </li>
              </ul>
            </div>
          </GroupItem>
        </UseCaseBox>

        <UseCaseBox ref={researchRef}>
          <UseCaseTitle>Research</UseCaseTitle>
          <p>There are several ways that the PQEs could be used for research. For example:</p>
          <ul>
            <li>Describe the impact of policy changes on outcomes.</li>
            <li>Compare outcomes, specifically disparities, across different communities.</li>
            <li>
              Describe correlations of area-level indices of Social Determinants of Health (SDoH)
              with outcomes.
            </li>
            <li>
              Assess the impact of interventions aimed at increasing access to primary care.
            </li>
          </ul>
        </UseCaseBox>

        <UseCaseBox ref={publicReportingRef}>
          <UseCaseTitle>Public Reporting</UseCaseTitle>
          <p>
            Since the measures are at the area-level, results can be used to publicly report
            outcomes for communities in a given region/state.
          </p>
        </UseCaseBox>
      </PageRow>

      <PageRow className='page-row disclaimer-row' lightBlue py={0} pb={6}>
        <PageDisclaimer
          resource={`AHRQ ED PQI Technical Documentation, Version ${releaseVersion}`}
          technical
        />
      </PageRow>
    </>
  );
};

const GroupItem = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  padding: `${theme.spacing(3.75)} 0`,
  '& .icon-col': {
    paddingRight: theme.spacing(2.5),
  },
  '& .content-col': {
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2.5),
    '& h5': {
      color: theme.palette.primary.main,
    },
    '& sup': {
      color: theme.palette.primary.main,
      fontSize: '.65em',
      fontWeight: 600,
    },
  },
}));

const UseCasesHeader = styled('h2')(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
  textAlign: 'center',
}));

const UseCaseBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(6),
  padding: theme.spacing(5),
  '& p': {
    fontSize: '1.125rem',
    marginBottom: theme.spacing(3.75),
  },
  '& p:last-child': {
    fontSize: '1.125rem',
    marginBottom: 0,
  },
  '& ol, & ul': {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    paddingLeft: '1.5em',
  },
  '& ol': {
    paddingLeft: '1em',
  },
}));

const UseCaseNav = styled('ul')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fontWeight: 700,
  lineHeight: '20px',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  '& > li': {
    cursor: 'pointer',
    margin: 0,
    padding: `0 ${theme.spacing(1)}`,
  },
  '& > li.dot': {
    cursor: 'default',
    fontSize: '1.5rem',
    padding: `0 ${theme.spacing(0)}`,
  },
}));

const UseCaseTitle = styled('h4')(({ theme }) => ({
  marginBottom: theme.spacing(3.75),
  textAlign: 'center',
}));

const Emphasize = styled('em')(({ theme }) => ({
  fontStyle: 'italic',
  fontWeight: 600,
}));
