const WINQI_ITEMS = [
  // DOWNLOADS/SOFTWARE/WINQI
  {
    name: 'WinQIv2022',
    title: 'WinQI v2022.0.1',
    desc: 'This is an update to the v2022 WinQI software that applies to the Inpatient Quality Indicators (IQI) module.',
    filename: 'quality_indicators_202201_x64_Setup.zip',
    path: '/Downloads/Software/WinQI/V2022/',
    size: '245 MB',
    format: 'ZIP',
    revision: 'September 2022',
    dateModified: '2023-08-11',
  },
];

export default WINQI_ITEMS;
