import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PDITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Pediatric Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Pediatric Quality Indicators Technical Specifications, Version 4.3a, September 2012
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              All Pediatric Quality Indicators Technical Specifications, Version 4.3a (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PDI_NQI_Technical_Specifications_Version43a.zip'
              href='/Downloads/Modules/PDI/V43a/TechSpecs/PDI_NQI_Technical_Specifications_Version43a.zip'
            >
              Download All (ZIP File, 4.5 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pdi'} version={'v43a'} />
      </Container>
    </>
  );
};

export default PDITechnical;
