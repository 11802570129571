import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const IQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='align-center mb-9'>Inpatient Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Inpatient Quality Indicators Technical Specifications Updates - Version v2018 and
          v2018.0.1 (ICD 10), June 2018
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Inpatient Quality Indicators Technical Specifications (PDF Format), Version
              v2018 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download IQI_2018_ICD10_techspecs_Provider_pdf.zip'
              href='/Downloads/Modules/IQI/V2018/TechSpecs/IQI_2018_ICD10_techspecs_Provider_pdf.zip'>
              Download All (ZIP File, 13.9 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'iqi'} version={'icd10_v2018'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          The AHRQ Inpatient Quality Indicators (IQIs) contain two types of indicators, including
          those measured at the area (or geographic) level and those measured at the provider (or
          hospital) level. Effective v7.0, the following indicators have been retired. For
          additional information, refer to the retirement announcements:{' '}
          <a href='/News/IQI_Area_Level_Retirement_Notice.pdf'>IQI Area Level Indicators</a> (PDF
          File, 350 KB),{' '}
          <a href='/News/IQI_Hospital_Level_Retirement_Notice.pdf'>IQI Hospital Level Indicators</a>{' '}
          (PDF File, 356 KB).
        </p>
        <ul>
          <li>IQI 23 Laparoscopic Cholecystectomy Rate</li>
          <li>IQI 24 Incidental Appendectomy in the Elderly Rate</li>
          <li>IQI 25 Bilateral Cardiac Catheterization Rate</li>
          <li>IQI 26 Coronary Artery Bypass Graft (CABG) Rate</li>
          <li>IQI 27 Percutaneous Coronary Intervention (PCI) Rate</li>
          <li>IQI 28 Hysterectomy Rate</li>
          <li>IQI 29 Laminectomy or Spinal Fusion Rate</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Inpatient Quality Indicators Technical Specifications for SAS QI and WinQI ICD-10 v7.0
            can be found <Link to='/archive/qi_modules?category=iqi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default IQITechnical;
