import React from 'react';
import {
  StepConnector,
  Stepper,
  styled,
  stepConnectorClasses,
  stepLabelClasses,
  StepLabel,
  Step,
} from '@mui/material';
import Check from '@mui/icons-material/Check';

export const SurveyStepper = ({ children, ...rest }) => {
  return (
    <Stepper connector={<StyledConnector />} {...rest}>
      {children}
    </Stepper>
  );
};

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    display: 'none',
  },
}));

const SurveyStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,

  borderRadius: '50%',
  fontSize: '1.125rem',
  fontWeight: 600,
  height: '2rem',
  width: '2rem',

  ...(ownerState.active && {
    backgroundImage: `linear-gradient(135deg, #0066B9 0.33%, #DD4B87 100.33%)`,
    color: theme.palette.common.white,
  }),

  ...(!ownerState.active &&
    !ownerState.completed && {
      backgroundColor: theme.palette.fill.lightGrey,
      border: `1px solid ${theme.palette.divider}`,
    }),

  ...(!ownerState.active &&
    ownerState.completed && {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    }),
}));

export const SurveyStepIcon = ({ active, completed, error, icon, ...rest }) => {
  return (
    <SurveyStepIconRoot
      className='SurveyStepper-stepIconWrapper'
      {...rest}
      error={error}
      ownerState={{ completed, active }}>
      {!active && completed && <Check sx={{ fontSize: '1rem' }} />}
      {(active || !completed) && <span>{icon}</span>}
    </SurveyStepIconRoot>
  );
};

const SurveyStepLabelRoot = styled(StepLabel)(({ theme }) => ({
  [`.${stepLabelClasses.labelContainer}`]: {
    display: 'none',
  },

  [`.${stepLabelClasses.iconContainer}`]: {
    paddingRight: 0,
  },
}));

export const SurveyStepLabel = ({ children, ...rest }) => {
  return (
    <SurveyStepLabelRoot
      className='SurveyStepper-stepLabelRoot'
      {...rest}
      StepIconComponent={SurveyStepIcon}>
      {children}
    </SurveyStepLabelRoot>
  );
};

export const SurveyStep = ({ label, onClick, ...rest }) => {
  return (
    <Step {...rest} onClick={(e) => onClick(e, rest.index)}>
      <SurveyStepLabel>{label}</SurveyStepLabel>
    </Step>
  );
};
