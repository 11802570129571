import { getReleaseItem } from '../releases';

export const pdiMeasuresData = {
  technical: [
    {
      ...getReleaseItem('IndividualMeasureTechnicalSpecifications', 'PDI'),
      status: 'new',
    },
    {
      ...getReleaseItem('ParameterEstimates', 'PDI'),
      status: 'new',
    },
    {
      ...getReleaseItem('BenchmarkDataTables', 'PDI'),
      status: 'new',
    },
    {
      ...getReleaseItem('LogOfCodingUpdates', 'PDI'),
      status: 'new',
    },
    {
      ...getReleaseItem('CompositeMeasures', 'PDI'),
      status: 'new',
    },
  ],
  additional: [
    {
      title: 'AHRQ Composite Measures Workgroup',
      desc: "<p>This report summarizes the work of the PDI Composite Measure Workgroup, which assisted AHRQ in developing a composite measure based on the Pediatric Quality Indicators. For more information on Composite Workgroups, visit the <a href='../measures/composite_workgroup'>AHRQ QI Composite Workgroups page.</a></p>",
      url: '/Downloads/Modules/PDI/PDI_Composite_Development.pdf',
      aria: 'Download PDI_Composite_Development.pdf',
      cta: 'Download (PDF File, 468 KB)',
    },
    {
      title: 'AHRQ PDI Development',
      desc: '<p>How the PDI measure set was developed.</p>',
      url: '/Downloads/Modules/PDI/pdi_development.zip',
      aria: 'Download pdi_development.zip',
      cta: 'Download (ZIP File, 1.2 MB)',
    },
  ],
  // specifications: [
  //   {
  //     title: 'NQI 03 Neonatal Blood Stream Infection Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
  //     aria: 'Download NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 01 Accidental Puncture or Laceration Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
  //     aria: 'Download PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 05 Iatrogenic Pneumothorax Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
  //     aria: 'Download PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_08_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
  //     aria: 'Download PDI_08_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 09 Postoperative Respiratory Failure Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
  //     aria: 'Download PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 10 Postoperative Sepsis Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
  //     aria: 'Download PDI_10_Postoperative_Sepsis_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
  //     aria: 'Download PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 14 Asthma Admission Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
  //     aria: 'Download PDI_14_Asthma_Admission_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 15 Diabetes Short-term Complications Admission Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
  //     aria: 'Download PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 16 Gastroenteritis Admission Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
  //     aria: 'Download PDI_16_Gastroenteritis_Admission_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 18 Urinary Tract Infection Admission Rate',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
  //     aria: 'Download PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 90 Pediatric Quality Overall Composite',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI%2090%20Pediatric%20Quality%20Overall%20Composite.pdf',
  //     aria: 'Download PDI 90 Pediatric Quality Overall Composite.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 91 Pediatric Quality Acute Composite',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI%2091%20Pediatric%20Quality%20Acute%20Composite.pdf',
  //     aria: 'Download PDI 91 Pediatric Quality Acute Composite.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI 92 Pediatric Quality Chronic Composite',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI%2092%20Pediatric%20Quality%20Chronic%20Composite.pdf',
  //     aria: 'Download PDI 91 Pediatric Quality Chronic Composite.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix A - Operating Room Procedure Codes',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_A.pdf',
  //     aria: 'Download PDI_Appendix_A.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix C - Surgical discharge MS-DRGs',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_C.pdf',
  //     aria: 'Download PDI_Appendix_C.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix E - Medical Discharge MS-DRGs',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_E.pdf',
  //     aria: 'Download PDI_Appendix_E.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_F.pdf',
  //     aria: 'Download PDI_Appendix_F.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_G.pdf',
  //     aria: 'Download PDI_Appendix_G.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix H - Infection Diagnosis Codes',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_H.pdf',
  //     aria: 'Download PDI_Appendix_H.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_I.pdf',
  //     aria: 'Download PDI_Appendix_I.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix J - Admission Codes for Transfers',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_J.pdf',
  //     aria: 'Download PDI_Appendix_J.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix K - Stratification',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_K.pdf',
  //     aria: 'Download PDI_Appendix_K.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix L - Low Birth Weight Categories',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_L.pdf',
  //     aria: 'Download PDI_Appendix_L.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PDI Appendix M - Cancer',
  //     url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_M.pdf',
  //     aria: 'Download PDI_Appendix_M.pdf',
  //     cta: 'Download PDF',
  //   },
  // ],
  // retired: [
  //   {
  //     desc: "<p>Effective v2021, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='//News/AHRQ%20QI%20v2021%20Retirement%20Announcement.pdf'>Indicators Retirement Announcement</a>.</p>",
  //     title: [
  //       {
  //         titledesc: 'NQI 02 Neonatal Mortality Rate',
  //       },
  //     ],
  //   },
  //   {
  //     desc: "<p>Effective v2019, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='//News/Retirement%20Notice_v2019_Indicators.pdf'>Indicators Retirement Announcement</a>.</p>",
  //     title: [
  //       {
  //         titledesc: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
  //       },
  //       {
  //         titledesc: 'PDI 02 Pressure Ulcer Rate',
  //       },
  //       {
  //         titledesc: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
  //       },
  //       {
  //         titledesc: 'PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate',
  //       },
  //       {
  //         titledesc: 'PDI 07 RACHS-1 Pediatric Heart Surgery Volume',
  //       },
  //       {
  //         titledesc: 'PDI 11 Postoperative Wound Dehiscence Rate',
  //       },
  //       {
  //         titledesc: 'PDI 13 Transfusion Reaction Count',
  //       },
  //       {
  //         titledesc: 'PDI 17 Perforated Appendix Admission Rate',
  //       },
  //       {
  //         titledesc: 'PDI 19 Pediatric Safety for Selected Indicators Composite',
  //       },
  //     ],
  //   },
  // ],
};
