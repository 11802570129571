import React from 'react';
import { Link } from 'react-router-dom';
import TechSpecTable from '../TechSpecTable';
import {
  Button,
  Callout,
  Rule,
  Flex,
  PageHeader,
  PageRow,
  LinkItem,
  PageDisclaimer,
} from '../../../../ui';
import { getReleaseItem, releaseVersion } from '../../../../data/releases';

const PSITechnical = () => {
  const techSpecFile = getReleaseItem('TechSpecs', 'PSI', 'v2023');
  const techSpecExcelFile = getReleaseItem('TechSpecsExcel', 'PSI', 'v2023');
  const sasReleaseNotes = getReleaseItem('ReleaseNotes', 'SASQI', 'v2023');
  return (
    <>
      <PageHeader.Row bgSrc='/images/hex-grey.jpg' lightGrey>
        <PageHeader.Title>Technical Specifications</PageHeader.Title>
        <PageHeader.Content>
          <h4>For Patient Safety Indicators</h4>
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow>
        <Flex gap={4}>
          <h4>
            {techSpecFile.title} (PDF Format) - Version {techSpecFile.version},{' '}
            {techSpecFile.revision}
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' gap={2}>
            <Flex>
              Updated {techSpecFile.title} (PDF Format), Version {techSpecFile.version} (
              {techSpecFile.format} File)
            </Flex>
            <Button aria-label={techSpecFile.aria} href={techSpecFile.url}>
              {techSpecFile.cta}
            </Button>
          </Flex>

          <h4>
            {techSpecExcelFile.title} - Version {techSpecExcelFile.version},{' '}
            {techSpecExcelFile.revision}
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' gap={2}>
            <Flex>
              Updated {techSpecExcelFile.title}, Version {techSpecExcelFile.version} (
              {techSpecExcelFile.format} File)
            </Flex>
            <Button aria-label={techSpecExcelFile.aria} href={techSpecExcelFile.url}>
              {techSpecExcelFile.cta}
            </Button>
          </Flex>

          <TechSpecTable module={'psi'} version={'icd10_v2023'} />
          <div>
            ** To learn about the treatment of COVID-19 codes in the AHRQ Quality Indicators (QIs),
            refer to{' '}
            <a
              href={sasReleaseNotes.url}
              aria-label={sasReleaseNotes.aria}
              rel='noreferrer'
              target='_blank'
            >
              {sasReleaseNotes.title} {sasReleaseNotes.info}
            </a>
          </div>
        </Flex>
      </PageRow>

      <PageRow lightGrey>
        <h3>Retired Indicators</h3>
        <Rule />

        <p className='text-large'>
          Effective v2021, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/AHRQ%20QI%20v2021%20Retirement%20Announcement.pdf'
          meta='PDF File, 109 KB'
          light
          mb
        >
          Indicators Retirement Announcement
        </LinkItem>
        <ul>
          <li>NQI 02 Neonatal Mortality Rate</li>
        </ul>

        <Rule dashed />

        <p className='text-large'>
          Effective v2019, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/Retirement%20Notice_v2019_Indicators.pdf'
          meta='PDF File, 105 KB'
          light
          mb
        >
          Indicators Retirement Announcement
        </LinkItem>
        <ul>
          <li>PSI 16 Transfusion Reaction Count</li>
        </ul>

        <Rule dashed />

        <p className='text-large'>
          Effective v7.0, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem href='/News/PSI_Retirement_Notice.pdf' meta='PDF File, 349 KB' light mb>
          PSI Area Level Hospital
        </LinkItem>
        <ul>
          <li>PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate</li>
          <li>PSI 22 Iatrogenic Pneumothorax Rate</li>
          <li>PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate</li>
          <li>PSI 24 Postoperative Wound Dehiscence Rate</li>
          <li>PSI 25 Accidental Puncture or Laceration Rate</li>
          <li>PSI 26 Transfusion Reaction Rate</li>
          <li>PSI 27 Perioperative Hemorrhage or Hematoma Rate</li>
        </ul>

        <Rule dashed />

        <Callout>
          Patient Safety Indicators Technical Specifications for SAS and WinQI ICD-10 version v2022
          can be found <Link to='/archive/qi_modules?category=psi'>here</Link>.
        </Callout>
      </PageRow>

      <PageRow className='page-row disclaimer-row' lightGrey py={0} pb={6}>
        <PageDisclaimer
          resource={`AHRQ PSI Technical Documentation, Version ${releaseVersion}`}
          technical
        />
      </PageRow>
    </>
  );
};

export default PSITechnical;
