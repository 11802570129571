const PQEVideos = {
  intent: {
    id: 'X5PmOZDRhwc',
    url: 'https://www.youtube.com/embed/X5PmOZDRhwc?autoplay=1',
    text: 'Website Resources Walkthrough',
    label: 'Website Resources Walkthrough',
    thumb: '/images/video-website-resources-walthrough-icon.jpg',
  },
  feedback: {
    id: 'jsT_pVj4d4A',
    url: 'https://www.youtube.com/embed/jsT_pVj4d4A?autoplay=1',
    text: 'PQE Download and Installation (v2023 Software)',
    label: 'PQE Download and Installation (v2023 Software)',
    thumb: '/images/video-ed-pqi-download-and-installation-icon.jpg',
  },
  resources: {
    id: 'ZzNh_UnrvDQ',
    url: 'https://www.youtube.com/embed/ZzNh_UnrvDQ?autoplay=1',
    text: 'Using PQE Software (v2023 Software)',
    label: 'Using PQE Software (v2023 Software)',
    thumb: '/images/video-using-ed-pqi-software-icon.jpg',
  },
  edpqiTechnicalOverview: {
    id: 'P3N1LhgZvxY',
    url: 'https://www.youtube.com/embed/P3N1LhgZvxY?autoplay=1',
    text: 'PQE Technical Overview',
    label: 'PQE Technical Overview',
    thumb: '/images/video-edpqi-technical-overview-icon.jpg',
  },
};

const sasqiPQEVideos = {
  intent: PQEVideos.intent,
  resources: PQEVideos.resources,
  edpqiTechnicalOverview: PQEVideos.edpqiTechnicalOverview,
};

const cloudqiPQEVideos = {
  intent: PQEVideos.intent,
  feedback: PQEVideos.feedback,
  resources: PQEVideos.resources,
  edpqiTechnicalOverview: PQEVideos.edpqiTechnicalOverview,
};

const pqeResourcePQEVideos = {
  intent: PQEVideos.intent,
  resources: PQEVideos.resources,
  edpqiTechnicalOverview: PQEVideos.edpqiTechnicalOverview,
};

// THUMBNAILS - needs to be created with play icon
// https://i.ytimg.com/vi/{ID}/maxresdefault.jpg

export { pqeResourcePQEVideos, sasqiPQEVideos, cloudqiPQEVideos };
