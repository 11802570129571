import React from 'react';
import TechSpecTable from '../TechSpecTable';
import {Button, Rule, Flex, PageHeader, PageRow, PageDisclaimer} from '../../../../ui';
import {getReleaseItem, releaseVersion} from '../../../../data/releases';

const EDPQITechnical = () => {
  const techSpecFile = getReleaseItem('TechSpecs', 'EDPQI', 'v2023');
  const techSpecExcelFile = getReleaseItem('TechSpecsExcel', 'EDPQI', 'v2023');

  return (
    <>
      <PageHeader.Row bgSrc='/images/hex-grey.jpg' lightGrey>
        <PageHeader.Title>Technical Specifications</PageHeader.Title>
        <PageHeader.Content>
          <h4>For Emergency Department Prevention Quality Indicators</h4>
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow>
        <Flex gap={4}>
          <h4>
            {techSpecFile.title} (PDF Format) - Version {techSpecFile.version},{' '}
            {techSpecFile.revision}
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{xs: 'column', lg: 'row'}} alignItems='center' gap={2}>
            <Flex>
              {techSpecFile.title} (PDF Format), Version {techSpecFile.version} (
              {techSpecFile.format} File)
            </Flex>
            <Button aria-label={techSpecFile.aria} href={techSpecFile.url}>
              {techSpecFile.cta}
            </Button>
          </Flex>

          <h4>
            {techSpecExcelFile.title} - Version {techSpecExcelFile.version},{' '}
            {techSpecExcelFile.revision}
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{xs: 'column', lg: 'row'}} alignItems='center' gap={2}>
            <Flex>
              {techSpecExcelFile.title}, Version {techSpecExcelFile.version} (
              {techSpecExcelFile.format} File)
            </Flex>
            <Button aria-label={techSpecExcelFile.aria} href={techSpecExcelFile.url}>
              {techSpecExcelFile.cta}
            </Button>
          </Flex>

          <TechSpecTable module={'pqe'} version={'icd10_v2023'} />
        </Flex>
      </PageRow>

      <PageRow className='page-row disclaimer-row' lightGrey py={0} pt={4} pb={6}>
        <PageDisclaimer
          resource={`AHRQ ED PQI Technical Documentation, Version ${releaseVersion}`}
          technical
        />
      </PageRow>
    </>
  );
};

export default EDPQITechnical;
