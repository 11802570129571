import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PSITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Patient Safety Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Patient Safety Indicators Technical Specifications Updates - Version 5.0 (ICD 10), October
          2015
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Patient Safety Indicators Technical Specifications (PDF Format), Version 5.0
              (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI_50_ICD10_techspecs_Provider_pdf.zip'
              href='/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_50_ICD10_techspecs_Provider_pdf.zip'
            >
              Download All (ZIP File, 2.7 MB)
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={9}>
            <h5>
              Updated Patient Safety Indicators Technical Specifications (MS Excel Format), Version
              5.0 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI_50_ICD10_techspecs_Provider_excel.zip'
              href='/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_50_ICD10_techspecs_Provider_excel.zip'
            >
              Download All (ZIP File, 2.7 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'psi'} version={'icd10_v50'} />
      </Container>
      <Container className='outerContainer'>
        <Row>
          <Col md={9}>
            <h4>All PSI Appendices in MS Excel Format (ZIP file)</h4>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI Appendices'
              href='/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_All.zip'
            >
              Download (ZIP File, 20.3 MB)
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Patient Safety Indicators Technical Specifications for SAS v4.5 and WinQI v4.6 can be
            found <Link to='/archive'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PSITechnical;
