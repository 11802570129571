import React from 'react';
import { Box, Chip, styled, Typography } from '@mui/material';

export const NewsContent = ({ title, description, date, label, to, promote }) => {
  return (
    <CardContent>
      <Chip label={label} size='small' sx={{ paddingRight: '.5rem', paddingLeft: '.5rem' }} />
      <Typography variant='h5' className='title' mt={1.5}>
        <a href={to}>{title}</a>
        {promote === true && <span className='promote'> New!</span>}
      </Typography>
      <Typography variant='body2' component='div' className='date'>
        {date}
      </Typography>
      <Typography variant='body2' component='div' className='description'>
        {description}
      </Typography>
    </CardContent>
  );
};

const CardContent = styled(Box)(({ theme }) => ({
  '>.title': {
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: '24px',
  },
  '.description': {
    lineHeight: '18px',
    marginTop: theme.spacing(1),
    zIndex: 1,
  },
  '.promote': {
    color: theme.palette.error.main,
  },
}));
