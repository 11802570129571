import React from 'react';
import { styled } from '@mui/material';
import { Button } from './Button';

export const AltButton = ({ inverse, ...rest }) => {
  return <StyledButton {...rest} variant='outlined' inverse={inverse} />;
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'inverse',
})(({ inverse, theme }) => ({
  backgroundColor: inverse ? theme.palette.common.white : theme.palette.fill.lightBlue,
  border: '1px solid rgba(0, 0, 0, 0.07)',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: inverse ? theme.palette.grey[200] : theme.palette.grey[300],
    borderColor: inverse ? theme.palette.grey[200] : theme.palette.grey[300],
    color: theme.palette.text.secondary,
  },
  '&.Mui-disabled': {
    border: '1px solid rgba(0, 0, 0, 0.07)',
  },
}));
