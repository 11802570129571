import React from 'react';
import { Box, styled } from '@mui/material';

export const SplitRowColumn = ({ right, children }) => {
  return <Column right={right}>{children}</Column>;
};

const Column = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'right',
})(({ theme, right }) => ({
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,

  padding: right ? '0 0 3.5rem' : '3.5rem 0',
  textAlign: 'center',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    padding: right ? '0 3.5rem 3.5rem' : '3.5rem 7rem',
  },

  [theme.breakpoints.up('xl')]: {
    alignItems: right ? 'flex-end' : 'flex-start',
    minHeight: 250,
    padding: '3.5rem 0',
    paddingRight: right ? 0 : '3.5rem',
    textAlign: 'left',
    width: '50%',
  },
}));
