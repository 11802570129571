import React, { useEffect } from 'react'
import NewsMain from '../../components/News/NewsMain';

const News = () => {
    useEffect(() => {
        document.title = `AHRQ - Quality Indicators News`;
    });
    return (<NewsMain />)
}

export default News;