import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ArchivesNews from '../../components/Archives/ArchivesNews';
import ArchivesSoftware from '../../components/Archives/ArchivesSoftware';
import ArchivesResources from '../../components/Archives/ArchivesResources';
import ArchivesQIModules from '../../components/Archives/ArchivesQIModules';
import Landing from '../../components/Archives/Landing';
import TechSpec from '../../components/Measures/TechSpecs/TechSpec';

const Archives = () => {
  const params = useParams();
  const section = params?.section?.toLowerCase();
  const subsection = params?.subsection?.toLowerCase();
  const subsubsection = params?.subsubsection?.toLowerCase();

  const pageTitles = {
    news: 'AHRQ QI News Archive',
    software: 'AHRQ QI Archived Software',
    resources: 'AHRQ QI Archived Resources',
    pqi_techspec: {
      icd10_v2023:
        'AHRQ QI: PQI Technical Specifications Updates - Version v2023 (ICD 10-CM/PCS), August 2023',
      icd10_v2022:
        'AHRQ QI: PQI Technical Specifications Updates - Version v2022 (ICD 10-CM/PCS), July 2022',
      icd10_v2021:
        'AHRQ QI: PQI Technical Specifications Updates - Version v2021 (ICD 10-CM/PCS), July 2021',
      icd10_v2020:
        'AHRQ QI: PQI Technical Specifications Updates - Version v2020 (ICD 10-CM/PCS), July 2020',
      icd10_v2019:
        'AHRQ QI: PQI Technical Specifications Updates - Version v2019 (ICD 10-CM/PCS), July 2019',
      icd10_v2018:
        'AHRQ QI: PQI Technical Specifications Updates - Version v2018 and v2018.0.1 (ICD 10-CM/PCS), June 2018',
      icd10_v70:
        'AHRQ QI: PQI Technical Specifications Updates - Version v7.0 (ICD 10), September 2017',
      icd10_v60:
        'AHRQ QI: PQI Technical Specifications Updates - Version 6.0 (ICD-10), September 2016',
      icd9_v60: 'AHRQ QI: PQI Technical Specifications Updates - Version 6.0 (ICD-9), October 2016',
      icd10_v50:
        'AHRQ QI: PQI Technical Specifications Updates - Version 5.0 (ICD 10), October 2015',
      icd9_v50: 'AHRQ QI: PQI Technical Specifications Updates - Version 5.0, March 2015',
      v45: 'AHRQ QI: PQI Technical Specifications Updates - Version 4.5, May 2013',
      v44: 'AHRQ QI: PQI Technical Specifications Updates - Version 4.4, March 2012',
      v43: 'AHRQ QI: PQI Technical Specifications Updates - Version 4.3, August 2011',
      v43a: 'AHRQ QI: PQI Technical Specifications Updates - Version 4.3, September 2012',
      v42: 'AHRQ QI: PQI Technical Specifications Updates - Version 4.2, September 2010',
      v41: 'AHRQ QI: PQI Technical Specifications Updates - Version 4.1, December 2009',
    },
    iqi_techspec: {
      icd10_v2023: 'AHRQ QI: IQI Technical Specifications Updates - Version v2023, August 2023',
      icd10_v2022: 'AHRQ QI: IQI Technical Specifications Updates - Version v2022, July 2022',
      icd10_v2021: 'AHRQ QI: IQI Technical Specifications Updates - Version v2021, July 2021',
      icd10_v2020: 'AHRQ QI: IQI Technical Specifications Updates - Version v2020, July 2020',
      icd10_v2019:
        'AHRQ QI: IQI Technical Specifications Updates - Version v2019 (ICD 10-CM/PCS), July 2019',
      icd10_v2018:
        'AHRQ QI: IQI Technical Specifications Updates - Version v2018 and v2018.0.1 (ICD 10), June 2018',
      icd10_v70:
        'AHRQ QI: IQI Technical Specifications Updates - Version v7.0 (ICD 10), September 2017',
      icd10_v60: 'AHRQ QI: IQI Technical Specifications Updates - Version 6.0 (ICD 10), July 2016',
      icd9_v60: 'AHRQ QI: IQI Technical Specifications Updates - Version 6.0, March 2017',
      icd10_v50:
        'AHRQ QI: IQI Technical Specifications Updates - Version 5.0 (ICD 10), October 2015',
      icd9_v50: 'AHRQ QI: IQI Technical Specifications Updates - Version 5.0, March 2015',
      v45: 'AHRQ QI: IQI Technical Specifications Updates - Version 4.5, May 2013',
      v44: 'AHRQ QI: IQI Technical Specifications Updates - Version 4.4, March 2011',
      v43: 'AHRQ QI: IQI Technical Specifications Updates - Version 4.3, August 2011',
      v43a: 'AHRQ QI: IQI Technical Specifications Updates - Version 4.3a, September 2012',
      v42: 'AHRQ QI: IQI Technical Specifications Updates - Version 4.2, September 2010',
      v41: 'AHRQ QI: IQI Technical Specifications Updates - Ver. 4.1, December 2009',
    },
    psi_techspec: {
      icd10_v2023: 'AHRQ QI: PSI Technical Specifications Updates - Version v2023, August 2023',
      icd10_v2022: 'AHRQ QI: PSI Technical Specifications Updates - Version v2022, July 2022',
      icd10_v2021: 'AHRQ QI: PSI Technical Specifications Updates - Version v2021, July 2021',
      icd10_v2020: 'AHRQ QI: PSI Technical Specifications Updates - Version v2020, July 2020',
      icd10_v2019:
        'AHRQ QI: PSI Technical Specifications Updates - Version v2019 (ICD 10-CM/PCS), July 2019',
      icd10_v2018:
        'AHRQ QI: PSI Technical Specifications Updates - Version v2018 and v2018.0.1 (ICD 10), June 2018',
      icd10_v70:
        'AHRQ QI: PSI Technical Specifications Updates - Version v7.0 (ICD 10), September 2017',
      icd10_v60: 'AHRQ QI: PSI Technical Specifications Updates - Version 6.0 (ICD 10), July 2016',
      icd9_v60: 'AHRQ QI: PSI Technical Specifications Updates - Version 6.0 (ICD-9), July 2017',
      icd10_v50:
        'AHRQ QI: PSI Technical Specifications Updates - Version 5.0 (ICD 10), October 2015',
      icd9_v50: 'AHRQ QI: PSI Technical Specifications Updates - Version 5.0, March 2015',
      v45: 'AHRQ QI: PSI Technical Specifications Updates -  Version 4.5, May 2013',
      v45a: 'AHRQ QI: PSI Technical Specifications Updates - Version 4.5, May 2013',
      v44: 'AHRQ QI: PSI Technical Specifications Updates - Version 4.4, March 2012',
      v43: 'AHRQ QI: PSI Technical Specifications Updates - Version 4.3, August 2011',
      v43a: 'AHRQ QI: PSI Technical Specifications Updates - Version 4.3a, September 2012',
      v42: 'AHRQ QI: PSI Technical Specifications Updates - Version 4.2, September 2010',
      v41: 'AHRQ QI: PSI Technical Specifications Updates - Ver 4.1, December 2009',
    },
    pdi_techspec: {
      icd10_v2023: 'AHRQ QI: PDI Technical Specifications Updates - Version v2023, August 2023',
      icd10_v2022: 'AHRQ QI: PDI Technical Specifications Updates - Version v2022, July 2022',
      icd10_v2021: 'AHRQ QI: PDI Technical Specifications Updates - Version v2021, July 2021',
      icd10_v2020: 'AHRQ QI: PDI Technical Specifications Updates - Version v2020, July 2020',
      icd10_v2019:
        'AHRQ QI: PDI Technical Specifications Updates - Version v2019 (ICD 10-CM/PCS), July 2019',
      icd10_v2018:
        'AHRQ QI: PDI Technical Specifications Updates - Version v2018 and v2018.0.1 (ICD 10), June 2018',
      icd10_v70:
        'AHRQ QI: PDI Technical Specifications Updates - Version v7.0 (ICD 10), September 2017',
      icd10_v60: 'AHRQ QI: PDI Technical Specifications Updates - Version 6.0 (ICD 10), July 2016',
      icd9_v60: 'AHRQ QI: PDI Technical Specifications Updates - Version 6.0, August 2017',
      icd10_v50:
        'AHRQ QI: PDI Technical Specifications Updates - Version 5.0 Alpha (ICD 10), October 2015',
      icd9_v50: 'AHRQ QI: PDI Technical Specifications Updates - Version 5.0, March 2015',
      v45: 'AHRQ QI: PDI Technical Specifications Updates - Version 4.5, May 2013',
      v44: 'AHRQ QI: PDI Technical Specifications Updates - Version 4.4, March 2012',
      v43: 'AHRQ QI: PDI Technical Specifications Updates - Version 4.3, August 2011',
      v43a: 'AHRQ QI: PDI Technical Specifications Updates - Version 4.3a, September 2012',
      v42: 'AHRQ QI: PDI Technical Specifications Updates - Version 4.2, September 2010',
      v41: 'AHRQ QI: PDI Technical Specifications Updates - Ver 4.1, December 2009',
    },
    pqe_techspec: {
      icd10_v2023: 'AHRQ QI: PQE Technical Specifications Updates - Version v2023, August 2023'
    },
    default: 'Previous Versions of AHRQ QI Modules',
  };

  useEffect(() => {
    const keys = [section, subsection, subsubsection];
    let repo = pageTitles;
    let title = null;
    for (const key of keys) {
      const lowerKey = key?.toLowerCase();
      if (repo[lowerKey] === undefined) {
        break;
      } else {
        title = repo[lowerKey];
        repo = repo[lowerKey];
      }

      if (typeof title === 'string') {
        break;
      }
    }

    document.title = title ? title : pageTitles['default'];
  });

  if (section === 'news') return <ArchivesNews />;
  else if (section === 'software') return <ArchivesSoftware />;
  else if (section === 'resources') return <ArchivesResources />;
  else if (section === 'pqi_techspec') return <TechSpec module={'pqi'} version={subsection} />;
  else if (section === 'pdi_techspec') return <TechSpec module={'pdi'} version={subsection} />;
  else if (section === 'psi_techspec') return <TechSpec module={'psi'} version={subsection} />;
  else if (section === 'iqi_techspec') return <TechSpec module={'iqi'} version={subsection} />;
  else if (section === 'ed_pqi_techspec') return <TechSpec module={'pqe'} version={subsection} />;
  else if (section === 'qi_modules') return <ArchivesQIModules />;
  else return <Landing />;
};
export default Archives;
