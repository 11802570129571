export const nqfData = {
    "iqi": [
        {
            "indicator": "IQI 2",
            "url": "/Downloads/Modules/IQI/v2018/TechSpecs/IQI_02_Pancreatic_Resection_Volume.pdf",
            "desc": "Pancreatic Resection Volume",
            "status": "Retired in v2019",
            "size": "551 KB",
            "number": "366"
        },
        {
            "indicator": "IQI 4",
            "url": "/Downloads/Modules/IQI/v2018/TechSpecs/IQI_04_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Volume.pdf",
            "desc": "Abdominal Aortic Aneurysm (AAA) Repair Volume",
            "status": "Retired in v2019",
            "size": "676 KB",
            "number": "357"
        },
        {
            "indicator": "IQI 9",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf",
            "desc": "Pancreatic Resection Mortality Rate",
            "size": "344 KB",
            "number": "365"
        },
        {
            "indicator": "IQI 11",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf",
            "desc": "Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate",
            "size": "400 KB",
            "number": "359"
        },
        {
            "indicator": "IQI 15",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf",
            "desc": "Acute Myocardial Infarction (AMI) Mortality Rate",
            "size": "324 KB",
            "number": "730"
        },
        {
            "indicator": "IQI 16",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf",
            "desc": "Heart Failure Mortality Rate",
            "size": "336 KB",
            "number": "358"
        },
        {
            "indicator": "IQI 17",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf",
            "desc": "Acute Stroke Mortality Rate",
            "size": "364 KB",
            "number": "467"
        },
        {
            "indicator": "IQI 18",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf",
            "desc": "Gastrointestinal Hemorrhage Mortality Rate",
            "size": "381 KB",
            "number": "2065"
        },
        {
            "indicator": "IQI 19",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf",
            "desc": "Hip Fracture Mortality Rate",
            "size": "384 KB",
            "number": "354"
        },
        {
            "indicator": "IQI 20",
            "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf",
            "desc": "Pneumonia Mortality Rate",
            "size": "344 KB",
            "number": "231"
        },
        {
            "indicator": "IQI 25",
            "url": "/Downloads/Modules/IQI/v60-ICD10/TechSpecs/IQI_25_Bilateral_Cardiac_Catheterization_Rate.pdf",
            "desc": "Bilateral Cardiac Catheterization Rate",
            "status": "Retired in v7.0",
            "size": "404 KB",
            "number": "355"
        },
    ],
    "psi": [
        {
            "indicator": "PSI 2",
            "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf",
            "desc": "Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)",
            "size": "348 KB",
            "number": "347",
        },
        {
            "indicator": "PSI 4",
            "url": "",
            "desc": "Death Rate among Surgical Inpatients with Serious Treatable Complications <sup>1</sup> <span class='italic-footnote'>Indicator withdrawn from NQF endorsement in 2017</span>",
            "number": "N/A",
        },
        {
            "indicator": "PSI 5",
            "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf",
            "desc": "Retained Surgical Item or Unretrieved Device Fragment Count",
            "size": "336 KB",
            "number": "363",
        },
        {
            "indicator": "PSI 9",
            "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_09_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf",
            "desc": "Postoperative Hemorrhage or Hematoma Rate",
            "size": "1.15 MB",
            "number": "2909",
        },
        {
            "indicator": "PSI 11",
            "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf",
            "desc": "Postoperative Respiratory Failure Rate",
            "size": "754 KB",
            "number": "533",
        },
        {
            "indicator": "PSI 12",
            "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf",
            "desc": "Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate",
            "size": "480 KB",
            "number": "450"
        },
        {
            "indicator": "PSI 15",
            "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf",
            "desc": "Accidental Puncture or Laceration Rate",
            "size": "4.1 MB",
            "number": "345"
        },
        {
            "indicator": "PSI 16",
            "url": "/Downloads/Modules/PSI/v2018/TechSpecs/PSI_16_Transfusion_Reaction_Count.pdf",
            "desc": "Transfusion Reaction Volume Count",
            "status": "Retired in v2019",
            "size": "521 KB",
            "number": "349"
        }
    ],
    "pdi": [
        {
            "indicator": "NQI 3",
            "url": "/Downloads/Modules/PDI/v2022/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf",
            "desc": "Neonatal Blood Stream Infection Rate",
            "size": "456 KB",
            "number": "478"
        },
        {
            "indicator": "PDI 1",
            "url": "/Downloads/Modules/PDI/v2022/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf",
            "desc": "Accidental Puncture or Laceration Rate",
            "size": "660 KB",
            "number": "344"
        },
        {
            "indicator": "PDI 2",
            "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_02_Pressure_Ulcer_Rate.pdf",
            "desc": "Pressure Ulcer Rate",
            "status": "Retired in v2019",
            "size": "532 KB",
            "number": "337"
        },
        {
            "indicator": "PDI 3",
            "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_03_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf",
            "desc": "Retained Surgical Item or Unretrieved Device Fragment Count",
            "status": "Retired in v2019",
            "size": "575 KB",
            "number": "362"
        },
        {
            "indicator": "PDI 6",
            "url": "/Downloads/Modules/PDI/V60/TechSpecs/PDI_06_Pediatric_Heart_Surgery_Mortality_Rate.pdf",
            "desc": "RACHS-1 Pediatric Heart Surgery Mortality Rate",
            "status": "Retired in v2019",
            "size": "353 KB",
            "number": "339"
        },
        {
            "indicator": "PDI 7",
            "url": "/Downloads/Modules/PDI/V60/TechSpecs/PDI_07_Pediatric_Heart_Surgery_Volume.pdf",
            "desc": "RACHS-1 Pediatric Heart Surgery Volume",
            "status": "Retired in v2019",
            "size": "341 KB",
            "number": "340"
        },
        {
            "indicator": "PDI 13",
            "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_13_Transfusion_Reaction_Count.pdf",
            "desc": "Transfusion Reaction Volume Count",
            "status": "Retired in v2019",
            "size": "532 KB",
            "number": "350"
        },
        {
            "indicator": "PDI 14",
            "url": "/Downloads/Modules/PDI/v2022/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf",
            "desc": "Asthma Admission Rate",
            "size": "420 KB",
            "number": "728"
        },
        {
            "indicator": "PDI 16",
            "url": "/Downloads/Modules/PDI/v2022/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf",
            "desc": "Gastroenteritis Admission Rate",
            "size": "372 KB",
            "number": "727"
        },
    ],
    "pqi": [
        {
            "indicator": "PQI 1",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf",
            "desc": "Diabetes Short-Term Complications Admission Rate",
            "size": "328 KB",
            "number": "272"
        },
        {
            "indicator": "PQI 2",
            "url": "/Downloads/Modules/PQI/v2018/TechSpecs/PQI_02_Perforated_Appendix_Admission_Rate.pdf",
            "desc": "Perforated Appendix Admission Rate",
            "status": "Retired in v2019",
            "size": "337 KB",
            "number": "273"
        },
        {
            "indicator": "PQI 3",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf",
            "desc": "Diabetes Long-Term Complications Admission Rate",
            "size": "376 KB",
            "number": "274"
        },
        {
            "indicator": "PQI 5",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf",
            "desc": "Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate",
            "size": "216 KB",
            "number": "275"
        },
        {
            "indicator": "PQI 8",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf",
            "desc": "Heart Failure Admission Rate",
            "size": "331 KB",
            "number": "277"
        },
        {
            "indicator": "PQI 10",
            "url": "/Downloads/Modules/PQI/v2018/TechSpecs/PQI_10_Dehydration_Admission_Rate.pdf",
            "desc": "Dehydration Admission Rate",
            "status": "Retired in v2019",
            "size": "443 KB",
            "number": "280"
        },
        {
            "indicator": "PQI 11",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf",
            "desc": "Community-Acquired Pneumonia Admission Rate",
            "size": "503 KB",
            "number": "279"
        },
        {
            "indicator": "PQI 12",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf",
            "desc": "Urinary Tract Infection Admission Rate",
            "size": "516 KB",
            "number": "281"
        },
        {
            "indicator": "PQI 14",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf",
            "desc": "Uncontrolled Diabetes Admission Rate",
            "size": "415 KB",
            "number": "638"
        },
        {
            "indicator": "PQI 16",
            "url": "/Downloads/Modules/PQI/v2022/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf",
            "desc": "Lower-Extremity Amputation among Patients with Diabetes Rate",
            "size": "518 KB",
            "number": "285"
        },
    ],
    "composite": [
        {
            "indicator": "IQI 91",
            "url": "/Downloads/Modules/IQI/V2022/TechSpecs/IQI 91 Mortality for Selected Inpatient Conditions.pdf",
            "desc": "Mortality for Selected Conditions",
            "size": "488 KB",
            "number": "530",
            "children": [
                {
                    "indicator": "IQI 15",
                    "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf",
                    "desc": "Acute Myocardial Infarction (AMI) Mortality Rate",
                    "size": "324 KB",
                    "number": "730",
                },
                {
                    "indicator": "IQI 16",
                    "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf",
                    "desc": "Heart Failure Mortality Rate",
                    "size": "336 KB",
                    "number": "358",
                },
                {
                    "indicator": "IQI 17",
                    "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf",
                    "desc": "Acute Stroke Mortality Rate",
                    "size": "354 KB",
                    "number": "467",
                },
                {
                    "indicator": "IQI 18",
                    "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf",
                    "desc": "Gastrointestinal Hemorrhage Mortality Rate",
                    "size": "372 KB",
                    "number": "2065",
                },
                {
                    "indicator": "IQI 19",
                    "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf",
                    "desc": "Hip Fracture Mortality Rate",
                    "size": "384 KB",
                    "number": "354",
                },
                {
                    "indicator": "IQI 20",
                    "url": "/Downloads/Modules/IQI/v2022/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf",
                    "desc": "Pneumonia Mortality Rate",
                    "size": "344 KB",
                    "number": "231",
                }
            ]
        },
        {
            "indicator": "PSI 90",
            "url": "/Downloads/Modules/PSI/V2022/TechSpecs/PSI 90 Patient Safety and Adverse Events Composite.pdf",
            "desc": "Patient Safety for Selected Indicators<sup>2</sup>",
            "status": "No longer endorsed",
            "size": "538 KB",
            "number": "531",
            "children": [
                {
                    "indicator": "PSI 3",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf",
                    "desc": "Pressure Ulcer Rate",
                    "size": "441 KB",
                    "number": "",
                },
                {
                    "indicator": "PSI 6",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf",
                    "desc": "Iatrogenic Pneumothorax Rate",
                    "size": "1.88 MB",
                    "number": "346",
                },
                {
                    "indicator": "PSI 7",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf",
                    "desc": "Central Venous Catheter-Related Blood Stream Infection Rate",
                    "size": "323 KB",
                    "number": "",
                },
                {
                    "indicator": "PSI 8",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf",
                    "desc": "Postoperative Hip Fracture Rate",
                    "size": "385 KB",
                    "number": "",
                },
                {
                    "indicator": "PSI 12",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf",
                    "desc": "Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate",
                    "size": "480 KB",
                    "number": "450",
                },
                {
                    "indicator": "PSI 13",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf",
                    "desc": "Postoperative Sepsis Rate",
                    "size": "332 KB",
                    "number": "",
                },
                {
                    "indicator": "PSI 14",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf",
                    "desc": "Postoperative Wound Dehiscence Rate",
                    "size": "2.54 MB",
                    "number": "",
                },
                {
                    "indicator": "PSI 15",
                    "url": "/Downloads/Modules/PSI/v2022/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf",
                    "desc": "Accidental Puncture or Laceration Rate",
                    "size": "4.1 MB",
                    "number": "345",
                },
            ]
        },
        {
            "indicator": "PDI 19",
            "url": "/Downloads/Modules/PDI/v60/TechSpecs/PDI_19_Pediatric_Safety_for_Selected_Indicators.pdf",
            "desc": "Pediatric Patient Safety for Selected Indicators<sup>3</sup>",
            "status": "Retired in v2019",
            "size": "373 KB",
            "number": "532",
            "children": [
                {
                    "indicator": "PDI 01",
                    "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf",
                    "desc": "Accidental Puncture or Laceration Rate",
                    "size": "1.4 MB",
                    "number": "344",
                },
                {
                    "indicator": "PDI 02",
                    "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_02_Pressure_Ulcer_Rate.pdf",
                    "desc": "Pressure Ulcer Rate",
                    "size": "532 KB",
                    "number": "337",
                },
                {
                    "indicator": "PDI 05",
                    "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf",
                    "desc": "Iatrogenic Pneumothorax Rate",
                    "size": "4.2 MB",
                    "number": "348",
                },
                {
                    "indicator": "PDI 10",
                    "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf",
                    "desc": "Postoperative Sepsis Rate",
                    "size": "897 KB",
                    "number": "",
                },
                {
                    "indicator": "PDI 11",
                    "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_11_Postoperative_Wound_Dehiscence_Rate.pdf",
                    "desc": "Postoperative Wound Dehiscence Rate",
                    "size": "8.6 MB",
                    "number": "",
                },
                {
                    "indicator": "PDI 12",
                    "url": "/Downloads/Modules/PDI/v2018/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf",
                    "desc": "Central Venous Catheter-Related Blood Stream Infection Rate",
                    "size": "686 KB",
                    "number": "",
                },
            ]
        },
    ],
};