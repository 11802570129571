import React, { useEffect } from 'react';
import AnnouncementMain from '../../components/Announcements/AnnouncementMain';

const Announcements = () => {
  useEffect(() => {
    document.title = `AHRQ - Quality Indicators Announcements`;
  });

  return <AnnouncementMain />;
};

export default Announcements;
