import React from 'react'
import TechSpecComponents from './TechSpecComponentsIndex';
import { modulesMetadata } from '../../../data/modules_metadata';

const TechSpec = (props) => {
    const { module, version } = props;
    
    const versionToUse = version === 'current'
        ? modulesMetadata['current-version']
        : version;
    const ComponentToRender = TechSpecComponents[module] && TechSpecComponents[module][versionToUse]
        ? TechSpecComponents[module][versionToUse]
        : 'p';
    return (<ComponentToRender />);
}

export default TechSpec;