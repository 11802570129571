import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TileGroup, Tile } from '../../../ui';

export const LatestNews = () => {
  return (
    <Container>
      <Typography variant='h3' align='center' mt={8}>
        Read the Latest News
      </Typography>
      <TileGroup type='news' py={3}>
        <Tile
          title='AHRQ Seeking Members for Maternal Health Indicators Workgroup.'
          description='AHRQ is recruiting clinical experts and patient/family advocates to serve on a Maternal Health Indicators (MHI) Expert Work Group (EWG). Read AHRQ’s announcement to learn more.'
          label='Announcement'
          date='October 2024'
          to='/announcements/2024/10'
          promote
        />
        <Tile
          title='Release of AHRQ SAS QI, WinQI, and CloudQI v2024.0.1 Software.'
          description='This is a minor release of AHRQ QI Software to introduce the Maternal Health Indicators (MHI) and other updates. Details are in the Software Release Notes for each software product.'
          label='Software'
          date='September 23, 2024'
          to='/news#software-release-2024-09-23'
          promote
        />
        <Tile
          title='Beta release of a new module: Maternal Health Indicators (MHI), 2024.'
          description={`The MHI module aims to broadly address healthcare quality in the domain of maternal health and identify opportunities to reduce complications during the peripartum period. Read AHRQ's announcement to learn more.`}
          label='Software'
          date='September 23, 2024'
          to='/news#mhi-release-2024-09-23'
          promote
        />
      </TileGroup>
      <Box mb={8} textAlign='center'>
        <Typography variant='h5' color='primary'>
          <Link to='/news'>
            See all news <ChevronRightIcon />
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};
