import React from 'react';
import { Link } from 'react-router-dom';
import TechSpecTable from '../TechSpecTable';
import { Button, Callout, Rule, Flex, PageHeader, PageRow, LinkItem } from '../../../../ui';

const PQITechnical = () => {
  return (
    <>
      <PageHeader.Row bgSrc='/images/hex-grey.jpg' lightGrey>
        <PageHeader.Title>Technical Specifications</PageHeader.Title>
        <PageHeader.Content>
          <h4>For Prevention Quality Indicators</h4>
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow>
        <Flex gap={4}>
          <h4>
            Prevention Quality Indicators Technical Specifications Updates - Version v2022, July
            2022
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' gap={2}>
            <Flex>
              Updated Prevention Quality Indicators Technical Specifications (PDF Format), Version
              v2022 (Zip File)
            </Flex>
            <Button
              aria-label='Download PQI_2022_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/PQI/V2022/TechSpecs/PQI_2022_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 6.53 MB)
            </Button>
          </Flex>
          <TechSpecTable module={'pqi'} version={'icd10_v2022'} />
        </Flex>
      </PageRow>

      <PageRow lightGrey>
        <h3>Retired Indicators</h3>
        <Rule />

        <p className='text-large'>
          Effective v2019, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/Retirement%20Notice_v2019_Indicators.pdf'
          meta='PDF File, 105 KB'
          light
          mb>
          Indicators Retirement Announcement
        </LinkItem>
        <ul>
          <li>PQI 02 Perforated Appendix Admission Rate</li>
          <li>PQI 09 Low Birth Weight Rate</li>
          <li>PQI 10 Dehydration Admission Rate</li>
        </ul>

        <Rule dashed />

        <p className='text-large'>Effective v6.0, the following indicators have been retired.</p>
        <ul>
          <li>PQI 13 Angina Without Procedure Admission Rate is retired in version 6.0</li>
        </ul>

        <Rule dashed />

        <Callout>
          Prevention Quality Indicators Technical Specifications for SAS QI and WinQI ICD-10 v2021
          can be found <Link to='/archive/qi_modules?category=pqi'>here</Link>.
        </Callout>
      </PageRow>
    </>
  );
};

export default PQITechnical;
