import { Container } from 'react-bootstrap';
import { archivesData } from '../../data/archives';
import SearchableAccordion from '../SearchableAccordion/SearchableAccordion';

const ArchivesResources = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='align-center mb-2'>Archives</h1>
        <h2 className='align-center mb-2'>Resources</h2>
      </Container>
      <SearchableAccordion data={archivesData} section={'resources'} />
    </>
  );
};
export default ArchivesResources;
