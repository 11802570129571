import { Box, styled } from '@mui/material';

export const Callout = ({ children }) => {
  return (
    <Container
      bgcolor='fill.lightBlue'
      border='1px solid'
      borderColor='divider'
      fontSize={18}
      px={2.5}
      py={2}>
      {children}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  a: {
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none',
    },
  },
}));
