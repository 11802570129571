import React from 'react';
import { Button, Container } from 'react-bootstrap';
import styles from './resources.module.scss';
import ToolKitImg from '../../img/ToolKitResources.png';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const Toolkits = () => {
  return (
    <div className={styles.Toolkits}>
      <NormalTopBanner>
        <h1>Toolkits</h1>
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. */}
        </p>
      </NormalTopBanner>
      <div className={`bg-primary-blue-6 ${styles.Toolkit}`}>
        <Container className={`outer-container ${styles.Panel}`}>
          <div className={styles.ImageContainer}>
            <div>
              <img alt='Toolkit Icon' src={ToolKitImg} />
            </div>
          </div>
          <div className={styles.TextContainer}>
            <h2>Toolkits</h2>
            <p className='text-large'>
              AHRQ QI Toolkits provide practical applications and adaptable solutions to guide the
              provision of safe care and to help reduce patient harm.
            </p>
            <ul className='text-large'>
              <li>
                The QI Toolkit serves as an "inventory" from which hospitals can select those tools
                that are most appropriate for their current quality improvement priorities and
                capabilities.
              </li>
              <li>
                Each of the tools is-by design-adaptable to the needs of an individual hospital.
              </li>
            </ul>
            <Button
              href='http://www.ahrq.gov/professionals/systems/hospital/qitoolkit/index.html'
              aria-label='Go to hospital toolkits'
              className='link-button transparent arrow-right'
              variant='none'>
              View Hospital QI Toolkit
            </Button>
          </div>
        </Container>
      </div>
      <div className={styles.Resources}>
        <Container className='outer-container'>
          <h2 className='align-center'>Quality Improvement Resources.</h2>
          <p className='align-center'>
            The tools are organized by the six quality improvement process steps, followed by a
            resources section:
          </p>
          <div className={styles.MainContainer}>
            <div className={styles.Improvements}>
              <div className={styles.Improvement}>
                <h3>
                  <span>1</span>
                </h3>
                <h6>Assessing Readiness to Change</h6>
                <p>
                  Fact sheets on the QIs, a board presentation template, a survey to self-assess
                  readiness to change, and case studies of QI Toolkit users.
                </p>
              </div>
              <div className={styles.Improvement}>
                <h3>
                  <span>2</span>
                </h3>
                <h6>Applying QIs to Your Hospital's Data.</h6>
                <p>
                  Software instructions to calculate the QI rates and tips for coding and
                  documentation.
                </p>
              </div>
              <div className={styles.Improvement}>
                <h3>
                  <span>3</span>
                </h3>
                <h6>Implementing Evidence-Based Strategies to Improve Clinical Care.</h6>
                <p>
                  25 indicator-specific best practices, a project charter, a gap analysis, and an
                  implementation plan.
                </p>
              </div>
              <div className={styles.Improvement}>
                <h3>
                  <span>4</span>
                </h3>
                <h6>Identifying Priorities for Quality Improvement.</h6>
                <p>
                  Prioritization worksheet, with example, and a presentation template aimed at
                  engaging staff in the improvement process.
                </p>
              </div>
              <div className={styles.Improvement}>
                <h3>
                  <span>5</span>
                </h3>
                <h6>Monitoring Progress and Sustainability of Improvements.</h6>
                <p>
                  Guide to support staff in tracking trends and monitoring progress for sustainable
                  improvement.
                </p>
              </div>
              <div className={styles.Improvement}>
                <h3>
                  <span>6</span>
                </h3>
                <h6>Analyzing Return on Investment.</h6>
                <p>
                  Step-by-step method for calculating the return on investment for an intervention
                  implemented to improve performance on an AHRQ QI, and a case study of return on
                  investment calculated by a hospital.
                </p>
              </div>
            </div>
            <div className={styles.OtherImprovements}>
              <h6>
                The toolkit has undergone a field test, evaluation, and revisions in response to
                feedback from six hospitals. In 2016, AHRQ updated the toolkit to include:
              </h6>
              <ul>
                <li>
                  Updated content to partially address the transition from ICD-9 to ICD-10,
                  including updated documentation and coding practices
                </li>
                <li>
                  Guidance that can be used to improve pediatric quality in hospitals using the PDIs
                  or other quality measures
                </li>
                <li>A new presentation template for engaging staff in improvement efforts</li>
                <li>Case studies of two hospitals that have successfully used the QI Toolkit</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className={`bg-primary-blue-6 ${styles.Tool}`}>
        <Container className={`outer-container ${styles.Panel}`}>
          <h2 className='align-center'>MapIT Automated In-house Stand-alone Mapping Tool</h2>
          <div className={styles.Container}>
            <div className={styles.Left}>
              <div>
                <img
                  alt='map of United States showing opioid-related hospital stays'
                  src='/images/mapit.png'
                />
                <div className='pt-3'>
                  <a href='/Downloads/Resources/MapIT_FY_2018.zip'>
                    <Button
                      aria-label={`Link to`}
                      className='button large primary '
                      variant='primary'>
                      Download MapIt Toolkit (ZIP File, 60.2 MB)
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.Right}>
              <p>
                The AHRQ MapIT toolkit takes a selected set of ICD-9 codes, applies the CMS General
                Equivalence Mapping in various ways, then outputs the set of related ICD-9 and
                ICD-10 codes. The tool applies the GEM in a two-stage process using both the forward
                and backward maps in conjunction with a novel reverse mapping.
              </p>
              <p>
                Download and install the MapIT tool to facilitate conversion of set names to
                ICD-10-CM/PCS codes.
              </p>
              <ul>
                <li>
                  <a href='/Downloads/Resources/AHRQ_MapIT_FY2018_Installation_Instructions.pdf'>
                    MapIT Users Guide FY2018
                  </a>{' '}
                  (PDF file, 1 MB) (Posted: 3/26/2018)
                </li>
                <li>
                  <a href='/Downloads/Resources/MapIT_FY_2018.zip'>MapIT Tool </a> (ZIP file, 53.2
                  MB) (Posted: 3/26/2018)
                </li>
                <li>
                  <a href='/Downloads/Resources/AHRQ_Quality_Indicators_MapIT_tool_documentation_March_2014.pdf'>
                    MapIT Tool documentation: Conversion of ICD9 to ICD10{' '}
                  </a>{' '}
                  (PDF file, 99 KB)
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Toolkits;
