import {styled} from '@mui/material';
import {clsx} from 'clsx';
import {Link} from 'react-router-dom';

export const LinkItem = ({
  children,
  className,
  href,
  meta,
  noborder,
  noclip,
  noicon,
  wrap,
  leftBorder,
  ...rest
}) => {
  const to = href ?? rest.to;
  const label = children?.toString();
  const fileExt = to.toLowerCase().split('.').pop() ?? undefined;
  const isFile = ['xlsx', 'pdf', 'zip'].includes(fileExt);

  return (
    <Item
      aria-label={label}
      className={clsx('list-link', className)}
      fileExt={isFile && fileExt}
      leftBorder={leftBorder}
      noborder={noborder}
      noclip={noclip}
      noicon={noicon}
      wrap={wrap}
      rel='noreferrer'
      target={isFile ? '_blank' : undefined}
      {...rest}
      to={to}>
      <div className='label'>{children}</div>
      {meta && <div className='meta'>{meta}</div>}
    </Item>
  );
};

const Item = styled(Link, {
  shouldForwardProp: (prop) =>
    ![
      'fileExt',
      'light',
      'mb',
      'leftBorder',
      'noborder',
      'noclip',
      'noicon',
      'rounded',
      'wrap',
    ].includes(prop),
})(({theme, fileExt, leftBorder, light, mb, noborder, noclip, noicon, rounded, wrap}) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  backgroundColor: light ? theme.palette.common.white : theme.palette.fill.lightGrey,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: rounded ? '0.33rem' : 0,
  borderWidth: noborder ? 0 : 1,
  fontSize: '1rem',
  lineHeight: 1.1,
  height: 61,
  marginBottom: mb !== undefined ? theme.spacing(mb === true ? 2 : mb) : 0,
  overflow: 'hidden',

  padding: '0rem 4rem',
  paddingLeft: '0',
  transition: 'background-color .2s',

  ...(leftBorder === true && {
    borderLeft: `3px solid ${theme.palette.primaryButton.main}`,
  }),

  ...(fileExt === 'pdf' &&
    !noicon && {
      backgroundImage: 'url(/images/icon-list-pdf.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '1rem center',
      paddingLeft: '2.5rem',
    }),

  ':before': {
    content: '""',
    position: 'absolute',
    top: -1,
    right: -1,
    bottom: -1,

    display: 'block',
    backgroundImage: `linear-gradient(110deg, transparent 32%, ${theme.palette.primaryButton.main} 33%)`,
    backgroundRepeat: `no-repeat`,
    transition: 'opacity .2s, width .2s',
    opacity: 0,
    width: '0rem',
  },

  '&:after': {
    content: `""`,
    position: 'absolute',
    top: -1,
    right: -1,
    bottom: -1,

    display: 'block',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: 0,
    transition: 'opacity .2s',
    width: '3rem',
  },

  ':hover': {
    backgroundColor: theme.palette.fill.lightBlue,
    textDecoration: 'none',

    ':before': {
      opacity: 1,
      width: '4rem',
    },
    '&:after': {
      backgroundImage: fileExt
        ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill="white" d="M2.5 20C1.8125 20 1.22396 19.7552 0.734375 19.2656C0.244792 18.776 0 18.1875 0 17.5V13.75H2.5V17.5H17.5V13.75H20V17.5C20 18.1875 19.7552 18.776 19.2656 19.2656C18.776 19.7552 18.1875 20 17.5 20H2.5ZM10 15L3.75 8.75L5.5 6.9375L8.75 10.1875V0H11.25V10.1875L14.5 6.9375L16.25 8.75L10 15Z"/></svg>')`
        : `url('data:image/svg+xml;utf8,<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 15.5L10.75 13.6875L15.1875 9.25H0V6.75H15.1875L10.75 2.3125L12.5 0.5L20 8L12.5 15.5Z" fill="white"/></svg>')`,
      opacity: 1,
    },
  },

  '.label, .meta': {
    display: '-webkit-box',
    lineHeight: 1.1,
    padding: '0 0 0 1rem',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',

    ...(noclip === true && {
      overflow: 'initial',
      whiteSpace: 'nowrap',
    }),

    ...(wrap === true && {
      overflow: 'hidden',
      whiteSpace: 'normal',
      WebkitLineClamp: 2,
    }),
  },
  '.label': {
    color: theme.palette.link.main,
    fontWeight: 700,

    ...(wrap === true && {
      lineHeight: 1,
      paddingBottom: '2px',
    }),
  },
  '.meta': {
    color: theme.palette.text.primary,
  },
}));
