import React from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const QISoft = () => {
    return (<>

        <Container className='outerContainer'>
            <h1 className="mb-4">QI Windows® Software Version v6.0 ICD-9-CM</h1>
            <p className="lead">The Quality Indicators Windows Application is easy to use with step-by-step instructions on loading your data and verifying that the data are in the format required. Users can look at the results for individual cases, the organization as a whole, or for subgroups based on patient demographics.</p>
            <p>Please note that WinQI v6.0 incorporates the same updates as found in SAS v6.0.</p>
        </Container>
        <Container className='outerContainer'>

            <h2 className="mb-4">Software Instructions</h2>
            <ul>
                <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V60/Software_Inst_WinQI_V60_July_2016.pdf'>Software Instructions (WinQI) Version v6.0 ICD-9-CM, July 2016</a> (valid for version 6.0)</li>
            </ul>
            <p>The AHRQ Quality Indicators Windows Application may be installed on 64-bit or 32-bit platforms, and no third-party statistical software is needed.</p>
            <p>SQL Server and .NET are provided free of charge by Microsoft. For more information please see the following links on the Microsoft website: (this will take you away from the AHRQ website)</p>
            <ul>
                <li><a href='http://www.microsoft.com/en-us/download/details.aspx?id=1695'>Microsoft SQL Server 2008 Express Edition</a><Link to="/ExternalDisclaimer"><img alt="external web link policy" className="ml-1" src="/images/exit_disclaimer.png" /></Link> (select 64-bit or 32-bit version)</li>
                <li><a href='http://www.microsoft.com/download/en/details.aspx?id=17718'>Microsoft .NET Framework External Web Link Policy</a><Link to="/ExternalDisclaimer"><img alt="external web link policy" className="ml-1" src="/images/exit_disclaimer.png" /></Link> (one version covers both 64-bit and 32-bit)</li>
            </ul>
        </Container>
        <Container className='outerContainer'>
            <h2 className="mb-4">Software Instructions</h2>
            <ul>
                <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V60/Software_Inst_WinQI_V60_July_2016.pdf'>Software Instructions (WinQI) Version v6.0 ICD-9-CM, July 2016</a> (valid for version 6.0)</li>
            </ul>
            <p>The AHRQ Quality Indicators Windows Application may be installed on 64-bit or 32-bit platforms, and no third-party statistical software is needed.</p>
            <p>SQL Server and .NET are provided free of charge by Microsoft. For more information please see the following links on the Microsoft website: (this will take you away from the AHRQ website)</p>
            <ul>
                <li><a href='http://www.microsoft.com/en-us/download/details.aspx?id=1695'>Microsoft SQL Server 2008 Express Edition</a><Link to="/ExternalDisclaimer"><img alt="external web link policy" className="ml-1" src="/images/exit_disclaimer.png" /></Link> (select 64-bit or 32-bit version)</li>
                <li><a href='http://www.microsoft.com/download/en/details.aspx?id=17718'>Microsoft .NET Framework External Web Link Policy</a><Link to="/ExternalDisclaimer"><img alt="external web link policy" className="ml-1" src="/images/exit_disclaimer.png" /></Link> (one version covers both 64-bit and 32-bit)</li>
            </ul>
        </Container>
        <Container className='outerContainer'>
            <h2 className="mb-4">Software Tutorial Video</h2>
            <ul>
                <li>To learn more about how to use the software, view the <a href='https://youtu.be/-lNnrq5IW2I'>WinQI tutorial video</a><Link to="/ExternalDisclaimer"><img alt="external web link policy" className="ml-1" src="/images/exit_disclaimer.png" /></Link></li>
            </ul>
        </Container>
        <Container className='outerContainer'>
            <h2 className="mb-4">WinQI v6.0 ICD-9-CM Software for PSI and PQI Indicators</h2>
            <ul>
                <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V60_ICD09/quality_indicators_x64_Setup.zip'>WinQI v6.0 ICD-9-CM (64 bit) without Grouper Software for download</a> – Version 6.0, September 2016</li>
                <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V60_ICD09/quality_indicators_x32_Setup.zip'>WinQI v6.0 ICD-9-CM (32 bit) without Grouper Software for download</a> – Version 6.0, September 2016</li>

            </ul>
            <p>APR-DRG codes are used in risk adjustment calculation for IQI modules only. WinQI v6.0 ICD-9-CM does not yet incorporate the updated APR-DRG grouper as the IQI module is not included in this release. The APR-DRG grouper software will be included in the future version of WinQI v6.0 ICD-9-CM that incorporates IQI module.</p>
        </Container>
        <Container className='outerContainer'>
            <h2 className="mb-4">WinQI v5.0.3 ICD-9-CM Software</h2>
            <ul>
               <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V50/quality_indicators_with_apr_x64_Setup.exe'>WinQI v5.0.3 ICD-9-CM (64 bit) with Grouper Software for download</a> – Version 5.0.3, February 2016</li>
               <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V50/quality_indicators_x64_Setup.exe'>WinQI v5.0.3 ICD-9-CM (64 bit) without Grouper Software for download</a> – Version 5.0.3, February 2016</li>
            </ul>
            <ul>
               <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V50/quality_indicators_with_apr_x32_Setup.exe'>WinQI v5.0.3 ICD-9-CM (32 bit) with Grouper Software for download</a> – Version 5.0.3, February 2016</li>
               <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V50/quality_indicators_x32_Setup.exe'>WinQI v5.0.3 ICD-9-CM (32 bit) without Grouper Software for download</a> – Version 5.0.3, February 2016</li>
            </ul>
            <p>The ICD-9 WinQI v5.0 QI modules and earlier can be found on the <Link to="/archive/software">Archives page.</Link></p>
        </Container>
        <Container className='outerContainer'>
            <h2 className="mb-4">Supporting Software Feature Documentation</h2>
            <ul>
              <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V41B/Calculating%20the%20Smoothed%20Rate%20CI%20in%20WinQI.pdf'>Instructions for Calculating Smoothed Rate Confidence Intervals in WinQI, September 2010</a></li>
              <ul>
                  <li><a href='https://www.qualityindicators.ahrq.gov/Downloads/Software/WinQI/V41B/Calculating%20the%20Smoothed%20Rate%20CI%20in%20WINQI.xls'>Workbook for Calculating the Smoothed Rate Confidence Intervals in WinQI</a></li>
                  </ul>
              </ul>
        </Container>
    </>)
}

export default QISoft;