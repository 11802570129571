import styles from './ux.module.scss';
import '../../css/button.scss';
import { Box } from '@mui/material';
// import { Button } from '@mui/material';
import { Button } from 'react-bootstrap';
import { MuiUx } from './MuiUx';

const Ux = () => {
  return (
    <>
      <Box m={4}>
        <h1>UI Example</h1>
        <div>
          <h2>Colors</h2>
          <div className={styles.ColorContainerMain}>
            <div>
              <h3>Primary Color Palette</h3>
              <div className={`${styles.ColorContainer} ${styles.Primary} ${styles.Blue}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className={`${styles.ColorContainer} ${styles.Primary} ${styles.Purple}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className={`${styles.ColorContainer} ${styles.Primary} ${styles.Yellow}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <h3>Secondary Color Palette</h3>
              <div className={`${styles.ColorContainer} ${styles.Secondary} ${styles.Red}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className={`${styles.ColorContainer} ${styles.Secondary} ${styles.Green}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <h3>Text Color Palette</h3>
              <div className={`${styles.ColorContainer} ${styles.Text}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <h3>Border Color Palette</h3>
              <div className={`${styles.ColorContainer} ${styles.BorderAccent}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <h3>LinkButton Color Palette</h3>
              <div className={`${styles.ColorContainer} ${styles.LinkButton}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2>Buttons</h2>
          <div className={styles.ButtonContainer}>
            <div>
              <Button className='large primary'>Large Primary</Button>
              <Button className='large primary arrow-left'>Large Primary Left</Button>
              <Button className='large primary arrow-down'>Large Primary Down</Button>
              <Button className='large primary arrow-right'>Large Primary Right</Button>
              <Button className='large primary' disabled>
                Large Primary Disabled
              </Button>
              <Button className='large primary arrow-left' disabled>
                Large Primary Left Disabled
              </Button>
              <Button className='large primary arrow-down' disabled>
                Large Primary Down Disabled
              </Button>
              <Button className='large primary arrow-right' disabled>
                Large Primary Right Disabled
              </Button>
            </div>
            <div>
              <Button className='small primary'>Small Primary</Button>
              <Button className='small primary arrow-left'>Small Primary Left</Button>
              <Button className='small primary arrow-down'>Small Primary Down</Button>
              <Button className='small primary arrow-right'>Small Primary Right</Button>
              <Button className='small primary' disabled>
                Small Primary Disabled
              </Button>
              <Button className='small primary arrow-left' disabled>
                Small Primary Left Disabled
              </Button>
              <Button className='small primary arrow-down' disabled>
                Small Primary Down Disabled
              </Button>
              <Button className='small primary arrow-right' disabled>
                Small Primary Right Disabled
              </Button>
            </div>
            <div>
              <Button className='large secondary'>Large Secondary</Button>
              <Button className='large secondary arrow-left'>Large Secondary Left</Button>
              <Button className='large secondary arrow-down'>Large Secondary Down</Button>
              <Button className='large secondary arrow-right'>Large Secondary Right</Button>
              <Button className='large secondary' disabled>
                Large Secondary Disabled
              </Button>
              <Button className='large secondary arrow-left' disabled>
                Large Secondary Left Disabled
              </Button>
              <Button className='large secondary arrow-down' disabled>
                Large Secondary Down Disabled
              </Button>
              <Button className='large secondary arrow-right' disabled>
                Large Secondary Right Disabled
              </Button>
            </div>
            <div>
              <Button className='small secondary'>Small Secondary</Button>
              <Button className='small secondary arrow-left'>Small Secondary Left</Button>
              <Button className='small secondary arrow-down'>Small Secondary Down</Button>
              <Button className='small secondary arrow-right'>Small Secondary Right</Button>
              <Button className='small secondary' disabled>
                Small Secondary Disabled
              </Button>
              <Button className='small secondary arrow-left' disabled>
                Small Secondary Left Disabled
              </Button>
              <Button className='small secondary arrow-down' disabled>
                Small Secondary Down Disabled
              </Button>
              <Button className='small secondary arrow-right' disabled>
                Small Secondary Right Disabled
              </Button>
            </div>
          </div>
        </div>
        <div>
          <h2>Typography</h2>
          <div className={styles.ButtonContainer}>
            <h1>H1 Heading</h1>
            <h2>H2 Heading</h2>
            <h3>H3 Heading</h3>
            <h4>H4 Heading</h4>
            <h5>H5 Heading</h5>
            <h6>H6 Heading</h6>
            <div className='text-xl'>Body XL</div>
            <div className='text-large'>Body Large</div>
            <div className='text-regular'>Body Regular</div>
            <div className='text-small'>Body Small</div>
            <div className='text-xs'>Body XS</div>
            <div className='text-tag-large'>Tag Large</div>
            <div className='text-tag-small'>Tag Small</div>
          </div>
        </div>
      </Box>
      <MuiUx />
    </>
  );
};

export default Ux;
