import { Box, Chip, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { format } from 'date-fns';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

const contentData = {
  software: {
    title: `Using Software and Instructional Documentation Materials`,
    desc: `References to Agency for Healthcare Research and Quality (AHRQ) Quality Indicator (QI) measures produced from AHRQ QI software must be attributed to AHRQ. Citation should list the name of the tool, tool version, Agency for Healthcare Research and Quality, Rockville, MD., the related Web link, and access date.`,
  },
  technical: {
    title: `Using Technical Documentation Materials`,
    desc: `The Agency for Healthcare Research and Quality (AHRQ) must be cited when referencing AHRQ Quality Indicator (QI) measures or using them for other materials or products. Any alterations to QI measure specifications must be noted, along with a disclaimer of not being endorsed by AHRQ.`,
  },
};

export const PageDisclaimer = ({ resource = '', software = false, technical = false }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleOnCopy = (text, result) => {
    setIsCopied(true);
  };

  const data = software
    ? contentData.software
    : technical
    ? contentData.technical
    : { title: 'MISSING_DISTINCTION', dec: 'use: software or technical' };

  const date = format(new Date(), 'PPP');

  const citation = `${resource}, Agency for Healthcare Research and Quality, Rockville, MD. ${window.document.location.origin}${window.document.location.pathname}. Accessed ${date}.`;

  return (
    <Container>
      <h5 className='title'>{data.title}</h5>
      <div className='desc'>
        {data.desc} <Link to='/disclaimer'>Click to view the disclaimer.</Link>
      </div>

      <CopyToClipboard text={citation} onCopy={handleOnCopy}>
        <CitationBox className='copy-block'>
          <span>{citation}</span>
          <div className='mt-5'>
            <CitationChip
              label={isCopied ? 'Citation Copied' : 'Copy this Required Citation'}
              
              copied={isCopied}
              icon={isCopied ? <CheckIcon /> : undefined}
            />
          </div>
        </CitationBox>
      </CopyToClipboard>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'rgba(0, 113, 188, .25)',
  border: `1px solid rgba(0, 0, 0, .07)`,
  borderRadius: '0.33rem',
  padding: theme.spacing(4),
}));

const CitationBox = styled(Box)(({ theme, copied = false }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  backgroundColor: theme.palette.fill.lightGrey,
  borderRadius: '0.5rem',
  boxShadow: theme.shadows[4],
  cursor: 'pointer',
  marginTop: theme.spacing(3),
  outline: `1px solid ${theme.palette.grey[200]}`,
  outlineOffset: '-1px',
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  textAlign: 'left',
  transition: 'box-shadow .3s ease-in-out, background-color .2s',

  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    right: 0,

    display: 'block',
    borderRadius: `0 0 0.5rem 0`,
    width: 30,
    height: 60,
    transition: 'width .2s',

    backgroundImage: `linear-gradient(to bottom right, transparent 50%, ${theme.palette.warning.main} 51%)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: '100%',
  },
  '&:after': {
    content: `""`,
    position: 'absolute',
    bottom: 2,
    right: 0,

    display: 'block',
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },

  '&:hover': {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],

    '&:before': {
      backgroundImage: `linear-gradient(to bottom right, transparent 50%, ${theme.palette.primaryButton.main} 51%)`,
      width: 40,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="white" fill-opacity="1" d="M12.6667 2.66675H4.66671C3.93337 2.66675 3.33337 3.26675 3.33337 4.00008V13.3334H4.66671V4.00008H12.6667V2.66675ZM14.6667 5.33341H7.33337C6.60004 5.33341 6.00004 5.93341 6.00004 6.66675V16.0001C6.00004 16.7334 6.60004 17.3334 7.33337 17.3334H14.6667C15.4 17.3334 16 16.7334 16 16.0001V6.66675C16 5.93341 15.4 5.33341 14.6667 5.33341ZM14.6667 16.0001H7.33337V6.66675H14.6667V16.0001Z"/></svg>')`,
    },
  },
}));

const CitationChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'copied',
})(({ theme, copied }) => ({
  backgroundColor: copied ? theme.palette.success.main : theme.palette.primaryButton.main,
  color: theme.palette.common.white,
  cursor: 'pointer',
  height: 'auto',
  marginBottom: '.5rem',

  '.MuiSvgIcon-root': {
    color: copied ? 'white' : theme.palette.primaryButton.main,
    marginLeft: '12px',
  },

  '.MuiChip-label': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '38px',
    padding: copied ? '0 16px 0 8px' : '0 16px',
  },
}));
