const tryGetNewUrlIfApplicable = (location) => {
  try {
    return getNewUrlIfApplicable(location);
  } catch {
    // do nothing
  }
  return false;
};

const getNewUrlIfApplicable = (location) => {
  const currentPath = location?.pathname?.toLowerCase() ?? '';
  const currentSearch = location?.search;

  const simpleMappings = [
    // home
    ['/Default.aspx', '/home'],
    ['/Home/AboutUs.aspx', '/home/about'],
    ['/Home/ContactUs.aspx', '/home/contact'],
    // measures
    ['/Modules/default.aspx', '/measures/qi_resources'],
    ['/Modules/pqi_resources.aspx', '/measures/pqi_resources'],
    ['/Modules/iqi_resources.aspx', '/measures/iqi_resources'],
    ['/Modules/psi_resources.aspx', '/measures/psi_resources'],
    ['/Modules/pdi_resources.aspx', '/measures/pdi_resources'],
    ['/Modules/all_resources.aspx', '/measures/all_resources'],
    ['/Modules/list_ahrq_qi.aspx', '/measures/list_ahrq_qi'],
    // software
    ['/Software/Default.aspx', '/software/qi'],
    ['/Software/SAS.aspx', '/software/sas_qi'],
    ['/Software/winQI.aspx', '/software/win_qi'],
    // news
    ['/News/Default.aspx', '/news'],
    // resources
    ['/Resources/Default.aspx', '/resources/landing'],
    ['/Resources/webinars.aspx', '/resources/webinars'],
    ['/Resources/case_studies.aspx', '/resources/case_studies'],
    ['/Resources/Presentations.aspx', '/resources/presentations'],
    ['/Resources/Publications.aspx', '/resources/publications'],
    ['/Resources/Toolkits.aspx', '/resources/toolkits'],
    // faq
    ['/FAQs_Support/', '/faqs', 'faq'],
    // archive
    ['/Archive', '/archive/qi_modules', 'archive'],
    ['/Archive/Software.aspx', '/archive/software', 'archive'],
    ['/Archive/News.aspx', '/archive/news', 'archive'],
    ['/Archive/Resources.aspx', '/archive/resources', 'archive'],
  ];

  let mappingToUse = null;
  for (const simpleMapping of simpleMappings) {
    if (
      simpleMapping[0].toLowerCase().replace(/^\/+|\/+$/g, '') ===
      currentPath.replace(/^\/+|\/+$/g, '')
    ) {
      mappingToUse = simpleMapping;
      break;
    }
  }

  if (mappingToUse) {
    if (mappingToUse.length >= 2 && (mappingToUse[2] === 'faq' || mappingToUse[2] === 'archive')) {
      return currentSearch ? `${mappingToUse[1]}${currentSearch}` : `${mappingToUse[1]}`;
    }
    return mappingToUse[1];
  } else {
    // dynamic mapping

    // archive
    const archiveRegex = /archive\/(?<module>PQI|IQI|PSI|PDI)_TechSpec_(?<version>.+).aspx/i;
    const archiveFound = currentPath.match(archiveRegex);
    if (archiveFound && archiveFound.groups) {
      const archiveModule = archiveFound.groups.module;
      const archiveVersion = archiveFound.groups.version;
      return `/archive/${archiveModule}_techspec/${archiveVersion}`;
    }

    // log of coding update
    const codingUpdateRegex =
      /modules\/log_coding_updates_(?<module>PQI|IQI|PSI|PDI)_(?<version>.+).aspx/i;
    const codingUpdateFound = currentPath.match(codingUpdateRegex);
    if (codingUpdateFound && codingUpdateFound.groups) {
      const codingUpdateModule = codingUpdateFound.groups.module;
      const codingUpdateVersion = codingUpdateFound.groups.version;
      return `/measures/${codingUpdateModule}_log_coding_updates/${codingUpdateVersion}`;
    }

    // log of coding update
    const techSpecRegex = /modules\/(?<module>PQI|IQI|PSI|PDI)_techspec_(?<version>.+).aspx/i;
    const techSpecFound = currentPath.match(techSpecRegex);
    if (techSpecFound && techSpecFound.groups) {
      const techSpecModule = techSpecFound.groups.module;
      const techSpecVersion = techSpecFound.groups.version;
      return `/archive/${techSpecModule}_techspec/${techSpecVersion}`;
    }
  }

  return false;
};

export default tryGetNewUrlIfApplicable;
