import React from 'react';
import { Container } from 'react-bootstrap';
import { resourcesData } from '../../data/resources';
import { Link } from 'react-router-dom';
import styles from './resources.module.scss';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const Publications = () => {
  const publications = resourcesData.publications;
  publications.sort((a, b) => a.title.localeCompare(b.title));
  const maxYear = publications
    .map((publication) => publication.year)
    .reduce((a, b) => Math.max(a, b));
  const minYear = publications
    .map((publication) => publication.year)
    .reduce((a, b) => Math.min(a, b));
  return (
    <div className={styles.Publications}>
      <NormalTopBanner>
        <h1>Publications</h1>
        <p>
          The list below presents selected articles from {minYear} through {maxYear} that focus on
          the use of AHRQ QIs for quality improvement purposes.
        </p>
      </NormalTopBanner>
      <Container className='outer-container'>
        {publications.map((publication, index) => (
          <div
            key={index}
            className={`${styles.PublicationContainer} year-${publication.year ?? ''}`}
          >
            <div>
              <div className='mb-5' dangerouslySetInnerHTML={{ __html: publication.title }} />
              <div className={` ${styles.IndicatorsGroup}`}>
                <div className='bold'>Indicators:</div>
                {publication.indicators.map((indicator, index) => (
                  <div key={index}>
                    <div className={styles.IndicatorsTag}>{indicator.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div key='bottom' className={styles.PublicationContainer}>
          <div>
            <p>
              For a complete list of articles by year, please go the{' '}
              <Link to='/Downloads/Resources/Publications/FullListPublications-508.pdf'>
                AHRQ QI Publications Brochure (PDF File, 145 KB)
              </Link>
              .
            </p>
            <p>
              For publications prior to 2021 see the{' '}
              <Link to='/archive/resources'>Archive Resources page</Link>.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Publications;
