import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import { Box } from '@mui/material';
import QICard from '../../Common/QICard/QICard';
import { pqeMeasuresData } from '../../../data/measures/pqe';
import {
  Button,
  Flex,
  ImagePageRow,
  PageHeader,
  PageRow,
  Tile,
  TileGroup,
} from '../../../ui';
import { pqeResourcePQEVideos } from '../../../data/videos';
import { VideoModal } from '../../Common';
import { getReleaseItem } from '../../../data/releases';

export const PQEResources = () => {
  const scrollToResourcesRef = useRef(null);
  const scrollToSoftwareRef = useRef(null);
  const scrollToVideosRef = useRef(null);

  const icd10Faq = getReleaseItem('Icd10Faq');

  const [pqeVideoModalInfo, setPQEVideoModalInfo] = useState({ isOpen: false, url: '' });

  useEffect(() => {
    if (window.location.hash === '#ed-pqi-videos') {
      setTimeout(() => {
        scrollToVideosRef.current.scrollIntoView();
      });
    }
  }, []);

  const measure = {
    title: 'Prevention Quality Indicators in Emergency Department Settings',
    titlePlural: 'Prevention Quality Indicators in Emergency Department Settings',
    abbrev: 'PQE',
  };

  return (
    <>
      <PageHeader.Row bgSrc='/images/measures-hero-bg.jpg' split sx={{ paddingBottom: '6rem' }}>
        <PageHeader.Title>{measure.title} Measures</PageHeader.Title>
        <PageHeader.Content>
          <div>
            The {measure.titlePlural} (<strong>{measure.abbrev}</strong>s) are emergency department (ED) visit rates. 
            Like the other area QIs, they are avoidable use measures. 
            They identify conditions, for which access to quality ambulatory care can reduce the likelihood of hospital care.
          </div>
          <Box display='flex' flexDirection='column' gap={1.5}>
            <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={1.5}>
              <Button
                label={`Go to the ${measure.abbrev} Resources`}
                onClick={() => scrollToResourcesRef.current.scrollIntoView()}
                arrowDown
              />
              <Button
                label={`View ${measure.abbrev} Videos`}
                onClick={() => scrollToVideosRef.current.scrollIntoView()}
                media
              />
            </Box>
            <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={1.5}>
              <Button
                label='PQE Software'
                onClick={() => scrollToSoftwareRef.current.scrollIntoView()}
                arrowDown
              />
            </Box>
          </Box>
        </PageHeader.Content>
      </PageHeader.Row>

      <ImagePageRow
        bgSrc='/images/hex-purple.png'
        imageSrc='/images/bg-ed-pqi-indicators-uses.jpg'
        purple
      >
        <h2>How are {measure.titlePlural} used?</h2>
        <p>
          These measures are part of the set of area level QIs that includes the inpatient
          Prevention Quality Indicators in Inpatient Settings (PQIs) and Pediatric Quality Indicators (PDIs). Unlike those
          measures, which are rates of inpatient discharges, the Emergency Department Prevention
          Quality Indicators (PQEs) are emergency department (ED) visit rates. They are avoidable
          use measures and identify conditions, for which access to quality ambulatory care can
          reduce the likelihood of hospital care.
        </p>
        <div>
          <Button
            label='How to Use PQE Measures'
            aria-label='How to Use PQE Measures link'
            href='/measures/how_to_use_pqe_resources'
            color='info'
            variant='outlined'
            arrow
            sx={{
              backgroundColor: (t) => t.palette.common.white,
              borderColor: (t) => t.palette.common.white,
            }}
          />
        </div>
      </ImagePageRow>

      <PageRow
        sx={{
          paddingTop: '7rem',
          borderBottom: '1px solid',
          borderColor: (t) => t.palette.divider,
        }}
      >
        <h2 ref={scrollToSoftwareRef} className='align-center'>
          PQE Software Available
        </h2>
        <p>
          AHRQ offers free software to help users using the AHRQ QIs generate results that are both
          accurate and actionable. Use of this free software ensures a standard, trusted approach to
          quality measurement and means more resources are available for supporting improvements to
          patient care.
        </p>
        <TileGroup type='measures' columns={{ xs: 2, md: 4, lg: 4, xl: 4 }} gap='sm' py={3}>
          <Tile
            title='SAS QI v2024'
            description='Ideal for researchers and advanced users to run in a SAS/STAT software package.'
            to='/software/sas_qi'
            toLabel={
              <>
                Now available, PQE v2024
              </>
            }
          />
          <Tile
            title='CloudQI v2024'
            description={`CloudQI's robust user interface is ideal for non-programmers and health care professionals.`}
            to='/software/cloudqi'
            toLabel={
              <>
                Now available, PQE v2024
              </>
            }
          />
          <Tile
            title='Frequently Asked Questions'
            description={`Read the latest software release FAQ`}
            to={icd10Faq.url}
            newTab
            toLabel={
              <>
                <Button
                  label={`Learn More ${icd10Faq.info}`}
                />
              </>
            }
          />
        </TileGroup>
      </PageRow>

      <PageRow bgSrc='/images/bg-tech-specs-list.jpg' bgSrcNone='sm' sx={{ paddingTop: '7rem' }}>
        <Box
          pb={6}
          textAlign={{ xs: 'center', sm: 'left' }}
          width={{ xs: '100%', sm: '60%', lg: '50%' }}
        >
          <h2>{pqeMeasuresData.technical[0].title}</h2>
          <p>
            Breaks down calculations used to formulate PQEs, including a brief description of the
            measures, numerator and denominator information, and details on cases that should be
            excluded from calculations.
          </p>
          <Button
            label={pqeMeasuresData.technical[0].cta}
            aria-label={pqeMeasuresData.technical[0].aria}
            href={pqeMeasuresData.technical[0].url}
            arrow
          />
        </Box>
      </PageRow>

      <PageRow lightBlue>
        <h2 ref={scrollToResourcesRef} className='align-center'>
          PQE Resources
        </h2>
        <Tabs defaultActiveKey='technical' className='qiTabs'>
          <Tab id='techspecs' eventKey='technical' title='Technical Specifications'>
            <Container>
              <Row>
                {pqeMeasuresData.technical.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
          <Tab eventKey='additional' title='Additional PQE Resources'>
            <Container>
              <Row>
                {pqeMeasuresData.additional.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </PageRow>

      <PageRow>
        <Box ref={scrollToVideosRef}>
          <h2>Videos about PQE</h2>
          <Flex gap={3}>
            <div>
              <p>
                ED PQI is now PQE. The videos below reference version 2023, but remain relevant for version 2024.
              </p>
            </div>
            <Flex
              gap={3}
              pb={3}
              flexDirection={'row'}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              {Object.keys(pqeResourcePQEVideos).map((pqeVideoKey) => {
                const video = pqeResourcePQEVideos[pqeVideoKey];
                return (
                  <Flex
                    key={video.id}
                    flex='unset'
                    alignItems='center'
                    alignSelf='flex-start'
                    gap={3}
                    width={{ xs: '45%', md: '30%' }}
                  >
                    <button
                      onClick={() => setPQEVideoModalInfo({ isOpen: true, url: video.url })}
                      className='video-thumbnail'
                    >
                      {video.thumb !== undefined && (
                        <img alt='Video Thumbnail' src={video.thumb} width='100%' />
                      )}
                    </button>
                    <p className='text-center'>
                      <a aria-label={video.label} target='_blank' rel='noreferrer' href={video.url}>
                        {video.text}
                      </a>
                      <a href='/ExternalDisclaimer'>
                        <img
                          alt='external web link policy'
                          className='ml-1'
                          src='/images/exit_disclaimer.png'
                        />
                      </a>
                    </p>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
          <VideoModal
            isOpen={pqeVideoModalInfo?.isOpen ?? false}
            onClose={() => setPQEVideoModalInfo(null)}
            url={pqeVideoModalInfo?.url}
          />
        </Box>
      </PageRow>
    </>
  );
};
