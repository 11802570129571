import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import './custom.scss';
import './css/app.scss';
import './css/button.scss';
import './css/color.scss';
import './css/container.scss';
import './css/typography.scss';
import './css/qitabs.scss';
import './css/margins.scss';
import './css/stepper.scss';
import './css/accordion.scss';
import './css/footer.scss';
import Home from './pages/Home/home';
import DisclaimerPage from './pages/Other/DisclaimerPage';
import Ux from './pages/Example/ux';
import AboutUs from './pages/Home/aboutus';
import ContactUs from './pages/Home/contactus';
import Navigation from './components/Navigation/navbar';
import ScrollToTop from './ScrollToTop';
import Header from './components/Navigation/header';
import Footer from './components/Navigation/footer';
import News from './pages/News/news';
import Announcements from './pages/Announcements/announcement';
import Resources from './pages/Resources/Resources';
import FAQs from './pages/FAQs/faqs';
import Archives from './pages/Archives/Archives';
import Software from './pages/Software/Software';
import ExitDisclaimer from './pages/Other/ExitDisclaimer';
import Measures from './pages/Measures/measures';
import NotFound from './pages/Other/NotFound';
import GoogleAnalytics from './GoogleAnalytics';
import tryGetNewUrlIfApplicable from './redirectHelper';
import { MuiThemeProvider, ReactQueryProvider } from './providers';

const App = () => {
  const history = useHistory();

  useEffect(() => {
    if (history) {
      const location = history.location;
      const newUrl = tryGetNewUrlIfApplicable(location);
      if (newUrl) {
        history.push(newUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <ReactQueryProvider>
      <MuiThemeProvider>
        <Header />
        <Navigation />
        <ScrollToTop />
        <main id='main-content'>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/Ux' exact component={Ux} />
            <Route path='/home' exact component={Home} />
            <Route path='/home/about' component={AboutUs} />
            <Route path='/home/contact' component={ContactUs} />
            <Route path='/disclaimer' component={DisclaimerPage} />
            <Route path='/faqs' component={FAQs} />
            <Route path='/resources/:section?' component={Resources} />
            <Route path='/news' component={News} />
            <Route path='/announcements/:year?/:month?' component={Announcements} />
            <Route path='/software/:section?' component={Software} />
            <Route path='/externalDisclaimer' exact component={ExitDisclaimer} />
            <Route path='/measures/:section?/:subsection?/:subsubsection?' component={Measures} />
            <Route path='/archive/:section?/:subsection?/:subsubsection?' component={Archives} />
            <Route component={NotFound} />
          </Switch>
        </main>
        <GoogleAnalytics />
        <Footer />
      </MuiThemeProvider>
    </ReactQueryProvider>
  );
};

export default App;
