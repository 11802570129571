import React from 'react';
import { Box, styled } from '@mui/material';
import AHRQ from '../../../img/AHRQ.jpg';
import { Button, Flex, PageRow } from '../../../ui';

export const PowerOfQIs = () => {
  return (
    <PageRow sx={{ paddingTop: '7rem', paddingBottom: '6rem' }}>
      <Flex margin='0 auto' maxWidth={800} textAlign='center' gap={2} mb={2}>
        <h2>
          Putting the <span className='color-primary-1 text-italic'>power</span> of the AHRQ Quality
          Indicators in your hands
        </h2>
        <p>
          AHRQ offers free software to help organizations easily use the AHRQ QIs to generate
          actionable information about the quality of care they provide. The evidence-based AHRQ QIs
          provide a standard, trusted approach to quality measurement to guide improvements in
          patient care
        </p>
      </Flex>

      <Row className='power-of-qi'>
        <Column className='image-column' image>
          <Image alt='AHRQ' src={AHRQ} />
        </Column>
        <Column style={{maxWidth: '650px'}} className='text-column' text>
          <Content>
            <h4>Calculate Quality Indicators with our SAS and WinQI Software</h4>
            <p>
              Technical Specifications break down calculations used to formulate each indicator,
              including a brief description of the measure, numerator and denominator information,
              and details on cases that should be excluded from calculations.
            </p>
            <ul>
              <li>
                Easily calculate quality of care events that might need further study Leverage
                software for hospital inpatient administrative data Make data readily available and
                inexpensive Completely free and easy to use
              </li>
              <li>Leverage software for hospital inpatient administrative data Make data</li>
              <li>Make data readily available and inexpensive</li>
              <li>Completely free and easy to use</li>
            </ul>
            <Flex flexDirection={{ xs: 'column', md: 'row' }} gap={2} pt={1}>
              <Button href='/software/win_qi'>Get WinQI Software</Button>
              <Button href='/software/sas_qi'>Get SAS QI Software</Button>
              <Button href='/software/cloudqi'>Get CloudQI Software</Button>
            </Flex>
          </Content>
        </Column>
      </Row>
    </PageRow>
  );
};

const Row = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing(4),
  margin: '0 auto',
  maxWidth: 1100,

  [theme.breakpoints.up('lg')]: {
    alignItems: 'flex-end',
    gap: 0,
    paddingTop: '3rem',
  },
}));

const Column = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'image' && prop !== 'text',
})(({ theme, image, text }) => ({
  maxWidth: 584,

  ...(text && {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '0.5rem',
    borderTop: `9px solid ${theme.palette.warning.main}`,
    boxShadow: theme.shadows[4],
  }),

  [theme.breakpoints.up('lg')]: {
    ...(image && {
      position: 'absolute',
      top: 0,
      left: 0,
    }),
  },
}));

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
}));

const Image = styled('img')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '0.5rem',
  boxShadow: theme.shadows[4],

  width: '100%',
}));
