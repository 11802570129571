import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications
        </h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications Updates - Version 5.0 (ICD 10),
          October 2015
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Prevention Quality Indicators Technical Specifications (PDF Format), Version
              5.0 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PQI_50_ICD10_techspecs_Provider_pdf.zip'
              href='/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI_50_ICD10_techspecs_Provider_pdf.zip'
            >
              Download All (ZIP File, 2 MB)
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={9}>
            <h5>
              Updated Prevention Quality Indicators Technical Specifications (MS Excel Format),
              Version 5.0 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PQI_50_ICD10_techspecs_Provider_excel.zip'
              href='/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI_50_ICD10_techspecs_Provider_excel.zip'
            >
              Download All (ZIP File, 1.4 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pqi'} version={'icd10_v50'} />
      </Container>
      <Container className='outerContainer'>
        <Row>
          <Col md={9}>
            <h4>All PQI Appendices in MS Excel Format (ZIP file)</h4>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PQI Appendices'
              href='/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI_Appendix_All.zip'
            >
              Download (PDF File, 952 KB)
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PQITechnical;
