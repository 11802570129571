import React from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

const ExitDisclaimerMain = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container className='outerContainer'>
      <h1 className='align-center mb-4'>Exit Disclaimer</h1>
      <p>
        Notice to users upon leaving this federal government Web site and entering a non-federal Web
        site.
      </p>
      <p>
        This graphic notice, icon indicating an External Web Link, means that you are leaving this
        federal government Web site and entering a non-federal Web site. This external link provides
        additional information that is consistent with the intended purpose of a federal site.
      </p>
      <p>
        Linking to a non-federal site does not constitute an endorsement by the Department of Health
        and Human Services (HHS) or any of its employees of the sponsors or the information and
        products presented on the site. HHS cannot attest to the accuracy of information provided by
        the link.
      </p>
      <p>You will be subject to the destination site's privacy policy when you follow the link.</p>
      <p>
        Return to <Link onClick={goBack}>Previous Page</Link> External Web Link Policy to continue.
      </p>
      <p>Current as of May 2013</p>
      <p>
        Internet Citation: Exit Disclaimer. September 2012. Agency for Healthcare Research and
        Quality, Rockville, MD. http://www.ahrq.gov/externaldisclaimer.html
      </p>
    </Container>
  );
};

export default ExitDisclaimerMain;
