const FooterLinks = {
  left: [
    [
      {
        link: 'https://www.ahrq.gov/cpi/about/careers/index.html',
        label: 'Careers',
      },
      {
        link: 'https://www.ahrq.gov/contact/index.html',
        label: 'Contact Us',
      },
      {
        link: 'https://www.ahrq.gov/topics/informacion-en-espanol/index.html',
        label: 'Español',
      },
      {
        link: 'https://info.ahrq.gov/',
        label: 'FAQs',
      },
    ],
    [
      {
        link: 'https://www.ahrq.gov/policy/electronic/accessibility/index.html',
        label: 'Accessibility',
      },
      {
        link: 'https://www.ahrq.gov/policy/electronic/disclaimers/index.html',
        label: 'Disclaimers',
      },
      {
        link: 'https://www.ahrq.gov/policy/eeo/index.html',
        label: 'EEO',
      },
      {
        link: 'https://www.ahrq.gov/policy/electronic/about/policyix.html',
        label: 'Electronic Policies',
      },
    ],
    [
      {
        link: 'https://www.ahrq.gov/policy/foia/index.html',
        label: 'FOIA',
      },
      {
        link: 'https://www.hhs.gov/web/governance/digital-strategy/index.html',
        label: 'HHS Digital Strategy',
      },
      {
        link: 'https://www.hhs.gov/civil-rights/for-individuals/nondiscrimination/index.html',
        label: 'HHS Nondiscrimination Notice',
      },
      {
        link: 'https://oig.hhs.gov/',
        label: 'Inspector General',
      },
    ],
    [
      {
        link: 'https://www.ahrq.gov/policy/electronic/plain-writing/index.html',
        label: 'Plain Writing Act',
      },
      {
        link: 'https://www.ahrq.gov/policy/electronic/privacy/index.html',
        label: 'Privacy Policy',
      },
      {
        link: 'https://www.hhs.gov/plugins.html',
        label: 'Viewers and Players',
      },
    ],
  ],
  right: [
    [
      {
        link: 'https://www.hhs.gov/',
        label: 'U.S. Department of Health and Human Services',
      },
      {
        link: 'https://www.whitehouse.gov/',
        label: 'The White House',
      },
      {
        link: 'https://www.usa.gov/',
        label: 'USA.gov',
      },
    ],
  ],
};

export default FooterLinks;
