import { Box, styled } from '@mui/material';

export const Rule = ({ ...rest }) => {
  return <Container {...rest} />;
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => !['dashed', 'dotted', 'spacing', 'size'].includes(prop),
})(({ theme, dashed, dotted, spacing = 4, size = 1 }) => ({
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  borderBottomWidth: size,
  borderBottomStyle: dashed ? 'dashed' : dotted ? 'dotted' : 'solid',
  marginTop: theme.spacing(spacing),
  marginBottom: theme.spacing(spacing),

  height: 0,
  width: '100%',
}));
