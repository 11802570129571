import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications
        </h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications Updates - Version v2020 (ICD
          10-CM/PCS), July 2020
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Prevention Quality Indicators Technical Specifications (PDF Format), Version
              v2020 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PQI_2020_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/PQI/V2020/TechSpecs/PQI_2020_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 6.9 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pqi'} version={'icd10_v2020'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Updates</h3>
        <p>
          <span className='danger'>March 2021</span>: Minor updates were made in some v2020
          technical specifications to clarify the indicator logic. Language was updated to better
          align with the logic implemented in the AHRQ QI software v2020. These minor updates are
          limited to the technical specifications documents, and no changes where made in the v2020
          software.
        </p>
        <p>Indicators updated: PQI Appendix A</p>
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          <span className='danger'>Effective v2019</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/Retirement%20Notice_v2019_Indicators.pdf'>
            Indicators Retirement Announcement.
          </a>{' '}
          (PDF File, 108 KB)
        </p>
        <ul>
          <li>PQI 02 Perforated Appendix Admission Rate</li>
          <li>PQI 09 Low Birth Weight Rate</li>
          <li>PQI 10 Dehydration Admission Rate</li>
        </ul>
        <p>
          <span className='danger'>Effective v6.0</span>, the following indicators have been
          retired.
        </p>
        <ul>
          <li>PQI 13 Angina Without Procedure Admission Rate is retired in version 6.0</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Prevention Quality Indicators Technical Specifications for SAS QI and WinQI ICD-10 v2019
            can be found <Link to='/archive/qi_modules?category=iqi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PQITechnical;
