import React from 'react'
import {pepoleSay} from '../../data/pepoleSay';
import User from '../../img/user.svg';
import styles from './software.module.scss'
import {Box} from '@mui/material';
import {Flex} from '../../ui';

const PeopleSayCloudQI = () => {
    return (<>
    <Box px={{lg: 16}} textAlign='center'>
        <h2 className='mb-5'>What People Are Saying About CloudQI</h2>
        <Flex 
          gap={10}  
          scroll
          flexDirection={'row'} 
          textAlign='left'
          className='mt-5'>
          {
            pepoleSay['data'].map((data, index) => (
              <Box key={`say-${index}`}>
                <div className={styles.MessageContainer}>
                  <p className='lead'>{data.msg}</p>
                </div>
                <div>
                  <img alt='user' className='ml-1 mr-5' src={User} />
                  {data.role}, {data.organization}
                </div>
              </Box>))
          }
        </Flex>
        </Box>
    </>)
}

export default PeopleSayCloudQI;