import React from 'react';
import { Container } from 'react-bootstrap';
import EnvelopeRegularBlackIcon from '../../img/envelope-regular-black.png';
import styles from './home.module.scss';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const ContactUs = () => {
  return (
    <div className={styles.ContactUs}>
      <NormalTopBanner>
        <h1>Contact Us</h1>
      </NormalTopBanner>
      <Container className='outer-container'>
        <div className='mb-10'>
          <h2>Technical Assistance</h2>
          <p className='m-0 text-regular'>
            The support e-mail address for the AHRQ Quality Indicators is{' '}
            <a href='mailto: QISupport@ahrq.hhs.gov'>QISupport@ahrq.hhs.gov</a>. The AHRQ Quality
            Indicators support team can also be reached by phone in the USA at (301) 427-1949.
            Messages are responded to within three business days.
          </p>
        </div>
        <div>
          <h3>Email updates</h3>
          <p className='text-regular'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://subscriptions.ahrq.gov/accounts/USAHRQ/subscriber/new?topic_id=USAHRQ_39'
            >
              Sign up
            </a>{' '}
            for email updates to stay informed about modifications and enhancements to the Quality
            Indicators and other information related to the AHRQ Quality Indicators. By signing up,
            you will be notified by email when AHRQ announces the availability of new Quality
            Indicators, revisions to the software and related tool, as well as publications,
            reports, and other related news.
          </p>
          <div className='flex'>
            <div className=''>
              <img alt='envelope icon' className='envelope pr-2' src={EnvelopeRegularBlackIcon} />
            </div>
            <div className=''>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://subscriptions.ahrq.gov/accounts/USAHRQ/subscriber/new?topic_id=USAHRQ_39'
              >
                Sign Up: Quality Indicators email updates
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
