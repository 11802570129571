import { Button as MUIButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export const Button = ({
  children,
  label,
  ariaLabel,
  color = 'primary',
  arrow = false,
  arrowDown = false,
  media = false,
  ...rest
}) => {
  const title = label ?? children;
  const ariaTitle = ariaLabel ?? label ?? children?.toString();

  const fileExt = (rest.href || rest.to)?.toLowerCase().split('.').pop() ?? undefined;
  const isFile = ['xlsx', 'pdf', 'zip'].includes(fileExt);
  const isLink = (rest.href || rest.to)?.includes('http');

  return (
    <MUIButton
      aria-label={ariaTitle}
      color={color}
      endIcon={
        arrow === true ? (
          <ChevronRightIcon />
        ) : arrowDown ? (
          <ArrowDownwardIcon />
        ) : media ? (
          <PlayCircleIcon />
        ) : undefined
      }
      target={isLink || isFile ? '_blank' : undefined}
      rel={isLink || isFile ? 'noreferrer' : undefined}
      component={isLink ? 'a' : undefined}
      {...rest}
    >
      <span>{title}</span>
    </MUIButton>
  );
};
