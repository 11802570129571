import {getReleaseItem} from '../releases';

export const psiMeasuresData = {
  technical: [
    {
      ...getReleaseItem('IndividualMeasureTechnicalSpecifications', 'PSI'),
      status: 'new',
    },
    {
      ...getReleaseItem('ParameterEstimates', 'PSI'),
      status: 'new',
    },
    {
      ...getReleaseItem('BenchmarkDataTables', 'PSI'),
      status: 'new',
    },
    {
      ...getReleaseItem('LogOfCodingUpdates', 'PSI'),
      status: 'new',
    },
    {
      ...getReleaseItem('CompositeMeasures', 'PSI'),
      status: 'new',
    },
  ],
  additional: [
    {
      title: 'PSI 90 Fact Sheet',
      desc: '<p>The Patient Safety and Adverse Events Composite for the International Classification of Diseases, 9th Revision, Clinical Modification (ICD-9-CM) v6.0, 2016), is an updated and modified version of the Patient Safety Indicator for Selected Indicators Composite (v5.0 and prior).</p>',
      url: '/News/PSI90_Factsheet_FAQ_v1.pdf',
      aria: 'Download PSI90_Factsheet_FAQ_v1.pdf',
      cta: 'Download (PDF File, 550 KB)',
    },
    {
      title: 'AHRQ Composite Measures Workgroup',
      desc: "<p>This report summarizes the work of the PSI Composite Measure Workgroup, which assisted AHRQ in developing a composite measure based on the Patient Safety Indicators. For more information on Composite Workgroups, visit the <a href='../measures/composite_workgroup'>AHRQ QI Composite Workgroups page.</a></p>",
      url: '/Downloads/Modules/PSI/PSI_Composite_Development.pdf',
      aria: 'Download PSI_Composite_Development.pdf',
      cta: 'Download (PDF File, 1.79 MB)',
    },
    {
      title: 'AHRQ PSI Development',
      desc: '<p>How the PSI measure set was developed.</p>',
      url: '/Downloads/Modules/PSI/psi_development.zip',
      aria: 'Download psi_development.zip',
      cta: 'Download (ZIP File, 850 KB)',
    },
    {
      title: 'Retirement of the Area-Level Patient Safety Indicators (PSIs)',
      desc: '<p>Starting with V7.0 ICD-10-CM/PCS, all versions of the QI software will not include any area-level PSIs. For additional information, see the retirement announcement.</p>',
      url: '/News/PSI_Retirement_Notice.pdf',
      aria: 'Download PSI_Retirement_Notice.pdf',
      cta: 'Download (PDF File, 349 KB)',
    },
    {
      ...getReleaseItem('Psi08ExpansionAnnouncement', undefined, 'v2023'),
      title: 'PSI 08 Measure Logic Update',
      desc: `<p>AHRQ's v2023 QI software expands PSI 08 from "In-Hospital Fall with Hip Fracture Rate" to "In-Hospital Fall-Associated Fracture Rate." For more information, read the announcement.</p>`,
    },
  ],
  // specifications: [
  //   {
  //     title: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
  //     aria: 'Download PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 03 Pressure Ulcer Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
  //     aria: 'Download PSI_03_Pressure_Ulcer_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
  //     aria: 'Download PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
  //     aria: 'Download PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 06 Iatrogenic Pneumothorax Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
  //     aria: 'Download PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
  //     aria: 'Download PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
  //     aria: 'Download PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_09_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
  //     aria: 'Download PSI_09_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
  //     aria: 'Download PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 11 Postoperative Respiratory Failure Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
  //     aria: 'Download PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
  //     aria: 'Download PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 13 Postoperative Sepsis Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
  //     aria: 'Download PSI_13_Postoperative_Sepsis_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 14 Postoperative Wound Dehiscence Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
  //     aria: 'Download PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
  //     aria: 'Download PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
  //     aria: 'Download PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
  //     aria: 'Download PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
  //     aria: 'Download PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI 90 Patient Safety and Adverse Events Composite',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI%2090%20Patient%20Safety%20and%20Adverse%20Events%20Composite.pdf',
  //     aria: 'Download PSI 90 Patient Safety and Adverse Events Composite.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix A - Operating Room Procedure Codes',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_A.pdf',
  //     aria: 'Download PSI_Appendix_A.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix C - Medical Discharge MS-DRGs',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_C.pdf',
  //     aria: 'Download PSI_Appendix_C.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix E - Surgical Discharge MS-DRGs',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_E.pdf',
  //     aria: 'Download PSI_Appendix_E.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix F - Infection Diagnosis Codes',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_F.pdf',
  //     aria: 'Download PSI_Appendix_F.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix G - Trauma Diagnosis Codes',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_G.pdf',
  //     aria: 'Download PSI_Appendix_G.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix H - Cancer Diagnosis Codes',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_H.pdf',
  //     aria: 'Download PSI_Appendix_H.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_I.pdf',
  //     aria: 'Download PSI_Appendix_I.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix J - Admission Codes for Incoming Transfer after PSI Appendix I',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_J.pdf',
  //     aria: 'Download PSI_Appendix_J.pdf',
  //     cta: 'Download PDF',
  //   },
  //   {
  //     title: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
  //     url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_M.pdf',
  //     aria: 'Download PSI_Appendix_M.pdf',
  //     cta: 'Download PDF',
  //   },
  // ],
  // retired: [
  //   {
  //     desc: "<p>Effective v2021, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='//News/AHRQ%20QI%20v2021%20Retirement%20Announcement.pdf'>Indicators Retirement Announcement</a>.</p>",
  //     title: [
  //       {
  //         titledesc: 'NQI 02 Neonatal Mortality Rate',
  //       },
  //     ],
  //   },
  //   {
  //     desc: "<p>Effective v2019, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='//News/Retirement%20Notice_v2019_Indicators.pdf'>Indicators Retirement Announcement</a>.</p>",
  //     title: [
  //       {
  //         titledesc: 'PSI 16 Transfusion Reaction Count',
  //       },
  //     ],
  //   },
  //   {
  //     desc: "<p>Effective v7.0, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='//News/PSI_Retirement_Notice.pdf'>PSI Area Level Hospital</a>.</p>",
  //     title: [
  //       {
  //         titledesc: 'PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate',
  //       },
  //       {
  //         titledesc: 'PSI 22 Iatrogenic Pneumothorax Rate',
  //       },
  //       {
  //         titledesc: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
  //       },
  //       {
  //         titledesc: 'PSI 24 Postoperative Wound Dehiscence Rate',
  //       },
  //       {
  //         titledesc: 'PSI 25 Accidental Puncture or Laceration Rate',
  //       },
  //       {
  //         titledesc: 'PSI 26 Transfusion Reaction Rate',
  //       },
  //       {
  //         titledesc: 'PSI 27 Perioperative Hemorrhage or Hematoma Rate',
  //       },
  //     ],
  //   },
  // ],
};
