import { getReleaseItem } from './releases';

export const codelogData = {
  pqi: {
    v2024: {
      title: 'v2024, July 2024',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'PQI', 'v2024'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2024'),
        getReleaseItem('CodingRevisionsExcel', 'PQI', 'v2024'),
      ],
    },
    v2023: {
      title: 'v2023, August 2023',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'PQI', 'v2023'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2023'),
        getReleaseItem('CodingRevisionsExcel', 'PQI', 'v2023'),
      ],
    },
    v2022: {
      title: 'v2022, July 2022',
      items: [
        {
          title: 'Log of Updates and Revisions for v2022',
          desc: 'This document contains tables summarizing revisions made to the PQI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/PQI/V2022/ChangeLog_PQI_v2022.pdf',
          cta: 'Download (PDF File, 512 KB)',
          aria: 'Download ChangeLog PQI v2022',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2022/v2022_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 156 KB)',
          aria: 'Download v2022_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/PQI/V2022/AHRQ_PQI_v2022_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 536 KB)',
          aria: 'Download AHRQ_PQI_v2022_Code_Set_Changes.xlsx',
        },
      ],
    },
    v2021: {
      title: 'v2021, July 2021',
      items: [
        {
          title: 'Log of Updates and Revisions for v2021',
          desc: 'This document contains tables summarizing revisions made to the PQI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/PQI/V2021/ChangeLog_PQI_v2021.pdf',
          cta: 'Download (PDF File, 420 KB)',
          aria: 'Download changeLog PQI v2021',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2021/v2021_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 140 KB)',
          aria: 'Download v2021_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/PQI/V2021/AHRQ_PQI_v2021_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 530 KB)',
          aria: 'Download AHRQ_PQI_v2021_Code_Set_Changes.xlsx',
        },
      ],
    },
  },
  iqi: {
    v2024: {
      title: 'v2024, July 2024',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'IQI', 'v2024'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2024'),
        getReleaseItem('CodingRevisionsExcel', 'IQI', 'v2024'),
      ],
    },
    v2023: {
      title: 'v2023, August 2023',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'IQI', 'v2023'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2023'),
        getReleaseItem('CodingRevisionsExcel', 'IQI', 'v2023'),
      ],
    },
    v2022: {
      title: 'v2022, July 2022',
      items: [
        {
          title: 'Log of Updates and Revisions for v2022',
          desc: 'This document contains tables summarizing revisions made to the IQI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/IQI/V2022/ChangeLog_IQI_v2022.pdf',
          cta: 'Download (PDF File, 780 KB)',
          aria: 'Download ChangeLog_IQI_v2022.pdf',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2022/v2022_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 156 KB)',
          aria: 'Download v2022_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/IQI/V2022/AHRQ_IQI_v2022_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 6.76 MB)',
          aria: 'Download AHRQ_IQI_v2022_Code_Set_Changes.xlsx',
        },
      ],
    },
    v2021: {
      title: 'v2021, July 2021',
      items: [
        {
          title: 'Log of Updates and Revisions for v2021',
          desc: 'This document contains tables summarizing revisions made to the IQI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/IQI/V2021/ChangeLog_IQI_v2021.pdf',
          cta: 'Download (PDF File, 1.2 MB)',
          aria: 'Download ChangeLog_IQI_v2021.pdf',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2021/v2021_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 140 KB)',
          aria: 'Download v2021_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/IQI/V2021/AHRQ_IQI_v2021_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 5.6 MB)',
          aria: 'Download AHRQ_IQI_v2021_Code_Set_Changes.xlsx',
        },
      ],
    },
  },
  psi: {
    v2024: {
      title: 'v2024, July 2024',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'PSI', 'v2024'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2024'),
        getReleaseItem('CodingRevisionsExcel', 'PSI', 'v2024'),
      ],
    },
    v2023: {
      title: 'v2023, August 2023',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'PSI', 'v2023'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2023'),
        getReleaseItem('CodingRevisionsExcel', 'PSI', 'v2023'),
      ],
    },
    v2022: {
      title: 'v2022, July 2022',
      items: [
        {
          title: 'Log of Updates and Revisions for v2022',
          desc: 'This document contains tables summarizing revisions made to the PSI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/PSI/V2022/ChangeLog_PSI_v2022.pdf',
          cta: 'Download (PDF File, 1.81 MB)',
          aria: 'Download ChangeLog_PSI_v2022.pdf',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2022/v2022_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 156 KB)',
          aria: 'Download v2022_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/PSI/V2022/AHRQ_PSI_v2022_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 10.7 MB)',
          aria: 'Download AHRQ_PSI_v2022_Code_Set_Changes.xlsx',
        },
      ],
    },
    v2021: {
      title: 'v2021, July 2021',
      items: [
        {
          title: 'Log of Updates and Revisions for v2021',
          desc: 'This document contains tables summarizing revisions made to the PSI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/PSI/V2021/ChangeLog_PSI_v2021.pdf',
          cta: 'Download (PDF File, 2.4 MB)',
          aria: 'Download ChangeLog_PSI_v2021.pdf',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2021/v2021_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 140 KB)',
          aria: 'Download v2021_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/PSI/V2021/AHRQ_PSI_v2021_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 8.3 MB)',
          aria: 'Download AHRQ_PSI_v2021_Code_Set_Changes.xlsx',
        },
      ],
    },
  },
  pdi: {
    v2024: {
      title: 'v2024, July 2024',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'PDI', 'v2024'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2024'),
        getReleaseItem('CodingRevisionsExcel', 'PDI', 'v2024'),
      ],
    },
    v2023: {
      title: 'v2023, August 2023',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'PDI', 'v2023'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2023'),
        getReleaseItem('CodingRevisionsExcel', 'PDI', 'v2023'),
      ],
    },
    v2022: {
      title: 'v2022, July 2022',
      items: [
        {
          title: 'Log of Updates and Revisions for v2022',
          desc: 'This document contains tables summarizing revisions made to the PDI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/PDI/V2022/ChangeLog_PDI_v2022.pdf',
          cta: 'Download (PDF File, 1.01 MB)',
          aria: 'Download ChangeLog_PDI_v2022.pdf',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2022/v2022_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 156 KB)',
          aria: 'Download v2022_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/PDI/V2022/AHRQ_PDI_v2022_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 10.2 MB)',
          aria: 'Download AHRQ_PDI_v2022_Code_Set_Changes.xlsx',
        },
      ],
    },
    v2021: {
      title: 'v2021, July 2021',
      items: [
        {
          title: 'Log of Updates and Revisions for v2021',
          desc: 'This document contains tables summarizing revisions made to the PDI software, software documentation and technical specifications since the original release of these documents in November 2001.',
          url: '/Downloads/Modules/PDI/V2021/ChangeLog_PDI_v2021.pdf',
          cta: 'Download (PDF File, 1.3 MB)',
          aria: 'Download ChangeLog_PDI_v2021.pdf',
        },
        {
          title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
          desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
          url: '/Downloads/Modules/V2021/v2021_FY_Coding_Updates.pdf',
          cta: 'Download (PDF File, 140 KB)',
          aria: 'Download v2021_FY_Coding_Updates.pdf',
        },
        {
          title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
          desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
          url: '/Downloads/Modules/PDI/V2021/AHRQ_PDI_v2021_Code_Set_Changes.xlsx',
          cta: 'Download (Excel File, 9.9 MB)',
          aria: 'Download AHRQ_PDI_v2021_Code_Set_Changes.xlsx',
        },
      ],
    },
  },
  pqe: {
    v2024: {
      title: 'v2024, July 2024',
      items: [
        getReleaseItem('LogOfUpdatesRevisions', 'PQE', 'v2024'),
        getReleaseItem('FiscalYearCodingRevisions', undefined, 'v2024'),
        getReleaseItem('CodingRevisionsExcel', 'PQE', 'v2024'),
      ],
    }
  },
};
