import React from 'react';
import { styled } from '@mui/material';
import { Button } from './Button';

export const PromoteButton = ({ prefix = 'New!', label, ...rest }) => {
  return (
    <StyledButton
      {...rest}
      label={
        <>
          <Prefix className='prefix'>{prefix}</Prefix> {label}
        </>
      }
      variant='outlined'
    />
  );
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'inverse',
})(({ theme }) => ({
  borderWidth: '2px',
  fontWeight: 700,
  padding: '8px 14px',
  '&:hover': {
    borderWidth: '2px',
    '.prefix': {
      color: theme.palette.common.white,
    },
  },
}));

const Prefix = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: 700,
  transition: 'color .2s ease',
}));
