import { parseReleaseItems } from '../parseReleaseItems';
import BASE_ITEMS from './v2023_base';
import CLOUDQI_ITEMS from './v2023_cloudQi';
import ED_PQI_ITEMS from './v2023_edPqi';
import IQI_ITEMS from './v2023_iqi';
import PDI_ITEMS from './v2023_pdi';
import PQI_ITEMS from './v2023_pqi';
import PSI_ITEMS from './v2023_psi';
import SAS_QI_ITEMS from './v2023_sasQi';
import WINQI_ITEMS from './v2023_winQi';

export const VERSION = 'v2023';
export const REVISION = 'August 2023';
export const ITEMS = [
  ...parseReleaseItems(VERSION, REVISION, undefined, BASE_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'CLOUDQI', CLOUDQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'SASQI', SAS_QI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'WINQI', WINQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'EDPQI', ED_PQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'IQI', IQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'PDI', PDI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'PQI', PQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'PSI', PSI_ITEMS),
];

export default ITEMS;
