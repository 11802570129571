import React, { useRef } from 'react';
import Select from 'react-select';

const AHRQSimpleSelect = (params) => {
  const allMeasuresSelectRef = useRef();

  const onAllMeasuresDropDownKeyDown = (e) => {
    if (
      e.key === 'Enter' &&
      allMeasuresSelectRef.current &&
      !allMeasuresSelectRef.current.menuIsOpen
    ) {
      allMeasuresSelectRef.current.onMenuOpen();
    }
  };

  const getObjRecursive = (options) => {
    if (!params.defaultValueValue) {
      return null;
    }

    for (const option of options) {
      if (option.value && option.value === params.defaultValueValue) {
        return option;
      }
      if (option.options) {
        const result = getObjRecursive(option.options);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  return (
    <Select
      classNamePrefix='ahrq-simple-select'
      className={`${params.className} height-full`}
      ref={allMeasuresSelectRef}
      onKeyDown={onAllMeasuresDropDownKeyDown}
      isClearable={false}
      isRtl={false}
      isSearchable={false}
      defaultValue={getObjRecursive(params.options, params.defaultValueValue)}
      {...params}
    />
  );
};

export default AHRQSimpleSelect;
