import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from '../Measures/Measures.module.scss';
import { VideoModal } from '..';
import { Flex, PageHeader, PageRow } from '../../ui';
import { cloudqiPQEVideos } from '../../data/videos';
import { LatestNews } from '../Home/Sections';

const Announcements202402 = () => {
  const [edpqiVideoModalInfo, setEDPQIVideoModalInfo] = useState({ isOpen: false, url: '' });

  useEffect(() => {
    var hashValue = window.location.hash?.replace('#', '');
    if (hashValue) {
      const el = document.getElementById(hashValue);
      if (el) {
        setTimeout(function () {
          requestAnimationFrame(() => el.scrollIntoView());
        });
      }
    }
  });

  return (
    <>
      <div className={styles.Announcements}>
        <PageHeader.Row lightGrey bgSrc='/images/hex-grey.jpg'>
          <PageHeader.Title>Announcements</PageHeader.Title>
          <PageHeader.Content>
            <h4>February 2024</h4>
          </PageHeader.Content>
        </PageHeader.Row>
        <PageRow>
          <Flex>
            <h4>
              AHRQ Emergency Department Prevention Quality Indicators (ED PQI) Beta v2023 Software -
              New Resources and Opportunity to Provide Feedback!
            </h4>
            <p>
              AHRQ announces a guide to using the Emergency Department Prevention Quality Indicators
              (ED PQI) module for population health improvement, research, and public reporting.
              Review the use cases <a href='/measures/how_to_use_pqe_resources'>here</a>.
            </p>
            <p>
              Additionally, new video tutorials to help users calculate and understand the
              indicators are available on the{' '}
              <a href='/software/win_qi#ed-pqi-videos'>AHRQ QI website</a>. The videos provide a
              technical overview and also demonstrate:
            </p>
            <ul>
              <li>
                ED PQI module resources, such as technical measure specifications, national
                benchmarks, risk adjustment parameter estimates and software release notes;
              </li>
              <li>Downloading and installing the Windows software; and</li>
              <li>Using the ED PQI Windows software.</li>
            </ul>

            <br />
          </Flex>
          <Flex>
            <Flex
              gap={3}
              flexDirection={'row'}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              {Object.keys(cloudqiPQEVideos).map((edpqiVideoKey) => {
                const video = cloudqiPQEVideos[edpqiVideoKey];
                return (
                  <Flex
                    key={video.id}
                    flex='unset'
                    alignItems='center'
                    alignSelf='flex-start'
                    gap={3}
                    width={{ xs: '45%', md: '30%' }}
                  >
                    <button
                      onClick={() => setEDPQIVideoModalInfo({ isOpen: true, url: video.url })}
                      className='video-thumbnail'
                    >
                      {video.thumb !== undefined && (
                        <img alt='Video Thumbnail' src={video.thumb} width='100%' />
                      )}
                    </button>
                    <p className='text-center'>
                      <a aria-label={video.label} target='_blank' rel='noreferrer' href={video.url}>
                        {video.text}
                      </a>
                      <a href='/ExternalDisclaimer'>
                        <img
                          alt='external web link policy'
                          className='ml-1'
                          src='/images/exit_disclaimer.png'
                        />
                      </a>
                    </p>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </PageRow>
        <PageRow py={0}>
          <Typography variant='h5' color='primary' textAlign='center'>
            <Link to='/archive/news?category=announcements'>
              See previous announcements <ChevronRightIcon />
            </Link>
          </Typography>
        </PageRow>
        <LatestNews />
      </div>
      <VideoModal
        isOpen={edpqiVideoModalInfo?.isOpen ?? false}
        onClose={() => setEDPQIVideoModalInfo(null)}
        url={edpqiVideoModalInfo?.url}
      />
    </>
  );
};

export default Announcements202402;
