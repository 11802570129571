import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import NormalTopBanner from '../../components/Common/TopBanner/NormalTopBanner';

const DisclaimerPage = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = `AHRQ QI: Disclaimer`;
  });

  const handlePrevPageClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    history.goBack();
  };

  return (
    <div className='disclaimer-page'>
      <NormalTopBanner>
        <h1>Disclaimer</h1>
      </NormalTopBanner>
      <Container className='outer-container'>
        <h2>Using technical documentation materials:</h2>
        <p>
          The Agency for Healthcare Research and Quality (AHRQ) must be cited when referencing AHRQ
          Quality Indicator (QI) measures or using them for other materials or products. Any
          alterations to QI measure specifications must be noted, along with a disclaimer of not
          being endorsed by AHRQ.
        </p>
        <h5>General AHRQ QI Citation:</h5>
        <p>
          Quality Indicators, Agency for Healthcare Research and Quality, Rockville, MD.
          www.qualityindicators.ahrq.gov.
        </p>
        <h5>Disclaimer:</h5>
        <p>
          This product includes alterations to Agency for Healthcare Research Quality (AHRQ) Quality
          Indicator (QI) measure specifications and is not endorsed by AHRQ.
        </p>
        <br />
        <h2>Using software and instructional documentation materials:</h2>
        <p>
          References to Agency for Healthcare Research and Quality (AHRQ) Quality Indicator (QI)
          measures produced from AHRQ QI software must be attributed to AHRQ. Citation should list
          the name of the tool, tool version, Agency for Healthcare Research and Quality, Rockville,
          MD., the related Web link, and access date.{' '}
        </p>
        <h5>Product Citation Example:</h5>
        <p>
          <em>
            AHRQ SAS QI v2022 Individual Software Download for Patient Safety Indicators, Version
            v2022.0.1, Agency for Healthcare Research and Quality, Rockville, MD.
            https://qualityindicators.ahrq.gov/software/sas_qi. Accessed June 13, 2023.
          </em>
        </p>
        {location.key && (
          <a href='/' onClick={handlePrevPageClick}>
            Return to previous page
          </a>
        )}
      </Container>
    </div>
  );
};

export default DisclaimerPage;
