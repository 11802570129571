import { Box, styled } from '@mui/material';
import { PageRow } from './PageRow';
import { Flex } from './Flex';

export const ImagePageRow = ({ children, imageSrc, ...rest }) => {
  return (
    <Row {...rest}>
      <Flex cols='lg'>
        <Flex>
          <Image imageSrc={imageSrc} />
        </Flex>
        <Flex className='page-row__content-column'>
          <Flex className='page-row__content'>{children}</Flex>
        </Flex>
      </Flex>
    </Row>
  );
};

const Row = styled(PageRow)(({ theme, bgSrc, lightBlue, lightGrey, purple }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  [theme.breakpoints.up('lg')]: {
    '.page-row__content-column': {
      minHeight: 360,
    },
  },

  '.page-row__content': {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.down('lg')]: {
      marginTop: '-1rem',
    },

    [theme.breakpoints.up('lg')]: {
      padding: 0,
      paddingLeft: theme.spacing(5),
    },
  },
}));
Row.defaultProps = {
  className: 'page-row image-page-row',
};

const Image = styled(Box, {
  shouldForwardProp: (prop) => !['imageSrc'].includes(prop),
})(({ theme, imageSrc }) => ({
  backgroundImage: `url(${imageSrc})`,
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '1.25rem',
  boxShadow: `0px 10px 20px -15px rgba(0, 0, 0, 0.35)`,
  width: '100%',

  [theme.breakpoints.down('lg')]: {
    aspectRatio: '1/1',
    transform: 'translate(0, -3rem)',
  },
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    top: '-5rem',
    right: 0,
    bottom: '-5rem',
  },
}));
Image.defaultProps = {
  className: 'page-row__image',
};
