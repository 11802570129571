import { parseReleaseItems } from '../parseReleaseItems';
import SAS_QI_ITEMS from './v2022_sasQi';
import WINQI_ITEMS from './v2022_winQi';

export const VERSION = 'v2022';
export const REVISION = 'July 2022';
export const ITEMS = [
  ...parseReleaseItems(VERSION, REVISION, 'SASQI', SAS_QI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'WINQI', WINQI_ITEMS),
];

export default ITEMS;
