import React from 'react';
import { Container } from 'react-bootstrap';
import { resourcesData } from '../../data/resources';
import { Link } from 'react-router-dom';
import styles from './resources.module.scss';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const Webinars = () => {
  return (
    <div className={styles.Webinars}>
      <NormalTopBanner>
        <h1>Webinars</h1>
      </NormalTopBanner>
      <Container className='outerContainer'>
        {resourcesData['webinars'].map((webinar, webinarIndex) => (
          <div className={styles.WebinarContainer} key={`outer-${webinarIndex}`}>
            <div>
              <h5>{webinar.date}</h5>
              {webinar.resources.map((resource, resourceIndex) => (
                <div key={`outer-${webinarIndex}-resource-${resourceIndex}`}>
                  <h3>{resource.title}</h3>
                  <ul>
                    {resource.items.map((item, itemIndex) => (
                      <li key={`${item.name}-${itemIndex}`}>
                        {item.url.length > 0 && (
                          <>
                            <a
                              href={item.url}
                              aria-label={`Link to ${item.url}`}
                              target='_blank'
                              rel='noreferrer'>
                              {item.name}
                            </a>{' '}
                            {item.size}
                            {item.url.includes('http') === true && (
                              <Link to='/ExternalDisclaimer'>
                                <img
                                  alt='external web link policy'
                                  style={{ marginLeft: 4 }}
                                  src='/images/exit_disclaimer.png'
                                />
                              </Link>
                            )}
                          </>
                        )}
                        {item.url.length === 0 && <>{item.name}</>}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div key='bottom' className={styles.WebinarContainer}>
          <div>
            <p>
              For webinars prior to 2016 see the{' '}
              <Link to='/archive/resources?1'>Archive Resources page</Link>.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Webinars;
