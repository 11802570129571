export const pepoleSay = {
  data: [
    {
      msg: '"Now with CloudQI, I can take the updates as soon as it is available."',
      role: 'Quality Engineer',
      organization: 'Hospital',
    },
    {
      msg: '"Most of what I need for my other tasks are in virtual servers, so having CloudQI now available in our virtual server frees up the need for that extra machine."',
      role: 'Quality Engineer',
      organization: 'Hospital',
    },
  ],
};
