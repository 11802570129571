import React from 'react';
import { Button, Container } from 'react-bootstrap';
import ResourcesIcon from '../../img/ResourcesIcon.png';
import AHRQ from '../../img/AHRQ.jpg';
import { resourcesData } from '../../data/resources';
import CaseCard from '../Common/QICard/CaseCard';
import ResourceIcon from '../../img/ResourceIcon.png';
import FAQIcon from '../../img/FAQIcon.png';
import styles from './resources.module.scss';
import TopBanner from '../Common/TopBanner/TopBanner';

const Landing = () => {
  return (
    <div className={styles.Landing}>
      <TopBanner>
        <h1>Resources</h1>
        <p>
          These AHRQ QI resources are designed to help users learn more about the software and how
          it can be used to assess data, highlight potential quality concerns, identify areas for
          further study and investigation, and track changes over time.
        </p>
      </TopBanner>
      <div className={styles.Toolkit}>
        <Container className={`outer-container ${styles.Panel}`}>
          <div className={styles.ImageContainer}>
            <img alt='Toolkit Icon' src={ResourcesIcon} />
          </div>
          <div className={styles.TextContainer}>
            <h2>Toolkits</h2>
            <p className='text-large'>
              AHRQ QI Toolkits provide practical applications and adaptable solutions to guide the
              provision of safe care and to help reduce patient harm.
            </p>
            <ul className='text-large'>
              <li>
                The QI Toolkit serves as an "inventory" from which hospitals can select those tools
                that are most appropriate for their current quality improvement priorities and
                capabilities.
              </li>
              <li>
                Each of the tools is-by design-adaptable to the needs of an individual hospital.
              </li>
            </ul>
            <Button
              href='/resources/toolkits'
              aria-label='Go to toolkits'
              className='link-button transparent arrow-right'
              variant='none'
            >
              Learn more about Toolkits
            </Button>
          </div>
        </Container>
      </div>
      <div className={`bg-primary-blue-6 ${styles.Webinars}`}>
        <Container className={`outer-container ${styles.Panel}`}>
          <div className={styles.TopContainer}>
            <div className={styles.HeaderContainer}>
              <h2>Webinars</h2>
            </div>
            <div className={styles.TextContainer}>
              <p className='text-large'>
                The AHRQ QI webinars help users learn more about software releases, indicator
                changes, and other ways to use the QIs.
              </p>
            </div>
          </div>
          <div className={styles.BottomContainer}>
            <div className={styles.ImageContainer}>
              <div>
                <img alt='AHRQ' src={AHRQ} />
              </div>
            </div>
            <div className={styles.TextContainer}>
              <div className={styles.HeaderContainer}>
                <label>Featured Webinar</label>
                <h3>AHRQ Quality Indicators Software: v2024</h3>
              </div>
              <div className={styles.ItemContainer}>
                <p className='text-large'>
                  This webinar provides background on the history of the AHRQ QIs, important
                  information about this version's improvements and updates, highlights of indicator
                  changes, and details on how to download the software.
                </p>
              </div>
              <div>
                <Button
                  href='/Downloads/Resources/Webinars/2024/v2024 QI Software Release Webinar.pdf'
                  className='link-button primary mr-5 arrow-right'
                  target='_blank'
                >
                  View Webinar (PDF File, 4.66 MB)
                </Button>
              </div>
            </div>
          </div>
          <div className='align-center'>
            <Button
              aria-label='View All Webinars'
              className='link-button transparent arrow-right mt-5 ml-0'
              variant='primary'
              href='/resources/webinars'
            >
              View all Webinars
            </Button>
          </div>
        </Container>
      </div>
      <div className={styles.CaseStudies}>
        <Container className={`outer-container ${styles.Panel}`}>
          <div className={styles.TopContainer}>
            <div className={styles.HeaderContainer}>
              <h2>Case Studies</h2>
            </div>
            <div className={styles.TextContainer}>
              <p className='text-large'>
                These case studies provide real-world examples of ways that organizations, including
                hospitals and health systems, use the AHRQ QIs to improve the quality of care.
              </p>
            </div>
          </div>
          <div className={styles.StudiesContainer}>
            {resourcesData.caseStudies
              .filter((m) => m.showInLanding === true)
              .map((caseInfo) => (
                <div key={caseInfo.url}>
                  <CaseCard caseInfo={caseInfo} />
                </div>
              ))}
          </div>
          <div className='align-center'>
            <Button
              aria-label='View all Case Studies'
              className='link-button transparent arrow-right mt-5 ml-0'
              variant='primary'
              href='/resources/case_studies'
            >
              View all Case Studies
            </Button>
          </div>
        </Container>
      </div>
      <div className={`bg-primary-blue-6 ${styles.ResourcesContainer}`}>
        <Container className='outer-container'>
          <div className={`align-center ${styles.HeaderContainer}`}>
            <h2>Other Resources</h2>
          </div>
          <div className={styles.TextContainer}>
            <div>
              <div>
                <img alt='resources' src={ResourceIcon} />
                <h3 className='mt-2'>Publications</h3>
                <p>
                  These materials represent a selection of articles from 2020 through 2021 that
                  focus on the use of AHRQ QIs for quality improvement purposes.
                </p>
                <Button
                  href='/resources/publications'
                  aria-label='View Publications'
                  className='link-button primary arrow-right'
                  variant='primary'
                >
                  View Publications
                </Button>
              </div>
            </div>
            <div>
              <div>
                <img alt='faqIcon' src={FAQIcon} />
                <h3 className='mt-2'>Presentations</h3>
                <p>
                  AHRQ QI presentations allow user to learn more about how AHRQ shares information
                  and insights about the use of the AHRQ QIs to improve health care quality.
                </p>
                <Button
                  aria-label='View Presentations'
                  href='/resources/presentations'
                  className='link-button primary arrow-right'
                  variant='primary'
                >
                  View Presentations
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Landing;
