import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { resourcesData } from '../../data/resources';
import styles from './resources.module.scss';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const Presentations = () => {
  return (
    <div className={styles.Presentations}>
      <NormalTopBanner>
        <h1>Presentations</h1>
        <p>
          AHRQ engages in a variety of outreach activities to sharing information and insights about
          the use of the AHRQ QIs to improve health care quality. The national events listed below
          are a few examples of ways in which information about the AHRQ QIs is shared with hospital
          leaders, quality improvement specialists, researchers and other professionals throughout
          the country.
        </p>
      </NormalTopBanner>
      <Container className='outerContainer'>
        {resourcesData['presentations'].map((presentation, index) => (
          <div key={index} className={styles.PresentationContainer}>
            <div>
              <p className='text-large text-bold'>{presentation.title}</p>
              <p>{presentation.desc}</p>
            </div>
          </div>
        ))}
        <div key='bottom' className={styles.PresentationContainer}>
          <div>
            <p>
              For presentations prior to 2015 see the{' '}
              <Link to='/archive/resources'>Archive Resources page</Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Presentations;
