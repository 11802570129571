import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const IQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='align-center mb-9'>Inpatient Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Inpatient Quality Indicators Technical Specifications Updates - Version v2019 (ICD
          10-CM/PCS), July 2019
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Inpatient Quality Indicators Technical Specifications (PDF Format), Version
              v2019 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download IQI_2019_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/IQI/V2019/TechSpecs/IQI_2019_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 2.9 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'iqi'} version={'icd10_v2019'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          <span className='danger'>Effective v2019</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/Retirement%20Notice_v2019_Indicators.pdf'>
            Indicators Retirement Announcement.
          </a>{' '}
          (PDF File, 108 KB)
        </p>
        <ul>
          <li>IQI 01 Esophageal Resection Volume</li>
          <li>IQI 02 Pancreatic Resection Volume</li>
          <li>IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume</li>
          <li>IQI 05 Coronary Artery Bypass Graft (CABG)</li>
          <li>IQI 06 Percutaneous Coronary Intervention (PCI) Volume</li>
          <li>IQI 07 Carotid Endarterectomy Volume</li>
          <li>IQI 13 Craniotomy Mortality Rate</li>
          <li>IQI 14 Hip Replacement Mortality Rate</li>
        </ul>
        <p>
          <span className='danger'>Effective v7.0</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/IQI_Area_Level_Retirement_Notice.pdf'>IQI Area Level Indicators</a> (PDF
          File, 350 KB),{' '}
          <a href='/News/IQI_Hospital_Level_Retirement_Notice.pdf'>IQI Hospital Level Indicators</a>{' '}
          (PDF File, 356 KB).
        </p>
        <ul>
          <li>IQI 23 Laparoscopic Cholecystectomy Rate</li>
          <li>IQI 24 Incidental Appendectomy in the Elderly Rate</li>
          <li>IQI 25 Bilateral Cardiac Catheterization Rate</li>
          <li>IQI 26 Coronary Artery Bypass Graft (CABG) Rate</li>
          <li>IQI 27 Percutaneous Coronary Intervention (PCI) Rate</li>
          <li>IQI 28 Hysterectomy Rate</li>
          <li>IQI 29 Laminectomy or Spinal Fusion Rate</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Inpatient Quality Indicators Technical Specifications for SAS QI and WinQI ICD-10 v2018
            can be found <Link to='/archive/qi_modules?category=iqi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default IQITechnical;
