import { Box, styled } from '@mui/material';

export const Flex = styled(Box, {
  shouldForwardProp: (prop) => !['center', 'cols', 'scroll'].includes(prop),
})(({ theme, center, cols , scroll}) => ({
  
  ...(center && {
    justifyContent: 'center',
    alignItems: 'center',
  }),

  ...(cols === true && {
    flexDirection: 'row',
  }),
  ...(cols &&
    cols !== true && {
      [theme.breakpoints.up(cols)]: {
        flexDirection: 'row',
      },
    }),
  ...(scroll && {
    overflow:'auto',
    scrollbarWidth: 'thin',
    
  })
}));
Flex.defaultProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  className: 'flex-box',
};
