const SAS_QI_ITEMS = [
  // DOWNLOADS/SOFTWARE/SAS
  {
    name: 'SasQi',
    title: 'SAS QI v2023',
    filename: 'SAS_V2023_ICD-10-CM-PCS_QI_Software.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '50.6 MB',
    format: 'ZIP',
    ctaLabel: 'Download SAS QI v2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'ReleaseNotes',
    title: 'v2023 Release Notes',
    filename: 'AHRQ_SASQI_v2023_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/SAS/V2023/',
    size: '158 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'SasQiSoftwareInstructions',
    title: 'SAS QI Software Instructions',
    filename: 'Software_Inst_SASQI_v2023_August_2023.pdf',
    path: '/Downloads/Software/SAS/V2023/',
    size: '2.75 MB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'AutomateSasQiSoftware',
    title: 'Automate SAS QI Software Runs in Windows',
    filename: 'Automate_SAS_QI_Software_Runs_in_Windows_v2023.pdf',
    path: '/Downloads/Software/SAS/V2023/',
    size: '602 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'SasPopulationFiles',
    title: 'v2023 Population File',
    desc: 'Population estimates through 2022 for use with SAS QI v2023 (August 2023)',
    filename: '2000-2022_Population_Files_v2023.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '20.5 MB',
    format: 'ZIP',
    dateModified: '2023-08-11',
  },
  {
    name: 'AhrqQiPopulationFileDocumentation',
    title: 'AHRQ QI 2000-2022 Population File Documentation',
    desc: 'This document describes how the population data estimates are derived from public use Census data for use with the QI SAS® v2023',
    filename: 'AHRQ_QI_v2023_ICD10_Population_File.pdf',
    path: '/Downloads/Software/SAS/V2023/',
    size: '532 KB',
    format: 'PDF',
    revision: 'September 2023',
    dateModified: '2023-09-06',
  },
  {
    name: 'InpatientQualityIndicators',
    title: 'Inpatient Quality Indicators',
    filename: 'IQI_SAS_V2023_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '2.15 MB',
    format: 'ZIP',
    dateModified: '2023-08-11',
  },
  {
    name: 'PatientSafetyIndicators',
    title: 'Patient Safety Indicators',
    filename: 'PSI_SAS_V2023_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '2.97 MB',
    format: 'ZIP',
    dateModified: '2023-08-11',
  },
  {
    name: 'PediatricQualityIndicator',
    title: 'Pediatric Quality Indicator',
    filename: 'PDI_SAS_V2023_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '23.1 MB',
    format: 'ZIP',
    dateModified: '2023-08-11',
  },
  {
    name: 'PreventionQualityIndicator',
    title: 'Prevention Quality Indicator',
    filename: 'PQI_SAS_V2023_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '20.7 MB',
    format: 'ZIP',
    dateModified: '2023-08-11',
  },
  {
    name: 'Psi17',
    title: 'PSI 17',
    filename: 'PSI17_SAS_V2023_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '1.70 MB',
    format: 'ZIP',
    dateModified: '2023-08-11',
  },

  // PQE
  {
    name: 'SasEdPqi',
    title: 'ED PQI v2023',
    filename: 'PQE_BETA_SAS_V2023_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '20.6 MB',
    format: 'ZIP',
    ctaLabel: 'Download ED PQI v2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'SasEdPqiReleaseNotes',
    title: 'ED PQI v2023 Release Notes',
    filename: 'AHRQ_QI_EDPQI_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/WINQI/V2023/', // this is same file as in winqi - not a typo
    size: '251 KB',
    format: 'PDF',
    ctaLabel: 'Download v2023 release notes',
    revision: 'September 2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'SasEdPqiSoftwareInstructions',
    title: 'ED PQI v2023 Software Instructions',
    filename: 'Software_Inst_SASQI_v2023_September_2023.pdf',
    path: '/Downloads/Software/SAS/V2023/',
    size: '3.31 MB',
    format: 'PDF',
    revision: 'September 2023',
    dateModified: '2023-09-08',
  },
  {
    name: 'SasQiV2023Icd10CmPcsFullSoftwarePackage',
    title: 'SAS QI v2023 ICD-10-CM/PCS Full Software Package',
    desc: 'The QI/STAT modules are programs that run in the SAS statistical software package. To use these modules, users must have access to SAS which may be purchased from The SAS Institute.',
    filename: 'SAS_V2023_ICD-10-CM-PCS_QI_Software.zip',
    path: '/Downloads/Software/SAS/V2023/',
    size: '50.6 MB',
    format: 'ZIP',
    dateModified: '2024-08-11',
  },
];

export default SAS_QI_ITEMS;
