import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { AvailabilityDialogButton, QITileGroup, VideoModal } from '../';
import TutorialThumbnail from '../../img/tutorialThumbnail.png';
import WinqiSampleImg from '../../img/winqi-laptop.png';
import {
  SoftwareList,
  SoftwareListItem,
  Flex,
  LinkItem,
  PageRow,
  Rule,
  PageDisclaimer,
  LinkBox,
  Button,
} from '../../ui';
import { getReleaseItem } from '../../data/releases';

const WinQI = () => {
  const theme = useTheme();
  let history = useHistory();

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [edpqiVideoModalInfo, setEDPQIVideoModalInfo] = useState({ isOpen: false, url: '' });

  const winQiRelease = getReleaseItem('WinQi', 'WINQI');
  const icd10Faq = getReleaseItem('Icd10Faq');

  const releaseNotes = getReleaseItem('ReleaseNotes', 'WINQI');

  const softwareInstructions = getReleaseItem('WinQiSoftwareInstructions', 'WINQI');

  const archive1 = getReleaseItem('WinQi', 'WINQI', 'v2023');

  const archivedData = [
    {
      title: archive1.title,
      desc: archive1.desc,
      date: archive1.revision,
      url: archive1.url,
      aria: archive1.aria,
      cta: archive1.cta,
    },
  ];

  const handleAvailabilityDialogClose = (reason) => {
    if (reason === 'go') {
      history.push('/software/cloudqi');
    }
  };

  return (
    <>
      <PageRow className='win-qi-intro-row'>
        <Flex cols='lg' gap={{ xs: 4, lg: 10 }}>
          <Flex alignItems='flex-start' gap={3} marginBottom={{ xs: 4, lg: 0 }}>
            <div>
              <h1>WinQI</h1>
              <p className='text-xl'>
                Flexibility and ease-of-use for users preferring a traditional GUI
              </p>
              <p>
                The AHRQ Quality Indicators Windows application contains a robust user interface
                that can be installed on 64-bit platforms.
              </p>
              <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={1.5}>
                <AvailabilityDialogButton
                  label={winQiRelease.cta}
                  url={winQiRelease.url}
                  dialogSubtitle='Also Available: CloudQI v2024'
                  dialogLabel='Go to CloudQI Download'
                  dialogDescription='The AHRQ Quality Indicators CloudQI application provides multi-version and multi-user support, remote access, and ease-of-use for users calculating the Patient Safety Indicators (PSI) and the Prevention Quality Indicators in Emergency Department Settings (PQE).'
                  onClose={handleAvailabilityDialogClose}
                  arrow
                />
                <Button
                  aria-label='New CloudQI Software'
                  label='New CloudQI Software'
                  href='/software/cloudqi'
                  variant='outlined'
                  arrow
                />
              </Box>
            </div>

            <Rule spacing={1} />

            <img src={WinqiSampleImg} alt='WinQI software screenshot' style={{ width: '100%' }} />
          </Flex>
          <Box bgcolor='grey.50' p={2.5} width={{ lg: 320 }} margin={{ xs: '-1.25rem', lg: 0 }}>
            <div>
              <h6>Software v2024.0.1 Improvements</h6>
              <ul>
                <li>Fiscal Year 2024 Coding Updates</li>
                <li>Indicator refinements</li>
                <li>
                  Removal of the option to include or exclude COVID-19 discharges due to
                  risk-adjustment for COVID-19 time period and diagnoses present on admission
                </li>
                <li>Addresses user reported bugs</li>
              </ul>
            </div>
            <div>
              <h6>Release Notes</h6>
              <ul>
                <li>
                  v2024.0.1, September 2024 - Summary of the major changes from v2023 to v2024.0.1
                  of the Technical Specifications and QI Software.
                </li>
              </ul>
              <LinkBox>
                <LinkItem
                  href={releaseNotes.url}
                  meta={releaseNotes.info}
                  leftBorder
                  light
                  noicon
                  noclip
                >
                  {/*releaseNotes.version*/}2024.0.1 Release Notes
                </LinkItem>

                <LinkItem href={icd10Faq.url} meta={icd10Faq.info} leftBorder light noicon noclip>
                  {icd10Faq.version} Software Release FAQ
                </LinkItem>
              </LinkBox>
            </div>
          </Box>
        </Flex>

        <Rule spacing={5} sx={{ display: { xs: 'none', lg: 'block' } }} />

        <Flex cols='lg' gap={{ xs: 4, lg: 10 }}>
          <Flex gap={3}>
            <div>
              <h3>Installation Instructions</h3>
              <p>
                To learn more about WinQI installation and to get started, download the software
                instructions below.
              </p>
              <p>The software instructions for v2024 apply to v2024.0.1.</p>
              <LinkItem
                href={softwareInstructions.url}
                meta={`${softwareInstructions.revision} ${softwareInstructions.info}`}
                light
              >
                {softwareInstructions.title}
              </LinkItem>
            </div>
            <div>
              <h6>Supporting Software</h6>
              <p>
                The AHRQ Quality Indicators Windows Application may be installed on 64-bit or 32-bit
                platforms, and no third-party statistical software is needed. SQL Server and .NET
                are provided free of charge by Microsoft. For more information please see the
                following links on the Microsoft website:
              </p>
              <a
                href='https://go.microsoft.com/fwlink/?linkid=866658'
                target='_blank'
                rel='noreferrer'
              >
                Microsoft SQL Server 2019 Express Edition
              </a>
              <div>(This will take you away from the AHRQ website.)</div>
            </div>
          </Flex>
          <Flex flex='unset' alignItems='center' gap={3} maxWidth={{ lg: 320 }}>
            <button
              onClick={() => setIsVideoModalOpen(true)}
              style={{ padding: 0 }}
              className='video-thumbnail'
            >
              <img alt='Tutorial Thumbnail' src={TutorialThumbnail} width='100%' />
            </button>
            <p className='text-center'>
              <a
                aria-label='View Tutorial Video'
                target='_blank'
                rel='noreferrer'
                href='https://www.youtube.com/watch?v=-lNnrq5IW2I'
              >
                View the WinQI software tutorial video on YouTube
                <a href='/ExternalDisclaimer'>
                  <img
                    alt='external web link policy'
                    className='ml-1'
                    src='/images/exit_disclaimer.png'
                  />
                </a>
              </a>
            </p>
          </Flex>
        </Flex>
      </PageRow>

      <PageRow className='tech-specs-row' lightGrey>
        <Box px={{ lg: 16 }} textAlign='center'>
          <h2>Technical Specifications</h2>
          <p>
            Technical Specifications break down calculations used to formulate each indicator,
            including a brief description of the measure, numerator and denominator information, and
            details on cases that should be excluded from calculations.
          </p>
        </Box>
        <QITileGroup showPsiCloudQi isTechSpecs />
        <div className='align-center'>
          <Button
            aria-label='View all measures'
            label='View All Measures'
            href='/measures/all_measures'
            variant='outlined'
            arrow
          />
        </div>
      </PageRow>

      <PageRow className='population-files-row'>
        <h2 className='text-center'>Additional Software Resources</h2>
        <Flex cols='lg' mt={6}>
          <Flex
            borderRight={{ lg: `1px solid ${theme.palette.divider}` }}
            borderBottom={{ xs: `1px solid ${theme.palette.divider}`, lg: 'none' }}
            pr={{ xs: 0, lg: 6 }}
            pb={{ xs: 6, lg: 0 }}
          >
            <h4 className='color-primary-2'>
              <a
                href='/Downloads/Software/WinQI/V41B/Calculating%20the%20Smoothed%20Rate%20CI%20in%20WinQI.pdf'
                aria-label='Download Instructions for Calculating Smoothed Rate Confidence Intervals'
                target='_blank'
                style={{ color: 'inherit' }}
              >
                Instructions for Calculating Smoothed Rate Confidence Intervals in WinQI, Sept. 2010
              </a>
            </h4>
            <p>
              This document provides instructions for using the Excel workbook "Calculating the
              Smoothed Rate CI in WinQI"
            </p>
            <a
              href='/Downloads/Software/WinQI/V41B/Calculating%20the%20Smoothed%20Rate%20CI%20in%20WinQI.pdf'
              aria-label='Download Instructions for Calculating Smoothed Rate Confidence Intervals'
              target='_blank'
            >
              Download (PDF File, 19 KB)
            </a>
          </Flex>
          <Flex pt={{ xs: 6, lg: 0 }} pl={{ xs: 0, lg: 6 }}>
            <h4 className='color-primary-2'>
              <a
                href='/Downloads/Software/WinQI/V41B/Calculating%20the%20Smoothed%20Rate%20CI%20in%20WINQI.xls'
                aria-label='Download Workbook for Calculating the Smoothed Rate Confidence Intervals in WinQI'
                target='_blank'
                style={{ color: 'inherit' }}
              >
                Workbook for Calculating the Smoothed Rate Confidence Intervals in WinQI
              </a>
            </h4>
            <p>
              This workbook helps compute the upper and lower bounds of the smoothed rate confidence
              intervals in WinQI
            </p>
            <a
              href='/Downloads/Software/WinQI/V41B/Calculating%20the%20Smoothed%20Rate%20CI%20in%20WINQI.xls'
              aria-label='Download Workbook for Calculating the Smoothed Rate Confidence Intervals in WinQI'
              target='_blank'
            >
              Download (XLS File, 52 KB)
            </a>
          </Flex>
        </Flex>
      </PageRow>

      <PageRow className='archived-software-row'>
        <SoftwareList
          title='Archived Software'
          subtitle='Archived software for WinQI ICD-10'
          viewAllUrl='/archive/software?category=windows'
          paper
        >
          {archivedData.map((info) => (
            <SoftwareListItem
              key={info.url}
              title={info.title}
              subtitle={info.date}
              url={info.url}
              label={info.cta}
              ariaLabel={info.aria}
            />
          ))}
        </SoftwareList>
      </PageRow>

      <PageRow className='disclaimer-row' py={0} pb={6}>
        <PageDisclaimer
          resource={`AHRQ ${winQiRelease.title} Software Download for AHRQ Quality Indicators`}
          software
        />
      </PageRow>

      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        url='https://www.youtube.com/embed/-lNnrq5IW2I?autoplay=1'
      />

      <VideoModal
        isOpen={edpqiVideoModalInfo?.isOpen ?? false}
        onClose={() => setEDPQIVideoModalInfo(null)}
        url={edpqiVideoModalInfo?.url}
      />
    </>
  );
};

export default WinQI;
