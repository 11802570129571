import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const IQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='align-center mb-9'>Inpatient Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Inpatient Quality Indicators Technical Specifications - Version 6.0, March 2017
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              All Inpatient Quality Indicators Technical Specifications, Version 6.0 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download IQI_Technical_Specifications_Version60.zip'
              href='/Downloads/Modules/IQI/V60/TechSpecs/IQI_Technical_Specifications_Version60.zip'
            >
              Download All (ZIP File, 8.6 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'iqi'} version={'icd9_v60'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Inpatient Quality Indicators Technical Specifications for SAS v5.0 and WinQI v5.0 can be
            found <Link to='/archive'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default IQITechnical;
