import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';

const queryConfig = {
  queries: {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 1,
    useErrorBoundary: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export const ReactQueryProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools /> */}
      {children}
    </QueryClientProvider>
  );
};
