import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../../img/logo-ahrq.png';
import { Link } from 'react-router-dom';
import EnvelopeRegularIcon from '../../img/envelope-regular.png';
import LogHHSminiImg from '../../img/logo-HHSmini.png';
import USFlagSmallImg from '../../img/us_flag_small.png';

const Header = () => {
  const [focused, setFocused] = useState(false);

  return (
    <>
      <a
        className={focused ? 'skip-link-visible' : 'skip-link'}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        href='#main-content'>
        Skip to main content
      </a>
      <div className='top-header'>
        <Container>
          <div>
            <img alt='United States flag' src={USFlagSmallImg} />
            <img alt='Department of Health and Human Services logo' src={LogHHSminiImg} />
            <h6>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.hhs.gov/'
                style={{ color: '#005B94' }}>
                An official website of the Department of Health & Human Services
              </a>
            </h6>
          </div>
        </Container>
      </div>
      <Container className='header-top-section'>
        <div className='header-logo-container'>
          <div className='header-logo'>
            <a target='_blank' rel='noreferrer' href='https://www.ahrq.gov/'>
              <img alt='Agency for Healthcare Research and Quality Logo Header' src={Logo} />
            </a>
          </div>
        </div>
        <div className='header-link-container'>
          <div className='utility-nav'>
            <ul>
              <li>
                <a target='_blank' rel='noreferrer' href='https://search.ahrq.gov'>
                  Search All AHRQ Sites
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.ahrq.gov/cpi/about/careers/index.html'>
                  Careers
                </a>
              </li>
              <li>
                <a target='_blank' rel='noreferrer' href='https://www.ahrq.gov/contact/index.html'>
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.ahrq.gov/topics/informacion-en-espanol/index.html'>
                  Español
                </a>
              </li>
              <li>
                <a target='_blank' rel='noreferrer' href='https://info.ahrq.gov/'>
                  FAQs
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://subscriptions.ahrq.gov/accounts/USAHRQ/subscriber/new'>
                  <img alt='envelope icon' src={EnvelopeRegularIcon} />
                  Email Updates
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className='utility-site'>
        <Container>
          <ul>
            <li>
              <Link to='/home/about'>About</Link>
            </li>
            <li>
              <Link to='/news'>News</Link>
            </li>
            <li>
              <Link to='/home/contact'>Contact</Link>
            </li>
            <li>
              <Link to='/disclaimer'>Disclaimer</Link>
            </li>
          </ul>
        </Container>
      </div>
    </>
  );
};

export default Header;
