import React, { useEffect } from 'react';
import CaseStudies from '../../components/Resources/CaseStudies';
import Presentations from '../../components/Resources/Presentations';
import Publications from '../../components/Resources/Publications';
import Toolkits from '../../components/Resources/Toolkits';
import Webinars from '../../components/Resources/Webinars';
import Landing from '../../components/Resources/Landing';
import { useParams } from 'react-router-dom';

const Resources = () => {
  let { section } = useParams();

  const pageTitles = {
    case_studies: 'AHRQ QI Case Studies',
    presentations: 'AHRQ QI Presentations',
    publications: 'AHRQ QI Publications',
    toolkits: 'AHRQ QI Toolkits',
    webinars: 'AHRQ QI Webinars',
    default: 'AHRQ QI Overview',
  };

  useEffect(() => {
    document.title =
      pageTitles[section] === undefined ? pageTitles['default'] : pageTitles[section];
  });

  if (section === 'case_studies') return <CaseStudies />;
  else if (section === 'presentations') return <Presentations />;
  else if (section === 'publications') return <Publications />;
  else if (section === 'toolkits') return <Toolkits />;
  else if (section === 'webinars') return <Webinars />;
  else return <Landing />;
};
export default Resources;
