import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, Fade, IconButton, Slide, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useAddSurveySubmission, useUpdateSurveySubmission } from '../../data-access/';
import {
  AltButton,
  Button,
  SurveyStep,
  SurveyStepColumn,
  SurveyStepColumnContent,
  SurveyStepRow,
  SurveyStepper,
} from '../../ui';

export const SurveyStepForm = ({
  file,
  surveyId,
  surveyName,
  question,
  questions,
  submission,
  direction,
  mode,
  step,
  last,
  recaptchaToken,
  onClose,
  onResult,
}) => {
  const transitionContainerRef = useRef(null);
  const [defaultValues, setDefaultValues] = useState(() => getDefaultValue(question, submission));
  const addSurveySubmission = useAddSurveySubmission();
  const updateSurveySubmission = useUpdateSurveySubmission();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (question === undefined) return;

    const newDefaultValues = getDefaultValue(question, submission);

    setDefaultValues(newDefaultValues);
    reset(newDefaultValues);
  }, [question, reset, submission]);

  const handleClose = (reason = 'default') => {
    if (mode === 'loading') {
      handleSkip();
    } else {
      onClose(reason);
      reset(defaultValues);
    }
  };

  const handleProxySubmit = (event, action = 'next') => {
    event?.stopPropagation();
    event?.preventDefault();

    if (action === step || addSurveySubmission.isLoading || updateSurveySubmission.isLoading)
      return;

    handleSubmit((d) => onSubmit(d, event, action))();
  };

  const handleSkip = () => {
    onResult(submission, 'skip');
  };

  const handleStart = () => {
    onResult(submission, 'start');
  };

  const onSubmit = async (formData, event, action = 'next') => {
    const submitData = {
      ...submission,
      surveyId,
      surveyName,
      questionsAndAnswers: [...(submission?.questionsAndAnswers ?? [])],
    };

    for (const key in formData) {
      submitData.questionsAndAnswers = submitData.questionsAndAnswers.map((questionAnswer) => {
        if (questionAnswer.questionName === key) {
          return {
            questionName: key,
            answers: formData[key],
          };
        }
        return questionAnswer;
      });

      const hasQuestionAnswer = submitData.questionsAndAnswers.find(
        (item) => item.questionName === key
      );
      if (!hasQuestionAnswer) {
        submitData.questionsAndAnswers.push({
          questionName: key,
          answers: formData[key],
        });
      }
    }

    if (!recaptchaToken) {
      onResult(submitData, action);
      return;
    }

    if (submitData.id) {
      updateSurveySubmission.mutate(
        { id: submitData.id, patch: submitData.questionsAndAnswers, recaptchaToken },
        {
          onSuccess: (data) => {
            onResult(data, action);
          },
          onError: (error) => {
            console.log('');
            console.log('%c mutate():ERROR', 'color:orangered');
            console.log('%c error', 'color:orangered', error);
            onResult(submitData, action);
          },
        }
      );
    } else {
      addSurveySubmission.mutate(
        { data: submitData, recaptchaToken },
        {
          onSuccess: (data) => {
            onResult(data, action);
          },
          onError: (error) => {
            console.log('');
            console.log('%c mutate():ERROR', 'color:orangered');
            console.log('%c error', 'color:orangered', error);
            onResult(submitData, action);
          },
        }
      );
    }
  };

  const formValues = watch();

  const showComplete = mode === 'complete';
  const showIntro = mode === 'intro';
  const showLoading = mode === 'loading';
  const showSkipped = mode === 'skipped';
  const showStep = mode === 'step';

  const showSplash = (step === 0 && direction === 'forward') || mode !== 'step';

  const disableMutate = addSurveySubmission.isLoading || updateSurveySubmission.isLoading;

  return (
    <form
      id='downloadSurveyForm'
      noValidate
      onSubmit={(e) => handleProxySubmit(e, last ? 'complete' : 'next')}>
      <CloseButton aria-label='close dialog' splash={!showStep} onClick={() => handleClose()}>
        <CloseIcon />
      </CloseButton>

      <SurveyStepRow className='survey-step-row'>
        <SurveyStepColumn className='survey-question-column'>
          <SurveyStepColumnContent first>
            {(showIntro || showLoading) && (
              <ContentBox>
                <Box component={'h1'} m={0}>
                  Help us improve AHRQ QI
                </Box>
                <Box fontSize={22} lineHeight={1.1}>
                  2 minutes of your time will help us improve our services.
                </Box>
                <Box>
                  The Agency for Healthcare Research and Quality (AHRQ) would appreciate your
                  feedback about your use of the Quality improvement (QI) modules. Your responses
                  will be used to improve the QI modules and tools. All answers will be
                  confidential.
                </Box>
                <ButtonGroup>
                  {showIntro && <Button label="Yes, I'll Help" onClick={() => handleStart()} />}
                  {showLoading && (
                    <Button
                      startIcon={
                        <CircularProgress size={16} sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                      }
                      label='Loading...'
                      disabled
                    />
                  )}
                </ButtonGroup>
                <ButtonGroup>
                  <AltButton label='Skip Survey and Download File' onClick={() => handleSkip()} />
                </ButtonGroup>
              </ContentBox>
            )}

            {showStep && (
              <ContentBox>
                <SurveyStepper activeStep={step}>
                  {questions.map((item, index) => {
                    const submissionStepValue = submission?.questionsAndAnswers.find(
                      (answer) => answer.questionName === item.name
                    )?.answers;
                    const isStepCompleted = Array.isArray(submissionStepValue)
                      ? submissionStepValue.length > 0
                      : submissionStepValue !== undefined
                      ? Boolean(submissionStepValue)
                      : false;
                    return (
                      <SurveyStep
                        key={item.name}
                        label={index + 1}
                        completed={isStepCompleted}
                        onClick={(e, step) => handleProxySubmit(e, step)}
                      />
                    );
                  })}
                </SurveyStepper>

                <Box
                  component='h2'
                  mb={2}
                  sx={{
                    position: 'relative',
                    display: { xs: 'none', lg: 'block' },
                    width: '100%',
                  }}>
                  <Fade in={true} timeout={777}>
                    <Form.Label htmlFor={question.name}>{question.label}</Form.Label>
                  </Fade>
                </Box>

                <ButtonGroup>
                  <AltButton label='Skip Survey and Download File' onClick={() => handleSkip()} />
                </ButtonGroup>
              </ContentBox>
            )}

            {showSkipped && (
              <ContentBox>
                <Box component={'h1'} m={0}>
                  Thank You for Downloading
                </Box>
                <Box fontSize={22} lineHeight={1.1}>
                  If your download has not started use the link below.
                </Box>
                <ButtonGroup>
                  <Button
                    label='Download File'
                    endIcon={<FileDownloadIcon />}
                    href={file.url}
                    target='_blank'
                  />
                </ButtonGroup>
                <Box fontSize={22} lineHeight={1.1}>
                  Help us improve AHRQ QI. Take our 3 minute survey, which will help us improve our
                  services.
                </Box>
                <ButtonGroup>
                  <Button label="Yes, I'll Help" onClick={() => handleStart()} />
                  <AltButton label='Close Window' onClick={() => handleClose()} />
                </ButtonGroup>
              </ContentBox>
            )}

            {showComplete && (
              <ContentBox>
                <Box component={'h1'} m={0}>
                  Thank you for your feedback
                </Box>
                <Box fontSize={22} lineHeight={1.1}>
                  If your download has not started use the link below.
                </Box>
                <ButtonGroup>
                  <Button
                    label='Download File'
                    endIcon={<FileDownloadIcon />}
                    href={file.url}
                    target='_blank'
                  />
                  <AltButton label='Close Window' onClick={() => handleClose()} />
                </ButtonGroup>
              </ContentBox>
            )}
          </SurveyStepColumnContent>
        </SurveyStepColumn>

        <SurveyStepColumn
          className='survey-answer-column'
          empty={!showStep}
          last
          splash={showSplash}
          ref={transitionContainerRef}>
          {showStep && (
            <Slide
              direction={direction === 'forward' ? 'left' : 'right'}
              in={true}
              container={transitionContainerRef.current}>
              <SurveyStepColumnContent last>
                {/* CHECKBOX_LIST */}
                {question.type === 'checkBoxList' && (
                  <Form.Group
                    key={question.name}
                    className='checkbox-form-group w-100'
                    controlId={question.name}>
                    <FormLabel mobile>{question.label}</FormLabel>
                    <FormLabel>Please select all that apply</FormLabel>
                    {question.options.map((option, index) => {
                      const key = `${question.name}-${index}`;
                      const isChecked = formValues[question.name].includes(option.value);
                      const otherName = question.name + '_other';
                      const hasOtherValue = option.isOther && formValues[otherName] !== '';

                      return (
                        <FormCheckRow key={key} checked={isChecked} className='form-check-row'>
                          <Form.Check
                            id={key}
                            type='checkbox'
                            label={option.label ?? option.value}
                            value={option.value}
                            {...register(question.name, {
                              required: question.isRequired && `Field is required`,
                            })}
                          />
                          {option.isOther && isChecked && (
                            <>
                              <Form.Control
                                type='text'
                                className='form-check-other'
                                placeholder='Enter comments here'
                                autoFocus={!hasOtherValue}
                                {...register(otherName, {
                                  required: `Comments are required`,
                                })}
                              />
                              <FormGroupError error={errors[otherName]} />
                            </>
                          )}
                        </FormCheckRow>
                      );
                    })}
                    <FormGroupError error={errors[question.name]} />
                  </Form.Group>
                )}
                {/* SELECT */}
                {question.type === 'select' && (
                  <Form.Group
                    key={question.name}
                    className='select-form-group w-100'
                    controlId={question.name}>
                    <FormLabel mobile>{question.label}</FormLabel>
                    <FormLabel>Make your selection below</FormLabel>
                    <Form.Control
                      as='select'
                      type='select'
                      {...register(question.name, {
                        required: question.isRequired && `Field is required`,
                      })}>
                      <option value='' disabled>
                        -- Please Select --
                      </option>
                      {question.options.map((item, index) => (
                        <option key={`${question.name}-${index}`} value={item.value}>
                          {item.label ?? item.value}
                        </option>
                      ))}
                    </Form.Control>
                    <FormGroupError error={errors[question.name]} />
                  </Form.Group>
                )}
                {/* TEXT */}
                {question.type === 'text' && (
                  <Form.Group
                    key={question.name}
                    className='text-form-group w-100'
                    controlId={question.name}>
                    <FormLabel mobile>{question.label}</FormLabel>
                    <FormLabel>Enter your feedback below</FormLabel>
                    <Form.Control
                      type='text'
                      {...register(question.name, {
                        required: question.isRequired && `Field is required`,
                      })}
                    />
                    <FormGroupError error={errors[question.name]} />
                  </Form.Group>
                )}
                {/* TEXTAREA */}
                {question.type === 'textArea' && (
                  <Form.Group
                    key={question.name}
                    className='textarea-form-group w-100'
                    controlId={question.name}>
                    <FormLabel mobile>{question.label}</FormLabel>
                    <FormLabel>Enter your feedback below</FormLabel>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      {...register(question.name, {
                        required: question.isRequired && `Field is required`,
                      })}
                    />
                    <FormGroupError error={errors[question.name]} />
                  </Form.Group>
                )}

                <ButtonGroup mt={3} width='100%'>
                  <AltButton
                    name='prev'
                    label='Back'
                    disabled={step === 0 || disableMutate}
                    inverse
                    onClick={(e) => handleProxySubmit(e, 'prev')}
                    sx={{ flexGrow: 1, flexShrink: 1, justifyContent: 'flex-start', minHeight: 50 }}
                  />
                  {!last && (
                    <Button
                      type='submit'
                      name='next'
                      label='Next'
                      disabled={disableMutate}
                      sx={{ flexGrow: 1, flexShrink: 1, justifyContent: 'flex-end', minHeight: 50 }}
                    />
                  )}
                  {last && (
                    <Button
                      type='submit'
                      name='complete'
                      label={'Submit and Download'}
                      disabled={disableMutate}
                      endIcon={<FileDownloadIcon />}
                      sx={{ flexGrow: 0, flexShrink: 1, justifyContent: 'flex-end', minHeight: 50 }}
                    />
                  )}
                </ButtonGroup>
              </SurveyStepColumnContent>
            </Slide>
          )}
        </SurveyStepColumn>
      </SurveyStepRow>
    </form>
  );
};

const getDefaultValue = (question, submission) => {
  if (!question) return {};

  const submissionValue = submission?.questionsAndAnswers.find(
    (item) => item.questionName === question.name
  )?.answers;

  const result = {
    [question.name]: submissionValue ?? question.defaultValue ?? '',
  };

  if (question.options?.find((option) => option.isOther === true)) {
    const otherName = question.name + '_other';
    const otherSubmissionValue = submission?.questionsAndAnswers.find(
      (item) => item.questionName === otherName
    )?.answers;
    result[otherName] = otherSubmissionValue ?? '';
  }

  return result;
};

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.625rem',

  '.MuiButton-root': {
    flexGrow: 1,
    flexShrink: 1,

    minHeight: 50,
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  lineHeight: 1.1,
  width: '100%',
}));

const CloseButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'splash',
})(({ theme, splash }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,

  [theme.breakpoints.up('lg')]: {
    color: splash ? theme.palette.common.white : 'auto',
  },
}));

const FormCheckRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'checked',
})(({ theme, checked }) => ({
  display: 'flex',
  flexDirection: 'column',

  backgroundColor: checked ? theme.palette.fill.blue : theme.palette.common.white,
  border: `1px solid ${checked ? theme.palette.stroke.blue : theme.palette.divider}`,
  marginTop: '.25rem',
  minWidth: 220,
  overflow: 'hidden',
  padding: '.75rem .625rem',
  width: '100%',

  '.form-check': {
    display: 'flex',
    margin: 0,
    padding: 0,

    '.form-check-input': {
      border: '2px solid rgba(0, 0, 0, 0.75)',
      borderRadius: '0.125rem',
      float: 'none',
      marginLeft: 0,
      minHeight: '1.5rem',
      minWidth: '1.5rem',

      '&:checked': {
        backgroundColor: theme.palette.primaryButton.main,
        borderColor: theme.palette.primaryButton.main,
      },
    },
    '.form-check-label': {
      flexGrow: 1,
      lineHeight: 1.1,
      padding: '.375rem .75rem',
    },
  },

  '.form-check-other': {
    margin: 0,
    marginTop: '.5rem',
  },
}));

const FormGroupError = ({ error }) => {
  if (error === undefined) return null;
  return <div className='invalid-feedback block'>{error.message ?? 'Field is required'}</div>;
};

const FormLabel = styled(Form.Label, {
  shouldForwardProp: (prop) => prop !== 'mobile',
})(({ theme, mobile }) => ({
  display: 'block',

  fontSize: 22,
  marginBottom: '1.5rem',
  width: '100%',

  [theme.breakpoints.down('lg')]: {
    display: mobile ? 'block' : 'none',
    fontSize: 33,
    fontWeight: 700,
    lineHeight: 1,
  },
  [theme.breakpoints.up('lg')]: {
    display: mobile ? 'none' : 'block',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: '1.25rem',
  },
}));
