import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import QIDocIcon from '../../img/QIDocIcon.png';
import SoftWareIcon from '../../img/softwareIcon.png';
import NewsIcon from '../../img/newsIcon.png';
import ResourceIcon from '../../img/resourceIcon2.png';
import styles from './archives.module.scss';
import TopBanner from '../Common/TopBanner/TopBanner';

const Landing = () => {
  return (
    <div className={styles.Landings}>
      <TopBanner>
        <h1>Archives</h1>
        <p>
          This section contains information related to previous versions of the Quality Indicators
          software. View different types of materials or download earlier software releases.
        </p>
      </TopBanner>
      <Container className={`outerContainer align-center ${styles.LandingsSection}`}>
        <div className='grid-container grid-container-2'>
          <div className={styles.LandingsCard}>
            <img alt='QIDocIcon' src={QIDocIcon} />
            <h2 className='mt-2 color-primary-2'>QI Module Documentation</h2>
            <p className='mb-5'>
              Software instructions, technical specifications, change logs, and other items related
              to previous versions of the QI software.
            </p>
            <Link to='/archive/qi_modules' rel='noreferrer'>
              View Archived QI Modules
            </Link>
          </div>
          <div className={styles.LandingsCard}>
            <img alt='SoftWareIcon' src={SoftWareIcon} />
            <h2 className='mt-2 color-primary-2'>Software</h2>
            <p className='mb-5'>Previous versions of the QI modules going as far back as 2002.</p>
            <Link to='/archive/software' rel='noreferrer'>
              View Archived Software
            </Link>
          </div>
          <div className={styles.LandingsCard}>
            <img alt='NewsIcon' src={NewsIcon} />
            <h2 className='mt-2 color-primary-2'>News</h2>
            <p className='mb-5'>
              Announcements related to previous software updates, Federal Register notices,
              indicator changes, and other updates.
            </p>
            <Link to='/archive/news' rel='noreferrer'>
              View Archived News
            </Link>
          </div>
          <div className={styles.LandingsCard}>
            <img alt='ResourceIcon' src={ResourceIcon} />
            <h2 className='mt-2 color-primary-2'>Resources</h2>
            <p className='mb-5'>
              Webinars and review toolkits, case studies, and other materials for learning more
              about past versions.
            </p>
            <Link to='/archive/resources' rel='noreferrer'>
              View Archived Resources
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Landing;
