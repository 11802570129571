export const resourcesData = {
  presentations: [
    {
      title: '2023: AcademyHealth Annual Research Meeting, Seattle WA',
      desc: 'This presentation provided an overview of the AHRQ Quality Indicators and research-orientated applications of the AHRQ Quality Indicators.',
      year: 2023,
    },
    {
      title:
        'November 2018: National Association for Healthcare Quality NEXT Conference, Minneapolis MN ',
      desc: 'This panel presentation addressed "AHRQ\'s Prevention Quality Indicators: Using Data Measures to Improve Population Health", as an in-person event that was also broadcast to an estimated 2,500 attendees nationwide',
    },
    {
      title: "July 2018: American Health Quality Association's Quality Summit, Baltimore MD",
      desc: "This panel presentation addressed how to 'Improve Patient Care and the Bottom Line with AHRQ QIs'",
    },
    {
      title: 'October 2017: Department of Defense / ECRI webinar',
      desc: 'This online presentation explored "Case Studies Using the Agency for Healthcare Research and Quality\'s Indicators for Quality" and was broadcast to military health care facilities nationwide',
    },
    {
      title: "August 2016: America's Essential Hospitals member webinar",
      desc: 'This online presentation addressed the “AHRQ Quality Indicators" and was broadcast to several hundred public hospitals throughout the country',
    },
    {
      title: "June 2016: America's Essential Hospitals VITAL2016 Conference, Boston MA",
      desc: "This presentation addressed 'Measuring to Improve Hospital Care Using the AHRQ QIs'",
    },
    {
      title:
        "October 2015: National Association of Health Data Organization's 30th Anniversary Meeting, Washington DC",
      desc: "This panel presentation covered 'ICD-10 Implementation: Opportunities and Challenges for Health Data Organizations'",
    },
    {
      title: 'October 2015: AHRQ Research Conference, Arlington VA',
      desc: "This panel presentation addressed 'AHRQ Quality Measurement to Quality Improvement: AHRQ Quality Indicators: Connecting the Dots'",
    },
    {
      title: 'September 2015: AcademyHealth Concordium 2015, Washington DC',
      desc: "The topic of this presentation was 'AHRQ QI Software: Transforming Raw Data into Measure Results to Drive Quality Improvement'",
    },
    {
      title: 'September 2015: American Health Quality Association Annual Conference, Baltimore MD',
      desc: "This panel presentation addressed 'Using AHRQ QIs as Evidence-based Tools for Change'",
    },
  ],
  publications: [
    // 2024
    {
      title: `Carr, Zyad J., Judy Li, Daniel Agarkov, Makenzie Gazura, and Kunal Karamchandani. <a href="https://doi.org/10.1371/journal.pone.0299137" target="_blank" rel="noreferrer">"Estimates of 30-Day Postoperative Pulmonary Complications after Gastrointestinal Endoscopic Procedures: A Retrospective Cohort Analysis of a Health System Population."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> PLoS ONE 19, no. 2 (February 23, 2024): 1-13.`,
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1371/journal.pone.0299137',
      year: 2024,
    },
    {
      title: `Giese, Alice, Rasheda Khanam, Son Nghiem, Anthony Staines, Thomas Rosemann, Stefan Boes, and Michael M. Havranek. <a href="https://doi.org/10.1371/journal.pone.0285285" target="_blank" rel="noreferrer">"Assessing the Excess Costs of the In-Hospital Adverse Events Covered by the AHRQ's Patient Safety Indicators in Switzerland."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> PLOS ONE 19, no. 2 (February 5, 2024): e0285285.`,
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1371/journal.pone.0285285',
      year: 2024,
    },
    {
      title: `Meille, Giacomo, Pamela L. Owens, Sandra L. Decker, Thomas M. Selden, Melissa A. Miller, Jade K. Perdue-Puli, Erin N. Grace, Craig A. Umscheid, Joel W. Cohen, and R. Burciaga Valdez. <a href="https://doi.org/10.1001/jamanetworkopen.2024.13127" target="_blank" rel="noreferrer">"COVID-19 Admission Rates and Changes in Care Quality in US Hospitals."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> JAMA Network Open 7, no. 5 (May 24, 2024): e2413127.`,
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1001/jamanetworkopen.2024.13127',
      year: 2024,
    },
    {
      title: `Rodriguez, Jorge A., Lipika Samal, Sandya Ganesan, Nina H. Yuan, Matthew Wien, Kenney Ng, Hu Huang, et al. <a href="https://doi.org/10.1097/PTS.0000000000001216" target="_blank" rel="noreferrer">"Patient Safety Indicators During the Initial COVID-19 Pandemic Surge in the United States."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Patient Safety 20, no. 4 (June 1, 2024): 247-51.`,
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      payToAccess: true,
      url: 'https://doi.org/10.1097/PTS.0000000000001216',
      year: 2024,
    },
    {
      title: `Rondelet, Benoît, Fabian Dehanne, Julie Van Den Bulcke, Dimitri Martins, Asmae Belhaj, Benoît Libert, Pol Leclercq, and Magali Pirson. <a href="https://doi.org/10.1186/s12913-023-10535-2" target="_blank" rel="noreferrer">"Daly/Cost Comparison in the Management of Peripheral Arterial Disease at 17 Belgian Hospitals."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> BMC Health Services Research 24, no. 1 (January 19, 2024): 1-19.`,
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1186/s12913-023-10535-2',
      year: 2024,
    },
    {
      title: `Van Wilder, Astrid, Luk Bruyneel, Bianca Cox, Fien Claessens, Dirk De Ridder, and Kris Vanhaecht. <a href="https://doi.org/10.1377/hlthaff.2023.01120" target="_blank" rel="noreferrer">"Rates Of Patient Safety Indicators In Belgian Hospitals Were Low But Generally Higher Than In US Hospitals, 2016-18."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Health Affairs 43, no. 9 (September 2024): 1274-83.`,
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      payToAccess: true,
      url: 'https://doi.org/10.1377/hlthaff.2023.01120',
      year: 2024,
    },
    {
      title: `Alizadeh, Nasrin, Kimia Vahdat, Sara Shashaani, Julie L. Swann, and Osman Y. Özaltιn. <a href="https://doi.org/10.1371/journal.pone.0290215" target="_blank" rel="noreferrer">"Risk Score Models for Urinary Tract Infection Hospitalization."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> PLoS ONE 19, no. 6 (June 14, 2024): 1-16.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1371/journal.pone.0290215',
      year: 2024,
    },
    {
      title: `Berenson, Robert A., and Laura Skopec. <a href="https://doi.org/10.1377/forefront.20240531.688339" target="_blank" rel="noreferrer">"How Preventable Hospitalizations Became A Widely Used But Flawed Quality Measure."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Accessed September 25, 2024.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1377/forefront.20240531.688339',
      year: 2024,
    },
    {
      title: `Hsu, Chun-Chien, Hsi-Yu Lai, Hung-Yu Lin, Sung-Ching Pan, Nai-Chen Cheng, Liang-Kung Chen, Fei-Yuan Hsiao, and Shu-Wen Lin. <a href="https://doi.org/10.1093/ofid/ofae276" target="_blank" rel="noreferrer">"Recurrence of Diabetic Foot Complications: A Domino Effect Leading to Lethal Consequences—Insights From a National Longitudinal Study."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Open Forum Infectious Diseases 11, no. 6 (June 2024): 1-10.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1093/ofid/ofae276',
      year: 2024,
    },
    {
      title: `Koehlmoos, Tracey Pérez, Amanda Banaag, Jessica Korona-Bailey, Andrew J Schoenfeld, and Joel S Weissman. <a href="https://academic.oup.com/milmed/article/189/9-10/e2120/7663234" target="_blank" rel="noreferrer">"Avoidable Hospitalizations in the Military Health System, Fiscal Years 2018-2019."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Military Medicine 189, no. 9 (October 9, 2024): e2120-26.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: false,
      url: 'https://academic.oup.com/milmed/article/189/9-10/e2120/7663234',
      notes: `The DOI takes you to a page that says "manuscript has been accepted" - https://doi.org/10.1093/milmed/usae137. Use the direct link instead.`,
      year: 2024,
    },
    {
      title: `Lord, Jennifer, and Agricola Odoi. <a href="https://doi.org/10.1186/s12942-023-00360-5" target="_blank" rel="noreferrer">"Determinants of Disparities of Diabetes-Related Hospitalization Rates in Florida: A Retrospective Ecological Study Using a Multiscale Geographically Weighted Regression Approach."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> International Journal of Health Geographics 23, no. 1 (January 6, 2024): 1-18.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1186/s12942-023-00360-5',
      year: 2024,
    },
    {
      title: `Lord, Jennifer and Agricola Odoi. <a href="https://doi.org/10.1371/journal.pone.0298182" target="_blank" rel="noreferrer">"Investigation of Geographic Disparities of Diabetes-Related Hospitalizations in Florida Using Flexible Spatial Scan Statistics: An Ecological Study."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> PLoS ONE 19, no. 6 (June 4, 2024): 1-20.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1371/journal.pone.0298182',
      year: 2024,
    },
    {
      title: `Patel, Sadiq Y., Aaron Baum, and Sanjay Basu. <a href="https://doi.org/10.1038/s41598-023-51114-z" target="_blank" rel="noreferrer">"Prediction of Non Emergent Acute Care Utilization and Cost among Patients Receiving Medicaid."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Scientific Reports 14, no. 1 (January 23, 2024): 1-12.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: false,
      url: 'https://doi.org/10.1038/s41598-023-51114-z',
      year: 2024,
    },
    {
      title: `Rast, Jessica E., Sherira J. Fernandes, Whitney Schott, and Lindsay L. Shea. <a href="https://doi.org/10.1007/s10803-023-05911-0" target="_blank" rel="noreferrer">"Disparities by Race and Ethnicity in Inpatient Hospitalizations Among Autistic Adults."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Autism & Developmental Disorders 54, no. 5 (May 2024): 1672-79.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      payToAccess: true,
      url: 'https://doi.org/10.1007/s10803-023-05911-0',
      year: 2024,
    },
    {
      title: `Kunchay, Gayatri and Junhie Oh. <a href="https://pubmed.ncbi.nlm.nih.gov/38166078/" target="_blank" rel="noreferrer">"Potentially Preventable Emergency Department Utilization, Rhode Island, 2022."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Rhode Island Medical Journal 107, no. 1 (January 2024): 45-48.`,
      indicator: 'PQE',
      indicators: [
        {
          title: 'PQE',
        },
      ],
      payToAccess: false,
      url: 'https://pubmed.ncbi.nlm.nih.gov/38166078/',
      notes: `Crossref.org does not come up with a DOI number. Use the direct link.`,
      year: 2024,
    },
    // 2023
    {
      indicator: 'PDI',
      indicators: [
        {
          title: 'PDI',
        },
      ],
      title: `Lake, Eileen T., Douglas Staiger, Jessica G. Smith, and Jeannette A. Rogowski. <a href="https://doi.org/10.1177/10775587221150950" target="_blank" rel="noreferrer">"The Association of Missed Nursing Care With Very Low Birthweight Infant Outcomes."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Medical Care Research and Review 80, no. 3 (2023): 293-302.`,
      year: 2023,
    },
    {
      indicator: 'IQI 30',
      indicators: [
        {
          title: 'IQI 30',
        },
      ],
      title: `Wang, Christina, Karla Lindquist, Harlan Krumholz, and Renee Y. Hsia. <a href="https://doi.org/10.1371/journal.pone.0279905" target="_blank" rel="noreferrer">"Trends in the likelihood of receiving percutaneous coronary intervention in a low-volume hospital and disparities by sociodemographic communities."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> PloS one 18, no. 1 (2023): e0279905`,
      year: 2023,
    },
    // 2022
    {
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      title: `Alabbadi, Sundos, Amy Roach, Joanna Chikwe, and Natalia N. Egorova. 2022. <a href="https://doi.org/10.1016/j.jtcvs.2022.02.037" target="_blank" rel="noreferrer">“National Trend in Failure to Rescue after Cardiac Surgeries.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The Journal of Thoracic and Cardiovascular Surgery, March, S0022-5223(22)00231-8.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Allen, Philip, Farhan Zafar, Junhui Mi, Sarah Crook, Joyce Woo, Natalie Jayaram, Roosevelt Bryant III, et al. <a href="https://doi.org/10.1016/j.jacc.2021.11.036" target="_blank" rel="noreferrer">“Risk Stratification for Congenital Heart Surgery for ICD-10 Administrative Data (RACHS-2).”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of the American College of Cardiology 79, no. 5 (2022): 465-78.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Andersen, Martin, and Anurag Pant. <a href="https://doi.org/10.1080/14737167.2022.2067529" target="_blank" rel="noreferrer">“Effects of Utilization Management on Health Outcomes: Evidence from Urinary Tract Infections and Community-Acquired Pneumonia.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Expert Review of Pharmacoeconomics &amp; Outcomes Research 0, no. 0 (April 15, 2022): 1-12.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Andersen, Martin, and Anurag Pant. <a href="https://doi.org/10.1080/14737167.2022.2067529" target="_blank" rel="noreferrer">“Effects of Utilization Management on Health Outcomes: Evidence from Urinary Tract Infections and Community-Acquired Pneumonia.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Expert Review of Pharmacoeconomics &amp; Outcomes Research 22, no. 6 (2022): 981-92.`,
      year: 2022,
    },
    {
      indicator:
        'PSI 03, PSI 06, PSI 07, PSI 08, PSI 09, PSI 10, PSI 11, PSI 12, PSI 13, PSI 14, PSI 15, PSI 10, PSI 90',
      indicators: [
        {
          title: 'PSI 03',
        },
        {
          title: 'PSI 06',
        },
        {
          title: 'PSI 07',
        },
        {
          title: 'PSI 08',
        },
        {
          title: 'PSI 09',
        },
        {
          title: 'PSI 10',
        },
        {
          title: 'PSI 11',
        },
        {
          title: 'PSI 12',
        },
        {
          title: 'PSI 13',
        },
        {
          title: 'PSI 14',
        },
        {
          title: 'PSI 15',
        },
        {
          title: 'PSI 10',
        },
        {
          title: 'PSI 90',
        },
      ],
      title: `Ang, Darwin, Kenny Nieto, Mason Sutherland, Megan O'Brien, Huazhi Liu, and Adel Elkbuli. 2022. <a href="https://doi.org/10.1177/00031348211056284" target="_blank" rel="noreferrer">“Understanding Preventable Deaths in the Geriatric Trauma Population: Analysis of 3,452,339 Patients From the Center of Medicare and Medicaid Services Database.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The American Surgeon 88 (4): 587-96.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Araim, Fawaz, Artem Shmelev, and Gopal C Kowdley. <a href="https://doi.org/10.1177/0003134820953769" target="_blank" rel="noreferrer">“Incidence of Complicated Appendicitis as a Metric of Health Care Delivery.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The American Surgeon 88, no. 4 (2022): 597-607.`,
      year: 2022,
    },
    {
      indicator: 'IQI',
      indicators: [
        {
          title: 'IQI',
        },
      ],
      title: `Badari, A, Z Larned, and B Moore. <a href="https://doi.org/10.1007/978-3-031-04141-9_32 " target="_blank" rel="noreferrer">“Oncology Hospital Quality Metrics.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> In Optimizing Widely Reported Hospital Quality and Safety Grades: An Ochsner Quality and Value Playbook, 275-87. Springer, 2022.`,
      year: 2022,
    },
    {
      indicator: 'PSI 06, PSI 09, PSI 12, PSI 90',
      indicators: [
        {
          title: 'PSI 06',
        },
        {
          title: 'PSI 09',
        },
        {
          title: 'PSI 12',
        },
        {
          title: 'PSI 90',
        },
      ],
      title: `Bhakta, Shivang, Benjamin D Pollock, Young M Erben, Michael A Edwards, Katherine H Noe, Sean C Dowdy, Pablo Moreno Franco, and Jennifer B Cowart. 2022. <a href="https://doi.org/10.1002/jhm.12832" target="_blank" rel="noreferrer">“The Association of Acute COVID-19 Infection with Patient Safety Indicator-12 Events in a Multisite Healthcare System.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Hospital Medicine 17 (5): 350-57.`,
      year: 2022,
    },
    {
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      title: `Blike, George T, Irina M Perreard, Krystal M McGovern, and Susan P McGrath. <a href="https://doi.org/10.1097/PTS.0000000000000984" target="_blank" rel="noreferrer">“A Pragmatic Method for Measuring Inpatient Complications and Complication-Specific Mortality.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Patient Safety 18, no. 7 (2022): 659-66.`,
      year: 2022,
    },
    {
      indicator: 'IQI',
      indicators: [
        {
          title: 'IQI',
        },
      ],
      title: `Blike, George T, Irina M Perreard, Krystal M McGovern, and Susan P McGrath. <a href="https://doi.org/10.1097/pts.0000000000000984" target="_blank" rel="noreferrer">“A Pragmatic Method for Measuring Inpatient Complications and Complication-Specific Mortality.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Patient Safety 18, no. 7 (2022): 659-66.`,
      year: 2022,
    },
    {
      indicator: 'PQI 02',
      indicators: [
        {
          title: 'PQI 02',
        },
      ],
      title: `Bouchard, Megan E, Kristin Kan, Yao Tian, Mia Casale, Tracie Smith, Christopher De Boer, Samuel Linton, Fizan Abdullah, and Hassan MK Ghomrawi. <a href="https://doi.org/10.1001/jamanetworkopen.2021.48865" target="_blank" rel="noreferrer">“Association between Neighborhood-Level Social Determinants of Health and Access to Pediatric Appendicitis Care.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> JAMA Network Open 5, no. 2 (2022): e2148865-e2148865.`,
      year: 2022,
    },
    {
      indicator: 'PQI 16',
      indicators: [
        {
          title: 'PQI 16',
        },
      ],
      title: `Brennan, Meghan B., W. Ryan Powell, Farah Kaiksow, Joseph Kramer, Yao Liu, Amy JH Kind, and Christie M. Bartels. 2022. <a href="https://doi.org/10.1001/jamanetworkopen.2022.8399" target="_blank" rel="noreferrer">“Association of Race, Ethnicity, and Rurality With Major Leg Amputation or Death Among Medicare Beneficiaries Hospitalized With Diabetic Foot Ulcers.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> JAMA Network Open 5 (4): e228399-e228399.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Bronstein, Janet M, Lei Huang, John P Shelley, Emily B Levitan, Caroline A Presley, April A Agne, Favel L Mondesir, Kevin R Riggs, Maria Pisu, and Andrea L Cherrington. <a href="https://doi.org/10.1016/j.pcd.2021.10.005" target="_blank" rel="noreferrer">“Primary Care Visits and Ambulatory Care Sensitive Diabetes Hospitalizations among Adult Alabama Medicaid Beneficiaries.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Primary Care Diabetes 16, no. 1 (2022): 116-21.`,
      year: 2022,
    },
    {
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      title: `Buza, John A., Leah Y. Carreon, Portia A. Steele, Ryan G. Nazar, Steven D. Glassman, and Jeffrey L. Gum. 2022. <a href="https://doi.org/10.1016/j.spinee.2022.05.014" target="_blank" rel="noreferrer">“Patient Safety Indicators from a Spine Surgery Perspective: The Importance of a Specialty Specific Clinician Working with the Documentation Team and the Impact to Your Hospital.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The Spine Journal: Official Journal of the North American Spine Society, June, S1529-9430(22)00240-6.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Cedrone, F, P Di Giovanni, G Di Martino, F Romano, and T Staniscia. <a href="https://doi.org/10.1093/eurpub/ckac129.154" target="_blank" rel="noreferrer">“Association between Socio-Economic Deprivation and AHRQ Composite Indicator during Pandemic: Fabrizio Cedrone.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> European Journal of Public Health 32, no. Supplement_3 (2022): ckac129-154.`,
      year: 2022,
    },
    {
      indicator: 'PQI 90, PQI 91, PQI 92, PQI 93',
      indicators: [
        {
          title: 'PQI 90',
        },
        {
          title: 'PQI 91',
        },
        {
          title: 'PQI 92',
        },
        {
          title: 'PQI 93',
        },
      ],
      title: `Cedrone, Fabrizio, Alessandro Catalini, Lorenzo Stacchini, Nausicaa Berselli, Marta Caminiti, Clara Mazza, Claudia Cosma, Giuseppa Minutolo, and Giuseppe Di Martino. <a href="https://doi.org/10.3390/ijerph192214691" target="_blank" rel="noreferrer">“The Role of Gender in the Association between Mental Health and Potentially Preventable Hospitalizations: A Single-Center Retrospective Observational Study.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> International Journal of Environmental Research and Public Health 19, no. 22 (2022): 14691.`,
      year: 2022,
    },
    {
      indicator: 'PSI 04',
      indicators: [
        {
          title: 'PSI 04',
        },
      ],
      title: `D'Apuzzo, Michele R., Matthew D. Higgins, Wendy M. Novicoff, and James A. Browne. <a href="https://doi.org/10.1016/j.artd.2022.03.003" target="_blank" rel="noreferrer">“Hospital Volume as a Source of Variation for Major Complications and Early In-Hospital Mortality After Total Joint Arthroplasty.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Arthroplasty Today 16 (May 26, 2022): 53-56.`,
      year: 2022,
    },
    {
      indicator:
        'PQI 01, PQI 02, PQI 03, PQI 05, PQI 07, PQI 08, PQI 09, PQI 10, PQI 11, PQI 12, PQI 14, PQI 15, PQI 16',
      indicators: [
        {
          title: 'PQI 01',
        },
        {
          title: 'PQI 02',
        },
        {
          title: 'PQI 03',
        },
        {
          title: 'PQI 05',
        },
        {
          title: 'PQI 07',
        },
        {
          title: 'PQI 08',
        },
        {
          title: 'PQI 09',
        },
        {
          title: 'PQI 10',
        },
        {
          title: 'PQI 11',
        },
        {
          title: 'PQI 12',
        },
        {
          title: 'PQI 14',
        },
        {
          title: 'PQI 15',
        },
        {
          title: 'PQI 16',
        },
      ],
      title: `de Oliveira, Claire, Bryan Tanner, Patricia Colton, and Paul Kurdyak. <a href="https://doi.org/10.1002/eat.23910" target="_blank" rel="noreferrer">"Understanding the scope of preventable acute care spending among patients with eating disorders."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> International Journal of Eating Disorders, 1- 32.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Duveau, C, C Wets, K Delaruelle, S Demoulin, M Dauvrin, B Lepièce, M Ceuterick, S De Maesschalck, P Bracke, and V Lorant. <a href="https://doi.org/10.1093/eurpub/ckac129.155" target="_blank" rel="noreferrer">“Factors Influencing General Practitioners' Decisions in Migrant Patients with Mental Health Disorder: Camille Duveau.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> European Journal of Public Health 32, no. Supplement_3 (2022): ckac129-155.`,
      year: 2022,
    },
    {
      indicator: 'PSI 90',
      indicators: [
        {
          title: 'PSI 90',
        },
      ],
      title: `Enumah, Samuel J., Andrew S. Resnick, and David C. Chang. <a href="https://doi.org/10.1371/journal.pone.0266696" target="_blank" rel="noreferrer">“Association of Measured Quality with Financial Health among U.S. Hospitals.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> PLoS ONE 17, no. 4 (April 20, 2022): e0266696.`,
      year: 2022,
    },
    {
      indicator: 'PSI 03, PSI 06, PSI 09, PSI 11, PSI 12, PSI 13, PSI 14, PSI 15, PSI 16',
      indicators: [
        {
          title: 'PSI 03',
        },
        {
          title: 'PSI 06',
        },
        {
          title: 'PSI 09',
        },
        {
          title: 'PSI 11',
        },
        {
          title: 'PSI 12',
        },
        {
          title: 'PSI 13',
        },
        {
          title: 'PSI 14',
        },
        {
          title: 'PSI 15',
        },
        {
          title: 'PSI 16',
        },
      ],
      title: `Hamed, Motaz, Anna-Laura Potthoff, Julian P. Layer, David Koch, Valeri Borger, Muriel Heimann, Davide Scafa, et al. <a href="https://doi.org/10.3390/cancers14061515" target="_blank" rel="noreferrer">“Benchmarking Safety Indicators of Surgical Treatment of Brain Metastases Combined with Intraoperative Radiotherapy: Results of Prospective Observational Study with Comparative Matched-Pair Analysis.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Cancers 14, no. 6 (March 16, 2022): 1515.`,
      year: 2022,
    },
    {
      indicator: 'IQI',
      indicators: [
        {
          title: 'IQI',
        },
      ],
      title: `Hegland, Thomas A, Pamela L Owens, and Thomas M Selden. <a href="https://doi.org/10.1111/1475-6773.14010" target="_blank" rel="noreferrer">“New Evidence on Geographic Disparities in United States Hospital Capacity.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Health Services Research 57, no. 5 (2022): 1006-19.`,
      year: 2022,
    },
    {
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      title: `Hegland, Thomas A, Pamela L Owens, and Thomas M Selden. <a href="https://doi.org/10.1111/1475-6773.14010" target="_blank" rel="noreferrer">“New Evidence on Geographic Disparities in United States Hospital Capacity.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Health Services Research 57, no. 5 (2022): 1006-19.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Henderson, Morgan, Fei Han, Chad Perman, Howard Haft, and Ian Stockwell. 2022. <a href="https://doi.org/10.1111/1475-6773.13891" target="_blank" rel="noreferrer">“Predicting Avoidable Hospital Events in Maryland.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Health Services Research 57 (1): 192-99.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Henking, C. <a href="https://doi.org/10.1093/eurpub/ckac129.156" target="_blank" rel="noreferrer">“Socio-Economic Inequalities in Mental Health: A New Framework and Analysis across 113 Countries: Christoph Henking.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> European Journal of Public Health 32, no. Supplement_3 (2022): ckac129-156.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Khan, Anam, Paul Lin, Neil Kamdar, Mark Peterson, and Elham Mahmoudi. <a href="https://doi.org/10.1016/j.msard.2022.104105" target="_blank" rel="noreferrer">“Potentially Preventable Hospitalizations and Use of Preventive Services among People with Multiple Sclerosis: Large Cohort Study, USA.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Multiple Sclerosis and Related Disorders 68 (2022): 104105.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Kilaru, Austin S, Nicholas Illenberger, Zachary F Meisel, Peter W Groeneveld, Manqing Liu, Angira Mondal, Nandita Mitra, and Raina M Merchant. <a href="https://doi.org/10.1161/CIRCOUTCOMES.122.009001" target="_blank" rel="noreferrer">“Incidence of Timely Outpatient Follow-Up Care After Emergency Department Encounters for Acute Heart Failure.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Circulation: Cardiovascular Quality and Outcomes 15, no. 9 (2022): e009001.`,
      year: 2022,
    },
    {
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      title: `Lemdani, Mehdi S., Hannaan S. Choudhry, Christopher C. Tseng, Christina H. Fang, Donata Sukyte-Raube, Prayag Patel, and Jean Anderson Eloy. <a href="https://doi.org/10.1177/01945998221089826" target="_blank" rel="noreferrer">“Impact of Facility Volume on Patient Safety Indicator Events After Transsphenoidal Pituitary Surgery.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Otolaryngology-Head and Neck Surgery, April 5, 2022, 01945998221089826.`,
      year: 2022,
    },
    {
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      title: `Locey, Kenneth J., Thomas A. Webb, Brian Stein, Sana Farooqui, and Bala Hota. <a href="https://doi.org/10.1016/j.jcjq.2022.05.002" target="_blank" rel="noreferrer">“Variation in the Reporting of Elective Surgeries and Its Influence on Patient Safety Indicators.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The Joint Commission Journal on Quality and Patient Safety, May 20, 2022.`,
      year: 2022,
    },
    {
      indicator: 'PQI 90',
      indicators: [
        {
          title: 'PQI 90',
        },
      ],
      title: `Mahmoudi, Elham, Paul Lin, Samantha Ratakonda, Anam Khan, Neil Kamdar, and Mark D. Peterson. 2022. <a href="https://doi.org/10.1016/j.apmr.2021.12.004" target="_blank" rel="noreferrer">“Preventative Services Use and Risk Reduction for Potentially Preventative Hospitalizations Among People With Traumatic Spinal Cord Injury.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Archives of Physical Medicine and Rehabilitation 103 (7): 1255-62.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Milliren, Carly E., George Bailey, Dionne A. Graham, and Al Ozonoff. <a href="https://doi.org/10.1097/PTS.0000000000000938" target="_blank" rel="noreferrer">“Relationships Between Pediatric Safety Indicators Across a National Sample of Pediatric Hospitals: Dispelling the Myth of the ‘Safest' Hospital.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Patient Safety 18, no. 4 (June 1, 2022): e741-46.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Minegishi, Taeko, Gary J Young, Kristin M Madison, and Steven D Pizer. <a href="https://doi.org/10.1097/MLR.0000000000001672" target="_blank" rel="noreferrer">“Regional Economic Conditions and Preventable Hospitalization Among Older Patients With Diabetes.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Medical Care 60, no. 3 (2022): 212-18.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Minegishi, Taeko, Gary J. Young, Kristin M. Madison, and Steven D. Pizer. <a href="https://doi.org/10.1097/MLR.0000000000001672" target="_blank" rel="noreferrer">“Regional Economic Conditions and Preventable Hospitalization Among Older Patients With Diabetes.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Medical Care 60, no. 3 (March 1, 2022): 212-18.`,
      year: 2022,
    },
    {
      indicator: 'IQI',
      indicators: [
        {
          title: 'IQI',
        },
      ],
      title: `Mishra, Abhay Nath, Youyou Tao, Mark Keil, and Jeong-ha Oh. <a href="https://doi.org/10.1287/isre.2021.1064" target="_blank" rel="noreferrer">“Functional IT Complementarity and Hospital Performance in the United States: A Longitudinal Investigation.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Information Systems Research 33, no. 1 (2022): 55-75.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Moosazadeh, Mohammad, Pouya Ifaei, Amir Saman Tayerani Charmchi, Somayeh Asadi, and ChangKyoo Yoo. <a href="https://doi.org/10.1016/j.scs.2022.103990" target="_blank" rel="noreferrer">“A Machine Learning-Driven Spatio-Temporal Vulnerability Appraisal Based on Socio-Economic Data for COVID-19 Impact Prevention in the US Counties.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Sustainable Cities and Society 83 (2022): 103990.`,
      year: 2022,
    },
    {
      indicator: 'IQI 90',
      indicators: [
        {
          title: 'IQI 90',
        },
      ],
      title: `Mulligan, Karen, Seema Choksy, Catherine Ishitani, and John A Romley. <a href="https://doi.org/10.1371/journal.pone.0264712" target="_blank" rel="noreferrer">“What Do Nonprofit Hospitals Reward? An Examination of CEO Compensation in Nonprofit Hospitals.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Plos One 17, no. 3 (2022): e0264712.`,
      year: 2022,
    },
    {
      indicator: 'IQI',
      indicators: [
        {
          title: 'IQI',
        },
      ],
      title: `Ochoa, Juan G Diaz, and Faizan E Mustafa. <a href="https://doi.org/10.1016/j.artmed.2022.102359" target="_blank" rel="noreferrer">“Graph Neural Network Modelling as a Potentially Effective Method for Predicting and Analyzing Procedures Based on Patients' Diagnoses.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Artificial Intelligence in Medicine 131 (2022): 102359.`,
      year: 2022,
    },
    {
      indicator: 'PSI 11',
      indicators: [
        {
          title: 'PSI 11',
        },
      ],
      title: `Orabi, Danny, Robert Naples, Dominique Brundidge, Karen Snyder, Moheb Gohar, Deepak Agarwal, Srinivasa Govindarajan, et al. 2022. <a href="https://doi.org/10.1016/j.jss.2021.12.041" target="_blank" rel="noreferrer">“Postoperative Respiratory Failure After Elective Abdominal Surgery: A Case-Control Study.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The Journal of Surgical Research 274 (June): 160-68. `,
      year: 2022,
    },
    {
      indicator: 'PSI 04',
      indicators: [
        {
          title: 'PSI 04',
        },
      ],
      title: `Published Abstract Demer, melissa redier-, and Geoffrey Colby. 2022. <a href="https://www.ahajournals.org/doi/10.1161/str.53.suppl_1.TMP45" target="_blank" rel="noreferrer">“Abstract TMP45: Potential For Unwarranted Poor Institutional Patient Safety Scores From Inclusion Of Endovascular Thrombectomy In Us National Ahrq Patient Safety Indicator Program.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Stroke 53 (Suppl_1): ATMP45-ATMP45.`,
      year: 2022,
    },
    {
      indicator: 'IQI 09, IQI 11',
      indicators: [
        {
          title: 'IQI 09',
        },
        {
          title: 'IQI 11',
        },
      ],
      title: `Published Abstract Ray, Monika, and Patrick S. Romano. 2022. <a href="https://doi.org/10.1017/cts.2022.144" target="_blank" rel="noreferrer">“266 Inpatient Quality Indicators Risk-Adjustment Using Interactions Selected by Machine Learning Methods.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Clinical and Translational Science 6 (s1): 44-45.`,
      year: 2022,
    },
    {
      indicator: 'PSI',
      indicators: [
        {
          title: 'PSI',
        },
      ],
      title: `Published Editorial Stockwell, David C., and Paul Sharek. 2022. <a href="http://dx.doi.org/10.1136/bmjqs-2021-014517" target="_blank" rel="noreferrer">Diagnosing Diagnostic Errors: It's Time to Evolve the Patient Safety Research Paradigm.</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> BMJ Quality &amp; Safety. BMJ Publishing Group Ltd.`,
      year: 2022,
    },
    {
      indicator: 'PQI 93',
      indicators: [
        {
          title: 'PQI 93',
        },
      ],
      title: `Ramachandran, Sujith, Yiqiao Zhang, Tyler J Dunn, Swarnali Goswami, Eric Pittman, Georgianna Mann, and Annie Cafer. <a href="https://doi.org/10.37765/ajmc.2022.89260" target="_blank" rel="noreferrer">“Impact of Food Affordability on Diabetes-Related Preventable Hospitalization.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> American Journal of Managed Care 28, no. 11 (2022).`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Ramalho, Andre, Julio Souza, Pedro Castro, Mariana Lobo, Paulo Santos, and Alberto Freitas. <a href="https://doi.org/10.34172/ijhpm.2021.76" target="_blank" rel="noreferrer">“Portuguese Primary Healthcare and Prevention Quality Indicators for Diabetes Mellitus-a Data Envelopment Analysis.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> International Journal of Health Policy and Management 11, no. 9 (2022): 1725-34.`,
      year: 2022,
    },
    {
      indicator: 'IQI 09, IQI 11',
      indicators: [
        {
          title: 'IQI 09',
        },
        {
          title: 'IQI 11',
        },
      ],
      title: `Ray, Monika, Sharon Zhao, Sheng Wang, Alex Bohl, and Patrick S Romano. <a href="https://doi.org/10.21203/rs.3.rs-2026639/v1" target="_blank" rel="noreferrer">“Hierarchical Group Lasso Regression for Efficiently Identifying Interactions in Healthcare Risk-Adjustment Models,”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> 2022. (Preprint, not peer reviewed)`,
      year: 2022,
    },
    {
      indicator: 'PSI 12',
      indicators: [
        {
          title: 'PSI 12',
        },
      ],
      title: `Richie, Cheryl D, Jennifer T Castle, George A Davis, Joseph L Bobadilla, Qiang He, Mary B Moore, Tricia A Kellenbarger, and Eleftherios S Xenos. 2022. <a href="https://doi.org/10.1177/00033197221083724" target="_blank" rel="noreferrer">“Modes of Failure in Venous Thromboembolism Prophylaxis.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Angiology, no. 4ua, 0203706: 33197221083724.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Roman, Susan B, Lacey Whitmire, Lori Reynolds, Saamir Pasha, Anthony Brockman, and Benjamin J Oldfield. <a href="https://doi.org/10.1089/pop.2021.0169" target="_blank" rel="noreferrer">“Demographic and Clinical Correlates of the Cost of Potentially Preventable Hospital Encounters in a Community Health Center Cohort.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Population Health Management 25, no. 5 (2022): 625-31.`,
      year: 2022,
    },
    {
      indicator: 'IQI',
      indicators: [
        {
          title: 'IQI',
        },
      ],
      title: `Romley, John A, Abe Dunn, Dana Goldman, and Neeraj Snood. <a href="https://www.nber.org/books-and-chapters/big-data-twenty-first-century-economic-statistics/quantifying-productivity-growth-delivery-important-episodes-care-within-medicare-program-using" target="_blank" rel="noreferrer">"Big Data for Twenty-First-Century Economic Statistics. Studies in Income and Wealth 79."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> University of Chicago Press, 2022.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Rotter, Jason, Keri Calkins, Kate Stewart, Isabel Platt, Rachel Machta, Keith Kranker, Nancy McCall, and Greg Peterson. <a href="https://hscrc.maryland.gov/Documents/Modernization/MD_TCOC_Evaluation_Quantitative-Only%20Report.pdf" target="_blank" rel="noreferrer">“Evaluation of the Maryland Total Cost of Care Model: Quantitative-Only Report for the Model's First Three Years (2019 to 2021).”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Centers for Medicare &amp; Medicaid Services, 2022.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Saxena, Anshul, Venkataraghavan Ramamoorthy, Muni Rubens, Peter McGranaghan, Emir Veledar, and Khurram Nasir. 2022. <a href="https://doi.org/10.1038/s41598-022-06077-y" target="_blank" rel="noreferrer">“Trends in Quality of Primary Care in the United States, 2007-2016.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Scientific Reports 12 (1): 1982.`,
      year: 2022,
    },
    {
      indicator: 'PQI 05',
      indicators: [
        {
          title: 'PQI 05',
        },
      ],
      title: `Spain, C. Victor, Parul Dayal, Yingjie Ding, Carlos Iribarren, Theodore A. Omachi, and Hubert Chen. 2022. <a href="https://doi.org/10.1080/02770903.2021.1922915" target="_blank" rel="noreferrer">“Usage of Long-Acting Muscarinic Antagonists and Biologics as Add-on Therapy for Patients in the United States with Moderate-to-Severe Asthma.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The Journal of Asthma: Official Journal of the Association for the Care of Asthma 59 (6): 1237-47.`,
      year: 2022,
    },
    {
      indicator:
        'PSI 03, PSI 06, PSI 08, PSI 09, PSI 10, PSI 11, PSI 12, PSI 13, PSI 14, PSI 15, PSI 90',
      indicators: [
        {
          title: 'PSI 03',
        },
        {
          title: 'PSI 06',
        },
        {
          title: 'PSI 08',
        },
        {
          title: 'PSI 09',
        },
        {
          title: 'PSI 10',
        },
        {
          title: 'PSI 11',
        },
        {
          title: 'PSI 12',
        },
        {
          title: 'PSI 13',
        },
        {
          title: 'PSI 14',
        },
        {
          title: 'PSI 15',
        },
        {
          title: 'PSI 90',
        },
      ],
      title: `Stefanou, Amalia, Camden Gardner, and Ilan Rubinfeld. 2022. <a href="https://doi.org/10.1007/s00464-022-09100-5" target="_blank" rel="noreferrer">“A Retrospective Study of the Effects of Minimally Invasive Colorectal Surgery on Patient Safety Indicators across a Five-Hospital System.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Surgical Endoscopy, March.`,
      year: 2022,
    },
    {
      indicator: 'PSI 11',
      indicators: [
        {
          title: 'PSI 11',
        },
      ],
      title: `Stocking, Jacqueline C, Christiana Drake, J Matthew Aldrich, Michael K Ong, Alpesh Amin, Rebecca A Marmor, Laura Godat, et al. 2022. <a href="https://doi.org/10.1186/s12871-022-01681-x" target="_blank" rel="noreferrer">“Outcomes and Risk Factors for Delayed-Onset Postoperative Respiratory Failure: A Multi-Center Case-Control Study by the University of California Critical Care Research Collaborative (UC3RC).”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> BMC Anesthesiology 22 (1): 146.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Texas External Quality Review Organization. <a href="https://www.hhs.texas.gov/sites/default/files/documents/eqro-annual-tech-report-contract-yr-2022.pdf" target="_blank" rel="noreferrer">“External Quality Review of Texas Medicaid &amp; CHIP Managed Care Summary of Activities Report.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Austin, Texas: Texas Department of Health and Human Services, 2022.`,
      year: 2022,
    },
    {
      indicator: 'PQI, PDI',
      indicators: [
        {
          title: 'PQI',
        },
        {
          title: 'PDI',
        },
      ],
      title: `Texas External Quality Review Organization. <a href="https://www.hhs.texas.gov/sites/default/files/documents/eqro-annual-tech-report-contract-yr-2022.pdf" target="_blank" rel="noreferrer">“External Quality Review of Texas Medicaid &amp; CHIP Managed Care Summary of Activities Report.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Austin, Texas: Texas Department of Health and Human Services, 2022.`,
      year: 2022,
    },
    {
      indicator: 'PQI 05',
      indicators: [
        {
          title: 'PQI 05',
        },
      ],
      title: `Tkacz, Joseph, Kristin A Evans, Daniel R Touchette, Edward Portillo, Charlie Strange, Anthony Staresinic, Norbert Feigler, Sushma Patel, and Michael Pollack. <a href="https://doi.org/10.2147/COPD.S347735" target="_blank" rel="noreferrer">“PRIMUS-Prompt Initiation of Maintenance Therapy in the US: A Real-World Analysis of Clinical and Economic Outcomes among Patients Initiating Triple Therapy Following a COPD Exacerbation.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> International Journal of Chronic Obstructive Pulmonary Disease, 2022, 329-42.`,
      year: 2022,
    },
    {
      indicator: 'IQI 90, IQI 91',
      indicators: [
        {
          title: 'IQI 90',
        },
        {
          title: 'IQI 91',
        },
      ],
      title: `Trout, Kate E, Li-Wu Chen, Fernando A Wilson, Hyo Jung Tak, and David Palm. 2022. <a href="https://doi.org/10.1097/JHQ.0000000000000314" target="_blank" rel="noreferrer">“The Impact of Electronic Health Records and Meaningful Use on Inpatient Quality.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal for Healthcare Quality : Official Publication of the National Association for Healthcare Quality 44 (2): e15-23.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Twersky, Sylvia E., and Adam Davey. <a href="https://doi.org/10.3390/ijerph19020795" target="_blank" rel="noreferrer">“National Hospitalization Trends and the Role of Preventable Hospitalizations among Centenarians in the United States (2000-2009).”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> International Journal of Environmental Research and Public Health 19, no. 2 (January 2022): 795.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Ukert, Benjamin, Stephen Esquivel-Pickett, M. D. Manish Oza, PhD Andrea DeVries, and M. A. Gosia Sylwestrzak. 2022. <a href="https://www.ajmc.com/view/disparities-in-health-care-use-among-low-salary-and-high-salary-employees" target="_blank" rel="noreferrer">“Disparities in Health Care Use Among Low-Salary and High-Salary Employees.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The American Journal of Managed Care, May 2022, 28 (5).`,
      year: 2022,
    },
    {
      indicator:
        'IQI 11, IQI 12, IQI 13, IQI 14, IQI 15, IQI 16, IQI 17, IQI 18, IQI 19, IQI 20, IQI 30, IQI 32, PSI 03, PSI 04, PSI 06, PSI 07, PSI 08, PSI 09, PSI 10, PSI 11, PSI 12, PSI 13, PSI 14, PSI 15, PSI 90',
      indicators: [
        {
          title: 'IQI 11',
        },
        {
          title: 'IQI 12',
        },
        {
          title: 'IQI 13',
        },
        {
          title: 'IQI 14',
        },
        {
          title: 'IQI 15',
        },
        {
          title: 'IQI 16',
        },
        {
          title: 'IQI 17',
        },
        {
          title: 'IQI 18',
        },
        {
          title: 'IQI 19',
        },
        {
          title: 'IQI 20',
        },
        {
          title: 'IQI 30',
        },
        {
          title: 'IQI 32',
        },
        {
          title: 'PSI 03',
        },
        {
          title: 'PSI 04',
        },
        {
          title: 'PSI 06',
        },
        {
          title: 'PSI 07',
        },
        {
          title: 'PSI 08',
        },
        {
          title: 'PSI 09',
        },
        {
          title: 'PSI 10',
        },
        {
          title: 'PSI 11',
        },
        {
          title: 'PSI 12',
        },
        {
          title: 'PSI 13',
        },
        {
          title: 'PSI 14',
        },
        {
          title: 'PSI 15',
        },
        {
          title: 'PSI 90',
        },
      ],
      title: `Waters, Teresa M, Natalie Burns, Cameron M Kaplan, Ilana Graetz, Joseph Benitez, Roberto Cardarelli, and Michael J Daniels. <a href="https://doi.org/10.1186/s12913-022-08348-w" target="_blank" rel="noreferrer">“Combined Impact of Medicare's Hospital Pay for Performance Programs on Quality and Safety Outcomes Is Mixed.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> BMC Health Services Research 22, no. 1 (2022): 958.`,
      year: 2022,
    },
    // {
    //   indicator: 'IQI 11, IQI 12, IQI 13, IQI 14, IQI 15, IQI 16, IQI 17, IQI 18, IQI 19, IQI 20, IQI 32, PSI 03, PSI 04, PSI 06, PSI 07, PSI 08, PSI 09, PSI 10, PSI 11, PSI 12, PSI 13, PSI 14, PSI 15, PSI 90',
    //   indicators: [
    //     {
    //       title: 'IQI 11',
    //     },
    //     {
    //       title: 'IQI 12',
    //     },
    //     {
    //       title: 'IQI 13',
    //     },
    //     {
    //       title: 'IQI 14',
    //     },
    //     {
    //       title: 'IQI 15',
    //     },
    //     {
    //       title: 'IQI 16',
    //     },
    //     {
    //       title: 'IQI 17',
    //     },
    //     {
    //       title: 'IQI 18',
    //     },
    //     {
    //       title: 'IQI 19',
    //     },
    //     {
    //       title: 'IQI 20',
    //     },
    //     {
    //       title: 'IQI 32',
    //     },
    //     {
    //       title: 'PSI 03',
    //     },
    //     {
    //       title: 'PSI 04',
    //     },
    //     {
    //       title: 'PSI 06',
    //     },
    //     {
    //       title: 'PSI 07',
    //     },
    //     {
    //       title: 'PSI 08',
    //     },
    //     {
    //       title: 'PSI 09',
    //     },
    //     {
    //       title: 'PSI 10',
    //     },
    //     {
    //       title: 'PSI 11',
    //     },
    //     {
    //       title: 'PSI 12',
    //     },
    //     {
    //       title: 'PSI 13',
    //     },
    //     {
    //       title: 'PSI 14',
    //     },
    //     {
    //       title: 'PSI 15',
    //     },
    //     {
    //       title: 'PSI 90',
    //     },
    //   ],
    //   title: `Waters, Teresa M, Natalie Burns, Cameron M Kaplan, Ilana Graetz, Joseph Benitez, Roberto Cardarelli, and Michael J Daniels. <a href="https://doi.org/10.1186/s12913-022-08348-w" target="_blank" rel="noreferrer">“Combined Impact of Medicare's Hospital Pay for Performance Programs on Quality and Safety Outcomes Is Mixed.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> BMC Health Services Research 22, no. 1 (2022): 958.`,
    // },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Weeks, William B., Huabo Wang, Jeremy Smith, and James N. Weinstein. 2022. <a href="https://doi.org/10.1007%2Fs11606-021-06955-7" target="_blank" rel="noreferrer">“Ambulatory Care Sensitive Condition Admission Rates in Younger and Older Traditional Medicare and Medicare Advantage Populations, 2011-2019.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of General Internal Medicine 37 (7): 1814-17.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Wood, Shannon M, Molin Yue, Sandra V Kotsis, Anne V Seyferth, Lu Wang, and Kevin C Chung. <a href="https://doi.org/10.1016/j.focus.2022.100027" target="_blank" rel="noreferrer">“Preventable Hospitalization Trends Before and After the Affordable Care Act.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> AJPM Focus 1, no. 2 (2022): 100027.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Wooldridge, Jennalee S, Fernanda S Rossi, Coral Anderson, Natalie M Yarish, Ambri Pukhraj, and Ranak B Trivedi. <a href="https://doi.org/10.1080/07317115.2022.2086089" target="_blank" rel="noreferrer">“Systematic Review of Dyadic Interventions for Ambulatory Care Sensitive Conditions: Current Evidence and Key Gaps.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Clinical Gerontologist, 2022, 1-29.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Youn, Hin Moi, Dong-Woo Choi, Sung-In Jang, and Eun-Cheol Park. <a href="https://doi.org/10.1038/s41598-022-05557-5" target="_blank" rel="noreferrer">“Disparities in Diabetes-Related Avoidable Hospitalization among Diabetes Patients with Disability Using a Nationwide Cohort Study.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Scientific Reports 12 (February 2, 2022): 1794.`,
      year: 2022,
    },
    {
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      title: `Youn, Hin Moi, Dong-Woo Choi, Sung-In Jang, and Eun-Cheol Park. <a href="https://doi.org/10.1038/s41598-022-05557-5" target="_blank" rel="noreferrer">“Disparities in Diabetes-Related Avoidable Hospitalization among Diabetes Patients with Disability Using a Nationwide Cohort Study.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Scientific Reports 12, no. 1 (2022): 1794.`,
      year: 2022,
    },
    {
      indicator: 'PQI 93',
      indicators: [
        {
          title: 'PQI 93',
        },
      ],
      title: `Zhilkova, Anna, Shadi Chamany, Charlene Ngamwajasat, Samantha De Leon, Winfred Wu, and Tsu-Yu Tsao. <a href="https://doi.org/10.1016/j.amepre.2022.04.005" target="_blank" rel="noreferrer">“Medication Nonuse and Hospital Utilization: Medicaid Participants With Type 2 Diabetes in New York City.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> American Journal of Preventive Medicine 63, no. 4 (2022): 543-51.`,
      year: 2022,
    },
    {
      indicator: 'IQI',
      indicators: [
        {
          title: 'IQI',
        },
      ],
      title: `Zogg, Cheryl K, Judith H Lichtman, Michael K Dalton, Peter A Learn, Andrew J Schoenfeld, Tracey Perez Koehlmoos, Joel S Weissman, and Zara Cooper. <a href="https://doi.org/10.1111/1475-6773.13885" target="_blank" rel="noreferrer">“In Defense of Direct Care: Limiting Access to Military Hospitals Could Worsen Quality and Safety.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Health Services Research 57, no. 4 (2022): 723-33.`,
      year: 2022,
    },
    {
      indicator: 'PSI 11, PSI 12, PSI 13, PSI 90',
      indicators: [
        {
          title: 'PSI 11',
        },
        {
          title: 'PSI 12',
        },
        {
          title: 'PSI 13',
        },
        {
          title: 'PSI 90',
        },
      ],
      title: `Zrelak, Patricia A., Garth H. Utter, Kathryn M. McDonald, Robert L. Houchens, Sheryl M. Davies, Halcyon G. Skinner, Pamela L. Owens, and Patrick S. Romano. 2022. <a href="https://doi.org/10.1111/1475-6773.13918" target="_blank" rel="noreferrer">“Incorporating Harms into the Weighting of the Revised Agency for Healthcare Research and Quality Patient Safety for Selected Indicators Composite (Patient Safety Indicator 90).”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Health Services Research 57 (3): 654-67.`,
      year: 2022,
    },
    // 2021
    {
      title:
        "Baer, Rebecca J, Nichole Nidey, Gretchen Bandoli, Brittany D Chambers, Christina D Chambers, Sky Feuer, Deborah Karasek, et al. <a href='https://www.thieme-connect.de/products/ejournals/abstract/10.1055/s-0040-1721668' target='_blank' rel='noreferrer'> “Risk of Early Birth among Women with a Urinary Tract Infection: A Retrospective Cohort Study.”</a><a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> American Journal of Perinatology Reports 11, no. 01 (2021): e5–14.",
      indicator: 'PQI 12',
      indicators: [
        {
          title: 'PQI 12',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Chen, Jie, Ivy Benjenk, Deanna Barath, Andrew C. Anderson, and Charles F. Reynolds III. \"<a target='_blank' href='https://linkinghub.elsevier.com/retrieve/pii/S0749379721000672'>Disparities in Preventable Hospitalization Among Patients with Alzheimer Diseases.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> American Journal of Preventive Medicine, vol. 60, no. 5, 2021, pp. 595–604.",
      indicator: 'PQI 01, 03, 05, 07, 08, 14',
      indicators: [
        {
          title: 'PQI 01',
        },
        {
          title: 'PQI 03',
        },
        {
          title: 'PQI 05',
        },
        {
          title: 'PQI 07',
        },
        {
          title: 'PQI 08',
        },
        {
          title: 'PQI 14',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Chen, Yiwei, and Stephanie Lee. \"<a target='_blank' href='https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3796740'>User-Generated Physician Ratings and Their Effects on Patients' Physician Choices: Evidence from Yelp.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Available at SSRN 3796740, 2021. ",
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Chi, Winnie, Elena Andreyeva, Yongkang Zhang, Rainu Kaushal, and Kevin Haynes. \"<a target='_blank' href='https://www.liebertpub.com/doi/10.1089/pop.2021.0047'>Neighborhood-level Social Determinants of Health Improve Prediction of Preventable Hospitalization and Emergency Department Visits Beyond Claims History.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Population Health Management (2021). ",
      indicator: 'PQI 90, 92',
      indicators: [
        {
          title: 'PQI 90',
        },
        {
          title: 'PQI 92',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Di Martino, Giuseppe, Pamela Di Giovanni, Fabrizio Cedrone, Michela D'Addezio, Francesca Meo, Piera Scampoli, Ferdinando Romano, and Tommaso Staniscia. \"<a target='_blank' href='https://www.mdpi.com/2227-9032/9/8/997'>The Burden of Diabetes-Related Preventable Hospitalization: 11-Year Trend and Associated Factors in a Region of Southern Italy.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Healthcare (Basel, Switzerland) 9, no. 8 (2021). ",
      indicator: 'PQI 01, 03, 04, 14, 16',
      indicators: [
        {
          title: 'PQI 01',
        },
        {
          title: 'PQI 03',
        },
        {
          title: 'PQI 04',
        },
        {
          title: 'PQI 14',
        },
        {
          title: 'PQI 16',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Flattau, Anna, Molly L. Tanenbaum, Jeffrey S. Gonzalez, Cary Andrews, Stephanie Twomey, Loretta Vileikyte, and M. Diane McKee. \"<a target='_blank' href='https://muse.jhu.edu/article/802278'>Barriers to Prevention and Timely Presentation of Diabetic Foot Ulcers: Perspectives of Patients from a High-risk Urban Population in the United States.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal of Health Care for the Poor and Underserved 32, no. 3 (2021): 1554-1565. ",
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Gillespie, Suzanne M., Jiejin Li, Jurgis Karuza, Cari Levy, Stuti Dang, Tobie Olsan, Bruce Kinosian, and Orna Intrator. \"<a target='_blank' href='https://linkinghub.elsevier.com/retrieve/pii/S1525861020311002'>Factors Associated with Hospitalization by Veterans in Home-Based Primary Care.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal of the American Medical Directors Association 22, no. 5 (2021): 1043-1051.",
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      year: 2021,
    },
    {
      title: `Jiang, H Joanna, Kathryn R Fingar, Lan Liang, Rachel M Henke, and Teresa P Gibson. <a href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2784342" target="_blank" rel="noreferrer">“Quality of Care Before and After Mergers and Acquisitions of Rural Hospitals.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> JAMA Network Open 4, no. 9 (2021): e2124662–e2124662.`,
      indicator: 'IQI, PSI',
      indicators: [
        {
          title: 'IQI',
        },
        {
          title: 'PSI',
        },
      ],
      year: 2021,
    },
    {
      title: `Logan, Merranda S, Laura C Myers, Hojjat Salmasian, David Michael Levine, Christopher G Roy, Mark E Reynolds, Luke Sato, et al. <a href="https://doi.org/10.1097/PTS.0000000000000754" target="_blank" rel="noreferrer"> “Expert Consensus on Currently Accepted Measures of Harm.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Journal of Patient Safety 17, no. 8 (2021): e1726–31.`,
      indicator: 'PDI, PQI, PSI ',
      indicators: [
        {
          title: 'PDI',
        },
        {
          title: 'PQI',
        },
        {
          title: 'PSI',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Lut, Irina, Kate Lewis, Linda Wijlaars, Ruth Gilbert, Tiffany Fitzpatrick, Hong Lu, Astrid Guttmann et al. \"<a target='_blank' href='https://journals.sagepub.com/doi/10.1177/13558196211012732'>Challenges of using asthma admission rates as a measure of primary care quality in children: An international comparison.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal of Health Services Research & Policy (2021): 13558196211012732. ",
      indicator: 'PQI 15',
      indicators: [
        {
          title: 'PQI 15',
        },
      ],
      year: 2021,
    },
    {
      title: `Machta, Rachel, Gregory Peterson, Jason Rotter, Kate Stewart, Shannon Heitkamp, Isabel Platt, Danielle Whicher, et al. <a href="https://hscrc.maryland.gov/Documents/Modernization/MD_TCOC_Implementation_Report.pdf" target="_blank" rel="noreferrer">"Evaluation of the Maryland Total Cost of Care Model: Implementation Report."</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> Mathematica Policy Research, 2021.`,
      indicator: 'PQI 90',
      indicators: [
        {
          title: 'PQI 90',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Masonbrink, Abbey R, Mitch Harris, Matt Hall, Sunitha Kaiser, Alexander H Hogan, Kavita Parikh, Nicholas A Clark, and Shawn Rangel. \"<a href='https://hosppeds.aappublications.org/content/11/6/e95' aria-label='link' target='_blank'>Safety Events in Children's Hospitals During the COVID-19 Pandemic.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Hospital Pediatrics 11, no. 6 (2021): e95–100.",
      indicator: 'PDIs',
      indicators: [
        {
          title: 'PDIs',
        },
      ],
      year: 2021,
    },
    {
      title: `Musick, Hugh, Ann Kauth, Vincent L Freeman, Sanjib Basu, Heng Wang, Ronald Hershow, Kshitij Gotiwale, Joel Flax-Hatch, Jenni Schneiderman, and Yan Gao. <a href="https://www.academia.edu/92696916/Transformation_Data_and_Community_Needs_Report_South_Cook_" target="_blank" rel="noreferrer">"Transformation Data & Community Needs Report (South Cook)"</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> 2021.`,
      indicator: 'PQI 90, 91, 92, 93',
      indicators: [
        {
          title: 'PQI 90',
        },
        {
          title: 'PQI 91',
        },
        {
          title: 'PQI 92',
        },
        {
          title: 'PQI 93',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Nizamuddin, Sarah L, Atul Gupta, Usman Latif, Junaid Nizamuddin, Avery Tung, Mohammed M Minhaj, Jeffrey Apfelbaum, and Sajid S Shahul. \"<a href='https://journals.sagepub.com/doi/10.1177/0885066620928272' aria-label='link' target='_blank'>A Predictive Model for Pediatric Postoperative Respiratory Failure: A National Inpatient Sample Study.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal of Intensive Care Medicine 36, no. 7 (2021): 798–807.",
      indicator: 'PDI 09',
      indicators: [
        {
          title: 'PDI 09',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Padula, William V, Madhuram Nagarajan, Patricia M Davidson, and Peter J Pronovost. \"<a href='https://journals.lww.com/journalpatientsafety/Fulltext/2021/01000/Investing_in_Skilled_Specialists_to_Grow_Hospital.8.aspx' aria-label='link' target='_blank'>Investing in Skilled Specialists to Grow Hospital Infrastructure for Quality Improvement.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal of Patient Safety 17, no. 1 (2021): 51–55.",
      indicator: 'PSI 03',
      indicators: [
        {
          title: 'PSI 03',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Palacios-Ceña, Domingo, Lidiane Lima Florencio, Valentín Hernández-Barrera, Cesar Fernandez-de-Las-Peñas, Javier de Miguel-Diez, David Martínez-Hernández, David Carabantes-Alarcón, Rodrigo Jimenez-García, Ana Lopez-de-Andres, and Marta Lopez-Herranz. \"<a target='_blank' href='https://www.mdpi.com/2077-0383/10/11/2332'>Trends in Incidence and Outcomes of Hospitalizations for Urinary Tract Infection among Older People in Spain (2001–2018).</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal of Clinical Medicine 10, no. 11 (2021): 2332. ",
      indicator: 'PQI 12',
      indicators: [
        {
          title: 'PQI 12',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Ramalho, Andre, P. Castro, M. Lobo, Julio Souza, Paulo Santos, and Alberto Freitas. \"<a target='_blank' href='https://www.ijhpm.com/?_action=articleInfo&article=4084&lang'>Integrated quality assessment for diabetes care in Portuguese primary health care using prevention quality indicators.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Primary Care Diabetes 15, no. 3 (2021): 507-512. ",
      indicator: 'PQI 93',
      indicators: [
        {
          title: 'PQI 93',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Roman, Susan B., Lacey Whitmire, Lori Reynolds, Saamir Pasha, Anthony Brockman, and Benjamin J. Oldfield. \"<a target='_blank' href='https://www.liebertpub.com/doi/10.1089/pop.2021.0169'>Demographic and Clinical Correlates of the Cost of Potentially Preventable Hospital Encounters in a Community Health Center Cohort.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Population Health Management (2021). ",
      indicator: 'PQI 01, 03, 14, 16',
      indicators: [
        {
          title: 'PQI 01',
        },
        {
          title: 'PQI 03',
        },
        {
          title: 'PQI 14',
        },
        {
          title: 'PQI 16',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Schechter, Sarah, Sravya Jaladanki, Jonathan Rodean, Brittany Jennings, Marquita Genies, Michael D Cabana, and Sunitha Vemula Kaiser. \"<a target='_blank' href='https://qualitysafety.bmj.com/content/30/11/876'>Sustainability of Pediatric Asthma Care Quality in Community Hospitals after Ending a National Quality Improvement Collaborative.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> BMJ Quality & Safety, 2021.",
      indicator: 'PDI 14',
      indicators: [
        {
          title: 'PDI 14',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Shah, Mona, Cyrus Zhu, and Kael Wherry. \"<a target='_blank' href='https://diabetesjournals.org/diabetes/article/70/Supplement_1/811-P/140262/811-P-Durable-Insulin-Pumps-vs-Multiple-Daily'>811-P: Durable Insulin Pumps vs. Multiple Daily Injections for Type 1 Diabetes: Health Care Utilization and A1C.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Diabetes 70: S1 (2021). ",
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Spain, C. Victor, Parul Dayal, Yingjie Ding, Carlos Iribarren, Theodore A. Omachi, and Hubert Chen. \"<a target='_blank' href='https://www.tandfonline.com/doi/full/10.1080/02770903.2021.1922915'>Usage of long-acting muscarinic antagonists and biologics as add-on therapy for patients in the United States with moderate-to-severe asthma.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal of Asthma just-accepted (2021): 1-12. ",
      indicator: 'PQI 05',
      indicators: [
        {
          title: 'PQI 05',
        },
      ],
      year: 2021,
    },
    {
      title: `Tian, Yao, Martha-Conley E. Ingram, Matt Hall, and Mehul V. Raval. <a href="https://doi.org/10.1016/j.jss.2021.04.028" target="_blank" rel="noreferrer">“ICD-10 Transition Influences Trends in Perforated Appendix Admission Rate.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> The Journal of Surgical Research 266 (2021): 345–51.`,
      indicator: 'PDI 17, PQI 02',
      indicators: [
        {
          title: 'PDI 17',
        },
        {
          title: 'PQI 02',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Trout, Kate E, Li-Wu Chen, Fernando A Wilson, Hyo Jung Tak, and David Palm. \"<a href='https://journals.lww.com/jhqonline/Abstract/9000/The_Impact_of_Electronic_Health_Records_and.99681.aspx' aria-label='link' target='_blank'>The Impact of Electronic Health Records and Meaningful Use on Inpatient Quality.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Journal for Healthcare Quality : Official Publication of the National Association for Healthcare Quality, no. bbo, 9202994 (2021).",
      indicator: 'IQI 90, 91',
      indicators: [
        {
          title: 'IQI 90',
        },
        {
          title: 'IQI 91',
        },
      ],
      year: 2021,
    },
    {
      title: `Ulimwengu, John, and Aziza Kibonge. <a href="http://dx.doi.org/10.1186/s12889-021-11809-2" target="_blank" rel="noreferrer">“Spatial Spillover and COVID-19 Spread in the US.”</a><a href="/ExternalDisclaimer"><img alt="external web link policy" class="ml-1 mr-1" src="/images/exit_disclaimer.png"/></a> BMC Public Health 21, no. 1 (2021): 1–11.`,
      indicator: 'PQI',
      indicators: [
        {
          title: 'PQI',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Zhuang, Thompson, Austin Y Feng, Lauren M Shapiro, Serena S Hu, Michael Gardner, and Robin N Kamal. \"<a target='_blank' href='https://journals.lww.com/clinorthop/Abstract/9900/Is_Uncontrolled_Diabetes_Mellitus_Associated_with.322.aspx'>Is Uncontrolled Diabetes Mellitus Associated with Incidence of Complications After Posterior Instrumented Lumbar Fusion? A National Claims Database Analysis.</a>\"<a href='/ExternalDisclaimer'><img alt='external web link policy' class='ml-1 mr-1' src='/images/exit_disclaimer.png'/></a> Clinical Orthopaedics and Related Research®, 2021, 10–1097. ",
      indicator: 'PQI 14',
      indicators: [
        {
          title: 'PQI 14',
        },
      ],
      year: 2021,
    },
    {
      title:
        "Zogg, Cheryl K, Judith H Lichtman, Michael K Dalton, Peter A Learn, Andrew J Schoenfeld, Tracey Perez Koehlmoos, Joel S Weissman, and Zara Cooper. <a href='https://onlinelibrary.wiley.com/doi/10.1111/1475-6773.13885'> “In Defense of Direct Care: Limiting Access to Military Hospitals Could Worsen Quality and Safety.”</a> Health Services Research, October 4, 2021.",
      indicator: 'IQI 90, IQI 91, PSI 90',
      indicators: [
        {
          title: 'IQI 90',
        },
        {
          title: 'IQI 91',
        },
        {
          title: 'PSI 90',
        },
      ],
      year: 2021,
    },
  ],
  webinars: [
    {
      date: 'August 13, 2024',
      resources: [
        {
          title: 'AHRQ Quality Indicators Software: v2024',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, August 13, 2024',
              size: '(PDF File, 4.66 MB)',
              url: '/Downloads/Resources/Webinars/2024/v2024 QI Software Release Webinar.pdf',
            },
            {
              name: 'Video, August 16, 2024',
              size: '',
              url: 'https://www.youtube.com/watch?v=kWAdt868GVg',
            },
            {
              name: 'Transcript, August 16, 2024',
              size: '(PDF File, 174 KB)',
              url: '/Downloads/Resources/Webinars/2024/AHRQ QI v2024 Release Webinar Transcript.pdf',
            },
          ],
        },
      ],
    },
    {
      date: 'August 22, 2023',
      resources: [
        {
          title: 'AHRQ Quality Indicators Software: v2023',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, August 25, 2023',
              size: '(PDF File, 797 KB)',
              url: '/Downloads/Resources/Webinars/2023/v2023 QI Software Release Webinar.pdf',
            },
            {
              name: 'Video, August 30, 2023',
              size: '',
              url: 'https://www.youtube.com/watch?v=CqTkU577DUo',
            },
            {
              name: 'Transcripts, August 30, 2023',
              size: '(PDF File, 146 KB)',
              url: '/Downloads/Resources/Webinars/2023/AHRQ Quality Indicator Software v2023 Release Webinar.pdf',
            },
          ],
        },
      ],
    },
    {
      date: 'August 8, 2022',
      resources: [
        {
          title: 'AHRQ Quality Indicators Software: v2022',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, August 8, 2022',
              size: '(PDF File, 3 MB)',
              url: '/Downloads/Resources/Webinars/2022/v2022 QI Software Release.pdf',
            },
            {
              name: 'Video, August 12, 2022',
              size: '',
              url: 'https://www.youtube.com/watch?v=3yqWURk1Iao',
            },
            {
              name: 'Transcripts, August 23, 2022',
              size: '(PDF File, 135 KB)',
              url: '/Downloads/Resources/Webinars/2022/AHRQ Quality Indicators Software v2022 ICD-10-CMPCS.pdf',
            },
          ],
        },
      ],
    },
    {
      date: 'July 18, 2022',
      category: ['software'],
      resources: [
        {
          title: 'AHRQ Quality Indicators Program Listening Session',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, July 18, 2022',
              size: '(PDF File, 704 KB)',
              url: '/Downloads/Resources/Webinars/2022/QI_Program_Listening_Session_Presentation.pdf',
            },
            {
              name: 'Listening Session Summary',
              size: '(PDF File, 264 KB)',
              url: '/Downloads/Resources/Summary_of_July_Listening_Session.pdf',
            },
          ],
        },
      ],
    },
    {
      date: 'August 4, 2021',
      resources: [
        {
          title: 'AHRQ Quality Indicators Software: v2021',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, August 4, 2021',
              size: '(PDF File, 1.6 MB)',
              url: '/Downloads/Resources/Webinars/2021/v2021%20QI%20Software%20Release.pdf',
            },
            {
              name: 'Video, August 12, 2021',
              size: '',
              url: 'https://www.youtube.com/watch?v=9Es0fnS2Yko',
            },
            {
              name: 'Transcripts, August 12, 2021',
              size: '(PDF File, 240 KB)',
              url: '/Downloads/Resources/Webinars/2021/AHRQ%20Quality%20Indicators%20Software%20v2021%20ICD-10-CMPCS.pdf',
            },
          ],
        },
      ],
    },
    {
      date: 'August 5, 2020',
      resources: [
        {
          title: 'AHRQ Quality Indicators Software: v2020',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, August 3, 2020',
              size: '(PDF File, 543 KB)',
              url: '/Downloads/Resources/Webinars/2020/v2020%20QI%20Software%20Release_Final-08032020.pdf',
            },
            {
              name: 'Video, August 26, 2020',
              size: '',
              url: 'https://youtu.be/PxFvabfUsGA',
            },
            {
              name: 'Transcripts, August 5, 2020',
              size: '(PDF File, 320 KB)',
              url: '/Downloads/Resources/Webinars/2020/AHRQ%20Quality%20Indicators%20Software%20v2020%20ICD-10-CMPCS.pdf',
            },
          ],
        },
      ],
    },
    {
      date: 'August 1, 2019',
      resources: [
        {
          title: 'AHRQ Quality Indicators Software: v2019 ICD-10-CM/PCS',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, August 1, 2019',
              size: '(PDF File, 2.8 MB)',
              url: '/Downloads/Resources/Webinars/2019/v2019_QI_Software_Release_Slides_080119.pdf',
            },
            {
              name: 'Transcripts, August 1, 2019',
              size: '(PDF File, 188 KB)',
              url: '/Downloads/Resources/Webinars/2019/AHRQ_QI_v2019_Software_Release_Webinar_Transcript.pdf',
            },
          ],
        },
      ],
    },
    {
      date: 'July 19, 2016',
      resources: [
        {
          title: 'AHRQ Quality Indicators Software: Transition to ICD-10',
          category: 'Software Release & Updates',
          items: [
            {
              name: 'Slides, July 19, 2016',
              size: '(PDF File, 9.66 MB)',
              url: '/Downloads/Resources/Webinars/2016/AHRQ_QI_ICD10_Webinar.pdf',
            },
            {
              name: 'Audio/Video',
              size: '(MP4 File, 440 MB)',
              url: '/Downloads/Resources/Webinars/2016/AHRQ_QI_ICD10_Webinar.zip',
            },
            {
              name: 'Transcripts, July 19, 2016',
              size: '(PDF File, 1.22 MB)',
              url: '/Downloads/Resources/Webinars/2016/AHRQ_QI_ICD10_Webinar_Transcript.pdf',
            },
          ],
        },
      ],
    },
  ],
  caseStudies: [
    {
      title: 'California(CA) Office of Statewide Health Planning and Development(OSHPD)',
      id: 'oshpd',
      category: ['featured'],
      date: 'August, 2019',
      organization:
        "California's Office of Statewide Health Planning and Development (OSHPD) serves as the hub for collecting and disseminating information about California's health care infrastructure. OSHPD monitors the construction, renovation, and seismic safety of hospitals and skilled nursing facilities, in addition to providing loan insurance to assist the capital needs of California's not-for-profit healthcare facilities. OSHPD includes the AHRQ QI results in a suite of reports which are routinely used by the state legislature, sister agencies, researchers and other state stakeholders.",
      improvement:
        'For the past 12 years, OSHPD has used the full range of AHRQ QIs to meet State mandated requirements for reporting on risk-adjusted quality measurement and hospital outcomes. OSHPD uses the AHRQ Quality Indicators, specifically, Inpatient Quality Indicators, Patient Safety Indicators, and Prevention Quality Indicators calculated from hospital inpatient discharge data using the methodology developed by AHRQ.',
      solution: '',
      result:
        "California's Office of Statewide Health Planning and Development prepares and publishes a suite of reports based on health care utilization data to support higher quality, more efficient, and cost-effective patient care. These reports include: <ul><li>AHRQ Quality Indicators Reports</li><li>Coronary Artery Bypass Graft Outcomes</li><li>Elective Percutaneous Coronary Intervention Reports</li><li>Ischemic Stroke Outcomes Report</li></ul>",
      image: '/images/oshpd.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_CA_OSHPD_Case_Study_Aug2019.pdf',
      size: '(PDF File, 113 KB)',
      showInLanding: true,
    },
    {
      title: 'Carilion Clinic',
      category: [],
      date: 'August, 2019',
      organization:
        "Carilion Clinic is a Roanoke, Virginia based integrated health care system that provides care to nearly one million people in Virginia and West Virginia. Carilion operates seven hospitals in western Virginia, including the region's only Level 1 Trauma Center at Carilion Roanoke Memorial Hospital. With more than 13,000 employees and more than 700 physicians representing 70 specialties and practicing at 225 sites, Carilion is the largest employer in Virginia's Roanoke Valley.",
      improvement:
        'In 2015, the quality and safety team at Carilion Roanoke Memorial Hospital noticed that there was opportunity to reduce the occurrence of Iatrogenic Pneumothorax (PSI 06). After a closer examination of the underlying data, the quality and safety team partnered with the Graduate Medical Education leadership to establish a simulation program to improve resident training for central line placement.',
      solution: '',
      result:
        "<p>After examining its rate of Iatrogenic Pneumothorax (PSI 06), Carilion Roanoke Memorial Hospital, part of the Carilion Clinic health system, instituted a residency simulation program that decreased its rate for this Patient Safety Indicator (PSI) by over 50 percent.</p><p>By implementing protocols for evidence - based best practices associated with surgery, Carilion Roanoke was able to reduce occurrences for Postoperative Acute Kidney Injury Requiring Dialysis(PSI 10) and Postoperative Respiratory Failure(PSI 11).</p><p>Following implementation of all of the PSIs, Carilion Roanoke is now focused on tracking its performance across the AHRQ Prevention Quality Indicators(PQIs) and initiating focused improvements in primary care as a result of the system's performance on the PQIs.</p>",
      image: '/images/carilion.png',
      url: '/Downloads/Resources/Case_Studies/Carilion_Clinic_Case_Study.pdf',
      size: '(PDF File, 100 KB)',
    },
    {
      title: 'Johns Hopkins Health System',
      category: ['featured'],
      date: 'August, 2019',
      organization:
        'The Johns Hopkins Health System comprises six inpatient facilities with over 2600 beds, including its flagship Johns Hopkins Hospital, with 1,059 beds. The Johns Hopkins Health System employs nearly 28,000 staff across its facilities and locations.',
      improvement:
        "The Johns Hopkins Health System adopted the AHRQ Patient Safety Indicators (PSIs) to bolster the health system's clinical quality processes to improve patient care. The organization has overarching goals to eliminate harm, improve patient outcomes and experience, and reduce cost in healthcare delivery.",
      solution: '',
      result:
        '<p>With the development of a mandatory, service-specific decision support tool combined with innovative patient and nurse education efforts, The Johns Hopkins Hospital significantly reduced the incidence of Perioperative Pulmonary Embolism (PSI 12) over the past five years.</p><p>By clinical improvements and coding changes, The Johns Hopkins Hospital also achieved a dramatic decline in the rate of Perioperative Hemorrhage or Hematoma(PSI 09).</p><p>The Johns Hopkins Hospital worked diligently to improve its performance for Postoperative Respiratory Failure(PSI 11).The effort started back in 2012 when only 30 percent were able to be removed from a ventilator within the desired timeframe.Today, nearly 60 percent of cardiac patients are taken off a ventilator in less than six hours following surgery.</p>',
      image: '/images/johnshopkins.png',
      url: '/Downloads/Resources/Case_Studies/Johns_Hopkins_Case_Study.pdf',
      size: '(PDF File, 183 KB)',
      showInLanding: true,
    },
    {
      title: 'Tampa General Hospital',
      category: [],
      date: 'April, 2019',
      organization:
        'Tampa General is a private not-for-profit hospital comprising of a 1000+ bed single facility teaching hospital with an affiliated ambulatory surgery center. The hospital is affiliated with University of South Florida medical school, and their residency program (750 residents).',
      improvement:
        "Tampa General Hospital began tracking its performance across the full set of AHRQ Patient Safety Indicators, both as a means of improving the quality and safety of patient care, and in response to the incentives underlying CMS' value-based purchasing programs.",
      solution: '',
      result:
        "<p>After implementing the nutrition program, Tampa General Hospital's PSI 03 rate improved significantly, dropping from an observed rate of .518 per 1,000 discharges for the fourth quarter of FY 2015 to zero incidents in the third and fourth quarters of FY 2018.</p><p>Tampa General Hospital's Quality Department also developed tools to help providers identify at-risk patients and trigger best practice alerts. Following implementation of the program, Tampa General Hospital's improved its PSI 12 observed rate from 13.8 per 1,000 in the first quarter of FY 2015 to 5.67 per 1,000 in the fourth quarter of FY 2018.</p>",
      image: '/images/tgh.png',
      url: '/Downloads/Resources/Case_Studies/Tampa%20General%20Hospital%20Case%20Study.pdf',
      size: '(PDF File, 219 KB)',
    },
    {
      title: 'CHRISTUS Health',
      category: [],
      date: 'July, 2018',
      organization:
        'An international Catholic, faith-based, not-for-profit health system comprising more than 60 hospitals and long-term care facilities, 175 clinics and outpatient centers.',
      improvement:
        "CHRISTUS created a composite Harm Index using the AHRQ PSIs, and used this Index to motivate the organization's leaders and clinical staff to achieve significant reductions in system-wide harm events.",
      solution: '',
      result:
        '<p>Since late 2015, CHRISTUS Health has achieved a 22 percent reduction in overall harm events after the system began to use 14 AHRQ Patient Safety Indicators (PSIs). CHRISTUS Health achieved significant improvements in its results for Central Venous Catheter-related Blood Stream Infections (PSIs 07) with a 53.8 percent improvement, and for Postoperative Respiratory Failure (PSI 011) with a 41.3 percent reduction for 2016 through 2017.</p>',
      image: '/images/christus.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_CHRISTUS_Case_Study.pdf',
      size: '(PDF File, 155 KB)',
    },
    {
      title: 'Missouri Hospital Association',
      category: [],
      date: 'October, 2017',
      organization:
        '<p>A not-for-profit membership association that represents every acute care hospital in Missouri, as well as most federal (VA, military) facilities, state psychiatric care and private rehabilitation facilities.</p><p>Since its creation in 1922 with 50 hospital members, MHA has grown to 145 members representing hospitals from across Missouri.</p>',
      improvement:
        "In support of the Missouri Hospital Association's (MHA's) statewide Triple Aim quality agenda—better health, better care, and lower costs—MHA and its members have reported on AHRQ Quality Indicators (QIs) since 2000.",
      solution: '',
      result:
        '<p>MHA launched a public reporting website that uses a range of AHRQ QIs to support greater statewide transparency on hospital quality and cost. Initially a voluntary effort, the state legislature now mandates that all Missouri hospitals report pricing data for the 100 most common Diagnostic-Related Groups.</p>',
      image: '/images/mha.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_MOHA_Case_Study.pdf',
      size: '(PDF File, 594 KB)',
      showInLanding: true,
    },
    {
      title: 'Vanderbilt University Medical Center',
      category: [],
      date: 'October, 2017',
      organization:
        '<p>VUMC is a 1,000-bed medical center with more than 2,000 medical staff, 60,000 admissions and 120,000 emergency room visits annually. </p><p> The organization has hospitals, clinics, physician practices and affiliates covering nine hospital systems and 48 hospital locations.</p>',
      improvement:
        "Improvement Opportunity: VUMC's focus on its PSI performance spurred several quality initiatives, including efforts to reduce the incidence of pressure ulcers (PSI 03) and postoperative respiratory failure (PSI 11).",
      solution: '',
      result:
        "<p>Achieved a 28 percent improvement in performance on AHRQ's Patient Safety Indicators (PSIs), largely as a result of improved collaboration across departments and increased attention to documentation.</p>",
      image: '/images/vanderbilt.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_VUMC_Case_Study.pdf',
      size: '(PDF File, 580 KB)',
    },
    {
      title: 'University of Pittsburgh Medical Center',
      category: [],
      date: 'June, 2017',
      organization:
        "<p>UPMC operates more than 25 academic, community, and specialty hospitals, with 600 doctors' offices and outpatient sites, and employs 3,600 physicians, and offers an array of rehabilitation, retirement, and long-term care facilities. </p> <p>The two largest hospitals are Presbyterian and Shadyside - with more than 1,000 beds across these two facilities.</p>",
      improvement:
        'Beginning in 2010, UPMC bolstered its commitment to patient safety by using the AHRQ Patient Safety Indicators (PSIs) for tracking its performance on a range of safety issues.',
      solution: '',
      result:
        "<p>UPMC's rate of Unrecognized Abdominopelvic Accidental Puncture/Laceration Rate (PSI 15) fell from 8.26 per 1,000 patient discharges in FY 2010 to 1.56 per 1,000 patients in FY 2016.           </p>",
      image: '/images/upmc.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_UPMC_Case_Study.pdf',
      size: '(PDF File, 572 KB)',
    },
    {
      title: 'SUNY Upstate University Hospital',
      category: [],
      date: 'November, 2016',
      organization:
        '<p>The only academic medical center in central New York, serving 1.8 million people.</p><p>Has 735 licensed beds and 77 hospital-based specialty clinics.</p>               <p>Has 10,000 staff members, 1,400 of whom are credentialed.</p> ',
      improvement:
        'In 2014, SUNY Upstate began a major patient safety initiative that included implementation of the full set of AHRQ Patient Safety Indicators (PSIs). At the same time, the medical center also began tracking its performance across 22 of the AHRQ Inpatient Quality Indicators (IQIs).',
      solution:
        "The hospital assigned a physician lead or 'champion' to each PSI. This person led a team that worked closely with clinical staff, providing feedback to providers on a daily basis. The hospital also dedicated staffing and resources to improve the clinical documentation and coding for each PSI.",
      result:
        '<p>SUNY Upstate improved its performance on several PSIs, including PSI 11 (Postoperative Respiratory Failure) and PSI 13 (Postoperative Sepsis), between the first quarter of 2015 and the second quarter of 2016. The hospital also avoided any financial penalty in 2016 for Healthcare-Acquired Conditions (HACs), eliminating what had been a $1 million penalty just two years prior.</p>',
      image: '/images/suny.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_SUNY_Case_Study.pdf',
      size: '(PDF File, 523 KB)',
    },
    {
      title: 'Keck Medical Center of the University of Southern California',
      category: [],
      date: 'November, 2016',
      organization:
        '<p>Fully owned by the University of Southern California (USC).</p><p>Includes Keck Hospital of USC, USC Norris Cancer Center, and Verdugo Hills Hospital – with more than 1,100 medical staff across these three facilities</p>',
      improvement:
        'Following a focus on patient safety as part of a new strategic plan, quality improvement and clinical documentation staff at Keck Medical Center worked together to improve performance on the Patient Safety Indicators (PSIs).',
      solution:
        'Keck Medical Center decided to tackle one PSI at a time. They started by reviewing documentation of safety events, and then focused on finding opportunities for increasing safety for patients.',
      result:
        '<p>Between 2014 and 2016, Keck Medical Center cut its rate in half or more for seven PSIs, including an 88 percent reduction in the occurrence of PSI 11(Postoperative Respiratory Failure).</p>',
      image: '/images/upmc.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_Keck_Case_Study.pdf',
      size: '(PDF File, 480 KB)',
    },
    {
      title: 'Cleveland Clinic',
      category: [],
      date: 'January, 2016',
      organization:
        "<p>Nearly 54,000 discharges in 2014, generating more than $13 billion in patient revenues.</p><p>1,400 beds on Cleveland Clinic's main campus and 4,450 beds systemwide.</p><p>43,000 employees, including 3,200 professional staff and 1,700 residents and fellows.</p>",
      improvement:
        'Cleveland Clinic began working with the Patient Safety Indicators (PSIs) in 2010 because patient safety is the "right thing to do" and because Federal payment programs and private payers use these quality indicators in their reimbursement programs.',
      solution:
        "By using the PSIs, Cleveland Clinic detected some clear opportunities to improve quality of care and used those opportunities to allocate resources. For example, for PSI 15 (Accidental Puncture or Laceration), Cleveland Clinic's rate was worse than its peer institutions—both for documentation and clinical reasons. Using the PSI specifications and partnering with its providers, the Clinic made dramatic improvements and now its PSI 15 results are in the best quartile.",
      result:
        '<p>Cleveland Clinic improved its performance on the PSIs from the lowest quartile in 2010 to the best quartile as of June 2015.</p>',
      image: '/images/upmc.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_Cleveland_Clinic_Case_Study.pdf',
      size: '(PDF File, 280 KB)',
    },
    {
      title: 'Essentia Health',
      category: [],
      date: 'November, 2015',
      organization:
        '<p>A private, non-profit integrated health care system with facilities in Minnesota, Wisconsin, North Dakota and Idaho.</p><p>A $1.7 billion enterprise with more than 14,000 employees including 1,500 physicians and advanced practitioners.</p><p>Includes 16 hospitals, 69 clinics, eight long term care facilities, two assisted living facilities, four independent living facilities, and one research institute</p>',
      improvement:
        'Essentia Health analyzed a benchmark report and found that select Patient Safety Indicators (PSIs) were higher than national benchmarks, suggesting opportunities for making improvements in the delivery of care.',
      solution:
        'Begining in 2013, Essentia Health tracked 10 of the PSIs, ultimately focusing on performance improvement for three of them—PSI 03 (Pressure Ulcer Rate), PSI 09 (Perioperative Hemorrhage or Hematoma Rate), and PSI 15 (Accidental Puncture or Laceration Rate).',
      result:
        '<p>Essentia Health-Fargo reduced PSI 15 from a rate of 1.2 events per 1000 eligible procedures in 2013 to 0.07 in the first half of 2015. Essentia Health has also improved the quality of care for treatment of pressure ulcers (PSI 03) and has experienced a decline in perioperative hemorrhage or hematoma incidences (PSI 09).</p>',
      image: '/images/essentia.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_Essentia_Case_Study.pdf',
      size: '(PDF File, 238 KB)',
    },
    {
      title: 'Yale New Haven Health System',
      category: [],
      date: 'November, 2015',
      organization:
        "<p>YNHHS' flagship facility is the Yale-New Haven Hospital (YNHH), a non-profit, 1,541-bed tertiary medical center.</p><p>YNHHS also includes Bridgeport Hospital, Greenwich Hospital and Northeast Medical Group.</p><p>With more than 20,000 employees and a medical staff of 6,491, Yale New Haven Health System (YNHHS) had more than 109,000 discharges in 2014, generating about $3.3 billion in revenue.</p>",
      improvement:
        'Yale New Haven Health System (YNHHS) wanted to identify variations in quality of care so that patients consistently receive better care at lower cost. YNHHS tackled this issue as part of a multiyear effort to improve the overall value of care.',
      solution:
        'Beginning in 2012, YNHHS developed a set of tailored indicators—Quality Variation Indicators (QVIs)—that identify variations in the quality of care provided at YNHHS. The purpose of the QVIs is to target areas for potential improvement. The QVIs include selected AHRQ Patient Safety Indicators (PSIs), as well as hospital acquired infections (HAI) indicators, among others.',
      result:
        '<p>Between 2012 and 2014, while most hospitals experienced rising costs, the expense to YNHHS per equivalent discharge was reduced by 4.6 percent, decreasing from $16,390 to $15,635.</p>',
      image: '/images/yale.png',
      url: '/Downloads/Resources/Case_Studies/AHRQ_QI_YNHHS_Case_Study.pdf',
      size: '(PDF File, 237 KB)',
    },
  ],
};
