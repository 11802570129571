import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PSITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Patient Safety Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Patient Safety Indicators Technical Specifications, Version 4.5, May 2013
        </h4>
        <Row>
          <Col md={9}>
            <h5>All Patient Safety Indicators Technical Specifications, Version 4.5 (Zip File)</h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI_EXP_Technical_Specifications_Version45.zip'
              href='/Downloads/Modules/PSI/V45/TechSpecs/PSI_EXP_Technical_Specifications_Version45.zip'
            >
              Download All (ZIP File, 9.9 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'psi'} version={'v45'} />
      </Container>
    </>
  );
};

export default PSITechnical;
