import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PSITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Patient Safety Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Patient Safety Indicators Technical Specifications Updates - Version v2021, July 2021
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Patient Safety Indicators Technical Specifications (PDF Format), Version 2021
              (ZIP File, 27.8 MB)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI_2021_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/PSI/V2021/TechSpecs/PSI_2021_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 27.8 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'psi'} version={'icd10_v2021'} />
        <p className='danger mt-3'>
          ** To learn how to isolate the impact of COVID-19 on the AHRQ Quality Indicators (QIs),{' '}
          <a href='/Downloads/Resources/COVID19_UserNote_July2021.pdf'>Click Here</a> (PDF File, 223
          KB)
        </p>
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          <span className='danger'>Effective v2021</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/AHRQ%20QI%20v2021%20Retirement%20Announcement.pdf'>
            Indicators Retirement Announcement.
          </a>{' '}
          (PDF File, 112 KB)
        </p>
        <ul>
          <li>NQI 02 Neonatal Mortality Rate</li>
        </ul>
        <p>
          <span className='danger'>Effective v2019</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/Retirement%20Notice_v2019_Indicators.pdf'>
            Indicators Retirement Announcement.
          </a>{' '}
          (PDF File, 108 KB)
        </p>
        <ul>
          <li>PSI 16 Transfusion Reaction Count</li>
        </ul>
        <p>
          <span className='danger'>Effective v7.0</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/PSI_Retirement_Notice.pdf'>PSI Area Level Hospital</a> (PDF File, 349 KB).
        </p>
        <ul>
          <li>PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate</li>
          <li>PSI 22 Iatrogenic Pneumothorax Rate</li>
          <li>PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate</li>
          <li>PSI 24 Postoperative Wound Dehiscence Rate</li>
          <li>PSI 25 Accidental Puncture or Laceration Rate</li>
          <li>PSI 26 Transfusion Reaction Rate</li>
          <li>PSI 27 Perioperative Hemorrhage or Hematoma Rate</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Patient Safety Indicators Technical Specifications for SAS and WinQI ICD-10 version
            v2020 can be found <Link to='/archive/qi_modules?category=psi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PSITechnical;
