import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PDITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Pediatric Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Pediatric Quality Indicators Technical Specifications Updates - Version v2018 and
          v2018.0.1 (ICD 10), June 2018
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Pediatric Quality Indicators Technical Specifications (PDF Format), Version
              2018 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PDI_2018_ICD10_techspecs_Provider_pdf.zip'
              href='/Downloads/Modules/PDI/V2018/TechSpecs/PDI_2018_ICD10_techspecs_Provider_pdf.zip'>
              Download All (ZIP File, 29.3 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pdi'} version={'icd10_v2018'} />
      </Container>
      <Container className='outerContainer'>
        <p>
          NQI 02 Neonatal Mortality Rate :{' '}
          <span className='danger'>Not currently available in v2018</span>
        </p>
        <p>
          PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate:{' '}
          <span className='danger'>
            Not currently available for ICD 10. It may be updated in future releases
          </span>
        </p>
        <p>
          PDI 07 RACHS-1 Pediatric Heart Surgery Volume:{' '}
          <span className='danger'>
            Not currently available for ICD 10. It may be updated in future releases
          </span>
        </p>
        <p>
          PDI 19 Pediatric Safety for Selected Indicators:{' '}
          <span className='danger'>
            Not currently available for ICD 10. It may be updated in future releases
          </span>
        </p>
        <p>
          NQI 02 Neonatal Mortality Rate :{' '}
          <span className='danger'>Not currently available in v2018</span>
        </p>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Pediatric Quality Indicators Technical Specifications for SAS and WinQI ICD-10 version
            v7.0 can be found <Link to='/archive/qi_modules?category=pdi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PDITechnical;
