const SAS_QI_ITEMS = [
  // DOWNLOADS/SOFTWARE/SAS
  {
    name: 'SasQiV2022Icd10CmPcsFullSoftwarePackage',
    title: 'SAS QI v2022.0.1 ICD-10-CM/PCS Full Software Package',
    desc: 'The QI/STAT modules are programs that run in the SAS statistical software package. To use these modules, users must have access to SAS which may be purchased from The SAS Institute.',
    filename: 'SAS_V202201_ICD-10-CM-PCS_QI_Software.zip',
    path: '/Downloads/Software/SAS/V2022/',
    size: '45.9 MB',
    format: 'ZIP',
    dateModified: '2023-08-11',
  },
];

export default SAS_QI_ITEMS;
