import { Box, styled } from '@mui/material';
import { Container } from 'react-bootstrap';

export const PageHeader = {};

PageHeader.Row = ({ children, split, ...rest }) => {
  return (
    <Row {...rest}>
      <Container className='page-header__container'>
        <Inner split={split}>{children}</Inner>
      </Container>
    </Row>
  );
};

const Row = styled(Box, {
  shouldForwardProp: (prop) => !['bgSrc', 'lightBlue', 'lightGrey', 'purple'].includes(prop),
})(({ theme, bgSrc, lightBlue, lightGrey, purple }) => ({
  backgroundColor: 'transparent',
  backgroundImage: bgSrc ? `url('${bgSrc}')` : 'none',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: theme.palette.text.main,
  minWidth: 320,
  padding: `5rem 0`,

  ...(lightBlue && {
    backgroundColor: theme.palette.fill.lightBlue,
  }),

  ...(lightGrey && {
    backgroundColor: theme.palette.fill.lightGrey,
  }),

  ...(purple && {
    backgroundColor: theme.palette.fill.purple,
    color: theme.palette.common.white,
  }),
}));
Row.defaultProps = {
  className: 'page-header__row',
};

const Inner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'split',
})(({ theme, split }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1.5rem',

  '.page-header__title': {
    margin: 0,
    textAlign: 'center',
  },

  '.page-header__desc': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',

    fontSize: '1.125rem',
    lineHeight: 1.5,
    maxWidth: 800,
    textAlign: 'center',
  },

  ...(split && {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',

      '.page-header__title': {
        alignItems: 'flex-end',
        flex: 1,
        paddingRight: '2rem',
        textAlign: 'right',
      },

      '.page-header__desc': {
        alignItems: 'flex-start',
        flex: 1,
        maxWidth: 'none',
        paddingLeft: '2rem',
        textAlign: 'left',
      },
    },
  }),
}));
Inner.defaultProps = {
  className: 'page-header__content',
};

PageHeader.Title = styled('h1')(({ theme }) => ({}));
PageHeader.Title.defaultProps = {
  className: 'page-header__title',
};

PageHeader.Subtitle = styled('h4')(({ theme }) => ({}));
PageHeader.Subtitle.defaultProps = {
  className: 'page-header__subtitle',
};

PageHeader.Content = styled('div')(({ theme }) => ({}));
PageHeader.Content.defaultProps = {
  className: 'page-header__desc',
};
