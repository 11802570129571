import { getReleaseItem } from '../releases';

export const qiMeasuresData = {
  methods: {
    title: 'AHRQ QI Methodology',
    children: [
      {
        ...getReleaseItem('EmpiricalMethods'),
        desc: `Describes the empirical methods used to calculate AHRQ QIs.`,
        isMostPopular: true,
        tag: 'AQIEM',
      },
      {
        title: 'Improving the AHRQ Quality Indicators',
        date: 'December 2014',
        desc: "Summary of findings and recommendations for improving the methodological approach of the AHRQ Quality Indicators.  NOTE: Persons using assistive technology may not be able to fully access information in this document. For assistance contact the Quality Indicators support at (301) 427-1949 or by email at: <a href='mailto:QIsupport@ahrq.hhs.gov'>QIsupport@ahrq.hhs.gov</a>",
        url: '/Downloads/Resources/Publications/2015/Improving_AHRQ_QIs_Summary.pdf',
        aria: 'Download AHRQ_QIs_Summary.pdf',
        cta: 'Download (PDF File, 1.1 MB)',
        tag: 'IAQI',
      },
      {
        title: 'AHRQ ICD-10-CM/PCS Conversion Project',
        date: 'Nov 2013',
        desc: 'Documents the process and status of converting AHRQ QIs from ICD-9-CM to ICD-10-CM/PCS.',
        url: '/Downloads/Resources/Publications/2013/C.14.10.D001_REVISED.pdf',
        aria: 'Download C.14.10.D001_REVISED.pdf',
        cta: 'Download (PDF File, 295 KB)',
        tag: 'AICP',
      },
      {
        title: 'ICD-9-CM to ICD-10-CM/PCS Conversion of AHRQ Quality Indicators',
        date: 'March 2011',
        desc: 'Background information on the transition from ICD-9-CM to ICD-10-CM and ICD-10-PCS for HIPAA transactions',
        url: '/Downloads/Resources/Publications/2011/ICD-10_Report_02-08-11_Final.pdf',
        aria: 'Download ICD-10_Report_02-08-11_Final.pdf',
        cta: 'Download (PDF File, 361 KB)',
        tag: 'IICAQI',
      },
      {
        title: 'AHRQ RAHM Workgroup Technical Report',
        isMostPopular: true,
        date: '',
        desc: 'Describes the data structure, statistical models, and tools used in the AHRQ QI Project and proposes alternative statistical models and methods for consideration',
        url: '/Downloads/Resources/Publications/2012/AHRQ_RAHM_Workgroup_Technical_Report_Final.pdf',
        aria: 'Download AHRQ_RAHM_Workgroup_Technical_Report_Final.pdf',
        cta: 'Download (PDF File, 502 KB)',
        tag: 'ARWTR',
      },
      {
        title:
          'Calculating Standard Errors and Confidence Intervals for the AHRQ Quality Indicators',
        date: '',
        desc: '',
        url: '/Downloads/Resources/Publications/2011/Calculating_Confidence_Intervals_for_the_AHRQ_QI.pdf',
        aria: 'Download Calculating_Confidence_Intervals_for_the_AHRQ_QI.pdf',
        cta: 'Download (PDF File, 46 KB)',
        tag: 'CSECIA',
      },
      {
        title:
          'The Markov Chain Monte Carlo Integration Approximation in the AHRQ QI Prediction Module Closely Matches Exact Integration Results',
        date: 'February 19, 2014',
        desc: 'Summarizes testing on prediction models.',
        url: '/Downloads/Modules/QI_Reporting/testing_v43_prediction_module_report_r.pdf',
        aria: 'Download testing_v43_prediction_module_report_r.pdf',
        cta: 'Download (PDF File, 322 KB)',
        tag: 'MCMC',
      },
    ],
  },
  reporting: {
    title: 'QI Reporting/User Tips',
    children: [
      {
        title: 'Guidance on Using the AHRQ QIs for Hospital-Level Comparative Reporting',
        category: 'main',
        date: 'June 2009',
        desc: 'Guidance on use for public reporting including analysis of each indicator’s appropriateness and reporting templates.',
        url: '/Downloads/News/AHRQ_QI_Guide_to_Comparative_Reporting_v10.pdf',
        aria: 'Download AHRQ_QI_Guide_to_Comparative_Reporting_v10.pdf',
        cta: 'Download (PDF File, 329 KB)',
        tag: 'GUAH',
      },
      {
        title: 'Summary Statement on Comparative Hospital Public Reporting',
        category: 'main',
        date: '',
        desc: 'How public reporting of hospital quality performance data and advances the quality improvement agenda in health care.',
        cta: 'Download (PDF File, 31 KB)',
        url: '/Downloads/Modules/QI_Reporting/Model_report_summary.pdf',
        aria: 'Download Model_report_summary.pdf',
        tag: 'SSCH',
      },
      {
        title: 'AHRQ QI Model Report',
        isMostPopular: true,
        category: 'main',
        date: '',
        desc: 'Templates for reporting comparative hospital performance information',
        url: '/Downloads/Modules/QI_Reporting/Model_Report1.pdf',
        aria: 'Download Model_Report1.pdf',
        url2: '/Downloads/Modules/QI_Reporting/Model_Report_Composite.pdf',
        aria2: 'Download Model_Report_Composite.pdf',
        url3: '/Downloads/Modules/QI_Reporting/Model_Report_Health_Topics.pdf',
        aria3: 'Download Model_Report_Health_Topics.pdf',
        cta: 'Download AHRQ QI Model Report (PDF File, 80 KB)',
        cta2: 'Download Composite Model Report (PDF File, 281 KB)',
        cta3: 'Download Health Topic Model Report (PDF File, 386 KB)',
        tag: 'AQMR',
      },
    ],
  },
  hcbs: {
    title: 'AHRQ QIs for Home and Community Based Services (HCBS)',
    children: [
      {
        title: 'Literature Review',
        category: 'hcbs',
        date: '',
        desc: 'Provides guidance for using AHRQ QIs for public reporting; includes analysis of each indicator to determine their appropriateness for use in comparative reporting and comparative reporting templates based on input from consumers, providers, experts in the field of public reporting, and others.',
        url: '/Downloads/Resources/Publications/2012/Appendix_1A_Details_of_literature_review.pdf',
        aria: 'Download Appendix_1A_Details_of_literature_review.pdf',
        cta: 'Download (PDF File, 271 KB)',
        tag: 'LR',
      },
      {
        title: 'Expert Panels',
        category: 'hcbs',
        date: '',
        desc: "Summarizes HCBS panel comments on the validity of the HCBS indicators as a set, factors that panelists believe might impact hospitalizations for the indicator conditions or events in the HCBS populations and panelists' comments and concerns about each indicator.",
        url: '/Downloads/Resources/Publications/2012/Appendix_1B_Details_of_Expert_Panel_Calls.pdf',
        aria: 'Download Appendix_1B_Details_of_Expert_Panel_Calls.pdf',
        cta: 'Download (PDF File, 104 KB)',
        tag: 'EP',
      },
    ],
  },
  cycle: {
    title: 'AHRQ QI Life Cycle',
    children: [
      {
        title: 'Measure Development Report',
        date: '',
        tag: 'MDR',
        desc: "<p class='bold'>Summary Report</p><p>Quality Indicator Measure Development, Implementation, Maintenance and Retirement Summary Report, January 2011</p><a href='/Downloads/Resources/Publications/2011/QI_Measure_Development_Implementation_Maintenance_Retirement_Summary_05-03-11.pdf' aria-label='Download QI_Measure_Development_Implementation_Maintenance_Retirement_Summary_05-03-11.pdf'><button class='button large primary'>Download (PDF File, 110 KB)</button></a></p><p class='bold mt-4' >Full Report</p><p>Quality Indicator Measure Development, Implementation, Maintenance and Retirement Full Report, January 2011</p><a  href='/Downloads/Resources/Publications/2011/QI_Measure_Development_Implementation_Maintenance_Retirement_Full_5-3-11.pdf' aria-label='Download QI_Measure_Development_Implementation_Maintenance_Retirement_Full_5-3-11.pdf'><button class='button large primary'>Download (PDF File, 299 KB)</button></a></p>",
      },
      {
        title: 'Retired Measures',
        date: '',
        tag: 'RM',
        desc: "<p class='bold'>Retirement Announcements</p><p><a href='/news#retirement-2019'>May 23, 2019</a> - 21 indicators retired in v2019: <a href='/news/Retirement%20Notice_v2019_Indicators.pdf' aria-label='Download Retirement%20Notice_v2019_Indicators.pdf'>Download PQI, IQI, PSI and PDI Indicators </a>(PDF File, 108 KB).</p>  <p className='bold'><a href='/news#retirement-v7'>September 22, 2017</a> - AHRQ announces the retirement of indicators in v7.0: <a href='/news/IQI_Area_Level_Retirement_Notice.pdf' aria-label='Download IQI_Area_Level_Retirement_Notice.pdf'>IQI Area Level (PDF File, 342 KB)</a>, <a href='/news/IQI_Hospital_Level_Retirement_Notice.pdf' aria-label='Download IQI_Hospital_Level_Retirement_Notice.pdf'>IQI Hospital Level </a> (PDF File, 356 KB) and <a href='/news/PSI_Retirement_Notice.pdf' aria-label='Download PSI_Retirement_Notice.pdf'>PSI Area Level & Hospital Level </a> (PDF File, 349 KB) Indicators.</p>",
      },
      {
        title: 'National Quality Forum',
        date: '',
        tag: 'NQF',
        desc: "<p>The <a href='http://www.qualityforum.org/Home.aspx'>National Quality Forum (NQF)</a><a href='/ExternalDisclaimer'><img alt='external web link policy' class='mr-1' src='/images/exit_disclaimer.png'/></a> is an independent, voluntary, consensus-based member organization that endorses standardized quality measures. <a href='/measures/list_ahrq_qi'>See which AHRQ QIs were previously endorsed by NQF</a>.</p><p>AHRQ will no longer seek NQF re-endorsement for its portfolio of measures in the AHRQ Quality Indicators (QIs) program starting in fiscal year 2022. <a href='/Downloads/News/AHRQ_Rationale4notseekingNQFendorsement-May2021.pdf' aria-label='Download AHRQ_Rationale4notseekingNQFendorsement-May2021.pdf'>View the rationale </a> (PDF File, 95 KB). <span class='new-label'> -New!</span></p><a  href='/measures/list_ahrq_qi'><button class='button large primary'>View Composite Measures</button></a>",
      },
    ],
  },
};
