import React, { useEffect } from 'react';
import QI from '../../components/Software/QI';
import SASQI from '../../components/Software/SASQI';
import WinQI from '../../components/Software/WinQI';
import { useParams } from 'react-router-dom';
import QISoftwareV6 from '../../components/Software/QISoftwareV6ICD9';
import SASICD10 from '../../components/Software/SASICD10';
import SoftwareICD10 from '../../components/Software/SoftwareICD10Planning';
import { CloudQI } from '../../components/Software';

const Software = () => {
  let { section } = useParams();

  const pageTitles = {
    cloudqi: 'CloudQI Software',
    sas_qi: 'SAS QI Software',
    win_qi: 'WinQI Software',
    default: 'AHRQ QI Software',
  };

  useEffect(() => {
    document.title =
      pageTitles[section] === undefined ? pageTitles['default'] : pageTitles[section];
  });

  if (section === 'cloudqi') return <CloudQI />;
  else if (section === 'sas_qi') return <SASQI />;
  else if (section === 'win_qi') return <WinQI />;
  else if (section === 'WinQIv60ICD9') return <QISoftwareV6 />;
  else if (section === 'ICD10Planning') return <SoftwareICD10 />;
  else if (section === 'SASICD10') return <SASICD10 />;
  else return <QI />;
};
export default Software;
