import { styled } from '@mui/material';
import { clsx } from 'clsx';

export const LinkTable = ({ children, className, ...rest }) => {
  return (
    <Table className={clsx('link-table', className)} {...rest}>
      {children}
    </Table>
  );
};

const Table = styled('table')(({ theme }) => ({
  borderCollapse: 'collapse',
  width: '100%',

  'th, td': {
    padding: '0.625rem 1rem',
  },

  tbody: {
    tr: {
      td: {
        padding: 0,
        paddingBottom: 10,
      },
    },
  },
}));
