import React from 'react';
import { Modal } from 'react-bootstrap';
import { Box, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styles from './SurveyDialog.module.scss';
import {
  AltButton,
  Button,
  SurveyStepColumn,
  SurveyStepColumnContent,
  SurveyStepRow,
} from '../../ui';
import Checkmark from '../../img/checkmark.svg';
import { getReleaseItem } from '../../data/releases';

export const EdPqiSurveyDialog = ({ open, onClose }) => {
  const feedbackPdf = getReleaseItem('EDPQIv2023BetaTestingFeedback', 'v2023');
  const feedbackUrl = getReleaseItem('EDPQIv2023BetaTestingFeedbackUrl', 'v2023');

  return (
    <Modal
      show={open}
      aria-label='Site survey'
      backdrop={'static'}
      className={`survey-modal survey-link-modal ${styles.SurveyDialog}`}
      keyboard={true}
      size='xl'
      onHide={() => onClose()}
    >
      <CloseButton aria-label='close dialog' onClick={() => onClose()}>
        <CloseIcon />
      </CloseButton>

      <SurveyStepRow className='survey-step-row'>
        <SurveyStepColumn className='survey-column-one'>
          <SurveyStepColumnContent mid>
            <ContentBox>
              <Box component={'h1'} m={0}>
                Help us improve AHRQ QI
              </Box>

              <Box fontSize={22} lineHeight={1.1}>
                10 minutes of your times will help us improve our services.
              </Box>

              <Box>
                The Agency for Healthcare Research and Quality (AHRQ) would appreciate your feedback
                about your use of the{' '}
                <strong>Emergency Department Prevention Quality Indicators (ED PQI)</strong> Beta
                module. Your responses will be used to improve the measures. All answers will be
                confidential.
              </Box>

              <ButtonGroup>
                <Button
                  label="Yes, I'll Take the Survey"
                  href={feedbackUrl.url}
                  target='_blank'
                  onClick={() => onClose('take-survey')}
                />

                <Box fontSize={'12px'} py='.25rem'>
                  <img src={Checkmark} alt='Check icon' height='23' width='22' />
                  By clicking yes, you are agreeing to go to a third-party platform.
                </Box>

                <AltButton
                  label={`Download Survey ${feedbackPdf.info}`}
                  endIcon={<FileDownloadIcon />}
                  href={feedbackPdf.url}
                  target='_blank'
                  onClick={() => onClose('download-pdf')}
                />

                <AltButton
                  label="Close and Don't Show Again"
                  onClick={() => onClose('dont-show-again')}
                  inverse
                />
              </ButtonGroup>
            </ContentBox>
          </SurveyStepColumnContent>
        </SurveyStepColumn>
        <SurveyStepColumn className='survey-column-two' empty last splash='02'></SurveyStepColumn>
      </SurveyStepRow>
    </Modal>
  );
};

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',

  '.MuiButton-root': {
    flexGrow: 1,
    flexShrink: 1,

    minHeight: 50,
  },
}));
ButtonGroup.defaultProps = {
  className: 'button-group',
};

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,

  [theme.breakpoints.up('lg')]: {
    color: theme.palette.common.white,
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  lineHeight: 1.1,
  width: '100%',
}));
