import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowIcon from '../../img/arrow-down.svg';

const SubNavigation = ({ menuData, section, onFocus }) => {
  const location = useLocation();
  const localData = menuData.find((item) => item.title === section);

  return (
    <Navbar className='navbar-sub' expand='xl' variant='dark'>
      <Navbar.Toggle className='sub-nav-toggle' aria-controls='responsive-navbar-subnav'>
        <div className={'expand-menu'}>
          <div className='menu-text'>Menu</div>
          <div className='arrow-icon'>
            <img alt='Arrow Icon' src={ArrowIcon} />
          </div>
        </div>
      </Navbar.Toggle>
      <Navbar.Collapse id='responsive-navbar-subnav'>
        <Nav
          onFocus={() => onFocus(false)}
          variant='pills'
          activeKey='measures'
          className='sub-nav'>
          {localData?.submenu.map((item) => {
            let className = '';
            if (Array.isArray(item.children)) {
              item.children.forEach((child) => {
                if (
                  location.pathname.toLowerCase().substring(0, child.length) === child.toLowerCase()
                )
                  className = 'active';
              });
            }
            return (
              <NavLink
                key={item.subtitle}
                exact
                activeClassName='active'
                className={className}
                to={item.path}>
                <span>{item.subtitle}</span> {item.tag !== undefined && <sup>{item.tag}</sup>}
              </NavLink>
            );
          })}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default SubNavigation;
