import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PSITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Patient Safety Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Patient Safety Indicators Technical Specifications Updates - Version v2018 and v2018.0.1
          (ICD 10), June 2018
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Patient Safety Indicators Technical Specifications (PDF Format), Version 2018
              (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI_2018_ICD10_techspecs_Provider_pdf.zip'
              href='/Downloads/Modules/PSI/V2018/TechSpecs/PSI_2018_ICD10_techspecs_Provider_pdf.zip'>
              Download All (ZIP File, 29.3 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'psi'} version={'icd10_v2018'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          The AHRQ Patient Safety Indicators (IQIs) contain two types of indicators, including those
          measured at the area (or geographic) level and those measured at the provider (or
          hospital) level. Effective v7.0, the following indicators have been retired. For
          additional information, refer to the retirement announcements:{' '}
          <a href='/News/PSI_Retirement_Notice.pdf'>PSI Area Level Hospital</a> (PDF File, 349 KB)
        </p>
        <ul>
          <li>PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate</li>
          <li>PSI 22 Iatrogenic Pneumothorax Rate</li>
          <li>PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate</li>
          <li>PSI 24 Postoperative Wound Dehiscence Rate</li>
          <li>PSI 25 Accidental Puncture or Laceration Rate</li>
          <li>PSI 26 Transfusion Reaction Rate</li>
          <li>PSI 27 Perioperative Hemorrhage or Hematoma Rate</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Patient Safety Indicators Technical Specifications for SAS and WinQI ICD-10 version v7.0
            can be found <Link to='/archive/qi_modules?category=psi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PSITechnical;
