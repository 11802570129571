import React from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
    componentWillUpdate ({ location, history }) {
        const gtag = window.gtag;

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH' &&
            typeof(gtag) === 'function') {
            
            //
            // -- BEGIN PROD-UPDATE CONFIG
            //

            // BEGIN FOR PROD
            gtag('config', 'UA-212969168-2', {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': location.pathname
            });
            gtag('config', 'G-GQW35VTQ9N', {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': location.pathname
            });
            // END FOR PROD
            //
            // BEGIN FOR NON-PROD
            // gtag('config', 'UA-212969168-1', {
            //     'page_title': document.title,
            //     'page_location': window.location.href,
            //     'page_path': location.pathname
            // });
            // END FOR NON-PROD
            
            //
            // -- END PROD-UPDATE CONFIG
            //
        }
    }

    render () {
        return null;
    }
}

export default withRouter(GoogleAnalytics);