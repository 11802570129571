import React, { useEffect } from 'react';
import { Container, Nav, Row, Col, Tab } from 'react-bootstrap';
import { newsArchiveData, newsArchiveMetaData } from '../../data/newsArchive';
import { announcementsArchiveData } from '../../data/announcementsArchive';
import styles from '../SearchableAccordion/SearchableAccordion.module.scss';
import { useLocation } from 'react-router-dom';

const ArchivesNews = () => {
  let location = useLocation();
  const newsYears = Object.keys(newsArchiveData).sort().reverse();

  useEffect(() => {
    if (location.hash) {
      const el = document.getElementById(location.hash.replace('#', ''));
      if (el) {
        setTimeout(() => {
          requestAnimationFrame(() => el.scrollIntoView());
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e, hash) => {
    e.stopPropagation();
    e.preventDefault();
    if (hash) {
      // history.push(`${location.pathname}#${hash}`);
      const el = document.getElementById(hash);
      if (el) {
        setTimeout(() => {
          requestAnimationFrame(() => el.scrollIntoView());
        });
      }
    }
  };

  return (
    <>
      <Container className='outerContainer'>
        <h1 className='align-center mb-2'>Archives</h1>
        <h2 className='align-center mb-2'>News</h2>
      </Container>
      <Container className='outerContainer'>
        <Tab.Container id='left-tabs' defaultActiveKey={`announcements`}>
          <Row>
            <Col sm={3}>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item key={`left-nav-item-announcements`}>
                  <Nav.Link eventKey={`announcements`}>Announcements</Nav.Link>
                </Nav.Item>
                <hr className='divider-blue' />

                {newsYears.map((year) => (
                  <div key={`left-div-${year}`}>
                    <Nav.Item key={`left-nav-item-${year}`}>
                      <Nav.Link eventKey={`news-${year}`}>{year}</Nav.Link>
                    </Nav.Item>
                    <hr className='divider-blue' />
                  </div>
                ))}
                {newsArchiveMetaData.additionLinks.map((info, index) => (
                  <div key={`left-div-add-${index}`}>
                    <Nav.Item key={`left-nav-item-add-${index}`}>
                      <div className={styles.archiveLeftLink}>
                        <a href={info.url} target='_blank' rel='noreferrer'>
                          {info.title}
                        </a>{' '}
                        {info.size ? info.size : null}
                      </div>
                    </Nav.Item>
                    <hr className='divider-blue' />
                  </div>
                ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane
                  eventKey={`announcements`}
                  key={`announcements-main`}
                  id={`tab-pane-announcements`}
                  name={`tab-pane-announcements`}
                >
                  <ul>
                    {announcementsArchiveData.map((announcement, index) => (
                      <li key={index}>
                        <a
                          href={`/archive/news#${announcement.id}`}
                          onClick={(e) => handleClick(e, announcement.id)}
                        >
                          {announcement.date} - {announcement.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <hr />
                  {announcementsArchiveData.map((announcement, index) => (
                    <div key={announcement.id} id={announcement.id} name={announcement.id}>
                      <p className='h6 mb-0 mt-1'>{announcement.date}</p>
                      <div style={{ display: 'flex' }}>
                        <h2 className='h4'>
                          <a href={announcement.url}>
                            <span className='mt-3 mb-0'>{announcement.title}</span>
                          </a>
                        </h2>
                      </div>
                      <div
                        className='mt-1 mb-3'
                        dangerouslySetInnerHTML={{ __html: announcement.desc }}
                      />
                      <hr />
                    </div>
                  ))}
                </Tab.Pane>
                {newsYears.map((year) => (
                  <Tab.Pane
                    eventKey={`news-${year}`}
                    key={`tab-pane-${year}`}
                    id={`tab-pane-${year}`}
                    name={`tab-pane-${year}`}
                  >
                    <ul>
                      {newsArchiveData[year].map((info, index) => (
                        <li key={index}>
                          <a
                            href={`/archive/news#${info.id}`}
                            onClick={(e) => handleClick(e, info.id)}
                          >
                            {info.date} -{' '}
                            <div
                              style={{ display: 'inline' }}
                              dangerouslySetInnerHTML={{ __html: info.title }}
                              className='mt-3 mb-3'
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                    <hr />
                    {newsArchiveData[year].map((info) => (
                      <div key={info.id} id={info.id} name={info.id}>
                        <p className='h6 mb-0 mt-1'>{info.date}</p>
                        <div style={{ display: 'flex' }}>
                          <h2 className='h4'>
                            <a href={info.url ? info.url : null}>
                              <span
                                dangerouslySetInnerHTML={{ __html: info.title }}
                                className='mt-3 mb-0'
                              />
                            </a>{' '}
                            {info.size ? (
                              <span style={{ fontWeight: 400 }} className='h5'>
                                ({info.size})
                              </span>
                            ) : null}
                          </h2>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{ __html: info.desc }}
                          className='mt-1 mb-3'
                        />
                        <hr />
                      </div>
                    ))}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default ArchivesNews;
