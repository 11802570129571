import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { nqfData } from '../../data/nqf';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';
import styles from './Measures.module.scss';

const NQFEndorsedMeasure = () => {
  return (
    <div className={styles.NQF}>
      <NormalTopBanner>
        <h1>National Quality Forum (NQF)</h1>
        <h2>Endorsed Individual and Composite Measures</h2>
        <h6 className=''>Last updated: May, 2021</h6>
      </NormalTopBanner>
      <Container className='outer-container'>
        <h3>Inpatient Quality Indicators (IQIs) Endorsed by NQF</h3>
        <p>
          May, 2021 - AHRQ will no longer seek NQF re-endorsement for its portfolio of measures in
          the AHRQ Quality Indicators (QIs) program starting in fiscal year 2022.{' '}
          <a href='/Downloads/News/AHRQ_Rationale4notseekingNQFendorsement-May2021.pdf'>
            Click here for rationale
          </a>
          .
        </p>
        <div key='div-iqi'>
          <Table className={styles.NqfTable} striped>
            <thead>
              <tr className={styles.TableHeading}>
                <th>Indicator</th>
                <td>&emsp;</td>
                <td>&emsp;</td>
                <th>NQF Number</th>
              </tr>
            </thead>
            <tbody>
              {nqfData['iqi'].map((spec, index) => (
                <tr key={`tr-iqi-${index}`}>
                  <td>
                    {spec.url ? (
                      <>
                        <a href={spec.url}>{spec.indicator}</a>
                        <br />
                        <span>PDF File ({spec.size})</span>
                      </>
                    ) : (
                      spec.indicator
                    )}{' '}
                  </td>
                  <td>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: spec.desc }} />
                    </span>
                  </td>
                  <td>{spec.status}</td>
                  <td>{spec.number}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
      <Container className='outer-container'>
        <h3>Patient Safety Indicators (PSIs) Endorsed by NQF</h3>
        <div key='div-psi'>
          <Table className={styles.NqfTable} striped>
            <thead>
              <tr className={styles.TableHeading}>
                <th>Indicator</th>
                <td>&emsp;</td>
                <td>&emsp;</td>
                <th>NQF Number</th>
              </tr>
            </thead>
            <tbody>
              {nqfData['psi'].map((spec, index) => (
                <tr key={`tr-psi-${index}`}>
                  <td>
                    {spec.url ? (
                      <>
                        <a href={spec.url}>{spec.indicator}</a> <br />
                        <span className='h6'>PDF File ({spec.size})</span>
                      </>
                    ) : (
                      spec.indicator
                    )}{' '}
                  </td>
                  <td>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: spec.desc }} />
                    </span>
                  </td>
                  <td>{spec.status}</td>
                  <td>{spec.number}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div key='div-psi-footnote' className={styles.footnoteDiv}>
          <p>
            <sub>1</sub>PSI 04 or Death Rate Among Surgical Inpatients with Serious Treatable
            Complications was submitted to the National Quality Forum (NQF) for continued
            endorsement. After three rounds of intensive review at both the NQF Surgery Standing
            Committee and the NQF Consensus Standards Approval Process (CSAC), AHRQ withdrew the
            measure from further consideration at NQF. AHRQ conducted rigorous testing which
            demonstrated that the measure is valid and reliable. Our findings were included in the
            materials submitted and reviewed at NQF
            (http://www.qualityforum.org/Publications/2017/04/Surgery_2015-2017_Final_Report.aspx).
            However, AHRQ has chosen not to continue with the NQF review process, pending a review
            of competing priorities. As with any measure withdrawn from consideration at NQF,
            endorsement was removed from the measure.
          </p>
        </div>
      </Container>
      <Container className='outer-container'>
        <h3>Pediatric Quality Indicators (PDIs) Endorsed by NQF</h3>
        <div key='div-pdi'>
          <Table className={styles.NqfTable} striped>
            <thead>
              <tr className={styles.TableHeading}>
                <th>Indicator</th>
                <td>&emsp;</td>
                <td>&emsp;</td>
                <th>NQF Number</th>
              </tr>
            </thead>
            <tbody>
              {nqfData['pdi'].map((spec, index) => (
                <tr key={`tr-pdi-${index}`}>
                  <td>
                    {spec.url ? (
                      <>
                        <a href={spec.url}>{spec.indicator}</a> <br />
                        <span className='h6'>PDF File ({spec.size})</span>
                      </>
                    ) : (
                      spec.indicator
                    )}{' '}
                  </td>
                  <td>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: spec.desc }} />
                    </span>
                  </td>
                  <td>{spec.status}</td>
                  <td>{spec.number}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
      <Container className='outer-container'>
        <h3>Prevention Quality Indicators in Inpatient Settings (PQIs) Endorsed by NQF</h3>
        <div key='div-pqi'>
          <Table className={styles.NqfTable} striped>
            <thead>
              <tr className={styles.TableHeading}>
                <th>Indicator</th>
                <td>&emsp;</td>
                <td>&emsp;</td>
                <th>NQF Number</th>
              </tr>
            </thead>
            <tbody>
              {nqfData['pqi'].map((spec, index) => (
                <tr key={`tr-pqi-${index}`}>
                  <td>
                    {spec.url ? (
                      <>
                        <a href={spec.url}>{spec.indicator}</a>
                        <br />
                        <span className='h6'>PDF File ({spec.size})</span>
                      </>
                    ) : (
                      spec.indicator
                    )}{' '}
                  </td>
                  <td>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: spec.desc }} />
                    </span>
                  </td>
                  <td>{spec.status}</td>
                  <td>{spec.number}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
      <Container className='outer-container'>
        <h3>Composite Indicators Endorsed by NQF</h3>
        <div key='div-composite'>
          <Table className={styles.NqfTable} striped>
            <thead>
              <tr className={styles.TableHeading}>
                <th>Indicator&emsp;</th>
                <td>&emsp;</td>
                <td>&emsp;</td>
                <th>
                  NQF Number<sup>1</sup>
                </th>
              </tr>
            </thead>
            <>
              {nqfData['composite'].map((comp, index) => (
                <>
                  <thead>
                    <tr
                      key={`tbody-composite-tr-${index}`}
                      className={styles.TableSecondaryHeading}
                    >
                      <th>
                        <>
                          <a href={comp.url}>{comp.indicator}</a>
                          <br />
                          <span className='h6'>PDF File ({comp.size})</span>
                        </>{' '}
                      </th>
                      <td>
                        <div dangerouslySetInnerHTML={{ __html: comp.desc }} />
                      </td>
                      <td>
                        <span className={styles.Danger}>{comp.status}</span>
                      </td>
                      <th>{comp.number}</th>
                    </tr>
                  </thead>
                  <tbody key={`tbody-composite`}>
                    {comp.children.map((child, index) => (
                      <tr key={`tbody-composite-tr-child-${index}`}>
                        <td style={{ marginLeft: 40, paddingLeft: 40 }}>
                          <>
                            <a href={child.url}>{child.indicator}</a>
                            <br />
                            <span className='h6'>PDF File ({child.size})</span>
                          </>
                        </td>
                        <td>{child.desc}</td>
                        <td>
                          <span className={styles.Danger}>{child.status}</span>
                        </td>
                        <td>{child.number}</td>
                      </tr>
                    ))}
                  </tbody>
                </>
              ))}
            </>
          </Table>
        </div>
        <div key='div-composite-footnote' className={styles.footnoteDiv}>
          <p>
            <sub>1</sub>Individual measures within each composite that do not have an NQF Number are
            not individually endorsed by NQF.{' '}
          </p>
          <p>
            <sub>2</sub>PSI 9, PSI 10, and PSI 11 are not part of the NQF Endorsed Composite, but
            they are components of the AHRQ QI<sup>TM</sup> Composite measure.
          </p>
          <p>
            <sub>3</sub>PDI 8 and PDI 9 are not part of the NQF Endorsed Composite, but they are
            components of the AHRQ QI<sup>TM</sup> Composite measure.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default NQFEndorsedMeasure;
