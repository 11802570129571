import { Box, styled } from '@mui/material';

export const SurveyStepColumn = styled(Box, {
  shouldForwardProp: (prop) => !['empty', 'last', 'splash'].includes(prop),
})(({ theme, empty, last, splash }) => ({
  display: 'flex',
  flex: 1,

  backgroundColor: splash
    ? theme.palette.primary.dark
    : last
    ? theme.palette.fill.lightGrey
    : theme.palette.common.white,

  overflow: 'hidden',

  ...(splash && {
    backgroundImage:
      splash === '02'
        ? `url('/images/survey-splash-bg-02.jpeg')`
        : `url('/images/survey-splash-bg.jpeg')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),

  [theme.breakpoints.down('lg')]: {
    display: empty ? 'none' : 'flex',
  },
}));

export const SurveyStepColumnContent = styled(Box, {
  shouldForwardProp: (prop) => !['first', 'last', 'mid'].includes(prop),
})(({ first, last, mid, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flex: 1,

  backgroundColor: last ? theme.palette.fill.lightGrey : theme.palette.common.white,
  padding: first || mid ? '3.5rem 1.5rem 5rem' : '1.5rem',

  ...((first || mid) && {
    backgroundImage: `url('/images/logo-ahrq.png')`,
    backgroundPosition: 'center 96%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '180px',
  }),

  [theme.breakpoints.up('md')]: {
    padding: first || mid ? '3rem 3rem 5rem' : '3rem',
  },

  [theme.breakpoints.up('lg')]: {
    justifyContent: (last || mid) && 'center',
    padding: first ? '9rem 3rem 4rem' : '4rem 3rem 5rem',
  },
  [theme.breakpoints.up('xl')]: {
    padding: first ? '13rem 5rem 4rem' : '4rem 5rem',
  },
}));
