import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styles from '../Measures.module.scss';
import { renderToString } from 'react-dom/server';
import { modulesData } from '../../../data/modules';
import { modulesMetadata } from '../../../data/modules_metadata';
import SearchableAccordion from '../../SearchableAccordion/SearchableAccordion';
import { qiMeasuresData } from '../../../data/measures/qi';
import AHRQSimpleSelect from '../../Common/Select/AHRQSimpleSelect';
import NormalTopBanner from '../../Common/TopBanner/NormalTopBanner';

const AllMeasures = () => {
  const areaLevelSearchOptions = [
    { value: 'All Levels', label: 'All Levels' },
    { value: 'Area Level', label: 'Area Level' },
    { value: 'Hospital Level', label: 'Hospital Level' },
  ];
  const currentVersion = modulesMetadata['current-version'];

  const convertToAccordionFormat = (data) => {
    let result = {
      'default-modules': 'mostpopular',
      modules: {
        generalresources: {
          topic: 'General Resources',
          children: [],
          groupInfo: {},
        },
      },
    };

    for (const qi of Object.keys(qiMeasuresData)) {
      result.modules['generalresources'].groupInfo[qi] = {
        title: qiMeasuresData[qi].title,
      };
      for (const child of qiMeasuresData[qi].children) {
        result.modules['generalresources'].children.push({
          tag: child['tag'],
          type: undefined,
          topic: child['title'],
          subTopic: undefined,
          underTopic: child['date'],
          isMostPopular: child['isMostPopular'],
          group: qi,
          desc: renderToString(
            <>
              <Row>
                <Col md={12}>
                  <p>
                    <div
                      style={{ wordBreak: 'break-word' }}
                      dangerouslySetInnerHTML={{ __html: child.desc }}
                    />
                  </p>
                </Col>
              </Row>
              {child.url && (
                <Row className='mt-2'>
                  <a rel='noreferrer' href={child.url}>
                    <button className={`button large primary`}>{child.cta}</button>
                  </a>
                </Row>
              )}
              {child.url2 && (
                <Row className='mt-2'>
                  <a rel='noreferrer' href={child.url2}>
                    <button className={`button large primary`}>{child.cta2}</button>
                  </a>
                </Row>
              )}
              {child.url3 && (
                <Row className='mt-2'>
                  <a rel='noreferrer' href={child.url3}>
                    <button className={`button large primary`}>{child.cta3}</button>
                  </a>
                </Row>
              )}
            </>
          ),
        });
      }
    }

    for (const key of Object.keys(data)) {
      result['modules'][key] = {
        topic: data[key]['topic'],
        groupInfo: undefined,
        children: data[key]['indicators']
          ? data[key]['indicators'].map((child, index) => {
              const currentVersionItem = child.versions ? child.versions[currentVersion] : '';
              const allVersionKeys = child.versions ? Object.keys(child.versions) : [];
              const previousVersionKeys = child.versions
                ? allVersionKeys.filter((v) => v !== currentVersion)
                : '';
              const subTopic = child['subTopic'] ? child['subTopic'] : '';
              return {
                tag: child['tag'],
                type: child['type'],
                topic: child['indicator'],
                subTopic: subTopic,
                isMostPopular: child['isMostPopular'],
                group: key,
                desc: renderToString(
                  <div key={index}>
                    <Row className={styles.accordionContent}>
                      <Col md={9}>
                        <p>
                          <h3 className='h4'>{modulesMetadata['metadata'][currentVersion].name}</h3>
                          <p className='h5'>{child.type}</p>
                        </p>
                      </Col>
                      <Col md={3} className='align-right'>
                        {currentVersionItem?.url ? (
                          <a rel='noreferrer' href={currentVersionItem.url}>
                            <Button
                              aria-label={currentVersionItem.aria}
                              className='button large primary'>
                              View (PDF File, {currentVersionItem.size}
                            </Button>
                          </a>
                        ) : null}
                      </Col>
                      {currentVersionItem?.url ? <hr /> : null}
                      <Col md={12}>
                        <p className='bold'>Description:</p>
                        <p>{child.desc}</p>
                        {previousVersionKeys.length > 0 ? (
                          <>
                            <p className='bold'>See Previous Versions:</p>
                            {previousVersionKeys.map((prevKey, index) => (
                              <p className='mb-0' key={index}>
                                <a href={child.versions[prevKey].url}>
                                  {modulesMetadata['metadata'][prevKey].name}{' '}
                                </a>
                                (PDF File, {child.versions[prevKey].size})&emsp;
                              </p>
                            ))}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                ),
              };
            })
          : [],
      };
      if (data[key]['appendices']) {
        result['modules'][key]['children'].push({
          tag: data[key]['appendices']['tag'],
          topic: data[key]['appendices']['indicator'],
          desc: renderToString(
            <>
              <p className='bold'>See appendices for all versions:</p>
              {Object.keys(data[key]['appendices']['versions']).map((appendixKey, index) => (
                <div key={index} className='mb-4'>
                  <p className='h4'>{modulesMetadata['metadata'][appendixKey].appendixName}</p>
                  {data[key]['appendices']['versions'][appendixKey].map(
                    (appendixVersionItem, index) => (
                      <p key={index}>
                        <a href={appendixVersionItem.url}>{appendixVersionItem.name} </a> (PDF File,{' '}
                        {appendixVersionItem.size})
                      </p>
                    )
                  )}
                </div>
              ))}
            </>
          ),
        });
      }
      if (data[key]['retired']) {
        result['modules'][key]['children'].push({
          tag: data[key]['retired']['tag'],
          topic: data[key]['retired']['indicator'],
          desc: renderToString(
            <>
              <p className='bold'>See previous versions:</p>
              {Object.keys(data[key]['retired']['versions']).map((retiredKey, index) => (
                <div key={index} className='mb-4'>
                  <p className='h4'>{retiredKey}</p>
                  {data[key]['retired']['versions'][retiredKey].previous.map(
                    (retiredVersionItem, index) => (
                      <p key={index}>
                        <a href={retiredVersionItem.url}>
                          {modulesMetadata['metadata'][retiredVersionItem.version].name}
                        </a>
                        (PDF File, {retiredVersionItem.size})
                      </p>
                    )
                  )}
                </div>
              ))}
            </>
          ),
        });
      }
    }

    // add most popular
    const mostPopular = {
      topic: 'Most Popular Resources',
      children: [],
      groupInfo: undefined,
    };
    for (const key of Object.keys(result.modules)) {
      for (const item of result.modules[key]['children']) {
        if (item.isMostPopular) {
          var updatedItem = { ...item, subTopic: result.modules[key].topic };
          mostPopular.children.push(updatedItem);
        }
      }
    }

    result.modules = {
      mostpopular: mostPopular,
      ...result.modules,
    };

    return result;
  };

  const [originalData] = useState(() => convertToAccordionFormat(modulesData));
  const [measureSelect, setMeasureSelect] = useState('All Levels');
  const [filterChanged, setFilterChanged] = useState(null);

  const applyDataFilter = (modules) => {
    if (measureSelect !== 'All Levels') {
      const newSection = {};
      for (const modKey of Object.keys(modules)) {
        const newChildren = [];
        for (const child of modules[modKey].children) {
          if (child.type === measureSelect) {
            newChildren.push({ ...child });
          }
        }

        if (newChildren.length > 0) {
          newSection[modKey] = { ...modules[modKey], children: newChildren };
        }
      }
      return newSection;
    } else {
      return modules;
    }
  };

  const resetSearch = () => {
    setMeasureSelect('All Levels');
  };

  const measuresData = JSON.parse(JSON.stringify(originalData));

  return (
    modulesData &&
    measuresData && (
      <>
        <NormalTopBanner setBackground={true}>
          <h1>All Measures</h1>
          <p>
            This page contains Quality Indicator's (QI) technical specifications from all four
            modules. You will be able to browse and search QI definitions and download most current
            and prior versions of the technical specifications document.
          </p>
        </NormalTopBanner>
        <SearchableAccordion
          data={measuresData}
          section={'modules'}
          onResetSearch={resetSearch}
          onApplyDataFilter={applyDataFilter}
          filterChanged={filterChanged}
          setSearchBackground={true}>
          <AHRQSimpleSelect
            aria-label='Select Measures'
            className={styles.MeasuresSelect}
            onChange={(selectedValue) => {
              setMeasureSelect(selectedValue.value);
              setFilterChanged(new Date());
            }}
            options={areaLevelSearchOptions}
            defaultValueValue={measureSelect}
          />
        </SearchableAccordion>
      </>
    )
  );
};

export default AllMeasures;
