const WINQI_ITEMS = [
  // DOWNLOADS/SOFTWARE/WINQI
  {
    name: 'WinQi',
    title: 'WinQI v2023',
    filename: 'quality_indicators_2023_x64_Setup.zip',
    path: '/Downloads/Software/WinQI/V2023/',
    size: '248 MB',
    format: 'ZIP',
    ctaLabel: 'Download WinQI v2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'ReleaseNotes',
    title: 'v2023 Release Notes',
    filename: 'AHRQ_WinQI_v2023_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/WinQI/V2023/',
    size: '238 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'WinQiSoftwareInstructions',
    title: 'AHRQ Quality Indicators Software Instructions (WinQI and CloudQI PSI Beta) v2023',
    filename: 'Software_Inst_WINQI_V2023_August_2023.pdf',
    path: '/Downloads/Software/WinQI/V2023/',
    size: '7.3 MB',
    format: 'PDF',
    dateModified: '2023-11-07',
  },

  // PQE
  {
    name: 'WinQiEdPqi',
    title: 'ED PQI v2023.0.1',
    filename: 'edpqi_1.0.1_x64_Setup.zip',
    path: '/Downloads/Software/WinQI/V2023/',
    size: '100 MB',
    format: 'ZIP',
    ctaLabel: 'Download ED PQI v2023.0.1',
    revision: 'December 2023',
    dateModified: '2023-12-21',
  },
  {
    name: 'WinQiEdPqiReleaseNotes',
    title: 'ED PQI v2023 Release Notes',
    filename: 'AHRQ_EDPQI_Beta_v2023.0.1_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/WinQI/V2023/', // this is same file as in sas
    size: '262 KB',
    format: 'PDF',
    ctaLabel: 'Download v2023 release notes',
    revision: 'December 2023',
    dateModified: '2023-12-18',
    version: 'v2023.0.1'
  },
  {
    name: 'WinQiEdPqiSoftwareInstructions',
    title: 'ED PQI v2023 Software Instructions',
    filename: 'Software_Inst_ED-PQI_V2023_September_2023.pdf',
    path: '/Downloads/Software/WinQI/V2023/',
    size: '5.53 MB',
    format: 'PDF',
    revision: 'September 2023',
    dateModified: '2023-08-11',
  },
];

export default WINQI_ITEMS;
