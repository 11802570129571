import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Container className='outerContainer align-center mb-10 mt-5'>
      <h1 className='align-center mb-4'>404</h1>
      <h3 className='align-center mb-4'>Not Found</h3>
      <p>
        HTTP 404. The resource you are looking for (or one of its dependencies) could have been
        removed, had its name changed, or is temporarily unavailable. Please review the following
        URL and make sure that it is spelled correctly.
      </p>
      <p>
        <strong>Requested URL:</strong> {window.location.pathname}{' '}
      </p>
      <p>
        Return to <Link to='/'>Home Page</Link>
      </p>
    </Container>
  );
};

export default NotFound;
