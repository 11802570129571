export const announcementsArchiveData = [
  {
    title: 'AHRQ Emergency Department Prevention Quality Indicators (ED PQI) Beta v2023 Software - New Resources and Opportunity to Provide Feedback',
    id: 'announcements-2024-02',
    date: 'February 2024',
    year: 2024,
    month: 2,
    desc: `AHRQ announces a guide to using the Emergency Department Prevention Quality Indicators (ED PQI) module for population health improvement, research, and public reporting. Review the use cases here. Additionally, new video tutorials to help users calculate and understand the indicators are available on the AHRQ QI website.`,
    url: '/announcements/2024/02',
  },
];