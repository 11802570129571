import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Button, Container as BSContainer } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Hero = () => {
  return (
    <Row>
      <Container>
        <Video autoPlay={true} loop={true} muted={true}>
          <source src='/media/home-ahrq-qi.webm' type='video/webm' />
        </Video>

        <Content>
          {/* include background color for usability contrast tests */}
          <Typography variant='h1' gutterBottom sx={{ backgroundColor: '#337BA9' }}>
            Quality Improvement and monitoring at your fingertips
          </Typography>
          <Link to='/measures/qi_resources'>
            <Button className='button secondary arrow-right'>Learn More</Button>
          </Link>
        </Content>
      </Container>
    </Row>
  );
};

const Row = styled(Box)(({ theme }) => ({
  position: 'relative',

  // backgroundImage: `url('images/home_banner.jpg')`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  overflow: 'hidden',

  [theme.breakpoints.down('xl')]: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,

      display: 'block',
      width: '100%',
      height: '100%',

      backgroundColor: theme.palette.primary.light,
    },
  },

  [theme.breakpoints.up('xl')]: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: `calc(50% + 570px - 12px)`,

      display: 'block',
      width: '100%',
      height: '100%',

      backgroundColor: theme.palette.primary.light,
      backgroundRepeat: `no-repeat`,
    },

    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: `calc(50% + 570px - 220px - 12px)`,

      display: 'block',
      width: '100%',
      height: '100%',

      backgroundImage: `linear-gradient(to right, transparent 0px, ${theme.palette.primary.light} 220px)`,
      backgroundRepeat: `no-repeat`,
    },
  },

  [theme.breakpoints.up('xxl')]: {
    ':before': {
      right: `calc(50% + 660px - 12px)`,
    },

    ':after': {
      left: `calc(50% + 660px - 220px - 12px)`,
    },
  },
}));

const Container = styled(BSContainer)(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.up('xl')]: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 12,

      display: 'block',
      width: '50%',
      height: '100%',

      backgroundImage: `linear-gradient(110deg, ${theme.palette.primary.light} 80%, transparent 80%)`,
      backgroundRepeat: `no-repeat`,
    },
  },
}));

const Video = styled('video')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  zIndex: -1,

  width: '65%',
}));

const Content = styled(Box)(({ theme }) => ({
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  color: theme.palette.common.white,
  minHeight: 380,
  padding: `${theme.spacing(8)} 0`,
  width: '100%',
  zIndex: 1,

  [theme.breakpoints.up('xl')]: {
    height: 380,
    padding: `${theme.spacing(8)} ${theme.spacing(12)}`,
    paddingLeft: 0,
    width: '50%',
  },
}));
