import React, { useState } from 'react';
import { Button } from '../../ui';
import { AvailabilityDialog } from './AvailabilityDialog';

export const AvailabilityDialogButton = ({
  dialogTitle,
  dialogSubtitle,
  dialogDescription,
  dialogLabel,
  url,
  onClose,
  disableAvailabilityDialog,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleDialogClose = (reason) => {
    setOpen(false);
    if (onClose) onClose(reason);
  };

  return (
    <>
      {open && (
        <AvailabilityDialog
          title={dialogTitle}
          subtitle={dialogSubtitle}
          description={dialogDescription}
          label={dialogLabel}
          open={open}
          onClose={handleDialogClose}
        />
      )}
      <Button {...rest} href={url} onClick={() => setOpen(!disableAvailabilityDialog && true)} />
    </>
  );
};
