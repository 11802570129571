import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PDITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Pediatric Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Pediatric Quality Indicators Technical Specifications - Version 6.0, August 2017
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              All Pediatric Quality Indicators Technical Specifications, Version 6.0 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PDI_v60_ICD9_Technical_Specifications.zip'
              href='/Downloads/Modules/PDI/V60/TechSpecs/PDI_v60_ICD9_Technical_Specifications.zip'
            >
              Download All (ZIP File, 13.8 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pdi'} version={'icd9_v60'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Pediatric Quality Indicators Technical Specifications for SAS v5.0 and WinQI v5.0 can be
            found <Link to='/archive'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PDITechnical;
