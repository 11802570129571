import React from 'react';
import Announcements202402 from './Announcements_2024_02';
import Announcements202410 from './Announcements_2024_10';
import { useParams } from 'react-router-dom';

const AnnouncementsMain = () => {
  const { year, month } = useParams();

  if (year === '2024' && month === '02') {
    return <Announcements202402 />;
  }

  return <Announcements202410 />;
};

export default AnnouncementsMain;
