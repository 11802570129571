import { Box, styled } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Flex } from '../Flex';
import { Button } from '../../Buttons';

export const SoftwareList = ({
  children,
  title,
  subtitle,
  viewAllUrl,
  viewAllLabel = 'View all archived software',
  paper = false,
}) => {
  const hasSubtitle = subtitle !== undefined;
  return (
    <ArchivedSoftwareContainer paper={paper}>
      <Flex
        cols='md'
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent={hasSubtitle ? 'space-between' : 'center'}
        textAlign='center'>
        {!hasSubtitle && <h2>{title}</h2>}
        {hasSubtitle && <h3>{title}</h3>}
        {hasSubtitle && <div>{subtitle}</div>}
      </Flex>
      <ArchivedSoftwareRows>{children}</ArchivedSoftwareRows>
      {viewAllUrl && (
        <ArchivedSoftwareMoreInfo>
          <a href={viewAllUrl}>
            {viewAllLabel} <ChevronRightIcon />
          </a>
        </ArchivedSoftwareMoreInfo>
      )}
    </ArchivedSoftwareContainer>
  );
};

export const SoftwareListItem = ({ title, subtitle, url, label, ariaLabel }) => {
  return (
    <ArchivedSoftwareRow>
      <div className='content'>
        <div className='title'>{title}</div>
        <div className='subtitle'>{subtitle}</div>
      </div>
      <Button href={url} ariaLabel={ariaLabel ?? label} arrow>
        {label}
      </Button>
    </ArchivedSoftwareRow>
  );
};

const ArchivedSoftwareContainer = styled(Box, {
  shouldForwardProp: (prop) => !['paper'].includes(prop),
})(({ theme, paper }) => ({
  ...(paper && {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: theme.shadows[4],
    padding: '2.5rem',
  }),
}));

const ArchivedSoftwareRows = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[400]}`,
  marginTop: '1rem',
}));

const ArchivedSoftwareRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '1.5rem 0',

  '.content': {
    flexGrow: 1,
    '.title': {
      fontSize: 18,
      fontWeight: 600,
    },
  },

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: 0,

    '.MuiButtonBase-root': {
      minWidth: '18rem',
    },
  },
}));

const ArchivedSoftwareMoreInfo = styled(Box)(({ theme }) => ({
  a: {
    color: theme.palette.primaryButton.main,
    display: 'flex',
    alignItems: 'center',
    fontSize: 21,
    fontWeight: 600,
    marginTop: '1.5rem',

    '&:hover': {
      color: theme.palette.primaryButton.hover,
      textDecoration: 'none',
    },
  },
}));
