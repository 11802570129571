import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PDITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Pediatric Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Pediatric Quality Indicators Technical Specifications Updates - Version v2020, July 2020
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Pediatric Quality Indicators Technical Specifications (PDF Format), Version
              v2020 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PDI_2020_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/PDI/V2020/TechSpecs/PDI_2020_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 17.5 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pdi'} version={'icd10_v2020'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Updates</h3>
        <p>
          <span className='danger'>March 2021</span>: Minor updates were made in some v2020
          technical specifications to clarify the indicator logic. Language was updated to better
          align with the logic implemented in the AHRQ QI software v2020. These minor updates are
          limited to the technical specifications documents, and no changes where made in the v2020
          software.
        </p>
        <p>
          Indicators updated: NQI 03, PDI 08, PDI 09, PDI 14, PDI 15, PDI 16, PDI 18, PDI Appendix
          J, PDI Appendix L
        </p>
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          <span className='danger'>Effective v2019</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/Retirement%20Notice_v2019_Indicators.pdf'>
            Indicators Retirement Announcement.
          </a>{' '}
          (PDF File, 108 KB)
        </p>
        <ul>
          <li>NQI 01 Neonatal Iatrogenic Pneumothorax Rate</li>
          <li>PDI 02 Pressure Ulcer Rate</li>
          <li>PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count</li>
          <li>PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate</li>
          <li>PDI 07 RACHS-1 Pediatric Heart Surgery Volume</li>
          <li>PDI 11 Postoperative Wound Dehiscence Rate</li>
          <li>PDI 13 Transfusion Reaction Count</li>
          <li>PDI 17 Perforated Appendix Admission Rate</li>
          <li>PDI 19 Pediatric Safety for Selected Indicators Composite</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Pediatric Quality Indicators Technical Specifications for SAS and WinQI ICD-10 version
            v2019 can be found <Link to='/archive/qi_modules?category=pdi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PDITechnical;
