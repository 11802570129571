import React from 'react';
import { Button, Flex, ImagePageRow } from '../../../ui';

export const QIsInAction = () => {
  return (
    <ImagePageRow
      bgSrc='/images/hex-purple.png'
      imageSrc='/images/bg-in-action.jpg'
      purple
      mt='3rem'>
      <h2>AHRQ QIs in Action</h2>
      <h3>Johns Hopkins Medicine</h3>
      <p>
        The Johns Hopkins Hospital worked diligently to improve its performance for Postoperative
        Respiratory Failure (PSI 11). The effort started back in 2012 when only 30 percent were able
        to be removed from a ventilator within the desired timeframe. Today, nearly 60 percent of
        cardiac patients are taken off a ventilator in less than six hours following surgery.
      </p>
      <Flex color='secondary.main' gap={2} cols='md'>
        <div>
          <Button
            variant='outlined'
            color='white'
            href='/resources/case_studies'
            label='View All Case Studies'
            arrow
          />
        </div>
      </Flex>
    </ImagePageRow>
  );
};
