import React, { useEffect } from 'react'
import ExitDisclaimerMain from '../../components/Other/ExitDisclaimerMain';

const News = () => {
    useEffect(() => {
        document.title = `AHRQ - Quality Indicators Exit Disclaimer`;
    });
    return (<ExitDisclaimerMain />)
}

export default News;