import React from 'react';

const NormalTopBanner = (props) => {
  return (
    <div
      className={`top-banner-container-normal align-center pb-0 ${
        props.setBackground ? 'bg-primary-blue-6' : ''
      }`}
    >
      {props.children}
    </div>
  );
};

export default NormalTopBanner;
