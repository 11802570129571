import React from 'react';

const TopBanner = (props) => {
  return (
    <div className='top-banner-container'>
      <div>{props.children}</div>
    </div>
  );
};

export default TopBanner;
