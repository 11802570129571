import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './AvailabilityDialog.module.scss';
import { Box, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  AltButton,
  Button,
  SurveyStepColumn,
  SurveyStepColumnContent,
  SurveyStepRow,
} from '../../ui';

export const AvailabilityDialog = ({
  title = 'Thank You for Downloading',
  subtitle = 'Also Available...',
  description,
  label,
  open,
  onClose,
}) => {
  const handleClose = (reason = 'close') => {
    onClose(reason);
  };

  return (
    <>
      <Modal
        show={open}
        aria-label='Software Availability dialog'
        backdrop={'static'}
        className={`availability-modal ${styles.AvailabilityDialog}`}
        keyboard={true}
        size='xl'
        onHide={handleClose}>
        <CloseButton aria-label='close dialog' splash onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>

        {/* ED PQI Beta v2023 */}
        <SurveyStepRow className='availability-step-row'>
          <SurveyStepColumn className='availability-question-column'>
            <SurveyStepColumnContent first>
              <ContentBox>
                <Box component={'h1'} m={0}>
                  {title}
                </Box>
                <Box fontSize={22} lineHeight={1.1}>
                  {subtitle}
                </Box>
                <Box>{description}</Box>
                <ButtonGroup>
                  <Button label={label} onClick={() => onClose('go')} />
                </ButtonGroup>
                <ButtonGroup>
                  <AltButton label={`I'll Try Later, Close Window`} onClick={() => onClose()} />
                </ButtonGroup>
              </ContentBox>
            </SurveyStepColumnContent>
          </SurveyStepColumn>

          <SurveyStepColumn
            className='availability-answer-column'
            empty
            last
            splash></SurveyStepColumn>
        </SurveyStepRow>
      </Modal>
    </>
  );
};

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.625rem',

  '.MuiButton-root': {
    flexGrow: 1,
    flexShrink: 1,

    minHeight: 50,
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  lineHeight: 1.1,
  width: '100%',
}));

const CloseButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'splash',
})(({ theme, splash }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,

  [theme.breakpoints.up('lg')]: {
    color: splash ? theme.palette.common.white : 'auto',
  },
}));
