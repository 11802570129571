const BASE_ITEMS = [
  // DOWNLOADS/MODULES
  {
    name: 'FiscalYearCodingRevisions',
    title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
    desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
    filename: 'v2023_FY_Coding_Updates.pdf',
    path: '/Downloads/Modules/V2023/',
    size: '114 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },

  // DOWNLOADS/RESOURCES
  {
    name: 'EmpiricalMethods',
    title: 'AHRQ Quality Indicator Empirical Methods',
    desc: 'Describes the empirical methods used to calculate AHRQ QIs',
    filename: 'Empirical_Methods_2023.pdf',
    path: '/Downloads/Resources/Publications/2023/',
    size: '1.18 MB',
    format: 'PDF',
    revision: 'September 2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'Psi08ExpansionAnnouncement',
    title: 'PSI 08 updates in AHRQ QI v2023.',
    date: 'August 14, 2023',
    desc: 'AHRQ has expanded the range of fractures included in the PSI 08 indicator to support improvement efforts underway at hospitals, hospital systems, and quality improvement entities.',
    filename: 'v2023_PSI08_Expansion_Announcement.pdf',
    path: '/Downloads/Resources/',
    size: '103 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'Psi08ExpansionFaq',
    title: 'v2023 PSI 08 Expansion FAQ',
    filename: 'v2023_PSI08_Expansion_FAQ.pdf',
    path: '/Downloads/Resources/',
    size: '144 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'Psi08ExpansionMemo',
    title: 'v2023 PSI 08 Expansion Memo',
    filename: 'v2023_PSI08_Expansion_Memo.pdf',
    path: '/Downloads/Resources/',
    size: '171 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },

  // DOWNLOADS/SOFTWARE
  {
    name: 'SASAndWinQIComparisonTesting',
    title: 'SAS v2023 and WinQI v2023 Comparison Testing',
    filename: 'SAS-WinQI_comparison_ICD-10v2023.pdf',
    path: '/Downloads/Software/V2023/',
    size: '186 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'AHRQQIRateComparison',
    title: 'AHRQ QI Rate Comparison: SAS QI v2022 vs. SAS QI v2023',
    filename: 'AHRQ_QI_Rate_Comparison_v2022_v2023.pdf',
    path: '/Downloads/Software/V2023/',
    size: '457 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },

  // DOWNLOADS/SURVEYS
  {
    name: 'EDPQIv2023BetaTestingFeedback',
    title:
      'Emergency Department Prevention Quality Indicators (ED PQI) Beta Software, v2023 User Feedback',
    filename: 'AHRQ_ED_PQI_v2023_Beta_Testing_Feedback.pdf',
    path: '/Downloads/Surveys/',
    size: '1.47 MB',
    format: 'PDF',
    dateModified: '2024-01-29',
  },
  {
    name: 'EDPQIv2023BetaTestingFeedbackUrl',
    title:
      'Emergency Department Prevention Quality Indicators (ED PQI) Beta Software, v2023 User Feedback',
    aria: 'ED PQI Survey',
    cta: 'ED PQI Survey! Give Us Your Feedback',
    url: 'https://panth.optimalworkshop.com/questions/9d7946d36f65bc0969f6505e7e0f0974',
    dateModified: '2024-02-08',
  },

  // NEWS
  {
    name: 'Icd10Faq',
    title: 'ICD10 v2023 FAQ',
    filename: 'ICD10_v2023_FAQ.pdf',
    path: '/News/',
    size: '381 KB',
    format: 'PDF',
    dateModified: '2023-08-14',
  },
];

export default BASE_ITEMS;
