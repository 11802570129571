import React from 'react';
import { Container as BSContainer } from 'react-bootstrap';
import { Box, styled } from '@mui/material';

export const SplitRow = ({ purple, children }) => {
  return (
    <Row purple={purple}>
      <Container purple={purple}>{children}</Container>
    </Row>
  );
};

const Row = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'purple',
})(({ theme, purple }) => ({
  position: 'relative',

  backgroundColor: '#F7FAFC',
  overflow: 'hidden',

  [theme.breakpoints.down('xl')]: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,

      display: 'block',

      backgroundColor: purple ? '#83378B' : theme.palette.primary.main,
    },
  },

  [theme.breakpoints.up('xl')]: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: `calc(50% + 570px - 12px)`,

      display: 'block',
      width: '100%',
      height: '100%',

      backgroundColor: purple ? '#83378B' : theme.palette.primary.main,
      backgroundRepeat: `no-repeat`,
    },
  },

  [theme.breakpoints.up('xxl')]: {
    ':before': {
      right: `calc(50% + 660px - 12px)`,
    },

    ':after': {
      left: `calc(50% + 660px - 220px - 12px)`,
    },
  },
}));

const Container = styled(BSContainer, {
  shouldForwardProp: (prop) => prop !== 'purple',
})(({ theme, purple }) => ({
  position: 'relative',

  color: theme.palette.common.white,
  display: 'flex',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.up('xl')]: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 12,

      display: 'block',
      width: '100%',
      height: '100%',

      backgroundImage: `linear-gradient(110deg, ${
        purple ? '#83378B' : theme.palette.primary.main
      } 49%, transparent 49%)`,
      backgroundRepeat: `no-repeat`,
    },
  },
}));
