import { Box, styled } from '@mui/material';

export const SurveyStepRow = styled(Box)(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '32rem',
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '42rem',
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '49rem',
  },
}));
