import React from 'react';
import ShowMoreText from 'react-show-more-text';
import { Button, Card } from 'react-bootstrap';
import styles from './QICard.module.scss';

const CaseCard = (params) => {
  const caseInfo = params.caseInfo;
  return (
    <Card className={styles.CaseCard}>
      <Card.Img alt={`${caseInfo.title} logo`} variant='top' src={caseInfo.image} />
      <Card.Body>
        <Card.Title className={`bold ${styles.CaseTitle}`}>
          <div>{caseInfo.title}</div>
        </Card.Title>
        <ShowMoreText
          lines={3}
          more='Read more'
          less='Read less'
          className={styles.CardText}
          expanded={false}>
          <div
            dangerouslySetInnerHTML={{
              __html: `Organization At a Glance: ${caseInfo.organization}`,
            }}
          />
        </ShowMoreText>
        <Button
          aria-label={`Link to ${caseInfo.title}`}
          className='link-button primary arrow-right mb-5'
          variant='none'
          href={caseInfo.url}
          target='_blank'>
          View Case Study {caseInfo.size}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default CaseCard;
