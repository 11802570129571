import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import AhrqiLogo from '../../img/ahrqi-logo.png';
import { Link } from 'react-router-dom';
import MenuData from './menu';
import FooterLinks from './footerLinks';

const Footer = () => {
  const [menuData] = useState(MenuData);

  return (
    <>
      <div className='footer-1'>
        <div className='items-container'>
          <div className='flex flex-column'>
            <div>
              <Link to='/'>
                <img alt='Agency for Healthcare Research and Quality Logo Footer' src={AhrqiLogo} />
              </Link>
            </div>
            <div className='pt-5' style={{ margin: 'auto 0' }}>
              <h5>Technical Support:</h5>
              <a href='mailto: QIsupport@ahrq.hhs.gov'>QIsupport@ahrq.hhs.gov</a>
              <p className='subtitle'>(301) 427-1949</p>
            </div>
          </div>
          {menuData
            .filter((item) => item.submenu?.length > 0)
            .map((item, index) => (
              <div key={index}>
                <Link to={item.path}>
                  <h5 className='mb-0'>{item.title}</h5>
                </Link>
                {item.submenu.map((subItem, index) => {
                  return subItem.subtitle === 'Overview' ? null : (
                    <div key={`sub-${index}`} className='mt-3'>
                      <Link to={subItem.path}>{subItem.subtitle}</Link>
                    </div>
                  );
                })}
              </div>
            ))}
          <div>
            {menuData
              .filter((item) => item.submenu?.length === 0)
              .map((item, index) => (
                <Link key={index} to={item.path}>
                  <h5>{item.title}</h5>
                </Link>
              ))}
            <Link to='/home/about'>
              <h5 className='mt-7'>About</h5>
            </Link>
            <Link to='/news'>
              <h5 className='mt-7'>News</h5>
            </Link>
            <Link to='/home/contact'>
              <h5 className='mt-7'>Contact</h5>
            </Link>
          </div>
        </div>
      </div>
      <div className='footer-2'>
        <div className='items-container'>
          <div>
            <h4 className='mb-3 bolder'>Connect with Us</h4>
            <div className='social-links'>
              <Button
                aria-label='Facebook Icon'
                target='_blank'
                href='http://www.facebook.com/ahrq.gov'
                className='primary'
                variant='none'>
                <FontAwesomeIcon
                  alt='Facebook Icon'
                  aria-label='Facebook icon'
                  size='2x'
                  icon={faFacebookF}
                />
              </Button>
              <Button
                aria-label='Twitter Icon'
                target='_blank'
                href='https://twitter.com/ahrqnews'
                variant='none'>
                <FontAwesomeIcon
                  alt='Twitter Icon'
                  aria-label='Twitter icon'
                  size='2x'
                  icon={faTwitter}
                />
              </Button>
              <Button
                aria-label='YouTube Icon'
                target='_blank'
                href='http://www.youtube.com/user/AHRQHealthTV'
                variant='none'>
                <FontAwesomeIcon
                  alt='YouTube Icon'
                  aria-label='YouTube icon'
                  size='2x'
                  icon={faYoutube}
                />
              </Button>
              <Button
                aria-label='LinkedIn Icon'
                target='_blank'
                href='http://www.linkedin.com/company/agency-for-healthcare-research-and-quality'
                variant='none'>
                <FontAwesomeIcon
                  size='2x'
                  aria-label='LinkedIn icon'
                  alt='LinkedIn Icon'
                  icon={faLinkedinIn}
                />
              </Button>
            </div>
          </div>
          <div>
            <h4 className='mb-3 bolder'>Sign up for Email Updates</h4>
            <p className='text-regular'>
              To sign up for updates or to access your subscriber preferences, please enter your
              email address below.
            </p>
            <Form
              target='_blank'
              className='email-signup'
              id='GD-snippet-form'
              action='https://subscriptions.ahrq.gov/accounts/USAHRQ/subscribers/qualify'
              acceptCharset='UTF-8'
              method='post'>
              <div className='signup-form'>
                <Form.Group className='flex'>
                  <Form.Control
                    type='email'
                    name='email'
                    id='formBasicEmail'
                    placeholder='Email Address'
                    aria-label='enter email address'
                  />
                  <Form.Control type='hidden' name='utf8' id='signup-utf8' value='✓' />
                  <Form.Control
                    type='hidden'
                    name='authenticity_token'
                    id='signup-authenticityToken'
                    value='y9/sIPG+wf/QL20R4sTfazeOp4MyAOwD9ZszmZKWH0PZjGUt2JIEhyyE6dPCNgLP+WZWUcH1NjJVivzbrc8wmg=='
                  />
                </Form.Group>
              </div>
              <div className='signup-btn'>
                <Button
                  aria-label='Sign Up for Mailing List'
                  type='submit'
                  name='submit'
                  value='Sign Up'
                  variant='none'>
                  Sign Up
                </Button>
              </div>
            </Form>
          </div>
          <div>
            <h4 className='mb-3 bolder'>Agency for Healthcare Research and Quality</h4>
            <p className='text-regular m-0'>5600 Fishers Lane</p>
            <p className='text-regular m-0'>Rockville, MD 20857</p>
            <p className='text-regular m-0'>Telephone: (301) 427-1364</p>
          </div>
        </div>
      </div>
      <div className='footer-3'>
        <div className='items-container'>
          <div className='inner-items-container flex-grow'>
            {FooterLinks.left.map((leftItem, index) => (
              <div key={index}>
                <ul className='footer-ul'>
                  {leftItem.map((linkItem, index) => (
                    <li key={`link-${index}`}>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={linkItem.link}
                        aria-label={linkItem.label}
                        className='text-regular'>
                        {linkItem.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className='inner-items-container'>
            {FooterLinks.right.map((leftItem, index) => (
              <div key={index} className='footer-left-border'>
                <ul className='footer-ul'>
                  {leftItem.map((linkItem, index) => (
                    <li key={`link-${index}`}>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={linkItem.link}
                        aria-label={linkItem.label}
                        className='text-regular'>
                        {linkItem.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
