import {Box, styled} from '@mui/material';
import {clsx} from 'clsx';

export const LinkBox = ({children, className, ...rest}) => {
  return (
    <Container className={clsx('link-box', className)} {...rest}>
      {children}
    </Container>
  );
};

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.25),
}));
