import React, { useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { QITileCloudQI, VideoModal } from '../';
import CloudQIScreenshot from '../../img/CloudQIScreenshot.png';
import {
  Button,
  Flex,
  LinkBox,
  LinkItem,
  PageDisclaimer,
  PageRow,
  Rule,
  SoftwareList,
  SoftwareListItem,
} from '../../ui';
import { getReleaseItem } from '../../data/releases';
import { cloudqiPQEVideos as PQEVedios } from '../../data/videos';
import PeopleSayCloudQI from './PeopleSayCloudQI';

export const CloudQI = () => {
  const cloudQiRelease = getReleaseItem('CloudQi', 'CLOUDQI');
  const infoSheet = getReleaseItem('PsiInfoSheet', 'CLOUDQI');
  const releaseFaq = getReleaseItem('Icd10Faq');
  const releaseFaqMinor = getReleaseItem('Icd10FaqMinor');
  const releaseNotes = getReleaseItem('CloudQIReleaseNotes', 'CLOUDQI');
  const softwareInstructions = getReleaseItem('CloudQiSoftwareInstructions', 'CLOUDQI');
  const scrollToVideosRef = useRef(null);
  const [pqeVideoModalInfo, setPQEVideoModalInfo] = useState({ isOpen: false, url: '' });

  useEffect(() => {
    if (window.location.hash === '#PQE-videos') {
      setTimeout(() => {
        scrollToVideosRef.current.scrollIntoView();
      });
    }
  }, []);

  const Archive_CloudQi_v2024_0_0 = getReleaseItem('CloudQi', 'CLOUDQI', 'v2024.0.0');
  const Archive_CloudQi_v2023_0_1 = getReleaseItem('CloudQi', 'CLOUDQI', 'v2023.0.1');

  const archivedData = [
    {
      title: Archive_CloudQi_v2024_0_0.title,
      desc: Archive_CloudQi_v2024_0_0.desc,
      date: Archive_CloudQi_v2024_0_0.revision,
      url: Archive_CloudQi_v2024_0_0.url,
      aria: Archive_CloudQi_v2024_0_0.aria,
      cta: Archive_CloudQi_v2024_0_0.cta,
    },
    {
      title: Archive_CloudQi_v2023_0_1.title,
      desc: Archive_CloudQi_v2023_0_1.desc,
      date: Archive_CloudQi_v2023_0_1.revision,
      url: Archive_CloudQi_v2023_0_1.url,
      aria: Archive_CloudQi_v2023_0_1.aria,
      cta: Archive_CloudQi_v2023_0_1.cta,
    },
  ];

  return (
    <>
      <PageRow className='cloud-qi-intro-row'>
        <Flex cols='lg' gap={{ xs: 4, lg: 10 }}>
          <Flex alignItems='flex-start' gap={3} marginBottom={{ xs: 4, lg: 0 }}>
            <div>
              <h1>CloudQI</h1>
              <span className='sub-tag subtitleBold'>
                For use on your local desktop or a server hosted on your private/public cloud
              </span>
              <p className='text-xl'>
                Multi-version, multi-user support, remote access, and ease-of-use for users
                preferring to run PSI, PQE and MHI using GUI
              </p>
              <p>
                AHRQ's latest technology platform, CloudQI, is positioned as the future platform of
                AHRQ QI Windows software tools. For v2024, CloudQI PSI Beta and ED PQI Beta are now
                integrated as a unified product called v2024 CloudQI. This integrated software,
                includes the PSI and MHI modules and what is now referred to as the Prevention
                Quality Indicators in Emergency Department Settings (PQE) module. Additional AHRQ QI
                modules, such as IQI, PQI, and PDI will be integrated in phases.
              </p>
              <p>
                <strong>
                  The software now includes the MHI <sup className='highlightCaps'>BETA</sup> module
                  - <span className='new'>New!</span>
                </strong>
              </p>
              <Button href={cloudQiRelease.url} aria-label={cloudQiRelease.aria} arrow>
                {cloudQiRelease.cta}
              </Button>
            </div>

            <Rule spacing={1} />

            <img
              className='mx-auto'
              src={CloudQIScreenshot}
              alt='CloudQI Screenshot'
              style={{ width: '100%' }}
            />
          </Flex>
          <Box bgcolor='grey.50' p={2.5} width={{ lg: 320 }} margin={{ xs: '-1.25rem', lg: 0 }}>
            <div>
              <h6>Software v2024.0.1 Improvements</h6>
              <ul>
                <li>Introduces the MHI module and other improvements</li>
                <li>Integrates the PSI, PQE and MHI modules </li>
                <li>
                  Allows single user mode on a local desktop or multi-user mode on private/public
                  cloud
                </li>
                <li>Provides remote access to the application via a browser</li>
                <li>Capability to run multiple versions of the QIs</li>
                <li>Access to previously uploaded files and reports</li>
                <li>
                  Collects telemetry information for software improvement purposes, with an option
                  to opt-out
                </li>
                <li>Allows users to submit feedback directly within the application</li>
              </ul>
            </div>
            <div>
              <h6>Release Notes</h6>
              <ul>
                <li>v2024.0.1, September 2024 - CloudQI</li>
              </ul>
              <LinkBox>
                <LinkItem
                  href={releaseNotes.url}
                  meta={releaseNotes.info}
                  leftBorder
                  light
                  noicon
                  noclip
                >
                  {releaseNotes.version} Release Notes
                </LinkItem>
                <LinkItem
                  href={releaseFaqMinor.url}
                  meta={releaseFaqMinor.info}
                  leftBorder
                  light
                  noicon
                  noclip
                >
                  {releaseFaqMinor.version}
                  {releaseFaqMinor.minorVersion} Software Release FAQ
                </LinkItem>
                <LinkItem
                  href={releaseFaq.url}
                  meta={releaseFaq.info}
                  leftBorder
                  light
                  noicon
                  noclip
                >
                  {releaseFaq.version} Software Release FAQ
                </LinkItem>
                <LinkItem href={infoSheet.url} meta={infoSheet.info} leftBorder light noicon noclip>
                  {infoSheet.version} Information Sheet
                </LinkItem>
              </LinkBox>
            </div>
          </Box>
        </Flex>

        <Rule spacing={5} sx={{ display: { xs: 'none', lg: 'block' } }} />

        <h2>Installation & Getting Started</h2>
        <Flex gap={3}>
          <div>
            <p>
              To learn more about CloudQI installation and to get started, download the software
              instructions below.
            </p>
            <p>
              The software instructions for v2024.0.1 are slightly modified from v2024 to include
              the Maternal Health Indicators (<strong>MHI</strong>)<sup>BETA</sup> module. For
              simplicity, the term "v2024" is retained throughout these instructions.
            </p>
            <LinkItem
              href={softwareInstructions.url}
              meta={`${softwareInstructions.revision} ${softwareInstructions.info}`}
              light
            >
              {softwareInstructions.title}
            </LinkItem>
          </div>
        </Flex>
      </PageRow>

      <PageRow className='getting-started-row' lightGrey>
        <h2>Videos about PQE</h2>
        <Flex gap={3}>
          <div>
            <p>
              ED PQI is now PQE. The videos below reference version 2023, but remain relevant for
              version 2024.
            </p>
          </div>
          <Flex
            gap={3}
            pb={3}
            flexDirection={'row'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {Object.keys(PQEVedios).map((pqeVideoKey) => {
              const video = PQEVedios[pqeVideoKey];
              return (
                <Flex
                  key={video.id}
                  flex='unset'
                  alignItems='center'
                  alignSelf='flex-start'
                  gap={3}
                  width={{ xs: '45%', md: '30%' }}
                >
                  <button
                    onClick={() => setPQEVideoModalInfo({ isOpen: true, url: video.url })}
                    className='video-thumbnail'
                  >
                    {video.thumb !== undefined && (
                      <img alt='Video Thumbnail' src={video.thumb} width='100%' />
                    )}
                  </button>
                  <p className='text-center'>
                    <a aria-label={video.label} target='_blank' rel='noreferrer' href={video.url}>
                      {video.text}
                    </a>
                    <a href='/ExternalDisclaimer'>
                      <img
                        alt='external web link policy'
                        className='ml-1'
                        src='/images/exit_disclaimer.png'
                      />
                    </a>
                  </p>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </PageRow>

      <PageRow babyBlue>
        <PeopleSayCloudQI />
      </PageRow>

      <PageRow className='tech-specs-row'>
        <Box px={{ lg: 16 }} textAlign='center'>
          <h2>Technical Specifications</h2>
          <p>
            Technical Specifications break down calculations used to formulate each indicator,
            including a brief description of the measure, numerator and denominator information, and
            details on cases that should be excluded from calculations. CloudQI includes PSI, PQE
            and MHI modules.
          </p>
        </Box>
        <QITileCloudQI isTechSpecs />
        <div className='align-center'>
          <Button
            aria-label='View all measures'
            label='View All Measures'
            href='/measures/all_measures'
            variant='outlined'
            arrow
          />
        </div>
      </PageRow>

      <PageRow className='archived-software-row' lightGrey>
        <SoftwareList
          title='Archived Software'
          subtitle='Archived software for CloudQI'
          viewAllUrl='/archive/software?tag=CloudQI-Software'
          paper
        >
          {archivedData.map((info) => (
            <SoftwareListItem
              key={info.url}
              title={info.title}
              subtitle={info.date}
              url={info.url}
              label={info.cta}
              ariaLabel={info.aria}
            />
          ))}
        </SoftwareList>
      </PageRow>

      <PageRow className='disclaimer-row' lightGrey>
        <PageDisclaimer
          resource={`AHRQ CloudQI v2024 Software Download for AHRQ Quality Indicators`}
          software
        />
      </PageRow>
      <VideoModal
        isOpen={pqeVideoModalInfo?.isOpen ?? false}
        onClose={() => setPQEVideoModalInfo(null)}
        url={pqeVideoModalInfo?.url}
      />
    </>
  );
};
