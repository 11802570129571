import React from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const SASQIDesc = () => {
    return (<>

        <Container className='outerContainer'>
            <h1 className="mb-4">ICD-10 Software (Alpha version)®, Version 4.5a</h1>
        </Container>

        <Container className="outerContainer">
            <p>The Alpha ICD-10-CM QI Software is in the first stages of development. It is intended as an initial look for users wishing to begin preliminary preparations for applying the AHRQ QI software to ICD-10 data, but definitions of the QI and structure of the software may change in future versions. The v5.0 alpha software will be replaced by v6.0 software when it is released in March, 2016.</p>
            <p>This alpha version of software includes three programs per QI module (CONTROL.SAS, FORMAT and SAS1). These will produce observed rates only for the QIs using ICD-10 data. At this time no national data are available to estimate reliability or risk adjustment coefficients for ICD-10 based AHRQ QIs. As a result, risk adjusted and smoothed rates are unavailable. Some sections of the programs which implement parts of the risk adjustment and smoothing protocols are retained for implementation in future versions as data become available.</p>
            <p>The software may be run using the same procedures as the current Version 5.0 of the AHRQ QI software. Instructions for applying the QI software to your data can be found in the <a href="https://www.qualityindicators.ahrq.gov/Downloads/Software/SAS/V50/Software_Instructions_SAS_Version50.pdf">Version 5.0 Software Instructions</a> document.</p>
            <p>The alpha software includes enhanced specifications that take advantage of features of ICD-10 that were not available in ICD-9-CM. The ICD-10 specifications are not expected to map directly to the ICD-9-CM specifications, but are intended to best capture the intended purpose of the indicator. The software does not map each code, but rather map code groups (e.g. hemorrhage, asthma) from ICD-9-CM code groups to ICD-10 code groups. More details about the translation of the Qis to ICD-10 are available at <a href="https://www.qualityindicators.ahrq.gov/Downloads/Resources/Publications/2013/C.14.10.D001_REVISED.pdf">AHRQ ICD-10-CM/PCS Conversion Project, Nov 2013.</a></p>
        </Container>
        <Container className="outerContainer">
            <h3 className="mt-5">Software Instructions</h3>
            <p><a href="https://www.qualityindicators.ahrq.gov/Downloads/Software/ICD_10_Alpha/QI_ICD_10_Alpha_Software_Notes.docx">Software Tips</a> Alpha Version, March 2015 (MS Word, 14 mb)</p>
        </Container>
        <Container className="outerContainer">
            <h3 className="mt-5">Technical Specifications</h3>
            <p>The draft technical coding specifications containing ICD-9-CM codes mapped to corresponding ICD-10-CM/PCS codes used in the Alpha version of software can be found <Link to="/software">here.</Link></p>
            <p>Note that the technical specifications are not the final version, and are not intended to be a definitive representation of the measures.</p>
            <p>This page was originally created to accept public comments on the conversion process and can be used to get informed on the ICD-9-CM and ICD- 10-CM/PCS mapping. The information contained on this page is being used as a basis for creating the final version of the technical specifications, but should not be used in place of those final technical specifications.</p>
        </Container>
        <Container className="outerContainer">
            <h3 className="mt-5">ICD-10 Software (Alpha version) - All Modules</h3>
            <p><a href="https://www.qualityindicators.ahrq.gov/Downloads/Software/ICD_10_Alpha/ICD_10E_Alpha.zip">ICD-10 Software</a> Alpha Version, March 2015 (zip, 1.58 mb)</p>
        </Container>
    </>)
}

export default SASQIDesc;