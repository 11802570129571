import React from 'react';
import { Box, Button, styled } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMediaQuery } from 'react-responsive';
import { SplitRow, SplitRowColumn } from '../../../ui';

export const Newsletter = ({ purple }) => {
  const isLessThanXL = useMediaQuery({ query: `(max-width: 1199px)` });

  return (
    <SplitRow purple={purple}>
      <SplitRowColumn>
        <Title>Stay Up To Date</Title>
        <Lead>
          Sign up to receive announcements and updates on the newest quality indicators and AHRQ
          software.
        </Lead>
      </SplitRowColumn>
      <SplitRowColumn right>
        <div>
          <Button
            href='https://subscriptions.ahrq.gov/accounts/USAHRQ/subscriber/new?topic_id=USAHRQ_39'
            aria-label='Sign up for announcements'
            target='_blank'
            rel='noreferrer'
            variant='outlined'
            color={isLessThanXL ? 'white' : 'primary'}
            size='large'
            endIcon={<ChevronRightIcon />}>
            Sign up for the newsletter
          </Button>
        </div>
      </SplitRowColumn>
    </SplitRow>
  );
};

// include background color for usability contrast tests

const Title = styled('h1')(({ theme }) => ({
  backgroundColor: '#005B94',
}));

const Lead = styled(Box)(({ theme }) => ({
  backgroundColor: '#005B94',
  fontSize: 21,
  textAlign: 'center',

  [theme.breakpoints.up('xl')]: {
    textAlign: 'left',
  },
}));
