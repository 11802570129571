import React, { useEffect } from 'react'
import FAQsMain from '../../components/FAQs/FAQsMain';

const FAQs = () => {
  useEffect(() => {
    document.title = `AHRQ - Quality Indicators Frequently Asked Questions`;
  });
  return (<FAQsMain />)
}

export default FAQs;