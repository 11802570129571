import React from 'react';
import { Container } from 'react-bootstrap';
import CodeLogTable from './CodeLogTable';
import { codelogData } from '../../../data/codelog';

const CodeLog = (props) => {
  const module = props.module.toLowerCase();
  const version = props.version.toLowerCase();
  return (
    <Container className='outer-container'>
      <h1 className='align-center'>{module.toUpperCase()} Log of Coding Updates and Revisions</h1>
      <p className='text-regular'>
        This page provides information on the revisions made to the {module.toUpperCase()} software,
        coding, software documentation, and technical specifications in Version{' '}
        {codelogData[module][version].title}.
      </p>
      <CodeLogTable items={codelogData[module][version].items} />
    </Container>
  );
};

export default CodeLog;
