export const modulesMetadata = {
  'current-version': 'icd10_v2024',
  metadata: {
    icd10_v2024: { name: 'Version 2024', appendixName: 'v2024' },
    icd10_v2023: { name: 'Version 2023', appendixName: 'v2023' },
    icd10_v2022: { name: 'Version 2022', appendixName: 'v2022' },
    icd10_v2021: { name: 'Version 2021', appendixName: 'v2021' },
    icd10_v2020: { name: 'Version 2020', appendixName: 'v2020' },
    icd10_v2019: { name: 'Version 2019', appendixName: 'v2019' },
    icd10_v2018: { name: 'Version 2018', appendixName: 'v2018' },
    icd10_v70: { name: 'v7.0', appendixName: 'v7.0' },
    icd10_v60: { name: 'v6.0 (ICD-10)', appendixName: 'v6.0 (ICD-10)' },
    icd9_v60: { name: 'v6.0 (ICD-9)', appendixName: 'v6.0 (ICD-9)' },
    icd10_v50: { name: 'v5.0 (ICD-10)', appendixName: 'v5.0 (ICD-10)' },
    icd9_v50: { name: 'v5.0 (ICD-9)', appendixName: 'v5.0 (ICD-9)' },
    v45: { name: 'v4.5', appendixName: 'v4.5' },
    v45a: { name: 'v4.5a', appendixName: 'v4.5a' },
    v44: { name: 'v4.4', appendixName: 'v4.4' },
    v43: { name: 'v4.3', appendixName: 'v4.3' },
    v43a: { name: 'v4.3a', appendixName: 'v4.3a' },
    v42: { name: 'v4.2', appendixName: 'v4.2' },
    v41: { name: 'v4.1', appendixName: 'v4.1' },
  },
};
