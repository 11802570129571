import { getReleaseItem } from '../releases';

export const pqiMeasuresData = {
  technical: [
    {
      ...getReleaseItem('IndividualMeasureTechnicalSpecifications', 'PQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('ParameterEstimates', 'PQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('BenchmarkDataTables', 'PQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('LogOfCodingUpdates', 'PQI'),
      status: 'new',
    },
    {
      ...getReleaseItem('CompositeMeasures', 'PQI'),
      status: 'new',
    },
  ],
  additional: [
    {
      title: 'AHRQ Composite Measures Workgroup',
      desc: "<p>This report summarizes the work of the PQI Composite Measure Workgroup, which assisted AHRQ in developing a composite measure based on the Prevention Quality Indicators in Inpatient Settings. For more information on Composite Workgroups, visit the <a href='../measures/composite_workgroup'>AHRQ QI Composite Workgroups page.</a></p>",
      url: '/Downloads/Modules/PQI/PQI_Composite_Development.pdf',
      aria: 'Download PQI_Composite_Development.pdf',
      cta: 'Download (PDF File, 3.48 MB)',
    },
    {
      title: 'Expanding the Use of the AHRQ PQI',
      desc: '<p>Summarizes a study aimed at exploring expanded use of the AHRQ PQI in new patient populations for comparative reporting and for pay-for-performance initiatives the PQIs in these expanded applications.</p>',
      url: '/Downloads/Modules/PQI/PQI_Summary_Report.pdf',
      aria: 'Download PQI_Summary_Report.pdf',
      cta: 'Download (PDF File, 665 KB)',
    },

    {
      title: 'AHRQ PQI Development',
      desc: '<p>How the PQI measure set was developed.</p>',
      url: '/Downloads/Modules/PQI/pqi_development.zip',
      aria: 'Download pqi_development.zip',
      cta: 'Download (ZIP File, 771 KB)',
    },
  ],
  // specifications: [
  //   {
  //     title: 'PQI 01 Diabetes Short-term Complications Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
  //     aria: 'Download PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 03 Diabetes Long-term Complications Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
  //     aria: 'Download PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
  //   },

  //   {
  //     title:
  //       'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
  //     aria: 'Download PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 07 Hypertension Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
  //     aria: 'Download PQI_07_Hypertension_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 08 Heart Failure Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
  //     aria: 'Download PQI_08_Heart_Failure_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 11 Community Acquired Pneumonia Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_11_Community_Acquired%20_Pneumonia_Admission_Rate.pdf',
  //     aria: 'Download PQI_11_Community_Acquired%20_Pneumonia_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 12 Urinary Tract Infection Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
  //     aria: 'Download PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 14 Uncontrolled Diabetes Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
  //     aria: 'Download PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 15 Asthma in Younger Adults Admission Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
  //     aria: 'Download PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
  //     aria: 'Download PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
  //   },
  //   {
  //     title: 'PQI 90 Prevention Quality Overall Composite',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
  //     aria: 'Download PQI_90_Prevention_Quality_Overall_Composite.pdf',
  //   },
  //   {
  //     title: 'PQI 91 Prevention Quality Acute Composite',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
  //     aria: 'Download PQI_91_Prevention_Quality_Acute_Composite.pdf',
  //   },
  //   {
  //     title: 'PQI 92 Prevention Quality Chronic Composite',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
  //     aria: 'Download PQI_92_Prevention_Quality_Chronic_Composite.pdf',
  //   },
  //   {
  //     title: 'PQI 93 Prevention Quality Diabetes Composite',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
  //     aria: 'Download PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
  //   },
  //   {
  //     title: 'PQI Appendix A - Admission Codes for Transfers',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_Appendix_A.pdf',
  //     aria: 'Download PQI_Appendix_A.pdf',
  //   },
  //   {
  //     title: 'PQI Appendix B - Cardiac Procedure Codes',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_Appendix_B.pdf',
  //     aria: 'Download PQI_Appendix_B.pdf',
  //   },
  //   {
  //     title: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
  //     url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_Appendix_C.pdf',
  //     aria: 'Download PQI_Appendix_C.pdf',
  //   },
  // ],
  // retired: [
  //   {
  //     desc: "<p>Effective v2019, the following indicators have been retired. For additional information, refer to the retirement announcements: <a href='/News/Retirement%20Notice_v2019_Indicators.pdf'>Indicators Retirement Announcement</a>.</p>",
  //     title: [
  //       {
  //         titledesc: 'PQI 02 Perforated Appendix Admission Rate',
  //       },
  //       {
  //         titledesc: 'PQI 09 Low Birth Weight Rate',
  //       },
  //       {
  //         titledesc: 'PQI 10 Dehydration Admission Rate',
  //       },
  //     ],
  //   },
  //   {
  //     desc: '<p>Effective v6.0, the following indicators have been retired.</p>',
  //     title: [
  //       {
  //         titledesc: 'PQI 13 Angina Without Procedure Admission Rate is retired in version 6.0',
  //       },
  //     ],
  //   },
  // ],
};
