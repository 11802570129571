import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PSITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Patient Safety Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Patient Safety Indicators Technical Specifications Updates - Version v2020, July 2020
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Patient Safety Indicators Technical Specifications (PDF Format), Version 2020
              (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI_2020_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/PSI/V2020/TechSpecs/PSI_2020_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 23.1 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'psi'} version={'icd10_v2020'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Updates</h3>
        <p>
          <span className='danger'>March 2021</span>: Minor updates were made in some v2020
          technical specifications to clarify the indicator logic. Language was updated to better
          align with the logic implemented in the AHRQ QI software v2020. These minor updates are
          limited to the technical specifications documents, and no changes where made in the v2020
          software.
        </p>
        <p>
          Indicators updated: PSI 02, PSI 03, PSI 05, PSI 06, PSI 07, PSI 08, PSI 09, PSI 11, PSI
          12, PSI 14, PSI 15, PSI 17
        </p>
      </Container>
      <Container className='outerContainer mt-5'>
        <h3>Retired Indicators</h3>
        <p>
          <span className='danger'>Effective v2019</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/Retirement%20Notice_v2019_Indicators.pdf'>
            Indicators Retirement Announcement.
          </a>{' '}
          (PDF File, 108 KB)
        </p>
        <ul>
          <li>PSI 16 Transfusion Reaction Count</li>
        </ul>
        <p>
          <span className='danger'>Effective v7.0</span>, the following indicators have been
          retired. For additional information, refer to the retirement announcements:{' '}
          <a href='/News/PSI_Retirement_Notice.pdf'>PSI Area Level Hospital</a> (PDF File, 349 KB).
        </p>
        <ul>
          <li>PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate</li>
          <li>PSI 22 Iatrogenic Pneumothorax Rate</li>
          <li>PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate</li>
          <li>PSI 24 Postoperative Wound Dehiscence Rate</li>
          <li>PSI 25 Accidental Puncture or Laceration Rate</li>
          <li>PSI 26 Transfusion Reaction Rate</li>
          <li>PSI 27 Perioperative Hemorrhage or Hematoma Rate</li>
        </ul>
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Patient Safety Indicators Technical Specifications for SAS and WinQI ICD-10 version
            v2019 can be found <Link to='/archive/qi_modules?category=psi'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PSITechnical;
