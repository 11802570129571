const PQI_ITEMS = [
  {
    name: 'TechSpecs',
    title: 'Prevention Quality Indicators Technical Specifications',
    filename: 'PQI_2023_ICD10_techspecs_pdf.zip',
    path: '/Downloads/Modules/PQI/V2023/TechSpecs/',
    size: '3.00 MB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    dateModified: '2023-08-11',
  },
  {
    name: 'TechSpecsExcel',
    title: 'Prevention Quality Indicators Technical Specifications (Excel Format)',
    filename: 'PQI_2023_ICD10_techspecs_excel.zip',
    path: '/Downloads/Modules/PQI/V2023/TechSpecs/',
    size: '1.46 MB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    dateModified: '2023-08-11',
  },
  {
    name: 'ParameterEstimates',
    title: 'Parameter Estimates for v2023',
    desc: 'Tables of PQI covariates and coefficients for risk adjustment logistic regression models.',
    filename: 'Parameter_Estimates_PQI_v2023.pdf',
    path: '/Downloads/Modules/PQI/V2023/',
    size: '3.13 MB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'BenchmarkDataTables',
    title: 'Benchmark Data Tables for v2023',
    desc: 'Tables of nationwide comparative rates for PQIs including observed rate, numerator, and denominator data for each indicator overall and stratified by sex and age group.',
    filename: 'Version_2023_Benchmark_Tables_PQI.pdf',
    path: '/Downloads/Modules/PQI/V2023/',
    size: '682 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'CompositeMeasures',
    title:
      'Quality Indicator User Guide: Prevention Quality Indicators (PQI) Composite Measures, August 2023',
    desc: 'Document providing an overview of the composite measures, including how they are created and maintained and other supporting resources.',
    filename: 'PQI_Composite_Measures.pdf',
    path: '/Downloads/Modules/PQI/V2023/',
    size: '137 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'LogOfUpdatesRevisions',
    title: 'Log of Updates and Revisions for v2023',
    desc: 'This document contains tables summarizing revisions made to the PQI software, software documentation and technical specifications since the original release of these documents in November 2001.',
    filename: 'ChangeLog_PQI_v2023.pdf',
    path: '/Downloads/Modules/PQI/V2023/',
    size: '408 KB',
    format: 'PDF',
    dateModified: '2023-08-11',
  },
  {
    name: 'CodingRevisionsExcel',
    title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
    desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
    filename: 'AHRQ_PQI_v2023_Code_Set_Changes.xlsx',
    path: '/Downloads/Modules/PQI/V2023/',
    size: '742 KB',
    format: 'Excel',
    dateModified: '2023-08-11',
  },

  // LINKS
  {
    name: 'IndividualMeasureTechnicalSpecifications',
    title: 'Individual Measure Technical Specifications (v2023 coding)',
    desc: 'Breaks down calculations used to formulate each PQI, including a brief description of the measure, numerator and denominator information, and details on cases that should be excluded from calculations.',
    url: '/measures/PQI_TechSpec',
    aria: 'View PQI Technical Specifications',
    cta: 'Learn More',
  },
  {
    name: 'LogOfCodingUpdates',
    title: 'Log of Coding Updates and Revisions for v2023',
    desc: 'Documents summarizing revisions made to the PQI software, software documentation and technical specifications since the original release of these documents in November 2001.',
    url: '/measures/pqi_log_coding_updates/v2023',
    aria: 'View Log Coding Updates PQI v2023',
    cta: 'Learn More',
  },
];

export default PQI_ITEMS;
