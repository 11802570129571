import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PSITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Patient Safety Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Patient Safety Indicators Technical Specifications Updates - Version 6.0 (ICD-9), July
          2017
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              Updated Patient Safety Indicators Technical Specifications (PDF Format), Version 6.0
              (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download PSI_60_ICD09_techspecs_Provider_pdf.zip'
              href='/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_60_ICD09_techspecs_Provider_pdf.zip'
            >
              Download All (ZIP File, 12.4 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'psi'} version={'icd9_v60'} />
      </Container>
      <Container className='outerContainer mt-5'>
        <div className='p-3 light-container'>
          <p className='mb-0'>
            Patient Safety Indicators Technical Specifications for SAS and WinQI ICD-10 alpha
            version v5.0 can be found <Link to='/archive'>here</Link> .
          </p>
        </div>
      </Container>
    </>
  );
};

export default PSITechnical;
