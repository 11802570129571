import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PDITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>Pediatric Quality Indicators Technical Specifications</h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Pediatric Quality Indicators Technical Specifications, Version 4.2, September 2010
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              All Pediatric Quality Indicators Technical Specifications, Version 4.2 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download pdi_nqi_technical_specifications_fy10_version42.zip'
              href='/Downloads/Modules/PDI/V42/TechSpecs/pdi_nqi_technical_specifications_fy10_version42.zip'
            >
              Download All (ZIP File, 2.4 MB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pdi'} version={'v42'} />
      </Container>
    </>
  );
};

export default PDITechnical;
