import { faqsData } from '../../data/faqs';
import { Container } from 'react-bootstrap';
import SearchableAccordion from '../SearchableAccordion/SearchableAccordion';
import TopBanner from '../Common/TopBanner/TopBanner';
import { Newsletter } from '../Common';

const FAQsMain = () => {
  const convertToAccordionFormat = () => {
    let result = { 'default-faqs': 'SoftwareReleases-2024', ...faqsData };

    // add most popular
    const mostPopular = {
      topic: 'Most Popular FAQs',
      children: [],
      groupInfo: undefined,
    };
    for (const key of Object.keys(faqsData['faqs'])) {
      for (const item of faqsData['faqs'][key].children) {
        if (item.isMostPopular) {
          var updatedItem = { ...item, subTopic: faqsData['faqs'][key].topic };
          mostPopular.children.push(updatedItem);
        }
      }
    }

    result.faqs = {
      mostpopular: {
        ...mostPopular,
        children: mostPopular.children.sort((a, b) => a.mostPopularSeq - b.mostPopularSeq),
      },
      ...result.faqs,
    };

    return result;
  };

  const originalData = convertToAccordionFormat();

  return (
    <>
      <TopBanner>
        <h1>Frequently Asked Questions</h1>
        <p>
          The Agency for Healthcare Research and Quality (AHRQ) Quality Indicators (QIs) are
          measures that organizations can apply to inpatient hospital data to assess and improve
          health care quality, identify areas that need further study and investigation, and track
          changes over time. The AHRQ QI modules represent aspects such as Prevention, Inpatient,
          Patient Safety, and Pediatric quality of care.
        </p>
      </TopBanner>
      <SearchableAccordion data={originalData} section={'faqs'} />
      <hr />
      <Container className='outer-container'>
        <h3>Need technical assistance?</h3>
        <p>
          The support e-mail address for the AHRQ Quality Indicators is
          <a href='mailto: QIsupport@ahrq.hhs.gov'> QIsupport@ahrq.hhs.gov</a>. The AHRQ Quality
          Indicators support team can also be reached by phone in the USA at (301) 427-1949.
          Messages are responded to within three business days.
        </p>
      </Container>
      <Newsletter purple />
    </>
  );
};

export default FAQsMain;
