import LATEST_ITEMS, { VERSION, REVISION } from './v2024';
import V2023_ITEMS from './v2023';
import V2022_ITEMS from './v2022';

const ITEMS = [...LATEST_ITEMS, ...V2023_ITEMS, ...V2022_ITEMS];

export const getReleaseItem = (name, group, version = 'latest') => {
  const groupValue = group !== undefined ? group.toLowerCase() : group;
  const result =
    version === 'latest'
      ? LATEST_ITEMS.find((item) => item.name === name && item.group === groupValue)
      : ITEMS.find(
          (item) => item.version === version && item.name === name && item.group === groupValue
        );
  if (result === undefined)
    throw new Error(
      `Release item not found in releases data. (Type: ${name}, Group: ${group}, Version: ${version})`
    );
  return result;
};

export { ITEMS as releaseItems, REVISION as releaseRevision, VERSION as releaseVersion };

// const releaseFiles = ITEMS.filter((item) => item.filename !== undefined).sort((a, b) => {
//   if (a.path === b.path) return a.filename.localeCompare(b.filename);
//   return a.path.localeCompare(b.path);
// });

// console.table(releaseFiles, ['path', 'filename', 'name', 'size', 'dateModified']);
