import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from '../Measures/Measures.module.scss';
import { Flex, PageHeader, PageRow } from '../../ui';
import { LatestNews } from '../Home/Sections';

const Announcements202410 = () => {
  useEffect(() => {
    var hashValue = window.location.hash?.replace('#', '');
    if (hashValue) {
      const el = document.getElementById(hashValue);
      if (el) {
        setTimeout(function () {
          requestAnimationFrame(() => el.scrollIntoView());
        });
      }
    }
  });

  return (
    <>
      <div className={styles.Announcements}>
        <PageHeader.Row lightGrey bgSrc='/images/hex-grey.jpg'>
          <PageHeader.Title>Announcements</PageHeader.Title>
          <PageHeader.Content>
            <h4>October 2024</h4>
          </PageHeader.Content>
        </PageHeader.Row>
        <PageRow fontSize={18}>
          <Flex>
            <h3>AHRQ Seeking Members for Maternal Health Indicators Workgroup</h3>
            <p>
              AHRQ's Quality Indicators (QI) Program is recruiting clinical experts and
              patient/family advocates to serve on a Maternal Health Indicators (MHI) Expert Work
              Group (EWG). Members will provide feedback on potential new measures of maternal
              health. MHI EWG members will participate in two virtual expert work group meetings in
              the next year. Please indicate your interest by email with the subject line “MHI EWG”
              to{' '}
              <a
                href='mailto:hqi_tech_support@mathematica-mpr.com?subject=MHI EWG'
                target='_blank'
                rel='noreferrer'
              >
                hqi_tech_support@mathematica-mpr.com
              </a>{' '}
              by November 8.
            </p>
            <p>
              To learn more about the Maternal Health Indicators, visit{' '}
              <a href='/measures/mhi_resources'>MHI Resources</a>.
            </p>
          </Flex>
        </PageRow>
        <PageRow py={0}>
          <Typography variant='h5' color='primary' textAlign='center'>
            <Link to='/archive/news?category=announcements'>
              See previous announcements <ChevronRightIcon />
            </Link>
          </Typography>
        </PageRow>
        <LatestNews />
      </div>
    </>
  );
};

export default Announcements202410;
