import { Box, styled } from '@mui/material';
import { clsx } from 'clsx';

export const PageRow = ({ children, container = true, ...rest }) => {
  return (
    <Row {...rest}>
      <div className={clsx('page-row__container', container && 'container')}>{children}</div>
    </Row>
  );
};

const Row = styled(Box, {
  shouldForwardProp: (prop) =>
    !['bgSrc', 'bgSrcNone', 'lightBlue', 'lightGrey', 'purple', 'babyBlue'].includes(prop),
})(({ theme, bgSrc, bgSrcNone, lightBlue, lightGrey, purple,babyBlue, ...rest }) => ({
  backgroundColor: 'transparent',
  backgroundImage: bgSrc ? `url('${bgSrc}')` : 'none',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: theme.palette.text.primary,
  minWidth: 320,

  ...(lightBlue && {
    backgroundColor: theme.palette.fill.lightBlue,
  }),
  ...(babyBlue && {
    backgroundColor: theme.palette.fill.babyBlue,
  }),

  ...(lightGrey && {
    backgroundColor: theme.palette.fill.lightGrey,
  }),

  ...(purple && {
    backgroundColor: theme.palette.fill.purple,
    color: theme.palette.common.white,

    'a:not(.MuiButton-root)': {
      color: 'inherit',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),

  ...(bgSrcNone !== undefined && {
    [theme.breakpoints.down(bgSrcNone)]: {
      backgroundImage: 'none',
    },
  }),
}));
Row.defaultProps = {
  className: 'page-row',
  py: 6,
};
