const ED_PQI_ITEMS = [
  {
    name: 'TechSpecs',
    title: 'Emergency Department Prevention Quality Indicator (PQE) Technical Specifications',
    filename: 'ED_PQI_2023_ICD10_techspecs_pdf.zip',
    path: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/',
    size: '2.97 MB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    revision: 'September 2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'TechSpecsExcel',
    title:
      'Emergency Department Prevention Quality Indicator (PQE) Technical Specifications (Excel Format)',
    filename: 'ED_PQI_2023_ICD10_techspecs_excel.zip',
    path: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/',
    size: '446 KB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    revision: 'September 2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'ParameterEstimates',
    title: 'Parameter Estimates for v2023',
    desc: 'Tables of Emergency Department Prevention Quality Indicator (PQE) covariates and coefficients for risk adjustment logistic regression models. ',
    filename: 'Parameter_Estimates_ED_PQI_v2023.pdf',
    path: '/Downloads/Modules/ED_PQI/V2023/',
    size: '441 KB',
    format: 'PDF',
    revision: 'September 2023',
    dateModified: '2023-08-11',
  },
  {
    name: 'BenchmarkDataTables',
    title: 'Benchmark Data Tables for v2023 ICD-10-CM/PCS',
    desc: 'Tables of nationwide comparative rates for each Emergency Department Prevention Quality Indicator (PQE) including observed rate, numerator, and denominator data for each indicator overall and stratified by sex and age group.',
    filename: 'Version_2023_Benchmark_Tables_ED_PQI.pdf',
    path: '/Downloads/Modules/ED_PQI/V2023/',
    size: '553 KB',
    format: 'PDF',
    revision: 'September 2023',
    dateModified: '2023-08-11',
  },

  // NEWS
  {
    name: 'EdPqiFaq',
    title: 'v2023 ED PQI software release FAQ document',
    filename: 'ICD10_v2023_FAQ_PQE.pdf',
    path: '/News/',
    size: '244 KB',
    format: 'PDF',
    revision: 'September 2023',
    dateModified: '2023-09-08',
  },

  // LINKS
  {
    name: 'IndividualMeasureTechnicalSpecifications',
    title: 'Individual Measure Technical Specifications (v2023 coding)',
    desc: 'Breaks down calculations used to formulate each Emergency Department Prevention Quality Indicator (PQE), including a brief description of the measures, numerator and denominator information, and details on cases that should be excluded from calculations.',
    url: '/measures/ED_PQI_TechSpec',
    aria: 'View PQE Technical Specifications',
    cta: 'Learn More',
  },
];

export default ED_PQI_ITEMS;
