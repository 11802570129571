import React, { useEffect } from 'react';
import AboutUsComonent from '../../components/Home/AboutUs';

const AboutUs = () => {
  useEffect(() => {
    document.title = `AHRQ QI: About Us`;
  });
  return <AboutUsComonent />;
};

export default AboutUs;
