import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    divider: 'rgba(27, 27, 27, 0.15)',
    fill: {
      blue: '#D9E8F6',
      lightBlue: '#EFF5F8',
      grey: '#F5F5F5',
      lightGrey: '#F7FAFC',
      purple: '#83378B',
      babyBlue: '#B9D8EC',
    },
    primary: {
      main: '#005B94',
    },
    primaryButton: {
      main: '#0071BC',
      hover: '#205493',
    },
    secondary: {
      main: '#691f74',
    },
    stroke: {
      blue: '#73B3E7',
    },
    success: {
      main: '#499D5A',
    },
    link: {
      main: '#005b94',
      hover: '#00476b',
    },
    warning: {
      main: '#ffbc00',
    },
    white: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: `'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    h1: {
      fontFamily: `'Public Sans', sans-serif`,
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontFamily: `'Public Sans', sans-serif`,
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontFamily: `'Public Sans', sans-serif`,
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h4: {
      fontFamily: `'Public Sans', sans-serif`,
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h5: {
      fontFamily: `'Public Sans', sans-serif`,
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    h6: {
      fontFamily: `'Public Sans', sans-serif`,
      fontSize: '1rem',
      fontWeight: 700,
    },
    button: {
      textTransform: 'none',
    },
  },
});

// map href (Material UI) -> to (react-router)
const LinkBehavior = forwardRef((props, ref) => {
  const isLink = (props.href || props.to)?.startsWith('http');
  if (isLink)
    return (
      <a ref={ref} href={props.href || props.to} {...props}>
        {props.children}
      </a>
    );
  return <RouterLink ref={ref} to={props.href} {...props} />;
});

theme = createTheme({
  ...theme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.33rem',
          fontWeight: 600,
          lineHeight: 1.25,
          textTransform: 'none',
        },
        endIcon: {
          marginLeft: theme.spacing(0.75),
        },
        startIcon: {
          marginRight: theme.spacing(0.75),
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: 15,

            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          },
        },
        {
          props: { size: 'medium' },
          style: {
            fontSize: 16,
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: 18,

            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
          },
        },
        {
          props: { variant: 'outlined', size: 'small' },
          style: {
            fontSize: 15,

            paddingTop: 7, // theme.spacing(1) - 1
            paddingBottom: 7,
          },
        },
        {
          props: { variant: 'outlined', size: 'medium' },
          style: {
            fontSize: 16,
            paddingTop: 9, // theme.spacing(1.25) - 1
            paddingBottom: 9,
          },
        },
        {
          props: { variant: 'outlined', size: 'large' },
          style: {
            fontSize: 18,

            paddingTop: 11, // theme.spacing(1.5) - 1
            paddingBottom: 11,
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: theme.palette.primaryButton.main,
            '&:hover': {
              backgroundColor: theme.palette.primaryButton.hover,
            },
            '&[href]': {
              '&:hover': {
                color: theme.palette.common.white,
              },
            },
          },
        },
        {
          props: { variant: 'contained', color: 'white' },
          style: {
            '&[href]': {
              color: theme.palette.text.link,
              '&:hover': {
                color: theme.palette.text.linkHover,
              },
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: theme.palette.primaryButton.main,
            '&:hover': {
              backgroundColor: theme.palette.primaryButton.hover,
              color: 'white',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.info.main,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.success.main,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.warning.main,
              color: theme.palette.warning.contrastText,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.error.main,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'white' },
          style: {
            borderColor: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.common.white,
              borderColor: theme.palette.common.white,
              color: theme.palette.text.primary,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'inherit' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.grey['300'],
              borderColor: theme.palette.grey['300'],
              color: theme.palette.text.primary,
            },
          },
        },
      ],
      defaultProps: {
        color: 'inherit',
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
  },
  shadows: [
    'none',
    'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
    'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px, rgba(0, 0, 0, 0.4) 0px 15px 40px 0px',
    'rgb(0 0 0 / 40%) 0px 3px 1px -1px, rgb(0 0 0 / 12%) 0px 1px 1px 0px',
  ],
});

export { theme };
