import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Measures.module.scss';

const CompositeWorkgroup = () => {
  return (
    <>
      <Container className={styles.outerContainer}>
        <h1 className='align-center'>AHRQ QI Composite Workgroups</h1>
      </Container>
      <Container className='outerContainer'>
        <h2>Purpose</h2>
        <p>
          Many users of the AHRQ Quality Indicators (AHRQ QI) have expressed interest in the
          development of one or more composite measures. In response, AHRQ convened a Composite
          Measure Workgroup for each of the modules to assist in developing a composite measure.
          AHRQ sought nominations for participants in each of the modules—Prevention Quality
          Indicators (PQI), Inpatient Quality Indicators (IQI), Patient Safety Indicators (PSI), and
          Pediatric Quality Indicators (PDI)—through an announcement on the AHRQ QI listserv and on
          the AHRQ QI website. Members were selected to represent individuals from a variety of
          fields and perspectives (epidemiology, health services research, medicine, performance
          measurement, etc.).
        </p>
        <p>
          The intent of the AHRQ QI Composite Measure Project was to develop a general methodology
          that could be used to monitor hospital performance over time at the national, regional,
          State, and provider level. To maintain the focus on the methodology, the workgroups did
          not consider the merits of including individual indicators in the composites.
        </p>
      </Container>
      <Container className='outerContainer'>
        <h2>Outcomes</h2>
        <p>
          AHRQ and the workgroups developed six composite measures that serve as useful tools to
          track hospital performance over time and inform quality improvement efforts. Below is a
          list of the composites by module:
        </p>
        <ul>
          <li>
            Prevention Quality Indicators in Inpatient Settings (PQI): Two composite
            measures—Prevention Quality Acute Composite (PQI 91) and Prevention Quality Chronic (PQI
            92)
          </li>
          <li>
            Inpatient Quality Indicators (IQI): Two composite measures—Mortality for Selected
            Procedures (IQI 90) and Mortality for Selected Conditions (IQI 91)
          </li>
          <li>
            Pediatric Quality Indicators (PDI): One composite measure—Pediatric Patient Safety for
            Selected Indicators (PDI 19)
          </li>
          <li>
            Patient Safety Indicators (PSI): One composite measure—Patient Safety and Adverse Events
            Composite (PSI 90)
          </li>
        </ul>
        <p>
          Additional information about the workgroups and the construction of the composite measures
          is available in the following reports:
        </p>
        <ul>
          <li>
            <a href='/Downloads/Modules/PQI/PQI_Composite_Development.pdf'>
              Prevention Quality Indicators in Inpatient Settings Composite Measure Workgroup: Final
              Report
            </a>{' '}
            (PDF File, 3.48 MB)
          </li>
          <li>
            <a href='/Downloads/Modules/IQI/IQI_Composite_Development.pdf'>
              Inpatient Quality Indicators Composite Measure Workgroup: Final Report
            </a>{' '}
            (PDF File, 760 KB)
          </li>
          <li>
            <a href='/Downloads/Modules/PSI/PSI_Composite_Development.pdf'>
              Patient Safety Indicators Composite Measure Workgroup: Final Report
            </a>{' '}
            (PDF File, 1.79 MB)
          </li>
          <li>
            <a href='/Downloads/Modules/PDI/PDI_Composite_Development.pdf'>
              Pediatric Quality Indicators Composite Measure Workgroup: Final Report
            </a>{' '}
            (PDF File, 468 KB)
          </li>
        </ul>
        <p>
          We encourage AHRQ QI users to continue to submit comments and suggestions for improvement
          on the composite measures and the component indicators to the AHRQ QIs support team at{' '}
          <a href='mailto:QIsupport@ahrq.hhs.gov'>QIsupport@ahrq.hhs.gov</a>
        </p>
      </Container>
    </>
  );
};

export default CompositeWorkgroup;
