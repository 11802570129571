import React from 'react';
import { Container } from 'react-bootstrap';
import { resourcesData } from '../../data/resources';
import styles from './resources.module.scss';
import CaseCard from '../Common/QICard/CaseCard';
import { useMediaQuery } from 'react-responsive';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const CaseStudies = () => {
  const featuredStudies = resourcesData['caseStudies'].filter((caseStudy) =>
    caseStudy['category'].includes('featured')
  );

  const oneRow = useMediaQuery({ query: `(max-width: 768px)` }) ? true : false;
  const twoRow = useMediaQuery({ query: `(max-width: 1197px)` }) ? true : false;

  const splitToChunks = () => {
    const array = [...resourcesData['caseStudies']];
    let parts = oneRow ? 1 : twoRow ? 2 : 3;
    let result = [];
    for (let i = parts; i > 0; i--) {
      result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  };
  const splitCaseStudies = splitToChunks();

  return (
    <div className={styles.CaseStudies}>
      <NormalTopBanner>
        <h1>Case Studies</h1>
        <p>
          This page provides information on how organizations, including hospitals and health
          systems, use the AHRQ QIs to improve the quality of care.
        </p>
      </NormalTopBanner>
      {(featuredStudies?.length ?? 0) === 0 ? (
        ''
      ) : (
        <Container className='outerContainer'>
          <h2 className='align-center'>Featured Case Studies</h2>
          <div className={styles.FeaturedCaseContainer}>
            {featuredStudies.map((featuredStudy, index) => (
              <div>
                <CaseCard caseInfo={featuredStudy} />
              </div>
            ))}
          </div>
        </Container>
      )}
      <div className='bg-primary-blue-6'>
        <Container className='outerContainer'>
          <h2 className='align-center'>All Case Studies</h2>
          <div className={styles.AllCaseContainer}>
            {splitCaseStudies.map((splitCaseStudies) => (
              <div className={styles.AllCaseInnerContainer}>
                {splitCaseStudies.map((caseStudy, index) => (
                  <div>
                    <CaseCard caseInfo={caseStudy} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Container>
      </div>
      <Container className={`align-center outer-container`}>
        <h4>How does your organization use the AHRQ QIs to improve quality of care?</h4>
        <p className='mb-0'>Share your story by contacting the AHRQ QI Support Team at</p>
        <a href='mailto: QIsupport@ahrq.hhs.gov'>QIsupport@ahrq.hhs.gov</a>
      </Container>
    </div>
  );
};

export default CaseStudies;
