import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Measures.module.scss';
import TechSpecTable from '../TechSpecTable';

const PQITechnical = () => {
  return (
    <>
      <Container className='outerContainer'>
        <h1 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications
        </h1>
      </Container>
      <Container className='outerContainer'>
        <h4 className='mb-9 align-center'>
          Prevention Quality Indicators Technical Specifications, Version 4.1, December 2009
        </h4>
        <Row>
          <Col md={9}>
            <h5>
              All Prevention Quality Indicators Technical Specifications, Version 4.1 (Zip File)
            </h5>
          </Col>
          <Col md={3} className={styles.DownloadSpecBtn}>
            <Button
              aria-label='Download pqi_technical_specifications_fy09_version41.zip'
              href='/Downloads/Modules/PQI/V41/TechSpecs/pqi_technical_specifications_fy09_version41.zip'
            >
              Download All (ZIP File, 623 KB)
            </Button>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className='outerContainer'>
        <TechSpecTable module={'pqi'} version={'v41'} />
      </Container>
    </>
  );
};

export default PQITechnical;
