import React, { useEffect } from 'react';
import ContactUs from '../../components/Home/ContactUs';

const Contact = () => {
  useEffect(() => {
    document.title = `AHRQ QI: Contact Us`;
  });
  return <ContactUs />;
};

export default Contact;
