import { getReleaseItem } from '../releases';

export const pqeMeasuresData = {
  technical: [
    {
      ...getReleaseItem('IndividualMeasureTechnicalSpecifications', 'PQE'),
      status: 'new',
    },
    {
      ...getReleaseItem('ParameterEstimates', 'PQE'),
      status: 'new',
    },
    {
      ...getReleaseItem('BenchmarkDataTables', 'PQE'),
      status: 'new',
    },
    {
      ...getReleaseItem('LogOfCodingUpdates', 'PQE'),
      status: 'new',
    },
  ],
  additional: [
    {
      title: 'Development and Validation of the AHRQ ED PQI',
      desc: '<p>Learn about the rigorous methods applied by Davies, Sheryl et al. (2017) to develop the ED PQI.</p>',
      url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5583364/',
      aria: 'National Library of Medicine document',
      cta: 'Learn More',
      newTab: true,
    },
    {
      title: 'PQE Use Cases',
      desc: "<p>Review AHRQ's guidance on how to use the PQEs.</p>",
      url: '/measures/how_to_use_pqe_resources',
      aria: 'PQE Use Cases reference',
      cta: 'Learn More',
      // newTab: true,
    },
  ],
  // specifications: [],
  // retired: [],
};
