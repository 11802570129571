import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { AvailabilityDialogButton, VideoModal } from '../';
import { QITileGroup } from '../Common';
import TutorialThumbnail from '../../img/tutorialThumbnail.png';
import { sasqiPQEVideos as PQEVideos } from '../../data/videos';
import {
  SoftwareList,
  SoftwareListItem,
  Flex,
  LinkItem,
  PageRow,
  Rule,
  PageDisclaimer,
  LinkBox,
  Button,
} from '../../ui';
import { getReleaseItem } from '../../data/releases';

const SASQI = () => {
  const theme = useTheme();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [pqeVideoModalInfo, setPQEVideoModalInfo] = useState({ isOpen: false, url: '' });

  const icd10Faq = getReleaseItem('Icd10Faq');
  const icd10FaqMinor = getReleaseItem('Icd10FaqMinor');
  const releaseNotes = getReleaseItem('ReleaseNotes', 'SASQI');

  const sasQiRelease = getReleaseItem('SasQi', 'SASQI');
  const iqiRelease = getReleaseItem('InpatientQualityIndicators', 'SASQI');
  const psiRelease = getReleaseItem('PatientSafetyIndicators', 'SASQI');
  const psi17Release = getReleaseItem('Psi17', 'SASQI');
  const pdiRelease = getReleaseItem('PediatricQualityIndicator', 'SASQI');
  const pqiRelease = getReleaseItem('PreventionQualityIndicator', 'SASQI');
  const pqeRelease = getReleaseItem('SasPQE', 'SASQI');
  const mhiRelease = getReleaseItem('MHI', 'SASQI');

  const automateSasQiSoftware = getReleaseItem('AutomateSasQiSoftware', 'SASQI');
  const softwareInstructions = getReleaseItem('SasQiSoftwareInstructions', 'SASQI');

  const populationFiles = getReleaseItem('SasPopulationFiles', 'SASQI');
  const populationFileDocumentation = getReleaseItem('AhrqQiPopulationFileDocumentation', 'SASQI');

  const v2022Archive = getReleaseItem('SasQiV2022Icd10CmPcsFullSoftwarePackage', 'SASQI', 'v2022');
  const v2023Archive = getReleaseItem('SasQiV2023Icd10CmPcsFullSoftwarePackage', 'SASQI', 'v2023');

  const archivedData = [
    {
      title: v2023Archive.title,
      desc: v2023Archive.desc,
      date: v2023Archive.revision,
      url: v2023Archive.url,
      aria: v2023Archive.aria,
      cta: v2023Archive.cta,
    },
    {
      title: v2022Archive.title,
      desc: v2022Archive.desc,
      date: v2022Archive.revision,
      url: v2022Archive.url,
      aria: v2022Archive.aria,
      cta: v2022Archive.cta,
    },
  ];

  const handleAvailabilityDialogClose = (reason) => {
    if (reason === 'go') {
      const $element = document.querySelector('.ed-pqi-container');
      if ($element) $element.scrollIntoView();
    }
  };

  return (
    <>
      <PageRow className='sas-qi-intro-row'>
        <Flex cols='lg' gap={{ xs: 4, lg: 10 }}>
          <Flex alignItems='flex-start' gap={3}>
            <div>
              <h1>SAS QI v2024.0.1 Full Software Package</h1>
              <p className='text-xl'>
                Harnessing the power of SAS/STAT software for advanced users
              </p>
              <p>
                The QI/STAT modules are programs that run in the SAS statistical software package.
                To use these modules, users must have access to SAS, which may be purchased from The
                SAS Institute.
              </p>
              <h6>
                The software now includes the MHI<sup className='highlightBold'>BETA</sup> module -{' '}
                <span className='new'>New!</span>
              </h6>
              <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={1.5}>
                <AvailabilityDialogButton
                  disableAvailabilityDialog={true}
                  label={sasQiRelease.cta}
                  url={sasQiRelease.url}
                  dialogSubtitle='Also Available: SAS QI PQE Beta v2024'
                  dialogLabel='Go to PQE Download'
                  dialogDescription='The Emergency Department Prevention Quality Indicators (PQEs) are geographic
                  area-level rates of emergency department visits that could be prevented by high
                  quality health care.'
                  onClose={handleAvailabilityDialogClose}
                  arrow
                />
              </Box>
            </div>

            <Rule spacing={1} />

            <div>
              <h5>SAS QI v2024.0.1 Individual Software Downloads</h5>
              <ul className='clean'>
                <li>
                  <a href={pqiRelease.url} rel='noreferrer' target='_blank'>
                    <strong>
                      {pqiRelease.title} {pqiRelease.info}
                    </strong>
                  </a>
                  {'  '}Version {pqiRelease.version} ({pqiRelease.revision})
                </li>
                <li>
                  <a href={iqiRelease.url} rel='noreferrer' target='_blank'>
                    <strong>
                      {iqiRelease.title} {iqiRelease.info}
                    </strong>
                  </a>
                  {'  '}Version {iqiRelease.version} ({iqiRelease.revision})
                </li>
                <li>
                  <a href={psiRelease.url} rel='noreferrer' target='_blank'>
                    <strong>
                      {psiRelease.title} {psiRelease.info}
                    </strong>
                  </a>
                  {'  '}Version {psiRelease.version} ({psiRelease.revision})
                </li>
                <li>
                  <a href={pdiRelease.url} rel='noreferrer' target='_blank'>
                    <strong>
                      {pdiRelease.title} {pdiRelease.info}
                    </strong>
                  </a>
                  {'  '}Version {pdiRelease.version} ({pdiRelease.revision})
                </li>
                <li>
                  <a href={pqeRelease.url} rel='noreferrer' target='_blank'>
                    <strong>
                      {pqeRelease.title} {pqeRelease.info}
                    </strong>
                  </a>
                  {'  '}Version {pqeRelease.version} ({pqeRelease.revision})
                </li>
                <li>
                  <a href={mhiRelease.url} rel='noreferrer' target='_blank'>
                    <strong>
                      {mhiRelease.title}
                      <sup className='highlightCaps'>Beta</sup> {mhiRelease.info}
                    </strong>
                  </a>
                  {'  '}Version {mhiRelease.version}
                  {mhiRelease.minorVersion} ({mhiRelease.revision}) -{' '}
                  <span className='new'>New!</span>
                </li>
              </ul>
            </div>

            <div>
              <h5>Supplemental Individual Software Files:</h5>
              <ul className='clean'>
                <li>
                  <a href={psi17Release.url} rel='noreferrer' target='_blank'>
                    <strong>
                      PSI: {psi17Release.title} Download {psi17Release.info}
                    </strong>
                  </a>
                </li>
              </ul>
            </div>
          </Flex>
          <Box bgcolor='grey.50' p={2.5} width={{ lg: 320 }} margin={{ xs: '-1.25rem', lg: 0 }}>
            <div>
              <h6>Software v2024.0.1 Improvements</h6>
              <ul>
                <li>Introduces the MHI module.</li>
                <li>Indicator refinements</li>
                <li>
                  Removal of the option to include or exclude COVID-19 discharges due to
                  risk-adjustment for COVID-19 time period and diagnoses present on admission
                </li>
                <li>New checks on user input for MDC, race, and payer to avoid software errors.</li>
              </ul>
            </div>
            <div>
              <h6>Release Notes</h6>
              <ul>
                <li>
                  Version v2024.0.1, September 2024 - Summary of the major change from v2023 to
                  v2024.0.1 of the Technical Specifications and AHRQ QI Software.
                </li>
              </ul>
              <LinkBox>
                <LinkItem
                  href={releaseNotes.url}
                  meta={releaseNotes.info}
                  leftBorder
                  light
                  noicon
                  noclip
                >
                  {/*releaseNotes.version*/}2024.0.1 Release Notes
                </LinkItem>
                <LinkItem
                  href={icd10FaqMinor.url}
                  meta={icd10FaqMinor.info}
                  leftBorder
                  light
                  noicon
                  noclip
                >
                  {icd10FaqMinor.version}
                  {icd10FaqMinor.minorVersion} Software Release FAQ
                </LinkItem>
                <LinkItem href={icd10Faq.url} meta={icd10Faq.info} leftBorder light noicon noclip>
                  {icd10Faq.version} Software Release FAQ
                </LinkItem>
              </LinkBox>
            </div>
          </Box>
        </Flex>

        <Rule spacing={5} sx={{ display: { xs: 'none', lg: 'block' } }} />

        <Flex cols='lg' gap={{ xs: 4, lg: 10 }}>
          <Flex gap={3}>
            <div>
              <h3>Installation Instructions</h3>
              <p>
                To learn more about installation of the SAS QI modules, download the software
                instructions below.
              </p>
              <p>
                The software instructions for v2024.0.1 are slightly modified from v2024 to include
                the Maternal Health Indicators (<strong>MHI</strong>) <sup>beta</sup> module. For
                simplicity, the term "v2024" is retained throughout these instructions.
              </p>
              <LinkItem
                href={softwareInstructions.url}
                meta={`${softwareInstructions.revision} ${softwareInstructions.info}`}
                light
              >
                Download {softwareInstructions.title} {softwareInstructions.version}
                {softwareInstructions.minorVersion}
              </LinkItem>
            </div>
            <div>
              <h6>Automate SAS QI Software Runs in Windows, {automateSasQiSoftware.version}</h6>
              <a href={automateSasQiSoftware.url} rel='noreferrer' target='_blank'>
                How to schedule automated Agency for Healthcare Research and Quality (AHRQ) Quality
                Indicator (QI) SAS QI Software runs on a Windows computer{' '}
                {automateSasQiSoftware.info}
              </a>
            </div>
          </Flex>
          <Flex flex='unset' alignItems='center' gap={3} maxWidth={{ lg: 320 }}>
            <button
              onClick={() => setIsVideoModalOpen(true)}
              style={{ padding: 0 }}
              className='video-thumbnail'
            >
              <img alt='Tutorial Thumbnail' src={TutorialThumbnail} width='100%' />
            </button>
            <p className='text-center mb-0'>
              <a
                aria-label='View Tutorial Video'
                target='_blank'
                rel='noreferrer'
                href='https://www.youtube.com/watch?v=KIG5u-t81CY'
              >
                View the SAS QI software tutorial video on YouTube
                <a href='/ExternalDisclaimer'>
                  <img
                    alt='external web link policy'
                    className='ml-1'
                    src='/images/exit_disclaimer.png'
                  />
                </a>
              </a>
            </p>
          </Flex>
        </Flex>
      </PageRow>

      <PageRow className='getting-started-row' lightGrey>
        <h2>Videos about PQE</h2>
        <Flex gap={3}>
          <div>
            <p>
              ED PQI is now PQE. The videos below reference version 2023, but remain relevant for
              version 2024.
            </p>
          </div>
          <Flex
            gap={3}
            pb={3}
            flexDirection={'row'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            {Object.keys(PQEVideos).map((pqeVideoKey) => {
              const video = PQEVideos[pqeVideoKey];
              return (
                <Flex
                  key={video.id}
                  flex='unset'
                  alignItems='center'
                  alignSelf='flex-start'
                  gap={3}
                  width={{ xs: '45%', md: '30%' }}
                >
                  <button
                    onClick={() => setPQEVideoModalInfo({ isOpen: true, url: video.url })}
                    className='video-thumbnail'
                  >
                    {video.thumb !== undefined && (
                      <img alt='Video Thumbnail' src={video.thumb} width='100%' />
                    )}
                  </button>
                  <p className='text-center'>
                    <a aria-label={video.label} target='_blank' rel='noreferrer' href={video.url}>
                      {video.text}
                    </a>
                    <a href='/ExternalDisclaimer'>
                      <img
                        alt='external web link policy'
                        className='ml-1'
                        src='/images/exit_disclaimer.png'
                      />
                    </a>
                  </p>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </PageRow>

      <PageRow className='tech-specs-row'>
        <Box px={{ lg: 16 }} textAlign='center'>
          <h2>Technical Specifications</h2>
          <p>
            Technical Specifications break down calculations used to formulate each indicator,
            including a brief description of the measure, numerator and denominator information, and
            details on cases that should be excluded from calculations.
          </p>
        </Box>
        <QITileGroup includePQE isTechSpecs includeMHI />
        <div className='align-center'>
          <Button
            aria-label='View all measures'
            label='View All Measures'
            href='/measures/all_measures'
            variant='outlined'
            arrow
          />
        </div>
      </PageRow>

      <PageRow className='population-files-row' lightGrey>
        <h2 className='text-center'>Population Files</h2>
        <Flex cols='lg' mt={6}>
          <Flex
            borderRight={{ lg: `1px solid ${theme.palette.divider}` }}
            borderBottom={{ xs: `1px solid ${theme.palette.divider}`, lg: 'none' }}
            pr={{ xs: 0, lg: 6 }}
            pb={{ xs: 6, lg: 0 }}
          >
            <h4 className='color-primary-2'>
              <a
                href={populationFiles.url}
                rel='noreferrer'
                target='_blank'
                style={{ color: 'inherit' }}
              >
                {populationFiles.title}
              </a>
            </h4>
            <p>{populationFiles.desc}</p>
            {/* <p>
              Version {populationFiles.version} ({populationFiles.revision})
            </p> */}
            <a href={populationFiles.url} rel='noreferrer' target='_blank'>
              {populationFiles.cta}
            </a>
          </Flex>
          <Flex pt={{ xs: 6, lg: 0 }} pl={{ xs: 0, lg: 6 }}>
            <h4 className='color-primary-2'>
              <a
                href={populationFileDocumentation.url}
                rel='noreferrer'
                target='_blank'
                style={{ color: 'inherit' }}
              >
                {populationFileDocumentation.title}
              </a>
            </h4>
            <p>{populationFileDocumentation.desc}</p>
            <a href={populationFileDocumentation.url} rel='noreferrer' target='_blank'>
              {populationFileDocumentation.cta}
            </a>
          </Flex>
        </Flex>
      </PageRow>

      <PageRow className='archived-software-row' lightGrey>
        <SoftwareList
          title='Archived Software'
          subtitle='Archived software for SAS QI ICD-10'
          viewAllUrl='/archive/software?category=sas'
          paper
        >
          {archivedData.map((info) => (
            <SoftwareListItem
              key={info.url}
              title={info.title}
              subtitle={info.date}
              url={info.url}
              label={info.cta}
              ariaLabel={info.aria}
            />
          ))}
        </SoftwareList>
      </PageRow>

      <PageRow className='disclaimer-row' py={0} pb={6} lightGrey>
        <PageDisclaimer
          resource={`AHRQ ${sasQiRelease.title} Software Download for AHRQ Quality Indicators`}
          software
        />
      </PageRow>

      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        url='https://www.youtube.com/embed/KIG5u-t81CY?autoplay=1'
      />

      <VideoModal
        isOpen={pqeVideoModalInfo?.isOpen ?? false}
        onClose={() => setPQEVideoModalInfo(null)}
        url={pqeVideoModalInfo?.url}
      />
    </>
  );
};

export default SASQI;
