import React from 'react';
import { Link } from 'react-router-dom';
import TechSpecTable from '../TechSpecTable';
import { Button, Callout, Rule, Flex, PageHeader, PageRow, LinkItem } from '../../../../ui';

const PDITechnical = () => {
  return (
    <>
      <PageHeader.Row bgSrc='/images/hex-grey.jpg' lightGrey>
        <PageHeader.Title>Technical Specifications</PageHeader.Title>
        <PageHeader.Content>
          <h4>For Pediatric Quality Indicators</h4>
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow>
        <Flex gap={4}>
          <h4>
            Pediatric Quality Indicators Technical Specifications Updates - Version v2022, July 2022
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' gap={2}>
            <Flex>
              Updated Pediatric Quality Indicators Technical Specifications (PDF Format), Version
              v2022 (Zip File)
            </Flex>
            <Button
              aria-label='Download PDI_2022_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/PDI/V2022/TechSpecs/PDI_2022_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 17.4 MB)
            </Button>
          </Flex>
          <TechSpecTable module={'pdi'} version={'icd10_v2022'} />
          <div>
            ** To learn how to isolate the impact of COVID-19 on the AHRQ Quality Indicators (QIs),{' '}
            <a href='/Downloads/Resources/COVID19_UserNote_July2021.pdf' target='_blank'>
              Click Here
            </a>{' '}
            (PDF File, 223 KB)
          </div>
        </Flex>
      </PageRow>

      <PageRow lightGrey>
        <h3>Retired Indicators</h3>
        <Rule />

        <p className='text-large'>
          Effective v2021, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/AHRQ%20QI%20v2021%20Retirement%20Announcement.pdf'
          meta='PDF File, 109 KB'
          light
          mb>
          Indicators Retirement Announcement
        </LinkItem>
        <ul>
          <li>NQI 02 Neonatal Mortality Rate</li>
        </ul>

        <Rule dashed />

        <p className='text-large'>
          Effective v2019, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/Retirement%20Notice_v2019_Indicators.pdf'
          meta='PDF File, 105 KB'
          light
          mb>
          Indicators Retirement Announcement
        </LinkItem>
        <ul>
          <li>NQI 01 Neonatal Iatrogenic Pneumothorax Rate</li>
          <li>PDI 02 Pressure Ulcer Rate</li>
          <li>PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count</li>
          <li>PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate</li>
          <li>PDI 07 RACHS-1 Pediatric Heart Surgery Volume</li>
          <li>PDI 11 Postoperative Wound Dehiscence Rate</li>
          <li>PDI 13 Transfusion Reaction Count</li>
          <li>PDI 17 Perforated Appendix Admission Rate</li>
          <li>PDI 19 Pediatric Safety for Selected Indicators Composite</li>
        </ul>

        <Rule dashed />

        <Callout>
          Pediatric Quality Indicators Technical Specifications for SAS and WinQI ICD-10 version
          v2021 can be found <Link to='/archive/qi_modules?category=pdi'>here</Link>.
        </Callout>
      </PageRow>
    </>
  );
};

export default PDITechnical;
