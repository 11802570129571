import React from 'react';
import { Link } from 'react-router-dom';
import TechSpecTable from '../TechSpecTable';
import { Button, Callout, Rule, Flex, PageHeader, PageRow, LinkItem } from '../../../../ui';

const IQITechnical = () => {
  return (
    <>
      <PageHeader.Row bgSrc='/images/hex-grey.jpg' lightGrey>
        <PageHeader.Title>Technical Specifications</PageHeader.Title>
        <PageHeader.Content>
          <h4>For Inpatient Quality Indicators</h4>
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow>
        <Flex gap={4}>
          <h4>
            Inpatient Quality Indicators Technical Specifications Updates - Version v2022, July 2022
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' gap={2}>
            <Flex>
              Updated Inpatient Quality Indicators Technical Specifications (PDF Format), Version
              v2022 (Zip File)
            </Flex>
            <Button
              aria-label='Download IQI_2022_ICD10_techspecs_pdf.zip'
              href='/Downloads/Modules/IQI/V2022/TechSpecs/IQI_2022_ICD10_techspecs_pdf.zip'>
              Download All (ZIP File, 5.85 MB)
            </Button>
          </Flex>
          <TechSpecTable module={'iqi'} version={'icd10_v2022'} />
          <div>
            ** To learn how to isolate the impact of COVID-19 on the AHRQ Quality Indicators (QIs),{' '}
            <a href='/Downloads/Resources/COVID19_UserNote_July2021.pdf' target='_blank'>
              Click Here
            </a>{' '}
            (PDF File, 223 KB)
          </div>
        </Flex>
      </PageRow>

      <PageRow lightGrey>
        <h3>Retired Indicators</h3>
        <Rule />

        <p className='text-large'>
          Effective v2021, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/AHRQ%20QI%20v2021%20Retirement%20Announcement.pdf'
          meta='PDF File, 109 KB'
          light
          mb>
          Indicators Retirement Announcement
        </LinkItem>
        <ul>
          <li>IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, without Transfer Cases</li>
          <li>IQI 34 Vaginal Birth After Cesarean (VBAC) Rate</li>
        </ul>

        <Rule dashed />

        <p className='text-large'>
          Effective v2019, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/Retirement%20Notice_v2019_Indicators.pdf'
          meta='PDF File, 105 KB'
          light
          mb>
          Indicators Retirement Announcement
        </LinkItem>
        <ul>
          <li>IQI 01 Esophageal Resection Volume</li>
          <li>IQI 02 Pancreatic Resection Volume</li>
          <li>IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume</li>
          <li>IQI 05 Coronary Artery Bypass Graft (CABG)</li>
          <li>IQI 06 Percutaneous Coronary Intervention (PCI) Volume</li>
          <li>IQI 07 Carotid Endarterectomy Volume</li>
          <li>IQI 13 Craniotomy Mortality Rate</li>
          <li>IQI 14 Hip Replacement Mortality Rate</li>
        </ul>

        <Rule dashed />

        <p className='text-large'>
          Effective v7.0, the following indicators have been retired. For additional information,
          refer to the retirement announcements:
        </p>
        <LinkItem
          href='/News/IQI_Area_Level_Retirement_Notice.pdf'
          meta='PDF File, 342 KB'
          light
          mb>
          IQI Area Level Indicators
        </LinkItem>
        <LinkItem
          href='/News/IQI_Hospital_Level_Retirement_Notice.pdf'
          meta='PDF File, 348 KB'
          light
          mb>
          IQI Hospital Level Indicators
        </LinkItem>
        <ul>
          <li>IQI 23 Laparoscopic Cholecystectomy Rate</li>
          <li>IQI 24 Incidental Appendectomy in the Elderly Rate</li>
          <li>IQI 25 Bilateral Cardiac Catheterization Rate</li>
          <li>IQI 26 Coronary Artery Bypass Graft (CABG) Rate</li>
          <li>IQI 27 Percutaneous Coronary Intervention (PCI) Rate</li>
          <li>IQI 28 Hysterectomy Rate</li>
          <li>IQI 29 Laminectomy or Spinal Fusion Rate</li>
        </ul>

        <Rule dashed />

        <Callout>
          Inpatient Quality Indicators Technical Specifications for SAS QI and WinQI ICD-10 v2021
          can be found <Link to='/archive/qi_modules?category=iqi'>here</Link>.
        </Callout>
      </PageRow>
    </>
  );
};

export default IQITechnical;
