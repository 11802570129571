import React from 'react';
import { Container, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './home.module.scss';
import QIContact from '../../img/QI-ContactUs.jpeg';
import QIOverview from '../../img/QIs-Overview.jpeg';
import QIUsing from '../../img/QI-Using.jpeg';
import SASQI from '../../img/SAS-QI.png';
import QICaseStudies from '../../img/QI-CaseStudies.jpeg';
import QINews from '../../img/QINews.jpg';
import QIToolkits from '../../img/QI-Toolkits.jpeg';
import QIResources from '../../img/QI-Resources.jpeg';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';

const AboutUs = () => {
  return (
    <div className={styles.AboutUs}>
      <NormalTopBanner>
        <h1>About Us</h1>
      </NormalTopBanner>
      <Container className='outer-container'>
        <div>
          <p>
            The AHRQ Quality Indicators (AHRQ QIs) are measures of health care quality designed for
            use by program managers, researchers, and others at the Federal, State, and local levels
            interested in health care quality measurement. The AHRQ QIs provide health care
            decisionmakers with tools to assess their data, highlight potential quality concerns,
            identify areas that need further study and investigation, and track changes over time.
            The modules represent various aspects of quality: Prevention Quality Indicators in
            Inpatient Settings (PQIs), Inpatient Quality Indicators (IQIs), Patient Safety
            Indicators (PSIs), and Pediatric Quality Indicators (PDIs). The AHRQ QIs are used in
            free software distributed by AHRQ; the software programs can be applied to hospital
            inpatient administrative data, which is readily available and relatively inexpensive to
            use.
          </p>
          <p>
            The AHRQ Quality Indicators were originally developed at the request of Healthcare Cost
            and Utilization Project (HCUP) partners in 1999 using evaluation methodologies developed
            in the AHRQ Evidence-based Practice Centers (EPC). Over the years several refinements
            have been made to the original indicators by incorporating risk adjustment and reference
            population to improve the reliability and validity of the indicators. The PQIs were
            developed in 2000, the IQIs in 2002, the PSIs in 2003 and finally the PDIs in 2006 using
            ICD-9 CM codes. In 2012, several other enhancements were added such as present on
            admission (POA), laboratory values and key clinical values as well as conversion of AHRQ
            QIs to ICD-10 CM/PCS.
          </p>
        </div>
        <div className={styles.LinksContainer}>
          <Card className={styles.CaseCard}>
            <Card.Img alt='AHRQ QIs Overview' variant='top' src={QIOverview} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>AHRQ QIs Overview</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/faqs?tag=QIOverview-WhatAreQIs' aria-label='View the QI overview'>
                  <Button className='link-button primary' aria-label='View the QI overview'>
                    View the QI Overview
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='Using AHRQ QIs' variant='top' src={QIUsing} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>Using AHRQ QIs</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/faqs?tag=UsingQI-FAQ70' aria-label='View using AHRQ QIs'>
                  <Button className='link-button primary' aria-label='View using AHRQ QIs'>
                    View Using AHRQ QIs
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='AHRQ QI Software' variant='top' src={SASQI} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>AHRQ QI Software</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/software' aria-label='View our software'>
                  <Button className='link-button primary' aria-label='View our software'>
                    View Our Software
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='AHRQ QI Case Studies' variant='top' src={QICaseStudies} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>AHRQ QI Case Studies</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/resources/case_studies' aria-label='See the AHRQ QI Case Studies'>
                  <Button className='link-button primary' aria-label='See the AHRQ QI Case Studies'>
                    See the AHRQ QI Case Studies
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='AHRQ QI Hospital Toolkits' variant='top' src={QIToolkits} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>AHRQ QI Hospital Toolkits</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/resources/toolkits' aria-label='View AHRQ QI Hospital Toolkits'>
                  <Button
                    className='link-button primary'
                    aria-label='View AHRQ QI Hospital Toolkits'
                  >
                    View AHRQ QI Hospital Toolkits
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='AHRQ QI Resources' variant='top' src={QIResources} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>AHRQ QI Resources</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/measures/qi_resources' aria-label='View AHRQ QI Resources'>
                  <Button className='link-button primary' aria-label='View AHRQ QI Resources'>
                    View AHRQ QI Resources
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='AHRQ QI News' variant='top' src={QINews} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>AHRQ QI News</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/news' aria-label='View AHRQ QI News'>
                  <Button className='link-button primary' aria-label='View AHRQ QI News'>
                    View AHRQ QI News
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='Contact Us' variant='top' src={QIContact} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>Contact Us</div>
              </Card.Title>
              <div className={styles.ResourcesCardButtonContainer}>
                <Link to='/home/contact' aria-label='Contact Us'>
                  <Button className='link-button primary' aria-label='Contact Us'>
                    Contact Us
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
