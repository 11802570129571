import React, { useEffect } from 'react'
import NotFound from '../../components/Other/404';

const News = () => {
    useEffect(() => {
        document.title = `AHRQ - Quality Indicators 404 Error`;
    });
    return (<NotFound />)
}

export default News;