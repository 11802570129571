import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Dropdown, Form, Nav, Navbar } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { clsx } from 'clsx';
import MenuData from './menu';
import SubNavigation from './SubNavigation';
import AhrqiLogo from '../../img/ahrqi-logo.png';
import Logo from '../../img/logo.png';
import { LinkItem } from '../../ui';

const Navigation = () => {
  const desktopNavContainerRef = useRef(null);
  const matchMeasures = useRouteMatch('/measures');
  const matchSoftware = useRouteMatch('/software');
  const matchResources = useRouteMatch('/resources');
  const matchArchives = useRouteMatch('/archive');

  const [dropShown, setDropShown] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');

  const isLessThanXL = useMediaQuery({ query: `(max-width: 1199px)` });

  const navLoseFocusSpanRef = useRef();
  const searchInputRef = useRef();

  const [menuData] = useState(MenuData);

  useEffect(() => {
    const onDocumentClick = (event) => {
      if (desktopNavContainerRef.current?.contains(event.target) === false) {
        setDropShown((prevValue) => (prevValue !== false ? false : prevValue));
      } else if (event.target.className.includes('main-nav-nodropdown')) {
        setDropShown(false);
      }
    };

    const onDocumentKeyDown = (event) => {
      if (event.key === 'Escape') setDropShown(false);
    };

    if (dropShown !== false) {
      document.addEventListener('click', onDocumentClick);
      document.addEventListener('keydown', onDocumentKeyDown);
    }
    return () => {
      document.removeEventListener('click', onDocumentClick);
      document.removeEventListener('keydown', onDocumentKeyDown);
    };
  }, [dropShown]);

  useEffect(() => {
    const element = searchInputRef.current;

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') setShowSearch(false);
    };

    if (element && showSearch) {
      element.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      element?.removeEventListener('keydown', handleKeyDown);
    };
  }, [showSearch]);

  const searchOption = 'AHRQ_QI';

  const checkAndSetDropShown = (index) => {
    setDropShown((prevValue) => (prevValue === index ? false : index));
  };

  const setFocusToNavLoseFocusSpan = () => {
    if (navLoseFocusSpanRef.current) {
      setTimeout(() => navLoseFocusSpanRef.current.focus());
    }
  };

  const handleMenuBlur = (event) => {
    if (!event || !event.relatedTarget || !event.relatedTarget?.className) {
      setDropShown(false);
    } else if (
      !event.relatedTarget.className.includes('main-nav-dropdown') &&
      !event.relatedTarget.className.includes('dropdown-item')
    ) {
      setDropShown(false);
    }
  };

  const handleDropdownMouseDown = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    checkAndSetDropShown(index);
  };

  const handleDropdownButtonFocus = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    checkAndSetDropShown(index);
  };

  const cancelEvent = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchOption === 'AHRQ') {
      document.getElementById('searchForm').submit();
    } else {
      var sendLoc = `https://search.ahrq.gov/search?q=${searchText}&siteDomain=qualityindicators.ahrq.gov`;
      window.open(sendLoc, '_blank');
    }
  };

  const onMainItemKeyDown = (e, index) => {
    if (e.keyCode !== 40) {
      return;
    }
    e.preventDefault();
    const el = document.getElementById(`basic-navbar-navitem-${index}-sub-0`);
    el.focus();
  };

  const activeSection = matchMeasures
    ? 'Measures'
    : matchSoftware
    ? 'Software'
    : matchResources
    ? 'Resources'
    : matchArchives
    ? 'Archives'
    : null;

  return (
    <>
      {isLessThanXL ? (
        <Navbar
          collapseOnSelect
          id='responsive-navbar-nav'
          expand='xl'
          className='navbar-main navbar-main-phone'
          variant='dark'
          expanded={expanded}>
          <div className='navbar-main-phone-brand-container container'>
            <Navbar.Brand>
              <Link to='/'>
                <img
                  alt='Agency for Healthcare Research and Quality Logo'
                  src={Logo}
                  style={{ minWidth: 260, width: '100%' }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls='responsive-navbar-nav'
              onClick={() => setExpanded(true)}
              className='mr-0'
            />
          </div>
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav variant='pills' className='side-nav'>
              <div className={'mobile-dropdown-nav-items fullwidth mobile-nav-header px-2 py-2'}>
                <Nav.Item>
                  <img alt='Agency for Healthcare Research and Quality Logo' src={AhrqiLogo} />
                </Nav.Item>
                <Navbar.Toggle
                  aria-controls='responsive-navbar-nav'
                  onClick={() => setExpanded(false)}
                />
              </div>
              {menuData.map((item, index) => {
                return (
                  <div style={{ width: '100%' }} key={index}>
                    {item.submenu.length === 0 ? (
                      <div className='fullwidth mobile-nav-border'>
                        <div className={'mobile-dropdown-nav-items'}>
                          <Nav.Item className='fullwidth mobile-dropdown-nav-items-item'>
                            <div className='flex'>
                              <NavLink
                                className='mobile-nav-link'
                                key={index}
                                to={item.path}
                                onClick={() => setExpanded(false)}>
                                <Container>{item.title}</Container>
                              </NavLink>
                              <div style={{ width: '32px' }}>&nbsp;</div>
                            </div>
                          </Nav.Item>
                        </div>
                      </div>
                    ) : (
                      <Dropdown className='mobile-dropdown' id='responsive-navbar-nav' key={index}>
                        <div className='fullwidth mobile-nav-border'>
                          <div className={'mobile-dropdown-nav-items'}>
                            <Nav.Item className={'mobile-dropdown-nav-items-item'}>
                              <NavLink
                                className='mobile-nav-link'
                                key={index}
                                to={item.path}
                                onClick={() => setExpanded(false)}>
                                <Container>{item.title}</Container>
                              </NavLink>
                            </Nav.Item>
                            <Dropdown.Toggle className={'side-nav-dropdown'}></Dropdown.Toggle>
                          </div>
                        </div>
                        <Dropdown.Menu className={'mobile-nav-dropdown-menu'}>
                          {item.submenu.map((subItem, index) => {
                            return (
                              <div key={index}>
                                <div className='mobile-nav-dropdown-container'>
                                  <Nav.Item
                                    style={{ display: 'flex' }}
                                    className={'mobile-sub-nav-item'}>
                                    <Dropdown.Item
                                      style={{ marginLeft: 45, paddingRight: 50 }}
                                      className='mobile-nav-dropdown-item'
                                      key={index}
                                      as={NavLink}
                                      to={subItem.path}
                                      onClick={() => setExpanded(false)}>
                                      <Container>{subItem.subtitle}</Container>
                                    </Dropdown.Item>
                                  </Nav.Item>
                                </div>
                              </div>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      ) : (
        <Box borderBottom={1} borderColor='#D3D3D3'>
          <Container className='navbar-main-large'>
            <Navbar.Brand>
              <Link to='/'>
                <img alt='Agency for Healthcare Research and Quality Logo' src={Logo} />
              </Link>
            </Navbar.Brand>
            <Navbar
              id='responsive-navbar-nav'
              expand='xl'
              className='navbar-main navbar-main-desktop'
              variant='dark'>
              {/* <Navbar.Toggle aria-controls='basic-navbar-nav' /> */}
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav variant='pills' className='main-nav' ref={desktopNavContainerRef}>
                  {menuData.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.submenu.length === 0 ? (
                          <NavLink
                            className='main-nav-link main-nav-nodropdown nav-ie-fix'
                            exact
                            activeClassName='active'
                            to={item.path}>
                            {item.title}
                          </NavLink>
                        ) : (
                          <Dropdown className='main-nav-dropdown-item' show={dropShown === index}>
                            <NavLink tabIndex='-1' to={item.path}>
                              <button
                                onBlur={handleMenuBlur}
                                aria-labelledby='basic-navbar-nav'
                                id={`basic-navbar-navlink-${index}`}
                                className={'main-nav-dropdown mb-0 mt-0'}
                                onClick={cancelEvent}
                                onMouseDown={(e) => handleDropdownMouseDown(e, index)}
                                onFocus={(e) => handleDropdownButtonFocus(e, index)}
                                onKeyDown={(e) => onMainItemKeyDown(e, index)}>
                                {item.title}
                                <i className='fa fa-solid fa-angle-down ml-2'></i>
                              </button>
                            </NavLink>
                            <Dropdown.Menu
                              aria-labelledby='basic-navbar-nav'
                              id={`basic-navbar-navmenu-${index}`}
                              className={clsx(
                                'main-nav-dropdown-menu',
                                item.promote && 'has-sidebar'
                              )}>
                              <div className='dropdown-menu__row'>
                                <div className='dropdown-menu__column items'>
                                  <h5>
                                    <Link
                                      to={item.submenu[0]?.path}
                                      style={{ color: 'inherit' }}
                                      onClick={() => setDropShown(false)}>
                                      {item.title}
                                    </Link>
                                  </h5>
                                  <div className={`links len-${item.submenu.length}`}>
                                    {item.submenu.map((subItem, subIndex) => (
                                      <Dropdown.Item
                                        onBlur={handleMenuBlur}
                                        onClick={() => setFocusToNavLoseFocusSpan()}
                                        key={`basic-navbar-navitem-${index}-sub-${subIndex}`}
                                        id={`basic-navbar-navitem-${index}-sub-${subIndex}`}
                                        as={NavLink}
                                        to={subItem.path}
                                        tabIndex={-1}>
                                        <span>{subItem.subtitle}</span>
                                        {subItem.tag !== undefined && <sup>{subItem.tag}</sup>}
                                      </Dropdown.Item>
                                    ))}
                                  </div>
                                </div>
                                {item.promote && (
                                  <div className='dropdown-menu__column text'>
                                    <h5>
                                      {item.promote.title}{' '}
                                      {item.promote.titleTag && <sup>{item.promote.titleTag}</sup>}
                                    </h5>
                                    <p>{item.promote.description}</p>
                                    <LinkItem
                                      href={item.promote.uri}
                                      tabIndex='-1'
                                      rounded
                                      light
                                      onClick={() => setDropShown(false)}>
                                      Learn More
                                    </LinkItem>
                                  </div>
                                )}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    );
                  })}
                  <span id='nav-lose-focus-el' tabIndex='-1' ref={navLoseFocusSpanRef}></span>
                </Nav>
                <Box pl={2}>
                  <Button
                    className={clsx('search-toggle-btn', showSearch && 'is-close')}
                    aria-label='search'
                    alt='search button'
                    variant='text'
                    type='button'
                    onClick={() => setShowSearch((prevState) => !prevState)}>
                    {showSearch ? <CloseIcon /> : <SearchIcon />}
                  </Button>
                </Box>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </Box>
      )}
      {(isLessThanXL || showSearch) && (
        <Box className='search-row'>
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='search-input-group' controlId='search'>
                <Form.Label>Search</Form.Label>
                <Form.Control
                  className='search-input'
                  type='search'
                  aria-label='Search'
                  autoFocus
                  placeholder='Search...'
                  name='searchText'
                  ref={searchInputRef}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button variant='text' className='search-btn' type='submit'>
                  <span className='search-btn-label'>Search</span>
                  <SearchIcon />
                </Button>
              </Form.Group>
            </Form>
          </Container>
        </Box>
      )}
      {activeSection && (
        <SubNavigation
          menuData={menuData}
          section={activeSection}
          onFocus={(index) => checkAndSetDropShown(index)}
        />
      )}
    </>
  );
};

export default Navigation;
